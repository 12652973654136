"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VoucherDraft = void 0;
exports.VoucherDraft = {
    split: true,
    table: [
        'shortId',
        'value',
        'localizations',
        'provider',
        'providerConstraints',
        'limitationPeriod',
        'rebookAllowed',
        'saleLock',
        'characteristics',
        'faqs',
        'hints',
        'tradeTerms',
        'prices',
        'files',
        'maturityTypes',
        'generateSingleProduct',
        'group'
    ],
    form: [
        'id',
        'shortId',
        'reference',
        'owner',
        'ownerReference',
        'provider',
        'providerConstraints',
        'value',
        'limitationPeriod',
        'rebookAllowed',
        'saleLock',
        'localizations',
        'characteristics',
        'faqs',
        'hints',
        'tradeTerms',
        'prices',
        'files',
        'maturityTypes',
        'generateSingleProduct',
        'group'
    ]
};
