"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var samsa = tslib_1.__importStar(require("@samsa/samsa-api/js"));
var js_1 = require("@samsa/samsa-api/js");
var server_1 = require("react-dom/server");
var Diff = tslib_1.__importStar(require("diff"));
var isPseudoEnum_1 = require("../client/util/isPseudoEnum");
var isZoomable_1 = require("../client/util/isZoomable");
var buildConfig_1 = require("../client/util/buildConfig");
var getRenderValue = function (newValue, oldValue, type, t, mode) {
    var value;
    switch (mode) {
        case 'old':
            value = oldValue;
            break;
        case 'new':
            value = newValue;
            break;
    }
    // eslint-disable-next-line import/namespace
    var Type = samsa[type];
    if (typeof Type !== 'function') {
        Type = null;
    }
    if (!value) {
        return '';
    }
    else if (Type === js_1.LinkedFile) {
        value = new Type(value);
        return aurum_1.React.createElement(aurum_1.React.Fragment, null,
            aurum_1.React.createElement("img", { style: { width: '100%', maxHeight: '400px', objectFit: 'contain' }, src: (0, js_1.getUrl)(value.file, {
                    background: '#f6f6f6',
                    width: 500,
                    height: 400,
                    fit: 'inside'
                }) }),
            aurum_1.React.createElement("div", null, value.toString()));
    }
    else if (Type && ((0, isZoomable_1.isZoomable)(Type) || (0, isPseudoEnum_1.isPseudoEnum)(Type))) {
        value = new Type(value);
        return (value.toString());
    }
    else if (Type) {
        if (newValue && oldValue) {
            oldValue = new Type(oldValue);
            newValue = new Type(newValue);
            var diff = Diff.diffWordsWithSpace(oldValue.toString(), newValue.toString());
            var newValues_1 = [];
            var oldValues_1 = [];
            diff.forEach(function (part) {
                if (part.added) {
                    newValues_1.push(aurum_1.React.createElement("strong", { className: 'string-operation-add' }, part.value));
                }
                else if (part.removed) {
                    oldValues_1.push(aurum_1.React.createElement("strong", { className: 'string-operation-remove' }, part.value));
                }
                else {
                    newValues_1.push(part.value);
                    oldValues_1.push(part.value);
                }
            });
            var output = void 0;
            switch (mode) {
                case 'old':
                    output = oldValues_1;
                    break;
                case 'new':
                    output = newValues_1;
                    break;
            }
            var fields = (0, buildConfig_1.getFields)(Type, t, 'form');
            return aurum_1.React.createElement(aurum_1.Table, { striped: true },
                aurum_1.React.createElement("tr", null,
                    aurum_1.React.createElement("td", { colSpan: 2 },
                        aurum_1.React.createElement("strong", null, output))),
                fields.map(function (field) {
                    var _a, _b;
                    var oldValueString = ((_a = oldValue[field.name]) !== null && _a !== void 0 ? _a : '').toString();
                    var newValueString = ((_b = newValue[field.name]) !== null && _b !== void 0 ? _b : '').toString();
                    if (oldValueString === newValueString) {
                        return null;
                    }
                    var diff = Diff.diffWordsWithSpace(oldValueString, newValueString);
                    var newValues = [];
                    var oldValues = [];
                    diff.forEach(function (part) {
                        if (part.added) {
                            newValues.push(aurum_1.React.createElement("strong", { className: 'string-operation-add' }, part.value));
                        }
                        else if (part.removed) {
                            oldValues.push(aurum_1.React.createElement("strong", { className: 'string-operation-remove' }, part.value));
                        }
                        else {
                            newValues.push(part.value);
                            oldValues.push(part.value);
                        }
                    });
                    var output;
                    switch (mode) {
                        case 'old':
                            output = oldValues;
                            break;
                        case 'new':
                            output = newValues;
                            break;
                    }
                    return aurum_1.React.createElement("tr", { key: field.name },
                        aurum_1.React.createElement("td", null, field.title),
                        aurum_1.React.createElement("td", null, output));
                }));
        }
        else {
            value = new Type(value);
            var fields = (0, buildConfig_1.getFields)(Type, t, 'form');
            return aurum_1.React.createElement(aurum_1.Table, { striped: true },
                aurum_1.React.createElement("tr", null,
                    aurum_1.React.createElement("td", { colSpan: 2 },
                        aurum_1.React.createElement("strong", null, value.toString()))),
                fields.map(function (field) {
                    var _a;
                    return aurum_1.React.createElement("tr", { key: field.name },
                        aurum_1.React.createElement("td", null, field.title),
                        aurum_1.React.createElement("td", null, ((_a = value[field.name]) !== null && _a !== void 0 ? _a : '').toString()));
                }));
        }
    }
    else {
        return value.toString();
    }
};
var getRenderValues = function (entry, entryDiffFieldMap, t) {
    var _a, _b, _c, _d, _f, _g, _h;
    var _j = tslib_1.__read(entry.fieldPath.split('::'), 2), path = _j[0], id = _j[1];
    var _k = tslib_1.__read(path.split('.')), ignoredClassName = _k[0], pathParts = _k.slice(1);
    var field = (_b = (_a = entryDiffFieldMap[path]) === null || _a === void 0 ? void 0 : _a.field) !== null && _b !== void 0 ? _b : {};
    var type = (_d = (_c = field === null || field === void 0 ? void 0 : field.type) !== null && _c !== void 0 ? _c : entry.targetType) !== null && _d !== void 0 ? _d : null;
    var label = (_f = field.title) !== null && _f !== void 0 ? _f : pathParts.map(function (part) { return t("EntityAttribute.".concat(part)); }).join(' > ');
    var oldValue = (_g = getRenderValue(entry.newValue, entry.oldValue, type, t, 'old')) !== null && _g !== void 0 ? _g : '';
    var newValue = (_h = getRenderValue(entry.newValue, entry.oldValue, type, t, 'new')) !== null && _h !== void 0 ? _h : '';
    return {
        id: id,
        label: label,
        type: type,
        oldValue: oldValue,
        newValue: newValue
    };
};
var getDiffTable = function (filterfunc, entryDiffResult, entryDiffFieldMap, t) {
    if (filterfunc === void 0) { filterfunc = function (_e) { return true; }; }
    return entryDiffResult
        ? entryDiffResult.diff
            .filter(filterfunc)
            .sort(function (e1, e2) {
            var _a, _b;
            var _c = tslib_1.__read(e1.fieldPath.split('::'), 1), p1 = _c[0];
            var _d = tslib_1.__read(e2.fieldPath.split('::'), 1), p2 = _d[0];
            var s1 = (_a = entryDiffFieldMap[p1]) === null || _a === void 0 ? void 0 : _a.sort;
            var s2 = (_b = entryDiffFieldMap[p2]) === null || _b === void 0 ? void 0 : _b.sort;
            if (s1 !== s2) {
                return s1 - s2;
            }
            if (e1.operation !== e2.operation) {
                return e1.operation.localeCompare(e2.operation);
            }
            return e1.fieldPath.localeCompare(e2.fieldPath);
        })
            .map(function (e) {
            var _a, _b;
            var val = getRenderValues(e, entryDiffFieldMap, t);
            if (e.operation === js_1.EntityDiffEntry.enum.operation.CHANGE &&
                typeof val.oldValue === 'string' && typeof val.newValue === 'string') {
                if (val.oldValue === val.newValue) {
                    return null;
                }
                var diff = Diff.diffWordsWithSpace(val.oldValue, val.newValue);
                val.newValue = [];
                val.oldValue = [];
                diff.forEach(function (part) {
                    if (part.added) {
                        val.newValue.push(aurum_1.React.createElement("strong", { className: 'string-operation-add' }, part.value));
                    }
                    else if (part.removed) {
                        val.oldValue.push(aurum_1.React.createElement("strong", { className: 'string-operation-remove' }, part.value));
                    }
                    else {
                        val.newValue.push(part.value);
                        val.oldValue.push(part.value);
                    }
                });
            }
            if (e.operation === js_1.EntityDiffEntry.enum.operation.CHANGE &&
                (0, server_1.renderToString)(val.oldValue) === (0, server_1.renderToString)(val.newValue)) {
                return null;
            }
            return aurum_1.React.createElement("tr", { key: e.fieldPath },
                aurum_1.React.createElement("td", null,
                    aurum_1.React.createElement("strong", null, val.label),
                    aurum_1.React.createElement("div", { className: "operation-".concat(e.operation) }, e.operation)),
                ((_a = entryDiffResult === null || entryDiffResult === void 0 ? void 0 : entryDiffResult.oldEntity) === null || _a === void 0 ? void 0 : _a.id) ? aurum_1.React.createElement("td", null, val.oldValue) : null,
                ((_b = entryDiffResult === null || entryDiffResult === void 0 ? void 0 : entryDiffResult.newEntity) === null || _b === void 0 ? void 0 : _b.id) ? aurum_1.React.createElement("td", null, val.newValue) : null);
        })
            .filter(function (e) { return !!e; })
        : null;
};
exports.default = getDiffTable;
