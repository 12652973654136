"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OwnerPaymentSearchBar = exports.fetchDateTypeEnum = void 0;
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var js_1 = require("@samsa/samsa-api/js");
var useOnChange_1 = require("../../hook/useOnChange");
var fetchDateTypeEnum;
(function (fetchDateTypeEnum) {
    fetchDateTypeEnum["PAYMENT_DATE"] = "PAYMENT_DATE";
    fetchDateTypeEnum["BOOKING_DATE"] = "BOOKING_DATE";
    fetchDateTypeEnum["TRAVEL_DATE"] = "TRAVEL_DATE";
})(fetchDateTypeEnum = exports.fetchDateTypeEnum || (exports.fetchDateTypeEnum = {}));
function OwnerPaymentSearchBar(props) {
    var _this = this;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _a = tslib_1.__read((0, react_1.useState)([]), 2), owners = _a[0], setOwners = _a[1];
    var selectedOwners = (0, useOnChange_1.useOnChange)(props.initialSearchData.selectedOwners);
    var bookingShortId = (0, useOnChange_1.useOnChange)(props.initialSearchData.bookingShortId);
    var from = (0, useOnChange_1.useOnChange)(props.initialSearchData.from);
    var to = (0, useOnChange_1.useOnChange)(props.initialSearchData.to);
    var allFetchTypes = [fetchDateTypeEnum.PAYMENT_DATE, fetchDateTypeEnum.BOOKING_DATE, fetchDateTypeEnum.TRAVEL_DATE];
    var fetchDateType = (0, useOnChange_1.useOnChange)(props.initialSearchData.fetchDateType);
    var loadOwners = (0, react_1.useCallback)(function () {
        (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var r, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, js_1.Owner.getAll({
                                size: 0
                            })];
                    case 1:
                        r = _a.sent();
                        setOwners(r.content);
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    (0, react_1.useEffect)(loadOwners, []);
    function renderFilter() {
        return react_1.default.createElement(aurum_1.InputGroup, { className: 'ownerSelect' },
            react_1.default.createElement(aurum_1.Input, { label: t('EntityAttribute.bookingId'), value: bookingShortId.inputvalue, native: false, onChange: bookingShortId.onChange }),
            react_1.default.createElement(aurum_1.Input, { type: 'multiselect', label: t('Entity.Owner'), value: selectedOwners.inputvalue, native: false, options: owners.map(function (value) {
                    return react_1.default.createElement("option", { key: value.id, value: value.id, label: value.companyName });
                }), onChange: selectedOwners.onChange }));
    }
    function executeSearch() {
        if (props.searchCallback) {
            props.searchCallback({
                bookingShortId: bookingShortId.value,
                selectedOwners: selectedOwners.value,
                from: from.value,
                to: to.value,
                fetchDateType: fetchDateType.value
            });
        }
    }
    function renderDateSearchControl() {
        return react_1.default.createElement("div", null,
            react_1.default.createElement(aurum_1.Row, null,
                react_1.default.createElement(aurum_1.InputGroup, { className: 'dateSearchControl' },
                    react_1.default.createElement(aurum_1.Input, { type: 'date', label: t('EntityAttribute.fromDate'), value: from.inputvalue, native: false, onChange: from.onChange }),
                    react_1.default.createElement(aurum_1.Input, { type: 'date', label: t('EntityAttribute.toDate'), value: to.inputvalue, native: false, onChange: to.onChange }),
                    react_1.default.createElement(aurum_1.Input, { type: 'select', label: t('EntityAttribute.fetchDateType'), value: fetchDateType.inputvalue, native: false, options: allFetchTypes.map(function (value) {
                            return react_1.default.createElement("option", { key: value, value: value, label: t('Enum.' + value) });
                        }), onChange: fetchDateType.onChange }),
                    react_1.default.createElement(aurum_1.Button, { onClick: function () { return executeSearch(); } },
                        react_1.default.createElement(aurum_1.Icon, { type: 'search' }),
                        t('Controls.search')))));
    }
    return react_1.default.createElement("div", { className: 'search-bar' },
        react_1.default.createElement("div", null, renderFilter()),
        react_1.default.createElement("div", null, renderDateSearchControl()));
}
exports.OwnerPaymentSearchBar = OwnerPaymentSearchBar;
