"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var aurum_1 = require("@mdsi/aurum");
var hasChanged_1 = require("../../../util/hasChanged");
var BasicFormInput = function (props) {
    var _a;
    var type = props.field.form.isTextarea ? 'textarea' : 'text';
    var eventprop = 'value';
    var defaultvalue = '';
    var min;
    var step;
    var step;
    switch (props.field.type) {
        case 'FileRef':
            type = 'file';
            eventprop = 'file';
            break;
        case 'boolean':
            type = 'checkbox';
            eventprop = 'checked';
            defaultvalue = false;
            break;
        case 'integer':
            type = 'number';
            min = 0;
            step = 1;
            break;
        case 'number':
            type = 'number';
            min = 0;
            step = 0.01;
            break;
    }
    var unit;
    if (props.field.unit) {
        unit = aurum_1.React.createElement(aurum_1.Addon, null, props.field.unit);
    }
    var iconAddon;
    if (props.field.name === 'cssClass') {
        iconAddon = aurum_1.React.createElement(aurum_1.Addon, { className: 'icon-addon' },
            aurum_1.React.createElement("i", { className: props.value }));
    }
    var changed = (0, hasChanged_1.hasChanged)(props.value, props.initialValue);
    var classes = [props.field.name];
    var style = {};
    if (props.field.form.width === 'full') {
        classes.push('width-full');
    }
    else if (props.field.form.width && Number.isInteger(props.field.form.width)) {
        style['grid-column'] = "span ".concat(props.field.form.width);
    }
    if (props.field.form.height) {
        style['grid-row'] = "span ".concat(props.field.form.height);
    }
    var onChange = aurum_1.React.useCallback(function (_a) {
        var _b;
        var target = _a.target;
        var value = target[eventprop];
        var changed = (0, hasChanged_1.hasChanged)(value, props.initialValue);
        // eslint-disable-next-line no-unused-expressions
        (_b = props.onChange) === null || _b === void 0 ? void 0 : _b.call(props, value, changed);
    }, [props.onChange, eventprop]);
    var input = aurum_1.React.createElement(aurum_1.Input, { type: type, min: min, step: step, label: props.label, value: (_a = props.value) !== null && _a !== void 0 ? _a : defaultvalue, changed: changed, className: classes.join(' '), onChange: onChange, disabled: props.field.readOnly || props.field.form.disabled, optional: props.field.optional, validationMessages: props.errorData, style: style });
    return (unit || iconAddon)
        ? aurum_1.React.createElement(aurum_1.InputGroup, null,
            iconAddon,
            input,
            unit)
        : input;
};
exports.default = aurum_1.React.memo(BasicFormInput);
