"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmployeeStatus = void 0;
var EmployeeStatus;
(function (EmployeeStatus) {
    EmployeeStatus[EmployeeStatus["NONE"] = 0] = "NONE";
    EmployeeStatus[EmployeeStatus["INTERNAL"] = 1] = "INTERNAL";
    EmployeeStatus[EmployeeStatus["EXTERNAL"] = 2] = "EXTERNAL";
    EmployeeStatus[EmployeeStatus["UNDEFINED"] = 3] = "UNDEFINED";
})(EmployeeStatus = exports.EmployeeStatus || (exports.EmployeeStatus = {}));
