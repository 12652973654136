"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FaqLocalized = void 0;
exports.FaqLocalized = {
    split: true,
    form: [
        { name: 'question', isTextarea: false, width: 'full', show: true },
        { name: 'answer', isTextarea: true, width: 'full', show: true }
    ]
};
