"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var js_1 = require("@samsa/samsa-api/js");
var react_1 = require("react");
var TransportationListingBookableServiceTypesFilter_1 = require("@samsa/samsa-api/js/class/TransportationListingBookableServiceTypesFilter");
var useOnChange_1 = require("../hook/useOnChange");
var dateUtils_1 = require("../util/dateUtils");
var formatPrice_1 = require("../util/formatPrice");
var TransportationListingTest = function () {
    var _a, _b, _c, _d, _f, _g, _h, _j, _k;
    var _l = tslib_1.__read(aurum_1.React.useState([]), 2), destinations = _l[0], setDestinations = _l[1];
    var _m = tslib_1.__read(aurum_1.React.useState(null), 2), destination = _m[0], setDestination = _m[1];
    var _o = tslib_1.__read(aurum_1.React.useState([]), 2), departureStations = _o[0], setDepartureStations = _o[1];
    var _p = tslib_1.__read(aurum_1.React.useState(null), 2), departureStation = _p[0], setDepartureStation = _p[1];
    var _q = tslib_1.__read(aurum_1.React.useState(null), 2), departureDestination = _q[0], setDepartureDestination = _q[1];
    var _r = tslib_1.__read(aurum_1.React.useState([]), 2), destinationStations = _r[0], setDestinationStations = _r[1];
    var _s = tslib_1.__read(aurum_1.React.useState(null), 2), destinationStation = _s[0], setDestinationStation = _s[1];
    var _t = tslib_1.__read(aurum_1.React.useState(null), 2), destinationDestination = _t[0], setDestinationDestination = _t[1];
    aurum_1.React.useEffect(function () {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var destinations;
            var _a, _b, _c, _d, _f;
            return tslib_1.__generator(this, function (_g) {
                switch (_g.label) {
                    case 0: return [4 /*yield*/, js_1.Destination.getAll({ size: 0 })];
                    case 1:
                        destinations = _g.sent();
                        setDestinations((_a = destinations.content) !== null && _a !== void 0 ? _a : []);
                        setDepartureDestination((_c = departureDestination !== null && departureDestination !== void 0 ? departureDestination : (_b = destinations.content) === null || _b === void 0 ? void 0 : _b.find(function (d) { return d.id === 'NORDDEICH'; })) !== null && _c !== void 0 ? _c : null);
                        setDestinationDestination((_f = destinationDestination !== null && destinationDestination !== void 0 ? destinationDestination : (_d = destinations.content) === null || _d === void 0 ? void 0 : _d.find(function (d) { return d.id === 'NORDERNEY'; })) !== null && _f !== void 0 ? _f : null);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    aurum_1.React.useEffect(function () {
        var cancelToken = (0, js_1.getCancelToken)();
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var result, departureStations, destinationStations;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, js_1.TransportationListing.getStations(new js_1.TransportationListingStationsFilter({
                            destinations: destination ? [destination.id] : undefined
                        }), {
                            cancelToken: cancelToken.token
                        })];
                    case 1:
                        result = _a.sent();
                        departureStations = [];
                        destinationStations = [];
                        result.forEach(function (r) {
                            if (!departureStations.find(function (s) { var _a; return s.id === ((_a = r.departureStation) === null || _a === void 0 ? void 0 : _a.id); })) {
                                departureStations.push(r.departureStation);
                            }
                            if (!destinationStations.find(function (s) { var _a; return s.id === ((_a = r.destinationStation) === null || _a === void 0 ? void 0 : _a.id); })) {
                                destinationStations.push(r.destinationStation);
                            }
                        });
                        setDepartureStations(departureStations);
                        setDestinationStations(destinationStations);
                        return [2 /*return*/];
                }
            });
        }); })();
        return cancelToken.cancel;
    }, [destination]);
    var temp = new Date();
    temp.setTime(Date.now() + (1 * 24 * 60 * 60 * 1000));
    var initFrom = temp.toISOString().substr(0, 10);
    temp.setTime(Date.now() + (2 * 24 * 60 * 60 * 1000));
    var initTo = temp.toISOString().substr(0, 10);
    var _u = (0, useOnChange_1.useOnChange)(initFrom + 'T00:00'), travelDateFrom = _u.value, changeTravelDateFrom = _u.onChange;
    var _v = (0, useOnChange_1.useOnChange)(initFrom + 'T23:59'), travelDateTo = _v.value, changeTravelDateTo = _v.onChange;
    var _w = (0, useOnChange_1.useOnChange)(initTo + 'T00:00'), travelBackDateFrom = _w.value, changeTravelBackDateFrom = _w.onChange;
    var _x = (0, useOnChange_1.useOnChange)(initTo + 'T23:59'), travelBackDateTo = _x.value, changeTravelBackDateTo = _x.onChange;
    var _y = tslib_1.__read((0, react_1.useState)(), 2), priceCategory = _y[0], setPriceCategory = _y[1];
    var _z = tslib_1.__read((0, react_1.useState)(), 2), priceCategoryBack = _z[0], setPriceCategoryBack = _z[1];
    var _0 = tslib_1.__read(aurum_1.React.useState(null), 2), trips = _0[0], setTrips = _0[1];
    var _1 = tslib_1.__read(aurum_1.React.useState(null), 2), trip = _1[0], setTrip = _1[1];
    var _2 = tslib_1.__read(aurum_1.React.useState(null), 2), tripBack = _2[0], setTripBack = _2[1];
    aurum_1.React.useEffect(function () {
        var cancelToken = (0, js_1.getCancelToken)();
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var result, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, js_1.TransportationListing.getTrips(new js_1.TransportationListingTripsFilter({
                                departureDestinationId: departureDestination === null || departureDestination === void 0 ? void 0 : departureDestination.id,
                                destinationDestinationId: destinationDestination === null || destinationDestination === void 0 ? void 0 : destinationDestination.id,
                                departureStationId: departureStation === null || departureStation === void 0 ? void 0 : departureStation.id,
                                destinationStationId: destinationStation === null || destinationStation === void 0 ? void 0 : destinationStation.id,
                                dateFrom: travelDateFrom ? travelDateFrom + '+02:00' : null,
                                dateTo: travelDateTo ? travelDateTo + '+02:00' : null,
                                dateBackFrom: travelBackDateFrom ? travelBackDateFrom + '+02:00' : null,
                                dateBackTo: travelBackDateTo ? travelBackDateTo + '+02:00' : null,
                                infoServiceTypes: [null],
                                infoPrivilegeTypes: [null],
                                showNotBookable: true
                            }), {
                                cancelToken: cancelToken.token
                            })];
                    case 1:
                        result = _a.sent();
                        setTrips(result);
                        setTrip(result.trips[0]);
                        setTripBack(null);
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        setTrips(null);
                        setTrip(null);
                        setTripBack(null);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); })();
        return cancelToken.cancel;
    }, [departureStation, destinationStation, departureDestination, destinationDestination,
        travelDateFrom, travelDateTo, travelBackDateFrom, travelBackDateTo]);
    var _3 = tslib_1.__read(aurum_1.React.useState([]), 2), serviceTypes = _3[0], setServiceTypes = _3[1];
    aurum_1.React.useEffect(function () {
        var cancelToken = (0, js_1.getCancelToken)();
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var bookableServiceTypes, result, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, js_1.TransportationListing.getBookableServiceTypes(new TransportationListingBookableServiceTypesFilter_1.TransportationListingBookableServiceTypesFilter({
                                tripId: trip === null || trip === void 0 ? void 0 : trip.id,
                                tripBackId: tripBack === null || tripBack === void 0 ? void 0 : tripBack.id,
                                priceCategory: priceCategory,
                                priceCategoryBack: priceCategoryBack
                            }))];
                    case 1:
                        bookableServiceTypes = _a.sent();
                        console.log('bookableServiceTypes', bookableServiceTypes);
                        return [4 /*yield*/, js_1.TransportationListing.getServiceTypes(new js_1.TransportationListingServiceTypesFilter({
                                tripId: trip === null || trip === void 0 ? void 0 : trip.id,
                                tripBackId: tripBack === null || tripBack === void 0 ? void 0 : tripBack.id,
                                positions: [
                                    new js_1.TransportationListingEntry({
                                        ref: 'person',
                                        priceCategory: priceCategory,
                                        priceCategoryBack: priceCategoryBack
                                    }),
                                    new js_1.TransportationListingEntry({
                                        ref: 'adult1',
                                        guest: new js_1.Person({ ref: 'adult1' }),
                                        priceCategory: priceCategory,
                                        priceCategoryBack: priceCategoryBack
                                    }),
                                    // new TransportationListingEntry({
                                    //   ref: 'adult2',
                                    //   guest: new Person(),
                                    //   priceCategory,
                                    //   priceCategoryBack
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'adult3',
                                    //   guest: new Person(),
                                    //   priceCategory,
                                    //   priceCategoryBack
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'child1',
                                    //   guest: new Person({
                                    //     age: 9
                                    //   }),
                                    //   priceCategory,
                                    //   priceCategoryBack
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'child2',
                                    //   guest: new Person({
                                    //     age: 8
                                    //   }),
                                    //   priceCategory,
                                    //   priceCategoryBack
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'child3',
                                    //   guest: new Person({
                                    //     age: 7
                                    //   }),
                                    //   priceCategory,
                                    //   priceCategoryBack
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'child4',
                                    //   guest: new Person({
                                    //     age: 6
                                    //   }),
                                    //   priceCategory,
                                    //   priceCategoryBack
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'dog1',
                                    //   serviceTypeId: 'DOG',
                                    //   priceCategory,
                                    //   priceCategoryBack
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'person_HAUPTDECK/HAUPTDECK',
                                    //   guest: new Person(),
                                    //   priceCategory: 'HAUPTDECK',
                                    //   priceCategoryBack: 'HAUPTDECK'
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'person_OBERDECK/OBERDECK',
                                    //   guest: new Person(),
                                    //   priceCategory: 'OBERDECK',
                                    //   priceCategoryBack: 'OBERDECK'
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'person_HAUPTDECK/NULL',
                                    //   guest: new Person(),
                                    //   priceCategory: 'HAUPTDECK'
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'person_OBERDECK/NULL',
                                    //   guest: new Person(),
                                    //   priceCategory: 'OBERDECK'
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'person_HAUPTDECK/OBERDECK',
                                    //   guest: new Person(),
                                    //   priceCategory: 'HAUPTDECK',
                                    //   priceCategoryBack: 'OBERDECK'
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'person_OBERDECK/HAUPTDECK',
                                    //   guest: new Person(),
                                    //   priceCategory: 'OBERDECK',
                                    //   priceCategoryBack: 'HAUPTDECK'
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'person1',
                                    //   guest: new Person()
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'person2',
                                    //   guest: new Person({
                                    //     age: 15
                                    //   })
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'person3',
                                    //   guest: new Person({
                                    //     age: 9
                                    //   })
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'person4',
                                    //   guest: new Person({
                                    //     age: 3
                                    //   })
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'person5',
                                    //   serviceTypeId: 'ADULT',
                                    //   guest: new Person({
                                    //     age: 3
                                    //   })
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'person6',
                                    //   serviceTypeId: 'CHILD',
                                    //   guest: new Person()
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'norderney1',
                                    //   guest: new Person({
                                    //     privilegeTypeId: 'ISLANDER',
                                    //     address: new Address({
                                    //       postalCode: '26548'
                                    //     })
                                    //   }),
                                    //   privilegeTypeId: 'ISLANDER'
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'norderney2',
                                    //   guest: new Person({
                                    //     age: 15,
                                    //     privilegeTypeId: 'ISLANDER',
                                    //     address: new Address({
                                    //       postalCode: '26548'
                                    //     })
                                    //   }),
                                    //   privilegeTypeId: 'ISLANDER'
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'norderney3',
                                    //   guest: new Person({
                                    //     age: 9,
                                    //     privilegeTypeId: 'ISLANDER',
                                    //     address: new Address({
                                    //       postalCode: '26548'
                                    //     })
                                    //   }),
                                    //   privilegeTypeId: 'ISLANDER'
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'juist1',
                                    //   guest: new Person({
                                    //     privilegeTypeId: 'ISLANDER',
                                    //     address: new Address({
                                    //       postalCode: '26571'
                                    //     })
                                    //   }),
                                    //   privilegeTypeId: 'ISLANDER'
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'juist2',
                                    //   guest: new Person({
                                    //     age: 15,
                                    //     privilegeTypeId: 'ISLANDER',
                                    //     address: new Address({
                                    //       postalCode: '26571'
                                    //     })
                                    //   }),
                                    //   privilegeTypeId: 'ISLANDER'
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'juist3',
                                    //   guest: new Person({
                                    //     age: 9,
                                    //     privilegeTypeId: 'ISLANDER',
                                    //     address: new Address({
                                    //       postalCode: '26571'
                                    //     })
                                    //   }),
                                    //   privilegeTypeId: 'ISLANDER'
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'dog',
                                    //   serviceTypeId: 'DOG'
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'bicycle',
                                    //   serviceTypeId: 'BICYCLE'
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'car1',
                                    //   serviceTypeId: 'CAR',
                                    //   vehicle: new Vehicle({
                                    //     length: 4100
                                    //   })
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'car2',
                                    //   serviceTypeId: 'CAR',
                                    //   vehicle: new Vehicle({
                                    //     length: 4600
                                    //   })
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'car3',
                                    //   serviceTypeId: 'CAR',
                                    //   vehicle: new Vehicle({
                                    //     customerVehicleId: 'fd711178-c93d-4948-95e2-afcb0e7fee1c',
                                    //     licensePlate: 'Volkswagen',
                                    //     length: 5100
                                    //   })
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'car-norderney',
                                    //   serviceTypeId: 'CAR',
                                    //   vehicle: new Vehicle({
                                    //     length: 4450,
                                    //     weight: 1900,
                                    //     exessWidth: false
                                    //   }),
                                    //   guest: new Person({
                                    //     privilegeTypeId: 'ISLANDER',
                                    //     address: new Address({
                                    //       postalCode: '26548'
                                    //     })
                                    //   }),
                                    //   privilegeTypeId: 'ISLANDER'
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'car-juist',
                                    //   serviceTypeId: 'CAR',
                                    //   vehicle: new Vehicle({
                                    //     length: 4450,
                                    //     weight: 1900,
                                    //     exessWidth: false
                                    //   }),
                                    //   guest: new Person({
                                    //     privilegeTypeId: 'ISLANDER',
                                    //     address: new Address({
                                    //       postalCode: '26571'
                                    //     })
                                    //   }),
                                    //   privilegeTypeId: 'ISLANDER'
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'car-exess-width',
                                    //   serviceTypeId: 'CAR',
                                    //   vehicle: new Vehicle({
                                    //     length: 4450,
                                    //     weight: 1900,
                                    //     exessWidth: true
                                    //   })
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'test3',
                                    //   serviceTypeId: 'CAR',
                                    //   vehicle: new Vehicle({
                                    //     length: 4450,
                                    //     weight: 1900
                                    //   })
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'test4',
                                    //   serviceTypeId: 'CAMPER',
                                    //   vehicle: new Vehicle({
                                    //     length: 8000,
                                    //     weight: 10000
                                    //   })
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'test5',
                                    //   serviceTypeId: 'CAMPER',
                                    //   vehicle: new Vehicle({
                                    //     length: 12000,
                                    //     weight: 10000
                                    //   })
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'test6',
                                    //   serviceTypeId: 'TRUCK',
                                    //   vehicle: new Vehicle({
                                    //     length: 8000,
                                    //     weight: 10000
                                    //   })
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'test7',
                                    //   serviceTypeId: 'TRUCK',
                                    //   vehicle: new Vehicle({
                                    //     length: 12000,
                                    //     weight: 10000
                                    //   })
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'test8',
                                    //   serviceTypeId: 'TRUCKTRAILER',
                                    //   vehicle: new Vehicle({
                                    //     length: 8000,
                                    //     weight: 10000
                                    //   })
                                    // }),
                                    // new TransportationListingEntry({
                                    //   ref: 'test9',
                                    //   serviceTypeId: 'TRUCKTRAILER',
                                    //   vehicle: new Vehicle({
                                    //     length: 12000,
                                    //     weight: 10000
                                    //   })
                                    // })
                                ]
                            }), {
                                cancelToken: cancelToken.token
                            })];
                    case 2:
                        result = _a.sent();
                        setServiceTypes(result.positions);
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        console.log('Nix gefunden');
                        setServiceTypes(null);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); })();
        return cancelToken.cancel;
    }, [trip, tripBack, priceCategory, priceCategoryBack]);
    var tripToString = function (e) {
        var _a, _b, _c, _d, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        var vehicleType = (_g = (_f = (_d = (_c = (_b = (_a = e.transportationTour) === null || _a === void 0 ? void 0 : _a.vehicle) === null || _b === void 0 ? void 0 : _b.vehicleType) === null || _c === void 0 ? void 0 : _c.localizations) === null || _d === void 0 ? void 0 : _d.de) === null || _f === void 0 ? void 0 : _f.title) !== null && _g !== void 0 ? _g : (_j = (_h = e.transportationTour) === null || _h === void 0 ? void 0 : _h.vehicleType) === null || _j === void 0 ? void 0 : _j.localizations.de.title;
        var departure = ((_k = e.transportationTour) === null || _k === void 0 ? void 0 : _k.allDay) ? 'ganztägig' : (0, dateUtils_1.formatTime)(e.departure);
        var departureStation = (_m = (_l = departureStations === null || departureStations === void 0 ? void 0 : departureStations.find) === null || _l === void 0 ? void 0 : _l.call(departureStations, function (d) { return d.id === e.departureStationId; })) === null || _m === void 0 ? void 0 : _m.localizations.de.title;
        var destinationStation = (_p = (_o = destinationStations === null || destinationStations === void 0 ? void 0 : destinationStations.find) === null || _o === void 0 ? void 0 : _o.call(destinationStations, function (d) { return d.id === e.destinationStationId; })) === null || _p === void 0 ? void 0 : _p.localizations.de.title;
        return {
            value: (_q = e.transportationTour) === null || _q === void 0 ? void 0 : _q.id,
            label: "".concat((0, dateUtils_1.formatDateShort)((_r = e.transportationTour) === null || _r === void 0 ? void 0 : _r.departureDate), " ").concat(departure, " ").concat(vehicleType, " ").concat(departureStation, "-").concat(destinationStation, " ").concat(null /* e?.capacities?.map((c) =>
              `${c.free}/${c.total} ${c.unit}`).join(', ') */)
        };
    };
    return aurum_1.React.createElement("div", { className: 'TransportationListingTest' },
        aurum_1.React.createElement(aurum_1.Row, { align: 'start' },
            aurum_1.React.createElement("div", null,
                aurum_1.React.createElement(aurum_1.Sheet, null,
                    aurum_1.React.createElement("h2", null, "1. (optional) Destination w\u00E4hlen (f\u00FCr Landingpage)"),
                    aurum_1.React.createElement(aurum_1.Select, { label: 'Destination', native: false, value: (_a = destination === null || destination === void 0 ? void 0 : destination.id) !== null && _a !== void 0 ? _a : '', options: tslib_1.__spreadArray([null], tslib_1.__read(destinations.map(function (e) { return ({
                            value: e.id,
                            label: e.localizations.de.title
                        }); })), false), onChange: function (e) {
                            var _a;
                            setDestination((_a = destinations.find(function (r) { return r.id === e.target.value; })) !== null && _a !== void 0 ? _a : null);
                        } }),
                    aurum_1.React.createElement("pre", null, JSON.stringify(destination, null, 2))),
                aurum_1.React.createElement(aurum_1.Sheet, null,
                    aurum_1.React.createElement("h2", null, "2. Start- und Zieldestinationen oder -h\u00E4fen w\u00E4hlen"),
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement(aurum_1.Select, { label: 'Startdestination', native: false, value: (_b = departureDestination === null || departureDestination === void 0 ? void 0 : departureDestination.id) !== null && _b !== void 0 ? _b : '', options: tslib_1.__spreadArray([null], tslib_1.__read(destinations.map(function (e) { return ({
                                value: e.id,
                                label: e.localizations.de.title
                            }); })), false), onChange: function (e) {
                                var _a;
                                setDepartureDestination((_a = destinations.find(function (r) { return r.id === e.target.value; })) !== null && _a !== void 0 ? _a : null);
                            } }),
                        aurum_1.React.createElement(aurum_1.Select, { label: 'Startdestination', native: false, value: (_c = destinationDestination === null || destinationDestination === void 0 ? void 0 : destinationDestination.id) !== null && _c !== void 0 ? _c : '', options: tslib_1.__spreadArray([null], tslib_1.__read(destinations.map(function (e) { return ({
                                value: e.id,
                                label: e.localizations.de.title
                            }); })), false), onChange: function (e) {
                                var _a;
                                setDestinationDestination((_a = destinations.find(function (r) { return r.id === e.target.value; })) !== null && _a !== void 0 ? _a : null);
                            } })),
                    aurum_1.React.createElement(aurum_1.Row, { align: 'start' },
                        aurum_1.React.createElement("pre", null, JSON.stringify(departureDestination, null, 2)),
                        aurum_1.React.createElement("pre", null, JSON.stringify(destinationDestination, null, 2))),
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement(aurum_1.Select, { label: 'Starthafen', native: false, value: (_d = departureStation === null || departureStation === void 0 ? void 0 : departureStation.id) !== null && _d !== void 0 ? _d : '', options: tslib_1.__spreadArray([null], tslib_1.__read(departureStations.map(function (e) { return ({
                                value: e.id,
                                label: e.localizations.de.title
                            }); })), false), onChange: function (e) {
                                var _a;
                                setDepartureStation((_a = departureStations.find(function (r) { return r.id === e.target.value; })) !== null && _a !== void 0 ? _a : null);
                            } }),
                        aurum_1.React.createElement(aurum_1.Select, { label: 'Zielhafen', native: false, value: (_f = destinationStation === null || destinationStation === void 0 ? void 0 : destinationStation.id) !== null && _f !== void 0 ? _f : '', options: tslib_1.__spreadArray([null], tslib_1.__read(destinationStations.map(function (e) { return ({
                                value: e.id,
                                label: e.localizations.de.title
                            }); })), false), onChange: function (e) {
                                var _a;
                                setDestinationStation((_a = destinationStations.find(function (r) { return r.id === e.target.value; })) !== null && _a !== void 0 ? _a : null);
                            } })),
                    aurum_1.React.createElement(aurum_1.Row, { align: 'start' },
                        aurum_1.React.createElement("pre", null, JSON.stringify(departureStation, null, 2)),
                        aurum_1.React.createElement("pre", null, JSON.stringify(destinationStation, null, 2)))),
                aurum_1.React.createElement(aurum_1.Sheet, null,
                    aurum_1.React.createElement("h2", null, "3. Hin- und R\u00FCckdatum w\u00E4hlen"),
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement(aurum_1.Input, { type: 'datetime-local', label: 'Hindatum von', value: travelDateFrom, onChange: changeTravelDateFrom }),
                        aurum_1.React.createElement(aurum_1.Input, { type: 'datetime-local', label: 'R\u00FCckdatum von', value: travelBackDateFrom, onChange: changeTravelBackDateFrom })),
                    aurum_1.React.createElement(aurum_1.Row, { align: 'start' },
                        aurum_1.React.createElement("pre", null, JSON.stringify(travelDateFrom, null, 2)),
                        aurum_1.React.createElement("pre", null, JSON.stringify(travelBackDateFrom, null, 2))),
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement(aurum_1.Input, { type: 'datetime-local', label: 'Hindatum bis', value: travelDateTo, onChange: changeTravelDateTo }),
                        aurum_1.React.createElement(aurum_1.Input, { type: 'datetime-local', label: 'R\u00FCckdatum bis', value: travelBackDateTo, onChange: changeTravelBackDateTo })),
                    aurum_1.React.createElement(aurum_1.Row, { align: 'start' },
                        aurum_1.React.createElement("pre", null, JSON.stringify(travelDateTo, null, 2)),
                        aurum_1.React.createElement("pre", null, JSON.stringify(travelBackDateTo, null, 2)))),
                aurum_1.React.createElement(aurum_1.Sheet, null,
                    aurum_1.React.createElement("h2", null, "4. Fahrt(en) w\u00E4hlen"),
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement(aurum_1.Select, { label: 'Start', native: false, value: (_g = trip === null || trip === void 0 ? void 0 : trip.transportationTour.id) !== null && _g !== void 0 ? _g : '', options: trips === null || trips === void 0 ? void 0 : trips.trips.map(tripToString), onChange: function (e) {
                                var _a;
                                setTrip((_a = trips === null || trips === void 0 ? void 0 : trips.trips.find(function (r) { return r.transportationTour.id === e.target.value; })) !== null && _a !== void 0 ? _a : null);
                            } }),
                        aurum_1.React.createElement(aurum_1.Select, { label: 'Ziel', native: false, value: (_h = tripBack === null || tripBack === void 0 ? void 0 : tripBack.transportationTour.id) !== null && _h !== void 0 ? _h : '', options: (_j = trips === null || trips === void 0 ? void 0 : trips.tripsBack) === null || _j === void 0 ? void 0 : _j.map(tripToString), onChange: function (e) {
                                var _a;
                                setTripBack((_a = trips === null || trips === void 0 ? void 0 : trips.tripsBack.find(function (r) { return r.transportationTour.id === e.target.value; })) !== null && _a !== void 0 ? _a : null);
                            } })),
                    aurum_1.React.createElement(aurum_1.Row, { align: 'start' },
                        aurum_1.React.createElement("pre", null, JSON.stringify(trip, null, 2)),
                        aurum_1.React.createElement("pre", null, JSON.stringify(tripBack, null, 2))))),
            aurum_1.React.createElement("div", null,
                aurum_1.React.createElement(aurum_1.Sheet, null,
                    aurum_1.React.createElement("h2", null, "5. Deck w\u00E4hlen"),
                    aurum_1.React.createElement(aurum_1.Row, null, trip
                        ? tslib_1.__spreadArray([trip.defaultPriceCategoryInfo], tslib_1.__read(trip.priceCategoryInfo), false).filter(function (pc) { return pc != null; }).map(function (pc) {
                            return (aurum_1.React.createElement(aurum_1.Button, { key: pc.id, type: pc.id === priceCategory ? 'primary' : undefined, onClick: function () { return setPriceCategory(pc.id); } }, pc.id));
                        })
                        : 'Fahrt auswählen'),
                    tripBack
                        ? aurum_1.React.createElement(aurum_1.Row, null, tslib_1.__spreadArray([tripBack.defaultPriceCategoryInfo], tslib_1.__read(tripBack.priceCategoryInfo), false).filter(function (pc) { return pc != null; }).map(function (pc) {
                            return (aurum_1.React.createElement(aurum_1.Button, { key: pc.id, type: pc.id === priceCategoryBack ? 'primary' : undefined, onClick: function () { return setPriceCategoryBack(pc.id); } }, pc.id));
                        }))
                        : null),
                aurum_1.React.createElement(aurum_1.Sheet, null,
                    aurum_1.React.createElement("h2", null, "6. Leistung(en) w\u00E4hlen"),
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement("div", null,
                            "Leistungstyp",
                            aurum_1.React.createElement("br", null),
                            "Preiskennz."),
                        aurum_1.React.createElement("div", null, "hin"),
                        aurum_1.React.createElement("div", null, "r\u00FCck"),
                        aurum_1.React.createElement("div", null, "kombiniert"),
                        aurum_1.React.createElement("div", null, "G\u00E4stebeitrag")), (_k = serviceTypes === null || serviceTypes === void 0 ? void 0 : serviceTypes.map) === null || _k === void 0 ? void 0 :
                    _k.call(serviceTypes, function (serviceType) {
                        var _a, _b, _c, _d, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6;
                        return aurum_1.React.createElement(aurum_1.React.Fragment, null,
                            aurum_1.React.createElement(aurum_1.Row, null,
                                aurum_1.React.createElement("div", null,
                                    serviceType.ref ? aurum_1.React.createElement("h4", null, serviceType.ref) : null,
                                    "Service-Typ: ", (_b = (_a = serviceType.serviceType) === null || _a === void 0 ? void 0 : _a.localizations.de.title) !== null && _b !== void 0 ? _b : '-',
                                    aurum_1.React.createElement("br", null),
                                    "Alter: ", (_g = (_f = (_d = (_c = serviceType.defaultBookable) === null || _c === void 0 ? void 0 : _c.productRequest.features[0]) === null || _d === void 0 ? void 0 : _d.guest) === null || _f === void 0 ? void 0 : _f.age) !== null && _g !== void 0 ? _g : '-',
                                    aurum_1.React.createElement("br", null)),
                                aurum_1.React.createElement("div", null,
                                    (0, formatPrice_1.formatPrice)((_j = (_h = serviceType === null || serviceType === void 0 ? void 0 : serviceType.defaultBookable) === null || _h === void 0 ? void 0 : _h.price) === null || _j === void 0 ? void 0 : _j.price),
                                    aurum_1.React.createElement("br", null), (_m = (_l = (_k = serviceType === null || serviceType === void 0 ? void 0 : serviceType.defaultBookable) === null || _k === void 0 ? void 0 : _k.localizations) === null || _l === void 0 ? void 0 : _l.de) === null || _m === void 0 ? void 0 :
                                    _m.title,
                                    aurum_1.React.createElement("br", null), (_o = serviceType === null || serviceType === void 0 ? void 0 : serviceType.defaultBookable) === null || _o === void 0 ? void 0 :
                                    _o.free),
                                aurum_1.React.createElement("div", null,
                                    (0, formatPrice_1.formatPrice)((_q = (_p = serviceType === null || serviceType === void 0 ? void 0 : serviceType.defaultBookableBack) === null || _p === void 0 ? void 0 : _p.price) === null || _q === void 0 ? void 0 : _q.price),
                                    aurum_1.React.createElement("br", null), (_t = (_s = (_r = serviceType === null || serviceType === void 0 ? void 0 : serviceType.defaultBookableBack) === null || _r === void 0 ? void 0 : _r.localizations) === null || _s === void 0 ? void 0 : _s.de) === null || _t === void 0 ? void 0 :
                                    _t.title,
                                    aurum_1.React.createElement("br", null), (_u = serviceType === null || serviceType === void 0 ? void 0 : serviceType.defaultBookableBack) === null || _u === void 0 ? void 0 :
                                    _u.free),
                                aurum_1.React.createElement("div", null,
                                    (0, formatPrice_1.formatPrice)((_w = (_v = serviceType === null || serviceType === void 0 ? void 0 : serviceType.defaultBookableCombined) === null || _v === void 0 ? void 0 : _v.price) === null || _w === void 0 ? void 0 : _w.price),
                                    aurum_1.React.createElement("br", null), (_z = (_y = (_x = serviceType === null || serviceType === void 0 ? void 0 : serviceType.defaultBookableCombined) === null || _x === void 0 ? void 0 : _x.localizations) === null || _y === void 0 ? void 0 : _y.de) === null || _z === void 0 ? void 0 :
                                    _z.title,
                                    aurum_1.React.createElement("br", null), (_0 = serviceType === null || serviceType === void 0 ? void 0 : serviceType.defaultBookableCombined) === null || _0 === void 0 ? void 0 :
                                    _0.free),
                                aurum_1.React.createElement("div", null,
                                    (0, formatPrice_1.formatPrice)((_2 = (_1 = serviceType === null || serviceType === void 0 ? void 0 : serviceType.defaultBookableVisitorTax) === null || _1 === void 0 ? void 0 : _1.price) === null || _2 === void 0 ? void 0 : _2.price),
                                    aurum_1.React.createElement("br", null), (_6 = (_5 = (_4 = (_3 = serviceType === null || serviceType === void 0 ? void 0 : serviceType.bookablesVisitorTax) === null || _3 === void 0 ? void 0 : _3.map) === null || _4 === void 0 ? void 0 : _4.call(_3, function (e) { var _a, _b; return (_b = (_a = e === null || e === void 0 ? void 0 : e.localizations) === null || _a === void 0 ? void 0 : _a.de) === null || _b === void 0 ? void 0 : _b.title; })) === null || _5 === void 0 ? void 0 : _5.join) === null || _6 === void 0 ? void 0 :
                                    _6.call(_5, ', '))));
                    }),
                    aurum_1.React.createElement("pre", null, JSON.stringify(serviceTypes, null, 2))),
                aurum_1.React.createElement(aurum_1.Sheet, null,
                    aurum_1.React.createElement("h2", null, "7. Buchung"),
                    aurum_1.React.createElement(aurum_1.Button, { onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                            var booking, payment;
                            var _a, _b, _c;
                            return tslib_1.__generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0: return [4 /*yield*/, js_1.Booking.create(new js_1.BookingRequest({
                                            noConfirmation: true,
                                            customer: new js_1.BookingCustomer({
                                                companyName: 'MDSI Test',
                                                givenName: 'Udo',
                                                middleName: 'Peter',
                                                familyName: 'Gerdes',
                                                email: 'u.gerdes@mdsi.de',
                                                phone: '555-NASE'
                                            }),
                                            productRequests: serviceTypes.map(function (st) { var _a; return (_a = st.defaultBookable) === null || _a === void 0 ? void 0 : _a.productRequest; }).filter(function (e) { return e != null; })
                                        }))];
                                    case 1:
                                        booking = _d.sent();
                                        console.log('BOOKING', (_c = (_b = (_a = booking === null || booking === void 0 ? void 0 : booking.custom) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.r2ref) === null || _c === void 0 ? void 0 : _c.reservid, booking);
                                        if (!window.confirm('auch zahlen?')) return [3 /*break*/, 3];
                                        return [4 /*yield*/, js_1.Booking.payDirect2({
                                                bookingId: booking.id,
                                                paymentRequest: new js_1.PaymentRequest({
                                                    paymentType: js_1.PaymentRequest.enum.paymentType.PAYPAL,
                                                    returnUrls: new js_1.PaymentReturnUrls()
                                                })
                                            }, {
                                                timeout: 300000
                                            })];
                                    case 2:
                                        payment = _d.sent();
                                        console.log('PAYMENT', payment);
                                        _d.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); } }, "alle Hinfahrten"),
                    aurum_1.React.createElement(aurum_1.Button, { onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                            var booking, payment;
                            var _a, _b, _c;
                            return tslib_1.__generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0: return [4 /*yield*/, js_1.Booking.create(new js_1.BookingRequest({
                                            noConfirmation: true,
                                            customer: new js_1.BookingCustomer({
                                                companyName: 'MDSI Test',
                                                givenName: 'Udo',
                                                middleName: 'Peter',
                                                familyName: 'Gerdes',
                                                email: 'u.gerdes@mdsi.de',
                                                phone: '555-NASE'
                                            }),
                                            productRequests: serviceTypes.map(function (st) { var _a; return (_a = st.defaultBookableCombined) === null || _a === void 0 ? void 0 : _a.productRequest; }).filter(function (e) { return e != null; })
                                        }))];
                                    case 1:
                                        booking = _d.sent();
                                        console.log('BOOKING', (_c = (_b = (_a = booking === null || booking === void 0 ? void 0 : booking.custom) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.r2ref) === null || _c === void 0 ? void 0 : _c.reservid, booking);
                                        if (!window.confirm('auch zahlen?')) return [3 /*break*/, 3];
                                        return [4 /*yield*/, js_1.Booking.payDirect2({
                                                bookingId: booking.id,
                                                paymentRequest: new js_1.PaymentRequest({
                                                    paymentType: js_1.PaymentRequest.enum.paymentType.PAYPAL,
                                                    returnUrls: new js_1.PaymentReturnUrls()
                                                })
                                            }, {
                                                timeout: 300000
                                            })];
                                    case 2:
                                        payment = _d.sent();
                                        console.log('PAYMENT', payment);
                                        _d.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); } }, "alle Hin-/R\u00FCckfahrten"),
                    aurum_1.React.createElement(aurum_1.Button, { onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                            var booking, payment;
                            var _a, _b, _c;
                            return tslib_1.__generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0: return [4 /*yield*/, js_1.Booking.create(new js_1.BookingRequest({
                                            noConfirmation: true,
                                            customer: new js_1.BookingCustomer({
                                                companyName: 'MDSI Test',
                                                givenName: 'Udo',
                                                middleName: 'Peter',
                                                familyName: 'Gerdes',
                                                email: 'u.gerdes@mdsi.de',
                                                phone: '555-NASE'
                                            }),
                                            productRequests: serviceTypes
                                                .filter(function (_e, i) { return i === 0; })
                                                .map(function (st) { var _a; return (_a = st.defaultBookableCombined) === null || _a === void 0 ? void 0 : _a.productRequest; })
                                                .filter(function (e) { return e != null; })
                                        }))];
                                    case 1:
                                        booking = _d.sent();
                                        console.log('BOOKING', (_c = (_b = (_a = booking === null || booking === void 0 ? void 0 : booking.custom) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.r2ref) === null || _c === void 0 ? void 0 : _c.reservid, booking);
                                        if (!window.confirm('auch zahlen?')) return [3 /*break*/, 3];
                                        return [4 /*yield*/, js_1.Booking.payDirect2({
                                                bookingId: booking.id,
                                                paymentRequest: new js_1.PaymentRequest({
                                                    paymentType: js_1.PaymentRequest.enum.paymentType.PAYPAL,
                                                    returnUrls: new js_1.PaymentReturnUrls({
                                                        success: 'success',
                                                        fail: 'fail'
                                                    })
                                                })
                                            }, {
                                                timeout: 300000
                                            })];
                                    case 2:
                                        payment = _d.sent();
                                        console.log('PAYMENT', payment);
                                        _d.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); } }, "ein Erwachsener Hin-/R\u00FCck"),
                    aurum_1.React.createElement(aurum_1.Button, { onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                            var productRequests, booking, payment;
                            var _a, _b, _c;
                            return tslib_1.__generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0:
                                        productRequests = [];
                                        serviceTypes
                                            .filter(function (_e, i) { return i === 0; })
                                            .forEach(function (st) {
                                            var _a, _b, _c, _d, _f, _g, _h, _j;
                                            if ((_a = st.defaultBookableCombined) === null || _a === void 0 ? void 0 : _a.productRequest) {
                                                productRequests.push((_b = st.defaultBookableCombined) === null || _b === void 0 ? void 0 : _b.productRequest);
                                            }
                                            else {
                                                if ((_c = st.defaultBookable) === null || _c === void 0 ? void 0 : _c.productRequest) {
                                                    productRequests.push((_d = st.defaultBookable) === null || _d === void 0 ? void 0 : _d.productRequest);
                                                }
                                                if ((_f = st.defaultBookableBack) === null || _f === void 0 ? void 0 : _f.productRequest) {
                                                    productRequests.push((_g = st.defaultBookableBack) === null || _g === void 0 ? void 0 : _g.productRequest);
                                                }
                                            }
                                            if ((_h = st.defaultBookableVisitorTax) === null || _h === void 0 ? void 0 : _h.productRequest) {
                                                productRequests.push((_j = st.defaultBookableVisitorTax) === null || _j === void 0 ? void 0 : _j.productRequest);
                                            }
                                        });
                                        return [4 /*yield*/, js_1.Booking.create(new js_1.BookingRequest({
                                                noConfirmation: true,
                                                customer: new js_1.BookingCustomer({
                                                    companyName: 'MDSI Test mit GB',
                                                    givenName: 'Udo',
                                                    middleName: 'Peter',
                                                    familyName: 'Gerdes',
                                                    email: 'u.gerdes@mdsi.de',
                                                    phone: '555-NASE'
                                                }),
                                                productRequests: productRequests
                                            }))];
                                    case 1:
                                        booking = _d.sent();
                                        console.log('BOOKING', (_c = (_b = (_a = booking === null || booking === void 0 ? void 0 : booking.custom) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.r2ref) === null || _c === void 0 ? void 0 : _c.reservid, booking);
                                        if (!window.confirm('auch zahlen?')) return [3 /*break*/, 3];
                                        return [4 /*yield*/, js_1.Booking.payDirect2({
                                                bookingId: booking.id,
                                                paymentRequest: new js_1.PaymentRequest({
                                                    paymentType: js_1.PaymentRequest.enum.paymentType.PAYPAL,
                                                    returnUrls: new js_1.PaymentReturnUrls({
                                                        success: 'success',
                                                        fail: 'fail'
                                                    })
                                                })
                                            }, {
                                                timeout: 300000
                                            })];
                                    case 2:
                                        payment = _d.sent();
                                        console.log('PAYMENT', payment);
                                        _d.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); } }, "ein Erwachsener mit GB"),
                    aurum_1.React.createElement(aurum_1.Button, { onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                            var booking, payment;
                            var _a, _b, _c;
                            return tslib_1.__generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0: return [4 /*yield*/, js_1.Booking.create(new js_1.BookingRequest({
                                            noConfirmation: true,
                                            customer: new js_1.BookingCustomer({
                                                companyName: 'MDSI Test',
                                                givenName: 'Udo',
                                                middleName: 'Peter',
                                                familyName: 'Gerdes',
                                                email: 'u.gerdes@mdsi.de',
                                                phone: '555-NASE'
                                            }),
                                            productRequests: serviceTypes
                                                .filter(function (_e, i) { return i === 0; })
                                                .map(function (st) { var _a; return (_a = st.defaultBookable) === null || _a === void 0 ? void 0 : _a.productRequest; })
                                                .filter(function (e) { return e != null; })
                                        }))];
                                    case 1:
                                        booking = _d.sent();
                                        console.log('BOOKING', (_c = (_b = (_a = booking === null || booking === void 0 ? void 0 : booking.custom) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.r2ref) === null || _c === void 0 ? void 0 : _c.reservid, booking);
                                        if (!window.confirm('auch zahlen?')) return [3 /*break*/, 3];
                                        return [4 /*yield*/, js_1.Booking.payDirect2({
                                                bookingId: booking.id,
                                                paymentRequest: new js_1.PaymentRequest({
                                                    paymentType: js_1.PaymentRequest.enum.paymentType.PAYPAL,
                                                    returnUrls: new js_1.PaymentReturnUrls({
                                                        success: 'success',
                                                        fail: 'fail'
                                                    })
                                                })
                                            }, {
                                                timeout: 300000
                                            })];
                                    case 2:
                                        payment = _d.sent();
                                        console.log('PAYMENT', payment);
                                        _d.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); } }, "ein Erwachsener Hin"),
                    aurum_1.React.createElement(aurum_1.Button, { onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                            var booking, payment;
                            var _a, _b, _c;
                            return tslib_1.__generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0: return [4 /*yield*/, js_1.Booking.create(new js_1.BookingRequest({
                                            noConfirmation: true,
                                            customer: new js_1.BookingCustomer({
                                                companyName: 'MDSI Test',
                                                givenName: 'Udo',
                                                middleName: 'Peter',
                                                familyName: 'Gerdes',
                                                email: 'u.gerdes@mdsi.de',
                                                phone: '555-NASE'
                                            }),
                                            productRequests: serviceTypes
                                                .filter(function (st) { return st.ref === 'norderney1' || st.ref === 'juist1'; })
                                                .map(function (st) { var _a; return (_a = st.defaultBookable) === null || _a === void 0 ? void 0 : _a.productRequest; })
                                                .filter(function (e) { return e != null; })
                                        }))];
                                    case 1:
                                        booking = _d.sent();
                                        console.log('BOOKING', (_c = (_b = (_a = booking === null || booking === void 0 ? void 0 : booking.custom) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.r2ref) === null || _c === void 0 ? void 0 : _c.reservid, booking);
                                        if (!window.confirm('auch zahlen?')) return [3 /*break*/, 3];
                                        return [4 /*yield*/, js_1.Booking.payDirect2({
                                                bookingId: booking.id,
                                                paymentRequest: new js_1.PaymentRequest({
                                                    paymentType: js_1.PaymentRequest.enum.paymentType.PAYPAL,
                                                    returnUrls: new js_1.PaymentReturnUrls({
                                                        success: 'success',
                                                        fail: 'fail'
                                                    })
                                                })
                                            }, {
                                                timeout: 300000
                                            })];
                                    case 2:
                                        payment = _d.sent();
                                        console.log('PAYMENT', payment);
                                        _d.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); } }, "Juister und Norderneyer Erwachsene"),
                    aurum_1.React.createElement(aurum_1.Button, { onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                            var booking, payment;
                            var _a, _b, _c;
                            return tslib_1.__generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0: return [4 /*yield*/, js_1.Booking.create(new js_1.BookingRequest({
                                            noConfirmation: true,
                                            customer: new js_1.BookingCustomer({
                                                companyName: 'MDSI Test',
                                                givenName: 'Udo',
                                                middleName: 'Peter',
                                                familyName: 'Gerdes',
                                                email: 'u.gerdes@mdsi.de',
                                                phone: '555-NASE'
                                            }),
                                            productRequests: tslib_1.__spreadArray(tslib_1.__spreadArray([], tslib_1.__read(serviceTypes.map(function (st) { var _a; return (_a = st.defaultBookableCombined) === null || _a === void 0 ? void 0 : _a.productRequest; })), false), tslib_1.__read(serviceTypes.map(function (st) { var _a; return (_a = st.defaultBookableCombined) === null || _a === void 0 ? void 0 : _a.productRequest; })
                                            /* ...serviceTypes.map((st) => ({
                                                    ...st.defaultBookableCombined?.productRequest,
                                                    amount: 2
                                                  })) */
                                            ), false).filter(function (e) { return e != null; })
                                        }))];
                                    case 1:
                                        booking = _d.sent();
                                        console.log('BOOKING', (_c = (_b = (_a = booking === null || booking === void 0 ? void 0 : booking.custom) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.r2ref) === null || _c === void 0 ? void 0 : _c.reservid, booking);
                                        if (!window.confirm('auch zahlen?')) return [3 /*break*/, 3];
                                        return [4 /*yield*/, js_1.Booking.payDirect2({
                                                bookingId: booking.id,
                                                paymentRequest: new js_1.PaymentRequest({
                                                    paymentType: js_1.PaymentRequest.enum.paymentType.PAYPAL,
                                                    returnUrls: new js_1.PaymentReturnUrls()
                                                })
                                            }, {
                                                timeout: 300000
                                            })];
                                    case 2:
                                        payment = _d.sent();
                                        console.log('PAYMENT', payment);
                                        _d.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); } }, "alle Hin-/R\u00FCckfahrten zweimal"),
                    aurum_1.React.createElement(aurum_1.Button, { onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                            var productRequests, booking, payment;
                            var _a, _b, _c;
                            return tslib_1.__generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0:
                                        productRequests = tslib_1.__spreadArray(tslib_1.__spreadArray(tslib_1.__spreadArray([], tslib_1.__read(serviceTypes.map(function (st) { var _a; return (_a = st.defaultBookable) === null || _a === void 0 ? void 0 : _a.productRequest; })), false), tslib_1.__read(serviceTypes.map(function (st) { var _a; return (_a = st.defaultBookableBack) === null || _a === void 0 ? void 0 : _a.productRequest; })), false), tslib_1.__read(serviceTypes.map(function (st) { var _a; return (_a = st.defaultBookableCombined) === null || _a === void 0 ? void 0 : _a.productRequest; })), false).filter(function (e) { return e != null; });
                                        return [4 /*yield*/, js_1.Booking.create(new js_1.BookingRequest({
                                                noConfirmation: true,
                                                customer: new js_1.BookingCustomer({
                                                    companyName: 'MDSI Test',
                                                    givenName: 'Udo',
                                                    middleName: 'Peter',
                                                    familyName: 'Gerdes',
                                                    email: 'u.gerdes@mdsi.de',
                                                    phone: '555-NASE'
                                                }),
                                                productRequests: productRequests
                                            }))];
                                    case 1:
                                        booking = _d.sent();
                                        console.log('BOOKING', (_c = (_b = (_a = booking === null || booking === void 0 ? void 0 : booking.custom) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.r2ref) === null || _c === void 0 ? void 0 : _c.reservid, booking);
                                        if (!window.confirm('auch zahlen?')) return [3 /*break*/, 3];
                                        return [4 /*yield*/, js_1.Booking.payDirect2({
                                                bookingId: booking.id,
                                                paymentRequest: new js_1.PaymentRequest({
                                                    paymentType: js_1.PaymentRequest.enum.paymentType.PAYPAL,
                                                    returnUrls: new js_1.PaymentReturnUrls()
                                                })
                                            }, {
                                                timeout: 300000
                                            })];
                                    case 2:
                                        payment = _d.sent();
                                        console.log('PAYMENT', payment);
                                        _d.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); } }, "alles"),
                    aurum_1.React.createElement(aurum_1.Button, { onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                            var booking, payment;
                            var _a, _b, _c;
                            return tslib_1.__generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0: return [4 /*yield*/, js_1.Booking.create(new js_1.BookingRequest({
                                            noConfirmation: true,
                                            customer: new js_1.BookingCustomer({
                                                companyName: 'MDSI Test YieldManager',
                                                givenName: 'Udo',
                                                middleName: 'Peter',
                                                familyName: 'Gerdes',
                                                email: 'u.gerdes@mdsi.de',
                                                phone: '555-NASE'
                                            }),
                                            productRequests: serviceTypes
                                                .filter(function (_e, i) { return i === 0; })
                                                .map(function (st) { var _a; return (_a = st.defaultBookable) === null || _a === void 0 ? void 0 : _a.productRequest; })
                                                .filter(function (e) { return e != null; })
                                                .map(function (e) {
                                                var _a;
                                                (_a = e === null || e === void 0 ? void 0 : e.features) === null || _a === void 0 ? void 0 : _a.forEach(function (f) {
                                                    var _a;
                                                    f.yieldManagementPrice = 10 / ((_a = e === null || e === void 0 ? void 0 : e.features) === null || _a === void 0 ? void 0 : _a.length);
                                                    f.yieldManagementComment = 'MDSI Test YieldManager';
                                                });
                                                return e;
                                            })
                                        }))];
                                    case 1:
                                        booking = _d.sent();
                                        console.log('BOOKING', (_c = (_b = (_a = booking === null || booking === void 0 ? void 0 : booking.custom) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.r2ref) === null || _c === void 0 ? void 0 : _c.reservid, booking);
                                        if (!window.confirm('auch zahlen?')) return [3 /*break*/, 3];
                                        return [4 /*yield*/, js_1.Booking.payDirect2({
                                                bookingId: booking.id,
                                                paymentRequest: new js_1.PaymentRequest({
                                                    paymentType: js_1.PaymentRequest.enum.paymentType.PAYPAL,
                                                    returnUrls: new js_1.PaymentReturnUrls({
                                                        success: 'success',
                                                        fail: 'fail'
                                                    })
                                                })
                                            }, {
                                                timeout: 300000
                                            })];
                                    case 2:
                                        payment = _d.sent();
                                        console.log('PAYMENT', payment);
                                        _d.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); } }, "ein Erwachsener Hin f\u00FCr 10\u20AC"),
                    aurum_1.React.createElement(aurum_1.Button, { onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                            var booking, payment;
                            var _a, _b, _c;
                            return tslib_1.__generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0: return [4 /*yield*/, js_1.Booking.create(new js_1.BookingRequest({
                                            noConfirmation: true,
                                            customer: new js_1.BookingCustomer({
                                                companyName: 'MDSI Test YieldManager',
                                                givenName: 'Udo',
                                                middleName: 'Peter',
                                                familyName: 'Gerdes',
                                                email: 'u.gerdes@mdsi.de',
                                                phone: '555-NASE'
                                            }),
                                            productRequests: serviceTypes
                                                .filter(function (_e, i) { return i === 0; })
                                                .map(function (st) { var _a; return (_a = st.defaultBookableCombined) === null || _a === void 0 ? void 0 : _a.productRequest; })
                                                .filter(function (e) { return e != null; })
                                                .map(function (e) {
                                                var _a;
                                                (_a = e === null || e === void 0 ? void 0 : e.features) === null || _a === void 0 ? void 0 : _a.forEach(function (f) {
                                                    var _a;
                                                    f.yieldManagementPrice = 10 / ((_a = e === null || e === void 0 ? void 0 : e.features) === null || _a === void 0 ? void 0 : _a.length);
                                                    f.yieldManagementComment = 'MDSI Test YieldManager';
                                                });
                                                return e;
                                            })
                                        }))];
                                    case 1:
                                        booking = _d.sent();
                                        console.log('BOOKING', (_c = (_b = (_a = booking === null || booking === void 0 ? void 0 : booking.custom) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.r2ref) === null || _c === void 0 ? void 0 : _c.reservid, booking);
                                        if (!window.confirm('auch zahlen?')) return [3 /*break*/, 3];
                                        return [4 /*yield*/, js_1.Booking.payDirect2({
                                                bookingId: booking.id,
                                                paymentRequest: new js_1.PaymentRequest({
                                                    paymentType: js_1.PaymentRequest.enum.paymentType.PAYPAL,
                                                    returnUrls: new js_1.PaymentReturnUrls({
                                                        success: 'success',
                                                        fail: 'fail'
                                                    })
                                                })
                                            }, {
                                                timeout: 300000
                                            })];
                                    case 2:
                                        payment = _d.sent();
                                        console.log('PAYMENT', payment);
                                        _d.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); } }, "ein Erwachsener Hin-/R\u00FCck f\u00FCr 10\u20AC"),
                    aurum_1.React.createElement(aurum_1.Button, { onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                            var booking, payment;
                            var _a, _b, _c;
                            return tslib_1.__generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0: return [4 /*yield*/, js_1.Booking.create(new js_1.BookingRequest({
                                            noConfirmation: true,
                                            customer: new js_1.BookingCustomer({
                                                companyName: 'MDSI Test YieldManager',
                                                givenName: 'Udo',
                                                middleName: 'Peter',
                                                familyName: 'Gerdes',
                                                email: 'u.gerdes@mdsi.de',
                                                phone: '555-NASE'
                                            }),
                                            productRequests: serviceTypes
                                                .filter(function (_e, i) { return i === 0; })
                                                .map(function (st) { var _a; return (_a = st.defaultBookable) === null || _a === void 0 ? void 0 : _a.productRequest; })
                                                .filter(function (e) { return e != null; })
                                                .map(function (e) {
                                                var _a;
                                                (_a = e === null || e === void 0 ? void 0 : e.features) === null || _a === void 0 ? void 0 : _a.forEach(function (f) {
                                                    var _a;
                                                    f.yieldManagementPrice = 100 / ((_a = e === null || e === void 0 ? void 0 : e.features) === null || _a === void 0 ? void 0 : _a.length);
                                                    f.yieldManagementComment = 'MDSI Test YieldManager';
                                                });
                                                return e;
                                            })
                                        }))];
                                    case 1:
                                        booking = _d.sent();
                                        console.log('BOOKING', (_c = (_b = (_a = booking === null || booking === void 0 ? void 0 : booking.custom) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.r2ref) === null || _c === void 0 ? void 0 : _c.reservid, booking);
                                        if (!window.confirm('auch zahlen?')) return [3 /*break*/, 3];
                                        return [4 /*yield*/, js_1.Booking.payDirect2({
                                                bookingId: booking.id,
                                                paymentRequest: new js_1.PaymentRequest({
                                                    paymentType: js_1.PaymentRequest.enum.paymentType.PAYPAL,
                                                    returnUrls: new js_1.PaymentReturnUrls({
                                                        success: 'success',
                                                        fail: 'fail'
                                                    })
                                                })
                                            }, {
                                                timeout: 300000
                                            })];
                                    case 2:
                                        payment = _d.sent();
                                        console.log('PAYMENT', payment);
                                        _d.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); } }, "ein Erwachsener Hin f\u00FCr 100\u20AC"),
                    aurum_1.React.createElement(aurum_1.Button, { onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                            var booking, payment;
                            var _a, _b, _c;
                            return tslib_1.__generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0: return [4 /*yield*/, js_1.Booking.create(new js_1.BookingRequest({
                                            noConfirmation: true,
                                            customer: new js_1.BookingCustomer({
                                                companyName: 'MDSI Test YieldManager',
                                                givenName: 'Udo',
                                                middleName: 'Peter',
                                                familyName: 'Gerdes',
                                                email: 'u.gerdes@mdsi.de',
                                                phone: '555-NASE'
                                            }),
                                            productRequests: serviceTypes
                                                .filter(function (_e, i) { return i === 0; })
                                                .map(function (st) { var _a; return (_a = st.defaultBookableCombined) === null || _a === void 0 ? void 0 : _a.productRequest; })
                                                .filter(function (e) { return e != null; })
                                                .map(function (e) {
                                                var _a;
                                                (_a = e === null || e === void 0 ? void 0 : e.features) === null || _a === void 0 ? void 0 : _a.forEach(function (f) {
                                                    var _a;
                                                    f.yieldManagementPrice = 100 / ((_a = e === null || e === void 0 ? void 0 : e.features) === null || _a === void 0 ? void 0 : _a.length);
                                                    f.yieldManagementComment = 'MDSI Test YieldManager';
                                                });
                                                return e;
                                            })
                                        }))];
                                    case 1:
                                        booking = _d.sent();
                                        console.log('BOOKING', (_c = (_b = (_a = booking === null || booking === void 0 ? void 0 : booking.custom) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.r2ref) === null || _c === void 0 ? void 0 : _c.reservid, booking);
                                        if (!window.confirm('auch zahlen?')) return [3 /*break*/, 3];
                                        return [4 /*yield*/, js_1.Booking.payDirect2({
                                                bookingId: booking.id,
                                                paymentRequest: new js_1.PaymentRequest({
                                                    paymentType: js_1.PaymentRequest.enum.paymentType.PAYPAL,
                                                    returnUrls: new js_1.PaymentReturnUrls({
                                                        success: 'success',
                                                        fail: 'fail'
                                                    })
                                                })
                                            }, {
                                                timeout: 300000
                                            })];
                                    case 2:
                                        payment = _d.sent();
                                        console.log('PAYMENT', payment);
                                        _d.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); } }, "ein Erwachsener Hin-/R\u00FCck f\u00FCr 100\u20AC")))));
};
exports.default = TransportationListingTest;
