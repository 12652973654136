"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getErrorMessage = void 0;
var tslib_1 = require("tslib");
var getErrorMessage = function (error) { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
    var data, _a, _b;
    var _c;
    return tslib_1.__generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                data = (_c = error.response) === null || _c === void 0 ? void 0 : _c.data;
                if (!(data instanceof Blob)) return [3 /*break*/, 2];
                _b = (_a = JSON).parse;
                return [4 /*yield*/, data.text()];
            case 1:
                data = _b.apply(_a, [_d.sent()]);
                _d.label = 2;
            case 2:
                if ((data === null || data === void 0 ? void 0 : data.message) != null) {
                    return [2 /*return*/, data.message];
                }
                else {
                    return [2 /*return*/, error.message];
                }
                return [2 /*return*/];
        }
    });
}); };
exports.getErrorMessage = getErrorMessage;
