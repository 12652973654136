"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var js_1 = require("@samsa/samsa-api/js");
var formatPercent = function (num, fractions) {
    if (fractions === void 0) { fractions = 1; }
    return (num * 100).toFixed(fractions).replace('.', ',') + '%';
};
var Demo2 = function () {
    var _a = tslib_1.__read(aurum_1.React.useState(null), 2), data = _a[0], setData = _a[1];
    var reload = aurum_1.React.useCallback(function () {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var sum, moep;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, js_1.HousingDraft.getAll({
                            size: 1
                        })];
                    case 1:
                        sum = _a.sent();
                        return [4 /*yield*/, js_1.HousingDraft.getAll({
                                size: 1,
                                equipments: {
                                    housingEquipment: {
                                        id: 'WIFI'
                                    }
                                }
                            })];
                    case 2:
                        moep = _a.sent();
                        setData({
                            sum: sum === null || sum === void 0 ? void 0 : sum.totalElements,
                            moep: moep === null || moep === void 0 ? void 0 : moep.totalElements
                        });
                        return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    aurum_1.React.useEffect(reload, [reload]);
    return aurum_1.React.createElement(aurum_1.Sheet, { className: 'tile size-1-1' },
        aurum_1.React.createElement(aurum_1.Header, null,
            aurum_1.React.createElement("h3", null, "Unterk\u00FCnfte mit WLAN")),
        aurum_1.React.createElement(aurum_1.Body, null, data
            ? aurum_1.React.createElement(aurum_1.React.Fragment, null,
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("h1", null, data === null || data === void 0 ? void 0 : data.sum),
                    aurum_1.React.createElement("h1", null, formatPercent((data === null || data === void 0 ? void 0 : data.moep) / (data === null || data === void 0 ? void 0 : data.sum))),
                    aurum_1.React.createElement("h1", null, formatPercent(1 - (data === null || data === void 0 ? void 0 : data.moep) / (data === null || data === void 0 ? void 0 : data.sum)))),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("span", null, "Unterk\u00FCnfte"),
                    aurum_1.React.createElement("span", null, "mit WLAN"),
                    aurum_1.React.createElement("span", null, "ohne WLAN")))
            : aurum_1.React.createElement(aurum_1.Progress, null)),
        aurum_1.React.createElement(aurum_1.Footer, null,
            aurum_1.React.createElement(aurum_1.Row, null,
                aurum_1.React.createElement(aurum_1.Button, null,
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'list' }),
                    "Unterk\u00FCnfte bearbeiten"))));
};
exports.default = aurum_1.React.memo(Demo2);
