"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var aurum_1 = require("@mdsi/aurum");
var js_1 = require("@samsa/samsa-api/js");
var react_i18next_1 = require("react-i18next");
// Object.keys(obj) => [key1, key2, key3];
// Object.values(obj) => [value1, value2, value3];
// Object.entries(obj) => [[key1, value1], [key2, value2], [key3, value3]];
var RegisterEmployee = function (_a) {
    var _b, _c, _d, _e;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _f = tslib_1.__read((0, react_1.useState)(''), 2), email = _f[0], setEmail = _f[1];
    var _g = tslib_1.__read((0, react_1.useState)(''), 2), password = _g[0], setPassword = _g[1];
    var _h = tslib_1.__read((0, react_1.useState)(null), 2), gender = _h[0], setGender = _h[1];
    var _j = tslib_1.__read((0, react_1.useState)(''), 2), givenName = _j[0], setGivenName = _j[1];
    var _k = tslib_1.__read((0, react_1.useState)(''), 2), familyName = _k[0], setFamilyName = _k[1];
    var _l = tslib_1.__read((0, react_1.useState)(''), 2), middleName = _l[0], setMiddleName = _l[1];
    var _m = tslib_1.__read((0, react_1.useState)(true), 2), sendMails = _m[0], setSendMails = _m[1];
    var _o = tslib_1.__read((0, react_1.useState)(null), 2), errorData = _o[0], setErrorData = _o[1];
    var _p = tslib_1.__read((0, react_1.useState)(false), 2), savingInProgress = _p[0], setSavingInProgress = _p[1];
    var catchError = function (error) {
        var _a;
        if (typeof error === 'string') {
            setErrorData({ plain: error, showModal: true });
            return;
        }
        var errorObject = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data;
        if ((errorObject === null || errorObject === void 0 ? void 0 : errorObject.fieldErrors) || (errorObject === null || errorObject === void 0 ? void 0 : errorObject.reason)) {
            setErrorData(tslib_1.__assign(tslib_1.__assign({}, errorObject), { showModal: true }));
        }
        else {
            setErrorData({ reason: error.message, showModal: true });
        }
    };
    var _q = tslib_1.__read((0, react_1.useState)(null), 2), owners = _q[0], setOwners = _q[1];
    var _r = tslib_1.__read((0, react_1.useState)(null), 2), accountRoles = _r[0], setAccountRoles = _r[1];
    var _s = tslib_1.__read((0, react_1.useState)(''), 2), selectedOwner = _s[0], setSelectedOwner = _s[1];
    var _t = tslib_1.__read((0, react_1.useState)(''), 2), selectedAccountRole = _t[0], setSelectedAccountRole = _t[1];
    var _u = tslib_1.__read((0, react_1.useState)(false), 2), customerProfileExists = _u[0], setCustomerProfileExtists = _u[1];
    // @ts-ignore
    var freeAllFields = function () {
        setEmail('');
        setPassword('');
        setGender(null);
        setGivenName('');
        setFamilyName('');
        setMiddleName('');
        setSendMails(false);
        setSelectedOwner('');
        setSelectedAccountRole(null);
    };
    var loadOwners = (0, react_1.useCallback)(function () {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var r, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, js_1.Owner.getAll({ size: 0 })];
                    case 1:
                        r = _a.sent();
                        setOwners(r.content);
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    var loadAccountRoles = (0, react_1.useCallback)(function () {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var accountroles, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, js_1.AccountRole.getAll({ size: 0 })];
                    case 1:
                        accountroles = _a.sent();
                        setAccountRoles(accountroles.content);
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.error(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    (0, react_1.useEffect)(function () {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var customerProfile, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, (0, js_1.getCustomerProfileByEmail)(email)];
                    case 1:
                        customerProfile = _a.sent();
                        setCustomerProfileExtists(customerProfile != null && customerProfile.contactPerson != null);
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        console.error(e_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); })();
    }, [email]);
    // wird initial mindestens einmal beim laden der Seite aufgerufen
    (0, react_1.useEffect)(loadAccountRoles, []);
    (0, react_1.useEffect)(loadOwners, []);
    var errorModal = null;
    if (errorData === null || errorData === void 0 ? void 0 : errorData.showModal) {
        errorModal = react_1.default.createElement(aurum_1.Modal, { className: 'simpleModal' },
            react_1.default.createElement(aurum_1.Header, null,
                react_1.default.createElement("h2", null, "".concat(t('Edit.errorModal.title')))),
            react_1.default.createElement(aurum_1.Body, null,
                errorData.plain && react_1.default.createElement(aurum_1.Row, null, errorData.plain),
                errorData.reason
                    ? react_1.default.createElement(aurum_1.Row, null, "".concat(t('Edit.errorModal.reason'), ": '").concat(t('Edit.errorModal.Enum.' + errorData.reason), "'"))
                    : null,
                ((_b = errorData.fieldErrors) === null || _b === void 0 ? void 0 : _b.length) ? react_1.default.createElement(aurum_1.Row, null, "".concat(t('Edit.errorModal.fieldErrors'), ": ")) : null, (_c = errorData.fieldErrors) === null || _c === void 0 ? void 0 :
                _c.map(function (f) {
                    var _a;
                    return react_1.default.createElement(aurum_1.Row, null, '• ' + t("EntityAttribute.".concat(f === null || f === void 0 ? void 0 : f.field)) + ": ".concat((_a = f === null || f === void 0 ? void 0 : f.messageList) === null || _a === void 0 ? void 0 : _a.join(', ')));
                })),
            react_1.default.createElement(aurum_1.Footer, null,
                react_1.default.createElement(aurum_1.Button, { type: 'error', onClick: function () {
                        setErrorData(tslib_1.__assign(tslib_1.__assign({}, errorData), { showModal: false }));
                    } }, t(['Edit.errorModal.close', 'Controls.close']))));
    }
    var invalid = react_1.default.createElement(aurum_1.Alert, { type: 'error' }, "Unzureichende Berechtigungen");
    var valid = react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(aurum_1.Row, null,
            react_1.default.createElement(aurum_1.Input, { label: t('EntityAttribute.givenName'), value: givenName, onChange: function (e) {
                    setGivenName(e.target.value);
                }, autoComplete: false, name: 'pls-no-autocomplete1' }),
            react_1.default.createElement(aurum_1.Input, { label: t('EntityAttribute.middleName'), optional: true, value: middleName, onChange: function (e) {
                    setMiddleName(e.target.value);
                }, autoComplete: false, name: 'pls-no-autocomplete2' }),
            react_1.default.createElement(aurum_1.Input, { label: t('EntityAttribute.familyName'), value: familyName, onChange: function (e) {
                    setFamilyName(e.target.value);
                }, autoComplete: false, name: 'pls-no-autocomplete3' })),
        react_1.default.createElement(aurum_1.Row, null,
            react_1.default.createElement(aurum_1.Input, { label: t('EntityAttribute.email'), value: email, onChange: function (e) {
                    setEmail(e.target.value);
                }, autoComplete: false, name: 'pls-no-autocomplete5' }),
            react_1.default.createElement(aurum_1.Input, { type: 'password', label: t('EntityAttribute.password'), disabled: customerProfileExists, value: password, onChange: function (e) {
                    setPassword(e.target.value);
                }, autoComplete: 'off', name: 'pls-no-autocomplete6' })),
        react_1.default.createElement(aurum_1.Row, null,
            react_1.default.createElement(aurum_1.Select, { value: selectedOwner, native: false, label: 'Partner', options: (_d = owners === null || owners === void 0 ? void 0 : owners.map) === null || _d === void 0 ? void 0 : _d.call(owners, function (owner) {
                    return { id: owner.id, label: owner.companyName };
                }), onChange: function (onChangeEvent) {
                    setSelectedOwner(onChangeEvent.target.value);
                } }),
            react_1.default.createElement(aurum_1.Select, { value: selectedAccountRole, native: false, label: 'Account Rolle', options: (_e = accountRoles === null || accountRoles === void 0 ? void 0 : accountRoles.map) === null || _e === void 0 ? void 0 : _e.call(accountRoles, function (accountRole) {
                    return react_1.default.createElement("option", { key: accountRole.id, value: accountRole.id, label: accountRole.title });
                }), onChange: function (onChangeEvent) {
                    setSelectedAccountRole(onChangeEvent.target.value);
                } }),
            react_1.default.createElement(aurum_1.Select, { label: t('EntityAttribute.gender'), value: gender || '', onChange: function (e) {
                    setGender(e.target.value);
                }, options: Object.values(js_1.OwnerRegistration.enum.gender).map(function (gender) { return ({
                    value: gender,
                    label: t("Enum.".concat(gender))
                }); }) }),
            react_1.default.createElement(aurum_1.Checkbox, { label: t('EntityAttribute.sendMails'), onClick: function (e) {
                    setSendMails(!e.target.value);
                }, name: 'sendMails' })),
        savingInProgress
            ? react_1.default.createElement(aurum_1.Progress, null)
            : react_1.default.createElement(aurum_1.Button, { type: 'primary', onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                    var role, employeeRegistration, contactPersonRegistration, e_4;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!selectedOwner) {
                                    catchError('Bitte wählen Sie einen Partner aus!');
                                    return [2 /*return*/];
                                }
                                setSavingInProgress(true);
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 5, , 6]);
                                role = selectedAccountRole ? accountRoles.find(function (accountRole) { return accountRole.id == selectedAccountRole; }) : null;
                                employeeRegistration = new js_1.EmployeeRegistration({
                                    email: email,
                                    familyName: familyName,
                                    gender: gender,
                                    givenName: givenName,
                                    linkWithExistingAccount: customerProfileExists,
                                    middleName: middleName,
                                    password: password,
                                    sendMails: sendMails,
                                    username: email,
                                    role: role
                                });
                                return [4 /*yield*/, (0, js_1.addEmployeeToOwnerProfileByOwnerId)({
                                        ownerId: selectedOwner,
                                        employeeRegistration: employeeRegistration
                                    })];
                            case 2:
                                _a.sent();
                                if (!!customerProfileExists) return [3 /*break*/, 4];
                                contactPersonRegistration = new js_1.CustomerContactPersonRegistration({
                                    email: email,
                                    familyName: familyName,
                                    gender: gender,
                                    givenName: givenName,
                                    linkWithExistingAccount: true,
                                    middleName: middleName,
                                    password: password,
                                    sendMails: sendMails,
                                    noConfirmation: true
                                });
                                return [4 /*yield*/, (0, js_1.registerCustomerProfile)({
                                        urlTemplate: null,
                                        customerRegistration: contactPersonRegistration
                                    })];
                            case 3:
                                _a.sent();
                                _a.label = 4;
                            case 4:
                                freeAllFields();
                                return [3 /*break*/, 6];
                            case 5:
                                e_4 = _a.sent();
                                catchError(e_4);
                                return [3 /*break*/, 6];
                            case 6:
                                setSavingInProgress(false);
                                return [2 /*return*/];
                        }
                    });
                }); } }, t('partnerFormular.save')));
    // wenn laden aktiv, dann zeige Ladebalken, ansonsten wird das Dropdown angezeigt
    return react_1.default.createElement(aurum_1.Sheet, { className: 'RegisterEmployee' },
        errorModal,
        react_1.default.createElement("h2", null, t('EmployeeFormular.title')),
        !js_1.addEmployeeToOwnerProfileByOwnerId.isAllowed() ? invalid : valid);
};
// notwendig, damit die Datei importiert werden kann
exports.default = (0, react_1.memo)(RegisterEmployee);
