"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var aurum_1 = require("@mdsi/aurum");
var Menu = function (_a) {
    var left = _a.left, center = _a.center, right = _a.right;
    return aurum_1.React.createElement(aurum_1.Sheet, { className: 'Menu' },
        left ? aurum_1.React.createElement("div", { className: 'left' }, left) : null,
        aurum_1.React.createElement("div", { className: 'center' }, center),
        right ? aurum_1.React.createElement("div", { className: 'right' }, right) : null);
};
exports.default = Menu;
