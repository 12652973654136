"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var js_1 = require("@samsa/samsa-api/js");
var useOnChange_1 = require("../../hook/useOnChange");
var PasswordModal = function (props) {
    var t = (0, react_i18next_1.useTranslation)().t;
    var setModal = props.setModal;
    var modUrl = props.modUrl;
    var token = props.token;
    var _a = tslib_1.__read((0, react_1.useState)(null), 2), errorMessage = _a[0], setErrorMessage = _a[1];
    var _b = tslib_1.__read((0, react_1.useState)(null), 2), message = _b[0], setMessage = _b[1];
    var _c = tslib_1.__read((0, react_1.useState)(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = tslib_1.__read((0, react_1.useState)(false), 2), resetDone = _d[0], setResetDone = _d[1];
    var password = (0, useOnChange_1.useOnChange)('', undefined, function (v) {
        setErrorMessage(null);
        return v;
    });
    var passwordRepeat = (0, useOnChange_1.useOnChange)('', undefined, function (v) {
        setErrorMessage(null);
        return v;
    });
    var isPasswordValid = function (value1) { return value1.value != null && value1.value.trim() !== ''; };
    var arePasswordsEqual = function () { return password.value === passwordRepeat.value; };
    var arePasswordsValid = function () { return isPasswordValid(password) && isPasswordValid(passwordRepeat); };
    var resetErrorMessages = function () {
        setErrorMessage(null);
        setMessage(null);
    };
    function handleServerRequests(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var baseUrl;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        baseUrl = window.location.origin + window.location.pathname;
                        return [4 /*yield*/, js_1.axiosInstance.post(baseUrl + 'auth/password/reset', data)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    }
    var handlePasswordReset = function () {
        setLoading(true);
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var response;
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, handleServerRequests({
                            grant_type: 'password',
                            methode: 'savePasswortReset',
                            password_token: token,
                            password: password.value
                        })];
                    case 1:
                        response = _b.sent();
                        if (response.data.ok) {
                            setMessage(t('App.PasswordReset.Modal.password.resetSuccess'));
                            password.set(null);
                            passwordRepeat.set(null);
                            setResetDone(true);
                        }
                        else {
                            setErrorMessage((_a = response.data.message) !== null && _a !== void 0 ? _a : t('App.PasswordReset.Modal.password.resetFail'));
                        }
                        setLoading(false);
                        modUrl();
                        return [2 /*return*/];
                }
            });
        }); })();
    };
    var validateToken = function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a;
        return tslib_1.__generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoading(true);
                    return [4 /*yield*/, handleServerRequests({
                            grant_type: 'password',
                            methode: 'validatePasswortReset',
                            password_token: token
                        })];
                case 1:
                    response = _b.sent();
                    if (response.data.ok) {
                        setLoading(false);
                        return [2 /*return*/, true];
                    }
                    else {
                        setErrorMessage((_a = response.data.message) !== null && _a !== void 0 ? _a : t('App.PasswordReset.Modal.token.fail'));
                        return [2 /*return*/, false];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleBtnClick = function () {
        resetErrorMessages();
        if (!arePasswordsEqual()) {
            setErrorMessage(t('App.PasswordReset.Modal.password.notEqual'));
            return;
        }
        if (!arePasswordsValid()) {
            setErrorMessage(t('App.PasswordReset.Modal.password.notValid'));
            return;
        }
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var tokenIsValid;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, validateToken()];
                    case 1:
                        tokenIsValid = _a.sent();
                        if (tokenIsValid) {
                            handlePasswordReset();
                        }
                        return [2 /*return*/];
                }
            });
        }); })();
    };
    return react_1.default.createElement(aurum_1.Modal, { className: 'passwordResetModalPassword' },
        react_1.default.createElement(aurum_1.Header, null,
            react_1.default.createElement("h2", null, "".concat(t('App.PasswordReset.Modal.title')))),
        react_1.default.createElement(aurum_1.Body, null,
            react_1.default.createElement(aurum_1.Input, { disabled: loading || resetDone, className: 'input-left', type: 'password', label: t('App.PasswordReset.Modal.password'), value: password.inputvalue, onChange: password.onChange }),
            react_1.default.createElement(aurum_1.Input, { disabled: loading || resetDone, className: 'input-left', type: 'password', label: t('App.PasswordReset.Modal.password.repeat'), value: passwordRepeat.inputvalue, onChange: passwordRepeat.onChange }),
            errorMessage != null ? react_1.default.createElement("p", { className: 'errorMessage' }, errorMessage) : null,
            message != null ? react_1.default.createElement("p", { className: 'infoMessage' }, message) : null),
        react_1.default.createElement(aurum_1.Footer, null, loading
            ? react_1.default.createElement(aurum_1.Progress, null)
            : react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(aurum_1.Button, { className: 'btn-left', type: 'error', onClick: function () {
                        setModal(null);
                        modUrl();
                    } }, t(resetDone ? 'Controls.close' : 'App.Menu.cancel')),
                react_1.default.createElement(aurum_1.Button, { disabled: resetDone, className: 'btn-right', type: 'primary', onClick: handleBtnClick }, t('App.PasswordReset.Modal.title')))));
};
exports.default = react_1.default.memo(PasswordModal);
