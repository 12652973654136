"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var hasChanged_1 = require("../../../util/hasChanged");
var EnumFormInput = function (props) {
    var _a;
    var t = (0, react_i18next_1.useTranslation)().t;
    var changed = (0, hasChanged_1.hasChanged)(props.value, props.initialValue);
    var classes = [props.field.name];
    if (props.field.form.width === 'full') {
        classes.push('width-full');
    }
    var onChange = aurum_1.React.useCallback(function (_a) {
        var _b;
        var value = _a.target.value;
        var changed = (0, hasChanged_1.hasChanged)(value, props.initialValue);
        // eslint-disable-next-line no-unused-expressions
        (_b = props.onChange) === null || _b === void 0 ? void 0 : _b.call(props, value, changed);
    }, [props.onChange]);
    var input = aurum_1.React.createElement(aurum_1.Input, { type: props.field.array ? 'multiselect' : 'select', label: props.label, value: (_a = props.value) !== null && _a !== void 0 ? _a : '', changed: changed, options: props.field.enum.map(function (value) { return aurum_1.React.createElement("option", { key: value, value: value, label: t === null || t === void 0 ? void 0 : t("Enum.".concat(value)) }); }), className: classes.join(' '), onChange: onChange, disabled: props.field.readOnly || props.field.form.disabled, optional: props.field.optional, validationMessages: props.errorData, native: false });
    return input;
};
exports.default = aurum_1.React.memo(EnumFormInput);
