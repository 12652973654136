"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var js_1 = require("@samsa/samsa-api/js");
var react_1 = require("react");
var useAsync_1 = require("../hook/useAsync");
var ResetPasswordForCustomer = function () {
    var _a, _b;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _c = tslib_1.__read(aurum_1.React.useState(false), 2), sendPasswordReset = _c[0], setSendPasswordReset = _c[1];
    var _d = tslib_1.__read(aurum_1.React.useState(null), 2), email = _d[0], setEmail = _d[1];
    var _e = tslib_1.__read((0, react_1.useState)(), 2), alert = _e[0], setAlert = _e[1];
    var _f = tslib_1.__read(aurum_1.React.useState(null), 2), overwriteEmail = _f[0], setOverwriteEmail = _f[1];
    (0, useAsync_1.useAsync)(function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
        var e_1, error;
        var _a, _b, _c, _d;
        return tslib_1.__generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (!sendPasswordReset) return [3 /*break*/, 5];
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, , 4]);
                    setAlert({ type: 'info', label: 'Email fürs Passwort zurücksetzen wird versendet..' });
                    return [4 /*yield*/, (0, js_1.sendPasswordResetCustomerProfileToEmail)({
                            doubleOptIn: new js_1.DoubleOptIn({
                                email: email,
                                doubleOptInType: js_1.DoubleOptIn.enum.doubleOptInType.PASSWORD_RESET,
                                urlTemplate: ''
                            }),
                            overwriteEmail: overwriteEmail
                        })];
                case 2:
                    _e.sent();
                    setAlert({ type: 'success', label: 'Email fürs Passwort zurücksetzen wurde erfolgreich versendet' });
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _e.sent();
                    error = (_d = (_c = (_b = (_a = e_1 === null || e_1 === void 0 ? void 0 : e_1.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error_description) !== null && _c !== void 0 ? _c : e_1 === null || e_1 === void 0 ? void 0 : e_1.message) !== null && _d !== void 0 ? _d : 'An error occured';
                    setAlert({ label: error });
                    return [3 /*break*/, 4];
                case 4:
                    setSendPasswordReset(false);
                    _e.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); }, [sendPasswordReset]);
    return aurum_1.React.createElement(aurum_1.Sheet, { className: 'login', onKeyDown: function (e) { return e.key === 'Enter'
            ? setSendPasswordReset(true)
            : null; } },
        aurum_1.React.createElement(aurum_1.Row, null,
            aurum_1.React.createElement(aurum_1.Input, { autoFocus: true, type: 'text', label: t('EntityAttribute.email'), value: email, onChange: function (e) {
                    setEmail(e.target.value);
                } })),
        aurum_1.React.createElement(aurum_1.Row, null,
            aurum_1.React.createElement(aurum_1.Input, { autoFocus: true, type: 'text', label: t('EntityAttribute.emailOverwrite'), value: overwriteEmail, onChange: function (e) {
                    setOverwriteEmail(e.target.value);
                } })),
        alert
            ? aurum_1.React.createElement(aurum_1.Row, null,
                aurum_1.React.createElement(aurum_1.Alert, { type: (_a = alert.type) !== null && _a !== void 0 ? _a : 'error' }, (_b = alert === null || alert === void 0 ? void 0 : alert.label.substring(0, 100)) !== null && _b !== void 0 ? _b : ''))
            : null,
        aurum_1.React.createElement(aurum_1.Row, null,
            aurum_1.React.createElement(aurum_1.Button, { type: 'primary', onClick: function () { return setSendPasswordReset(true); } },
                t('EntityAttribute.sendPasswordReset'),
                aurum_1.React.createElement(aurum_1.Icon, { type: 'navigate_next' }))));
};
exports.default = aurum_1.React.memo(ResetPasswordForCustomer);
