"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var js_1 = require("@samsa/samsa-api/js");
var react_i18next_1 = require("react-i18next");
var useOnChange_1 = require("../hook/useOnChange");
var tablefields = [
    { name: 'feature' },
    { name: 'status' }
];
var PublicationList = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    // console.log('Edit.render()');
    var t = (0, react_i18next_1.useTranslation)().t;
    var _j = tslib_1.__read(aurum_1.React.useState([]), 2), pageSelection = _j[0], setPageSelection = _j[1];
    var selected = (0, useOnChange_1.useOnChange)();
    var size = (0, useOnChange_1.useOnChange)(20);
    var page = (0, useOnChange_1.useOnChange)(0);
    var status = (0, useOnChange_1.useOnChange)([
        js_1.FeaturePublication.enum.status.REQUESTED
    ]);
    var _k = tslib_1.__read(aurum_1.React.useState(null), 2), list = _k[0], setList = _k[1];
    var listparams = {
        size: size.value,
        page: page.value,
        sort: ['createdAt,asc', 'id,asc'],
        status: status.value
    };
    var listReload = aurum_1.React.useCallback(function () {
        var cancelToken = (0, js_1.getCancelToken)();
        var promise = js_1.FeaturePublication.getAll(listparams, {
            cancelToken: cancelToken.token
        });
        promise.then(function (result) {
            setList(result);
        }).catch(function (e) {
            if (!(0, js_1.isCancel)(e)) {
                console.error('ERROR listReload', e.message, e);
                setList(null);
            }
        });
        return function () {
            cancelToken.cancel();
        };
    }, [JSON.stringify(listparams)]);
    aurum_1.React.useEffect(listReload, [listReload]);
    var _l = tslib_1.__read(aurum_1.React.useState(null), 2), entry = _l[0], setEntry = _l[1];
    var entryparams = selected.value;
    var entryReload = aurum_1.React.useCallback(function () {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var request, live, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!entryparams) return [3 /*break*/, 6];
                        return [4 /*yield*/, js_1.FeaturePublication.get(entryparams)];
                    case 1:
                        request = _a.sent();
                        live = null;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, js_1.Listing.get({
                                featureId: request.feature.id
                            })];
                    case 3:
                        live = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5:
                        setEntry({
                            request: request,
                            live: live
                        });
                        return [3 /*break*/, 7];
                    case 6:
                        setEntry(null);
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        }); })();
    }, [JSON.stringify(entryparams)]);
    aurum_1.React.useEffect(entryReload, [entryReload]);
    var _m = tslib_1.__read(aurum_1.React.useState(null), 2), cachedTable = _m[0], setCachedTable = _m[1];
    aurum_1.React.useEffect(function () {
        // console.log('setCachedTable.render()');
        setCachedTable(aurum_1.React.createElement(aurum_1.Table, { className: 'table' },
            aurum_1.React.createElement("thead", null,
                aurum_1.React.createElement("tr", null, tablefields.map(function (prop) {
                    if (prop.name === 'date') {
                        return aurum_1.React.createElement("th", { key: prop.name }, t("BookingList.EntityAttribute.".concat(prop.name)));
                    }
                    var title = t("Entity.".concat(js_1.FeaturePublication.name, ".").concat(prop.name), "EntityAttribute.".concat(prop.name));
                    if (prop.name.startsWith('generatedFor')) {
                        title = title.split(' ').slice(0, 3).join(' ');
                    }
                    return aurum_1.React.createElement("th", { key: prop.name }, title);
                }))),
            aurum_1.React.createElement("tbody", null, !list ? aurum_1.React.createElement("tr", null,
                aurum_1.React.createElement("td", { colSpan: tablefields.length },
                    aurum_1.React.createElement(aurum_1.Progress, null))) : list.content.map(function (entry) {
                // @ts-ignore
                entry.setter = entry.setter || (function () { return selected.set(entry.id); });
                var className = null;
                switch (entry.status) {
                    case 'ACCEPTED':
                        className = 'success';
                        break;
                    case 'DECLINED':
                        className = 'error';
                        break;
                    case 'REMOVED':
                        className = 'warn';
                        break;
                }
                if (entry.id === selected.value) {
                    className = 'primary';
                }
                // @ts-ignore
                return aurum_1.React.createElement("tr", { key: entry.id, onClick: entry.setter /* entry.status === 'REQUESTED' ? entry.setter : null */, className: className }, tablefields.map(function (prop) {
                    var val = (entry[prop.name] != null ? entry[prop.name] : '');
                    return aurum_1.React.createElement("td", { key: prop.name }, val.toString());
                }));
            }))));
    }, [list, entry, entry === null || entry === void 0 ? void 0 : entry.id]);
    var totalPages = (_a = list === null || list === void 0 ? void 0 : list.totalPages) !== null && _a !== void 0 ? _a : 1;
    aurum_1.React.useEffect(function () {
        setPageSelection(Array.apply(null, Array(totalPages))
            .map(function (_v, page) { return aurum_1.React.createElement("option", { value: page, label: (page + 1) + '/' + totalPages }); }));
    }, [totalPages]);
    var modal = null;
    if (entry) {
        // console.log('entry.request', entry.request?.feature);
        // console.log('entry.live', entry.live?.feature);
        var mergedKeys_1 = {};
        var addChildKeys_1 = function (type, obj, path) {
            var _a;
            var _b;
            if (path === void 0) { path = null; }
            if (obj != null && typeof obj === 'object' && !Array.isArray(obj)) {
                Object.entries(obj).forEach(function (_a) {
                    var _b = tslib_1.__read(_a, 2), key = _b[0], value = _b[1];
                    return addChildKeys_1(type, value, "".concat(path ? path + '.' : '').concat(key));
                });
            }
            else {
                mergedKeys_1[path] = tslib_1.__assign(tslib_1.__assign({}, (_b = mergedKeys_1[path]) !== null && _b !== void 0 ? _b : {}), (_a = {}, _a[type] = obj, _a));
            }
        };
        var deleteKeys = function (parent) {
            Object.keys(mergedKeys_1).forEach(function (key) {
                if (key.startsWith(parent + (key.includes('.') ? '.' : ''))) {
                    delete mergedKeys_1[key];
                }
            });
        };
        addChildKeys_1('request', (_c = (_b = entry.request) === null || _b === void 0 ? void 0 : _b.feature) !== null && _c !== void 0 ? _c : {});
        addChildKeys_1('live', (_e = (_d = entry.live) === null || _d === void 0 ? void 0 : _d.feature) !== null && _e !== void 0 ? _e : {});
        // console.log('mergedKeys', mergedKeys);
        deleteKeys('id');
        deleteKeys('custom');
        deleteKeys('publication');
        modal = aurum_1.React.createElement(aurum_1.Modal, { className: 'featurePublicationDetailsModal', onClickBackdrop: function () {
                selected.set(null);
            } },
            aurum_1.React.createElement(aurum_1.Header, null,
                aurum_1.React.createElement("h2", null, (_h = (_g = (_f = entry.request) === null || _f === void 0 ? void 0 : _f.feature) === null || _g === void 0 ? void 0 : _g.toString) === null || _h === void 0 ? void 0 : _h.call(_g))),
            aurum_1.React.createElement(aurum_1.Body, null,
                aurum_1.React.createElement(aurum_1.Table, null,
                    aurum_1.React.createElement("thead", null,
                        aurum_1.React.createElement("tr", null,
                            aurum_1.React.createElement("th", null, "Feld"),
                            aurum_1.React.createElement("th", null, "Neu"),
                            aurum_1.React.createElement("th", null, "Live"))),
                    aurum_1.React.createElement("tbody", null, Object.entries(mergedKeys_1).map(function (_a) {
                        var _b, _c, _d, _e, _f, _g, _h, _j;
                        var _k = tslib_1.__read(_a, 2), key = _k[0], value = _k[1];
                        var equals = JSON.stringify((_b = value.request) !== null && _b !== void 0 ? _b : null) === JSON.stringify((_c = value.live) !== null && _c !== void 0 ? _c : null);
                        var classes = [];
                        if (!equals) {
                            classes.push('warn');
                        }
                        return aurum_1.React.createElement("tr", { className: classes.join(' '), key: key },
                            aurum_1.React.createElement("td", null, key.split('.').map(function (k) { return t("EntityAttribute.".concat(k)); }).join(' > ')),
                            aurum_1.React.createElement("td", null, (_f = (_e = (_d = value.request) === null || _d === void 0 ? void 0 : _d.toString) === null || _e === void 0 ? void 0 : _e.call(_d)) !== null && _f !== void 0 ? _f : JSON.stringify(value.request)),
                            aurum_1.React.createElement("td", null, (_j = (_h = (_g = value.live) === null || _g === void 0 ? void 0 : _g.toString) === null || _h === void 0 ? void 0 : _h.call(_g)) !== null && _j !== void 0 ? _j : JSON.stringify(value.live)));
                    })))),
            aurum_1.React.createElement(aurum_1.Footer, null,
                aurum_1.React.createElement("span", null),
                aurum_1.React.createElement(aurum_1.Button, { type: 'error', onClick: function () {
                        selected.set(null);
                    } }, t(['featurePublicationDetailsModal.close', 'Controls.close'])),
                aurum_1.React.createElement(aurum_1.Button, { type: 'error-primary', onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, js_1.FeaturePublication.decline({
                                        publicationId: entry.request.id,
                                        decline: new js_1.FeaturePublicationDecline({
                                            comment: 'moep' // TODO: Eingabemöglichkeit für Kommentar
                                        })
                                    })];
                                case 1:
                                    _a.sent();
                                    selected.set(null);
                                    listReload();
                                    return [2 /*return*/];
                            }
                        });
                    }); } }, t(['featurePublicationDetailsModal.decline', 'Controls.decline'])),
                aurum_1.React.createElement(aurum_1.Button, { type: 'success-primary', onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, js_1.FeaturePublication.accept(entry.request.id)];
                                case 1:
                                    _a.sent();
                                    selected.set(null);
                                    listReload();
                                    return [2 /*return*/];
                            }
                        });
                    }); } }, t(['featurePublicationDetailsModal.accept', 'Controls.accept']))));
    }
    return aurum_1.React.createElement("div", { className: 'PublicationList' },
        modal,
        aurum_1.React.createElement(aurum_1.Sheet, { className: 'list' },
            aurum_1.React.createElement("div", { className: 'title' },
                aurum_1.React.createElement("h2", null, t('PublicationList.title')),
                !list
                    ? null
                    : aurum_1.React.createElement("div", { className: 'sub' }, list.totalElements === 0
                        ? t('Edit.navigation.paginationNone')
                        : t('Edit.navigation.pagination', {
                            from: list.number * list.size + 1,
                            to: Math.min(list.number * list.size + list.size, list.totalElements),
                            total: list.totalElements
                        }))),
            aurum_1.React.createElement(aurum_1.InputGroup, { className: 'search' },
                aurum_1.React.createElement(aurum_1.Multiselect, { native: false, label: t('PublicationList.navigation.status'), value: status.inputvalue, onChange: status.onChange, options: Object.keys(js_1.FeaturePublication.enum.status).map(function (v) {
                        return aurum_1.React.createElement("option", { key: v, value: v, label: v });
                    }) }),
                aurum_1.React.createElement(aurum_1.Button, null,
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'search' }),
                    t('Edit.navigation.search-button'))),
            aurum_1.React.createElement(aurum_1.InputGroup, { className: 'navigate' },
                aurum_1.React.createElement(aurum_1.Button, { disabled: list === null || list === void 0 ? void 0 : list.first, onClick: function () { return page.set(0); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'first_page' })),
                aurum_1.React.createElement(aurum_1.Button, { disabled: list === null || list === void 0 ? void 0 : list.first, onClick: function () { return page.set(page.value - 1); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'navigate_before' })),
                aurum_1.React.createElement(aurum_1.Select, { disabled: !(pageSelection === null || pageSelection === void 0 ? void 0 : pageSelection.length), label: t('Edit.navigation.page'), value: (pageSelection === null || pageSelection === void 0 ? void 0 : pageSelection.length) ? list === null || list === void 0 ? void 0 : list.number : '', onChange: page.onChange, options: pageSelection }),
                aurum_1.React.createElement(aurum_1.Button, { disabled: list === null || list === void 0 ? void 0 : list.last, onClick: function () { return page.set(page.value - -1); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'navigate_next' })),
                aurum_1.React.createElement(aurum_1.Button, { disabled: list === null || list === void 0 ? void 0 : list.last, onClick: function () { return page.set((list === null || list === void 0 ? void 0 : list.totalPages) - 1); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'last_page' }))),
            aurum_1.React.createElement(aurum_1.ButtonGroup, { className: 'pagesize' },
                aurum_1.React.createElement(aurum_1.Button, { onClick: function () {
                        size.set(20);
                        page.set(0);
                    }, type: size.value === 20 ? 'primary' : null }, "20"),
                aurum_1.React.createElement(aurum_1.Button, { onClick: function () {
                        size.set(100);
                        page.set(0);
                    }, type: size.value === 100 ? 'primary' : null }, "100")),
            aurum_1.React.createElement(aurum_1.Button, { className: 'reload', onClick: listReload },
                aurum_1.React.createElement(aurum_1.Icon, { type: 'refresh' })),
            aurum_1.React.createElement(aurum_1.Button, { className: 'expand', disabled: true },
                aurum_1.React.createElement(aurum_1.Icon, { type: 'expand_less' })),
            cachedTable));
};
exports.default = aurum_1.React.memo(PublicationList);
