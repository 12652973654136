"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergedComponentConfig = void 0;
var Address_1 = require("./Edit/Address");
var FaqLocalized_1 = require("./Edit/FaqLocalized");
var HintLocalized_1 = require("./Edit/HintLocalized");
var VoucherDraft_1 = require("./Edit/VoucherDraft");
var TradeTerm_1 = require("./Edit/TradeTerm");
var Owner_1 = require("./Edit/Owner");
exports.mergedComponentConfig = {
    Address: Address_1.Address,
    FaqLocalized: FaqLocalized_1.FaqLocalized,
    HintLocalized: HintLocalized_1.HintLocalized,
    VoucherDraft: VoucherDraft_1.VoucherDraft,
    TradeTerm: TradeTerm_1.TradeTerm,
    Owner: Owner_1.Owner
};
