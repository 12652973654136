"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOnChange = void 0;
var tslib_1 = require("tslib");
var react_1 = require("react");
var useOnChange = function (initialState, getMod, setMod) {
    if (initialState === void 0) { initialState = undefined; }
    if (getMod === void 0) { getMod = function (v) { return v; }; }
    if (setMod === void 0) { setMod = function (v) { return v; }; }
    var _a = tslib_1.__read((0, react_1.useState)(initialState), 2), value = _a[0], setValue = _a[1];
    var cachedChange = function (event) {
        setValue(setMod(event.target.type === 'checkbox' || event.target.type === 'radio'
            ? !!event.target.checked
            : (event.target.file || event.target.value || null)));
    };
    cachedChange.aurumIgnoreCheck = true;
    var onChange = (0, react_1.useCallback)(cachedChange, [initialState, setValue]);
    var cachedSet = function (newvalue) {
        setValue(setMod(newvalue));
    };
    cachedSet.aurumIgnoreCheck = true;
    var set = (0, react_1.useCallback)(cachedSet, [initialState, setValue]);
    // TODO: Müsste man das Result nicht auch cachen?
    return { value: value, set: set, onChange: onChange, inputvalue: value == null ? '' : getMod(value) };
};
exports.useOnChange = useOnChange;
