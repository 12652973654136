"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var js_1 = require("@samsa/samsa-api/js");
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var react_1 = require("react");
var useOnChange_1 = require("../hook/useOnChange");
var useSort_1 = require("../hook/useSort");
var useDebounce_1 = require("../hook/useDebounce");
var dateUtils_1 = require("../util/dateUtils");
var reSendEmailProgressState = {
    DONE: 0,
    PENDING: 1,
    ALERT: 2
};
var tablefields = [
    { name: 'recipientType' },
    { name: 'recipient' },
    { name: 'subject' },
    { name: 'content' },
    { name: 'dateSend' },
    { name: 'dateRead' },
    { name: 'deleted' }
];
var MessageList = function (props) {
    var _a, _b, _c, _d, _e;
    var field = props === null || props === void 0 ? void 0 : props.field;
    var selectedId = props === null || props === void 0 ? void 0 : props.selectedId;
    // console.log('Edit.render()');
    var t = (0, react_i18next_1.useTranslation)().t;
    var _f = tslib_1.__read((0, react_1.useState)([]), 2), pageSelection = _f[0], setPageSelection = _f[1];
    var selected = (0, useOnChange_1.useOnChange)();
    var size = (0, useOnChange_1.useOnChange)(20);
    var page = (0, useOnChange_1.useOnChange)(0);
    var _g = tslib_1.__read((0, react_1.useState)(null), 2), error = _g[0], setError = _g[1];
    var recipient = (0, useOnChange_1.useOnChange)();
    var search = (0, useOnChange_1.useOnChange)('');
    var searchDebounced = (0, useDebounce_1.useDebounce)(search.value);
    var _h = tslib_1.__read((0, useSort_1.useSort)('dateSend,desc'), 2), sort = _h[0], toggleSort = _h[1];
    var _j = tslib_1.__read(aurum_1.React.useState(null), 2), list = _j[0], setList = _j[1];
    var _k = tslib_1.__read(aurum_1.React.useState(null), 2), searchString = _k[0], setSearchString = _k[1];
    var _l = tslib_1.__read((0, react_1.useState)(reSendEmailProgressState.DONE), 2), reSendInProgress = _l[0], setReSendInProgress = _l[1];
    var _m = tslib_1.__read((0, react_1.useState)(null), 2), deleteModalData = _m[0], setDeleteModalData = _m[1];
    (0, react_1.useEffect)(function () {
        setSearchString(searchDebounced);
        page.set(0);
    }, [searchDebounced]);
    var listparams = {
        size: size.value,
        page: page.value,
        search: searchString,
        sort: [sort, 'id,asc'],
        'recipient.ownerId': props === null || props === void 0 ? void 0 : props.ownerId,
        'recipient.customerContactPersonId': props === null || props === void 0 ? void 0 : props.customerContactPersonId,
        'recipient.customerId': props === null || props === void 0 ? void 0 : props.customerId,
        'recipient.employeeId': props === null || props === void 0 ? void 0 : props.employeeId
    };
    if (selectedId) {
        listparams[field] = selectedId;
    }
    var _o = tslib_1.__read(aurum_1.React.useState(null), 2), errorData = _o[0], setErrorData = _o[1];
    var catchError = function (error) {
        var _a;
        var errorObject = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data;
        if ((errorObject === null || errorObject === void 0 ? void 0 : errorObject.fieldErrors) || (errorObject === null || errorObject === void 0 ? void 0 : errorObject.reason) || (errorObject === null || errorObject === void 0 ? void 0 : errorObject.error)) {
            setErrorData(tslib_1.__assign(tslib_1.__assign({}, errorObject), { showModal: true }));
        }
        else {
            setErrorData({ reason: error.message, showModal: true });
        }
    };
    var errorModal = null;
    if (errorData === null || errorData === void 0 ? void 0 : errorData.showModal) {
        errorModal = aurum_1.React.createElement(aurum_1.Modal, { className: 'simpleModal' },
            aurum_1.React.createElement(aurum_1.Header, null,
                aurum_1.React.createElement("h2", null, "".concat(t('Edit.errorModal.title')))),
            aurum_1.React.createElement(aurum_1.Body, null,
                aurum_1.React.createElement(aurum_1.Row, null, "".concat(t('Edit.errorModal.reason'), ": '").concat(t('Edit.errorModal.Enum.' + (errorData.reason || errorData.error)), "'")),
                errorData.message ? aurum_1.React.createElement(aurum_1.Row, null, errorData.message) : null,
                errorData.fieldErrors
                    ? aurum_1.React.createElement(aurum_1.React.Fragment, null,
                        aurum_1.React.createElement(aurum_1.Row, null, "".concat(t('Edit.errorModal.fieldErrors'), ":")),
                        errorData.fieldErrors.map(function (e) {
                            return aurum_1.React.createElement(aurum_1.Row, { key: e.field }, '• ' + t("EntityAttribute.".concat(e.field)) + ": ".concat(e.messageList.join(', ')));
                        }))
                    : null),
            aurum_1.React.createElement(aurum_1.Footer, null,
                aurum_1.React.createElement(aurum_1.Button, { type: 'error', onClick: function () {
                        setErrorData(tslib_1.__assign(tslib_1.__assign({}, errorData), { showModal: false }));
                    } }, t(['Edit.errorModal.close', 'Controls.close']))));
    }
    var _p = tslib_1.__read(aurum_1.React.useState(null), 2), viewModalEmployee = _p[0], setViewModalEmployee = _p[1];
    var _q = tslib_1.__read(aurum_1.React.useState(null), 2), employee = _q[0], setEmployee = _q[1];
    var loadEmployee = (0, react_1.useCallback)(function (id) {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var r, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        console.log('id', id);
                        return [4 /*yield*/, js_1.Employee.getAll({ accountId: id })];
                    case 1:
                        r = _a.sent();
                        setEmployee(r.content[0]);
                        setViewModalEmployee(true);
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); })();
    }, [viewModalEmployee]);
    var emailOverride = (0, useOnChange_1.useOnChange)();
    var listReload = aurum_1.React.useCallback(function () {
        setError(null);
        var cancelToken = (0, js_1.getCancelToken)();
        var promiseOwner = (0, js_1.getAllMessage)(listparams, {
            cancelToken: cancelToken.token
        });
        promiseOwner.then(function (result) {
            setList(result);
        }).catch(function (e) {
            if (!(0, js_1.isCancel)(e)) {
                console.error('ERROR listReload', e.message, e);
                setList(null);
                setError(e);
            }
        });
        return function () {
            cancelToken.cancel();
        };
    }, [JSON.stringify(listparams), recipient.value]);
    aurum_1.React.useEffect(listReload, [listReload]);
    var getDateTime = function (dateTimeAsString) {
        if (dateTimeAsString != null) {
            return dateTimeAsString.split('.')[0];
        }
        return '';
    };
    var _r = tslib_1.__read(aurum_1.React.useState(null), 2), entry = _r[0], setEntry = _r[1];
    var _s = tslib_1.__read(aurum_1.React.useState(false), 2), previewEntry = _s[0], setPreviewEntry = _s[1];
    var _t = tslib_1.__read(aurum_1.React.useState(null), 2), protocolData = _t[0], setProtocolData = _t[1];
    var entryparams = selected.value;
    var _u = tslib_1.__read((0, react_1.useState)(null), 2), content = _u[0], setContent = _u[1];
    var entryReload = aurum_1.React.useCallback(function () {
        if (entryparams) {
            var promise = (0, js_1.getMessage)(entryparams);
            promise.then(function (result) {
                setEntry(result);
            }).catch(function (e) {
                console.error('ERROR entryReload', e.message, e);
                setEntry(null);
            });
        }
        else {
            setEntry(null);
        }
    }, [JSON.stringify(entryparams)]);
    aurum_1.React.useEffect(entryReload, [entryReload]);
    var _w = tslib_1.__read(aurum_1.React.useState(null), 2), cachedTable = _w[0], setCachedTable = _w[1];
    aurum_1.React.useEffect(function () {
        var _a;
        // console.log('setCachedTable.render()');
        setCachedTable(aurum_1.React.createElement(aurum_1.Table, { className: 'table' },
            aurum_1.React.createElement("thead", null,
                aurum_1.React.createElement("tr", null, tablefields.map(function (prop) {
                    if (prop.name === 'date') {
                        return aurum_1.React.createElement("th", { key: prop.name }, t("EntityAttribute.".concat(prop.name)));
                    }
                    var title = t("Entity.".concat(js_1.Message.name, ".").concat(prop.name), "EntityAttribute.".concat(prop.name));
                    if (prop.name.startsWith('generatedFor')) {
                        title = title.split(' ').slice(0, 3).join(' ');
                    }
                    return aurum_1.React.createElement("th", { key: prop.name, onClick: toggleSort(prop.name) },
                        title,
                        sort === "".concat(prop.name, ",asc") ? aurum_1.React.createElement(aurum_1.Icon, { type: 'arrow_drop_up' }) : null,
                        sort === "".concat(prop.name, ",desc") ? aurum_1.React.createElement(aurum_1.Icon, { type: 'arrow_drop_down' }) : null);
                }))),
            aurum_1.React.createElement("tbody", null, error
                ? aurum_1.React.createElement("tr", null,
                    aurum_1.React.createElement("td", { colSpan: tablefields.length },
                        aurum_1.React.createElement(aurum_1.Alert, { type: 'error' }, ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) ? error.message + ' | ' + error.response.data.error_description : error.message)))
                : (!list ? aurum_1.React.createElement("tr", null,
                    aurum_1.React.createElement("td", { colSpan: tablefields.length },
                        aurum_1.React.createElement(aurum_1.Progress, null))) : list.content.map(function (entry) {
                    // @ts-ignore
                    entry.setter = entry.setter || (function () { return selected.set(entry.id); });
                    var className = null;
                    if (entry.id === selected.value) {
                        className = 'primary';
                    }
                    else if (entry.deleted) {
                        className = 'warn';
                    }
                    // @ts-ignore
                    return aurum_1.React.createElement("tr", { key: entry.id, onClick: entry.setter, className: className }, tablefields.map(function (prop) {
                        var _a, _b, _c, _d, _e, _f, _g;
                        if (prop.name === 'dateSend') {
                            return aurum_1.React.createElement("td", { key: prop.name }, (0, dateUtils_1.formatDateFull)(entry.dateSend));
                        }
                        if (prop.name === 'dateRead') {
                            return aurum_1.React.createElement("td", { key: prop.name }, (0, dateUtils_1.formatDateFull)(entry.dateRead));
                        }
                        var val = (entry[prop.name] != null ? entry[prop.name] : '');
                        if (prop.name === 'recipient') {
                            val = (_b = (_a = entry.recipient) === null || _a === void 0 ? void 0 : _a.emailAddress) !== null && _b !== void 0 ? _b : '';
                        }
                        else if (prop.name === 'content') {
                            var content_1 = (_c = entry.content) !== null && _c !== void 0 ? _c : '';
                            var contentMaxLength = 50;
                            if (content_1.length <= contentMaxLength) {
                                val = content_1;
                            }
                            else {
                                val = content_1.substr(0, contentMaxLength - 3) + '...';
                            }
                        }
                        else if (prop.name === 'deleted') {
                            if (entry[prop.name] === true) {
                                val = '✓';
                            }
                            else if (entry[prop.name] === false) {
                                val = '✗';
                            }
                            else {
                                val = '?';
                            }
                        }
                        else if (prop.name === 'recipientType') {
                            if ((_d = entry.recipient) === null || _d === void 0 ? void 0 : _d.ownerId) {
                                val = 'Partner';
                            }
                            else if ((_e = entry.recipient) === null || _e === void 0 ? void 0 : _e.employeeId) {
                                val = 'Mitarbeiter (Partner)';
                            }
                            else if ((_f = entry.recipient) === null || _f === void 0 ? void 0 : _f.customerId) {
                                val = 'Kunde';
                            }
                            else if ((_g = entry.recipient) === null || _g === void 0 ? void 0 : _g.customerContactPersonId) {
                                val = 'Ansprechpartner (Kunde)';
                            }
                            else {
                                val = 'Gast';
                            }
                        }
                        else {
                            val = val.toString();
                        }
                        return aurum_1.React.createElement("td", { key: prop.name }, val);
                    }));
                })))));
    }, [list, entry, entry === null || entry === void 0 ? void 0 : entry.id, error]);
    var totalPages = (_a = list === null || list === void 0 ? void 0 : list.totalPages) !== null && _a !== void 0 ? _a : 1;
    aurum_1.React.useEffect(function () {
        setPageSelection(Array.apply(null, Array(totalPages))
            // eslint-disable-next-line react/no-array-index-key
            .map(function (_v, page) { return aurum_1.React.createElement("option", { key: page, value: page, label: (page + 1) + '/' + totalPages }); }));
    }, [totalPages]);
    var previewModal = null;
    if (previewEntry) {
        previewModal = aurum_1.React.createElement(aurum_1.Modal, { className: 'previewModal', onClickBackdrop: function () {
                setPreviewEntry(false);
            } },
            aurum_1.React.createElement(aurum_1.Header, null,
                aurum_1.React.createElement("h2", null, t('MessageList.previewModal.title'))),
            aurum_1.React.createElement(aurum_1.Body, null,
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement(aurum_1.Input, { label: t('EntityAttribute.subject'), value: entry.subject })),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement(aurum_1.Input, { label: t('EntityAttribute.dateSend'), type: 'datetime', value: getDateTime(entry.dateSend) }),
                    aurum_1.React.createElement(aurum_1.Input, { label: t('EntityAttribute.dateRead'), type: 'datetime', value: getDateTime(entry.dateRead) })),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("div", { dangerouslySetInnerHTML: { __html: content } })),
                aurum_1.React.createElement("div", { className: 'filelist' }, entry.files.map(function (linkedFile) {
                    var _a, _b;
                    var children = [];
                    if (linkedFile.file.contentType.startsWith('image/')) {
                        children.push(aurum_1.React.createElement("div", { key: 'image', className: 'image', style: {
                                backgroundImage: "url(".concat((0, js_1.getUrl)(linkedFile.file, {
                                    width: 160,
                                    height: 160 /*,
                                                         fit: 'inside' */
                                }), ")")
                            } }));
                        children.push(aurum_1.React.createElement(aurum_1.Icon, { key: 'open', className: 'open', type: 'search', onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    return [2 /*return*/];
                                });
                            }); } }));
                    }
                    return aurum_1.React.createElement("div", { key: linkedFile.id, className: 'file' },
                        children,
                        aurum_1.React.createElement("div", { className: 'title' },
                            aurum_1.React.createElement("span", null, (_b = (_a = linkedFile.localizations) === null || _a === void 0 ? void 0 : _a.de) === null || _b === void 0 ? void 0 : _b.title)),
                        aurum_1.React.createElement(aurum_1.Icon, { className: 'delete', type: 'delete', onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    entryReload();
                                    return [2 /*return*/];
                                });
                            }); } }));
                }))),
            aurum_1.React.createElement(aurum_1.Footer, null,
                aurum_1.React.createElement("span", null),
                aurum_1.React.createElement(aurum_1.Button, { type: 'error', onClick: function () {
                        setPreviewEntry(false);
                    } }, t(['previewModal.close', 'Controls.close']))));
    }
    var protocolModal = null;
    if (protocolData) {
        protocolModal = aurum_1.React.createElement(aurum_1.Modal, { className: 'protocol-modal' },
            aurum_1.React.createElement(aurum_1.Header, null,
                aurum_1.React.createElement("h2", null, t('MessageList.protocolModal.title'))),
            aurum_1.React.createElement(aurum_1.Body, null, protocolData.content
                ? aurum_1.React.createElement(aurum_1.Table, null,
                    aurum_1.React.createElement("thead", null,
                        aurum_1.React.createElement("tr", null,
                            aurum_1.React.createElement("th", null, t('EntityAttribute.message')),
                            aurum_1.React.createElement("th", null, t('EntityAttribute.createdAt')),
                            aurum_1.React.createElement("th", null, t('Entity.Employee')))),
                    aurum_1.React.createElement("tbody", null, protocolData.content.sort(function (a, b) {
                        if (a.createdAt === b.createdAt) {
                            return a.message < b.message ? -1 : 1;
                        }
                        return a.createdAt > b.createdAt ? -1 : 1;
                    }).map(function (e) {
                        var _a;
                        return aurum_1.React.createElement("tr", { key: e.id, onClick: function () {
                                if (e.changedByAccountId) {
                                    loadEmployee(e.changedByAccountId);
                                }
                            } },
                            aurum_1.React.createElement("td", null, e.message),
                            aurum_1.React.createElement("td", null, (0, dateUtils_1.formatDateFull)(e.createdAt)),
                            aurum_1.React.createElement("td", null, (_a = e.changedByAccountName) !== null && _a !== void 0 ? _a : 'n/v'));
                    })))
                : 'Keine Protokolleinträge verfügbar'),
            aurum_1.React.createElement(aurum_1.Footer, null,
                aurum_1.React.createElement("span", null),
                aurum_1.React.createElement(aurum_1.Button, { type: 'error', onClick: function () {
                        setProtocolData(null);
                    } }, t('Controls.close'))));
    }
    var modalEmployee = null;
    if (viewModalEmployee) {
        modalEmployee = aurum_1.React.createElement(aurum_1.Modal, { className: 'EmployeeModal' },
            aurum_1.React.createElement(aurum_1.Header, null,
                aurum_1.React.createElement("h2", null, t('Entity.Employee'))),
            aurum_1.React.createElement(aurum_1.Body, null, employee
                ? aurum_1.React.createElement("div", null,
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement("p", null, t('EntityAttribute.familyName')),
                        aurum_1.React.createElement("p", null, employee.familyName)),
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement("p", null, t('EntityAttribute.firstName')),
                        aurum_1.React.createElement("p", null, employee.givenName)),
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement("p", null, t('EntityAttribute.email')),
                        aurum_1.React.createElement("p", null, employee.email)))
                : aurum_1.React.createElement(aurum_1.Progress, null, t('BookingList.loading'))),
            aurum_1.React.createElement(aurum_1.Footer, null,
                aurum_1.React.createElement(aurum_1.Button, { className: 'btnClose', type: 'error', onClick: function () {
                        setViewModalEmployee(false);
                    } }, t(['bookingDetailsModal.close', 'Controls.close']))));
    }
    var modal = null;
    if (entry) {
        var reSendEmailComponent = void 0;
        if (reSendInProgress === reSendEmailProgressState.PENDING) {
            reSendEmailComponent = aurum_1.React.createElement(aurum_1.Progress, { span: 2 });
        }
        else if (reSendInProgress === reSendEmailProgressState.ALERT) {
            reSendEmailComponent = aurum_1.React.createElement(aurum_1.Alert, { className: 'resend-email-alert', span: 2, type: 'success' }, "E-Mail versandt!");
        }
        else {
            reSendEmailComponent = aurum_1.React.createElement(aurum_1.InputGroup, { span: 2 },
                aurum_1.React.createElement(aurum_1.Input, { label: 'E-Mail-Adresse', placeholder: (_b = entry.recipient) === null || _b === void 0 ? void 0 : _b.emailAddress, value: emailOverride.inputvalue, onChange: emailOverride.onChange }),
                aurum_1.React.createElement(aurum_1.Button, { type: 'primary', onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                        var e_2;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    setReSendInProgress(reSendEmailProgressState.PENDING);
                                    _a.label = 1;
                                case 1:
                                    _a.trys.push([1, 3, , 4]);
                                    return [4 /*yield*/, (0, js_1.reSendEmail)({ messageId: entry.id, email: { email: emailOverride.inputvalue } })];
                                case 2:
                                    _a.sent();
                                    entryReload();
                                    emailOverride.set(null);
                                    setReSendInProgress(reSendEmailProgressState.ALERT);
                                    window.setTimeout(function () {
                                        setReSendInProgress(reSendEmailProgressState.DONE);
                                    }, 4000);
                                    return [3 /*break*/, 4];
                                case 3:
                                    e_2 = _a.sent();
                                    catchError(e_2);
                                    setReSendInProgress(reSendEmailProgressState.DONE);
                                    return [3 /*break*/, 4];
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); } }, "E-Mail erneut senden"));
        }
        var recipientName = null;
        if ((_c = entry.recipient) === null || _c === void 0 ? void 0 : _c.customerId) {
            recipientName = 'Kunde';
        }
        else if ((_d = entry.recipient) === null || _d === void 0 ? void 0 : _d.ownerId) {
            recipientName = 'Partner';
        }
        else if ((_e = entry.recipient) === null || _e === void 0 ? void 0 : _e.emailAddress) {
            recipientName = entry.recipient.emailAddress;
        }
        modal = aurum_1.React.createElement(aurum_1.Modal, { className: 'messageDetailModal', onClickBackdrop: function () {
                selected.set(null);
            } },
            aurum_1.React.createElement(aurum_1.Header, null,
                aurum_1.React.createElement("h2", null, t('MessageList.detailsModal.title') + (entry.deleted ? ' (Benachrichtigung wurde gelöscht)' : ''))),
            aurum_1.React.createElement(aurum_1.Body, null,
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement(aurum_1.Input, { label: t('EntityAttribute.subject'), value: entry.subject, span: 3 }),
                    aurum_1.React.createElement(aurum_1.Input, { label: t('EntityAttribute.dateSend'), type: 'datetime', value: getDateTime(entry.dateSend) }),
                    aurum_1.React.createElement(aurum_1.Input, { label: t('EntityAttribute.dateRead'), type: 'datetime', value: getDateTime(entry.dateRead) })),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement(aurum_1.Textarea, { label: t('EntityAttribute.content'), value: entry.content })),
                aurum_1.React.createElement("div", { className: 'filelist' }, entry.files.map(function (linkedFile) {
                    var _a, _b;
                    var children = [];
                    if (linkedFile.file.contentType.startsWith('image/')) {
                        children.push(aurum_1.React.createElement("div", { key: 'image', className: 'image', style: {
                                backgroundImage: "url(".concat((0, js_1.getUrl)(linkedFile.file, {
                                    width: 160,
                                    height: 160 /*,
                                                         fit: 'inside' */
                                }), ")")
                            } }));
                        children.push(aurum_1.React.createElement(aurum_1.Icon, { key: 'open', className: 'open', type: 'search', onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    return [2 /*return*/];
                                });
                            }); } }));
                    }
                    return aurum_1.React.createElement("div", { key: linkedFile.id, className: 'file' },
                        children,
                        aurum_1.React.createElement("div", { className: 'title' },
                            aurum_1.React.createElement("span", null, (_b = (_a = linkedFile.localizations) === null || _a === void 0 ? void 0 : _a.de) === null || _b === void 0 ? void 0 : _b.title)),
                        aurum_1.React.createElement(aurum_1.Icon, { className: 'delete', type: 'delete', onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    // await Type.deleteFile({
                                    //   linkedFileId: linkedFile.id,
                                    //   id: entry.id
                                    // });
                                    entryReload();
                                    return [2 /*return*/];
                                });
                            }); } }));
                })),
                aurum_1.React.createElement(aurum_1.Row, null,
                    js_1.reSendEmail.isAllowed() ? reSendEmailComponent : null,
                    aurum_1.React.createElement(aurum_1.Button, { onClick: function () {
                            setContent(entry.content);
                            setPreviewEntry(true);
                        } }, t('MessageList.Preview')),
                    aurum_1.React.createElement(aurum_1.Button, { onClick: function () {
                            setContent(entry.contentEmail);
                            setPreviewEntry(true);
                        } }, t('MessageList.PreviewEmail')),
                    aurum_1.React.createElement(aurum_1.Button, { onClick: function () {
                            setContent(entry.contentSms);
                            setPreviewEntry(true);
                        } }, t('MessageList.PreviewSms')),
                    aurum_1.React.createElement(aurum_1.Button, { type: 'primary', onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                            var pageOfProtocol, e_3;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, js_1.Protocol.getAll({ messageId: entry.id })];
                                    case 1:
                                        pageOfProtocol = _a.sent();
                                        setProtocolData(pageOfProtocol);
                                        return [3 /*break*/, 3];
                                    case 2:
                                        e_3 = _a.sent();
                                        catchError(e_3);
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); } }, t('MessageList.Protocol')))),
            aurum_1.React.createElement(aurum_1.Footer, null,
                props.setTabFilter && props.setTab && props.setFilter && recipientName
                    ? aurum_1.React.createElement(aurum_1.Button, { className: 'go-go-magic-button', onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                            var customerId, ownerId, email_1, customer_1, field_1, owner_1, field_2, field_3, e_4;
                            var _a, _b, _c;
                            return tslib_1.__generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0:
                                        _d.trys.push([0, 6, , 7]);
                                        customerId = (_a = entry.recipient) === null || _a === void 0 ? void 0 : _a.customerId;
                                        ownerId = (_b = entry.recipient) === null || _b === void 0 ? void 0 : _b.ownerId;
                                        email_1 = (_c = entry.recipient) === null || _c === void 0 ? void 0 : _c.emailAddress;
                                        if (!customerId) return [3 /*break*/, 2];
                                        return [4 /*yield*/, js_1.Customer.get(customerId)];
                                    case 1:
                                        customer_1 = _d.sent();
                                        field_1 = 'recipient.customerId';
                                        props.setTabFilter(function (state) { return (tslib_1.__assign(tslib_1.__assign({}, state), { field: field_1, selectedId: customer_1.id, entry: customer_1 })); });
                                        page.set(0);
                                        selected.set(null);
                                        return [3 /*break*/, 5];
                                    case 2:
                                        if (!ownerId) return [3 /*break*/, 4];
                                        return [4 /*yield*/, js_1.Owner.get(ownerId)];
                                    case 3:
                                        owner_1 = _d.sent();
                                        field_2 = 'recipient.ownerId';
                                        props.setTabFilter(function (state) { return (tslib_1.__assign(tslib_1.__assign({}, state), { field: field_2, selectedId: owner_1.id, entry: owner_1 })); });
                                        page.set(0);
                                        selected.set(null);
                                        return [3 /*break*/, 5];
                                    case 4:
                                        if (email_1) {
                                            field_3 = 'recipient.emailAddress';
                                            props.setTabFilter(function (state) { return (tslib_1.__assign(tslib_1.__assign({}, state), { field: field_3, selectedId: email_1, entry: entry.recipient.toString() })); });
                                            page.set(0);
                                            selected.set(null);
                                        }
                                        _d.label = 5;
                                    case 5: return [3 /*break*/, 7];
                                    case 6:
                                        e_4 = _d.sent();
                                        catchError(e_4);
                                        return [3 /*break*/, 7];
                                    case 7: return [2 /*return*/];
                                }
                            });
                        }); } },
                        recipientName,
                        " als Filter",
                        aurum_1.React.createElement(aurum_1.Icon, { type: 'open_in_new', size: 'big' }))
                    : null,
                aurum_1.React.createElement("span", null),
                aurum_1.React.createElement(aurum_1.Button, { type: 'error-primary', disabled: entry.deleted, onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            setDeleteModalData(true);
                            return [2 /*return*/];
                        });
                    }); } }, t('EntityAttribute.delete')),
                aurum_1.React.createElement(aurum_1.Button, { type: 'error', onClick: function () {
                        selected.set(null);
                    } }, t(['messageDetailModal.close', 'Controls.close']))));
    }
    var deleteModal = null;
    if (deleteModalData) {
        deleteModal = aurum_1.React.createElement(aurum_1.Modal, { className: 'simpleModal' },
            aurum_1.React.createElement(aurum_1.Header, null,
                aurum_1.React.createElement("h2", null, "Benachrichtigung l\u00F6schen")),
            aurum_1.React.createElement(aurum_1.Body, null, "Sind Sie sich sicher, dass Sie dieses Element l\u00F6schen m\u00F6chten?"),
            aurum_1.React.createElement(aurum_1.Footer, null,
                aurum_1.React.createElement(aurum_1.Button, { type: 'error-primary', onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                        var e_5;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, (0, js_1.deleteMessage)(entry.id)];
                                case 1:
                                    _a.sent();
                                    selected.set(null);
                                    listReload();
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_5 = _a.sent();
                                    catchError(e_5);
                                    return [3 /*break*/, 3];
                                case 3:
                                    setDeleteModalData(null);
                                    return [2 /*return*/];
                            }
                        });
                    }); } }, "L\u00F6schen"),
                aurum_1.React.createElement(aurum_1.Button, { type: 'error', onClick: function () {
                        setDeleteModalData(null);
                    } }, t(['Edit.errorModal.close', 'Controls.close']))));
    }
    return aurum_1.React.createElement("div", { className: 'MessageList' },
        errorModal,
        protocolModal,
        previewModal,
        deleteModal,
        modal,
        modalEmployee,
        aurum_1.React.createElement(aurum_1.Sheet, { className: 'list' },
            aurum_1.React.createElement("div", { className: 'title' },
                aurum_1.React.createElement("h2", null, t('MessageList.title')),
                !list
                    ? null
                    : aurum_1.React.createElement("div", { className: 'sub' }, list.totalElements === 0
                        ? t('Edit.navigation.paginationNone')
                        : t('Edit.navigation.pagination', {
                            from: list.number * list.size + 1,
                            to: Math.min(list.number * list.size + list.size, list.totalElements),
                            total: list.totalElements
                        }))),
            aurum_1.React.createElement(aurum_1.InputGroup, { className: 'search' },
                aurum_1.React.createElement(aurum_1.Input, { autoFocus: true, type: 'text', label: t('Edit.navigation.search-input'), value: search.inputvalue, onChange: search.onChange }),
                aurum_1.React.createElement(aurum_1.Button, null,
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'search' }),
                    t('Edit.navigation.search-button'))),
            aurum_1.React.createElement(aurum_1.InputGroup, { className: 'navigate' },
                aurum_1.React.createElement(aurum_1.Button, { disabled: list === null || list === void 0 ? void 0 : list.first, onClick: function () { return page.set(0); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'first_page' })),
                aurum_1.React.createElement(aurum_1.Button, { disabled: list === null || list === void 0 ? void 0 : list.first, onClick: function () { return page.set(page.value - 1); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'navigate_before' })),
                aurum_1.React.createElement(aurum_1.Select, { disabled: !(pageSelection === null || pageSelection === void 0 ? void 0 : pageSelection.length), label: t('Edit.navigation.page'), value: (pageSelection === null || pageSelection === void 0 ? void 0 : pageSelection.length) ? list === null || list === void 0 ? void 0 : list.number : '', onChange: page.onChange, options: pageSelection }),
                aurum_1.React.createElement(aurum_1.Button, { disabled: list === null || list === void 0 ? void 0 : list.last, onClick: function () { return page.set(page.value - -1); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'navigate_next' })),
                aurum_1.React.createElement(aurum_1.Button, { disabled: list === null || list === void 0 ? void 0 : list.last, onClick: function () { return page.set((list === null || list === void 0 ? void 0 : list.totalPages) - 1); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'last_page' }))),
            aurum_1.React.createElement(aurum_1.ButtonGroup, { className: 'pagesize' },
                aurum_1.React.createElement(aurum_1.Button, { onClick: function () {
                        size.set(20);
                        page.set(0);
                    }, type: size.value === 20 ? 'primary' : null }, "20"),
                aurum_1.React.createElement(aurum_1.Button, { onClick: function () {
                        size.set(100);
                        page.set(0);
                    }, type: size.value === 100 ? 'primary' : null }, "100")),
            aurum_1.React.createElement(aurum_1.Button, { className: 'reload', onClick: listReload },
                aurum_1.React.createElement(aurum_1.Icon, { type: 'refresh' })),
            aurum_1.React.createElement(aurum_1.Button, { className: 'expand', disabled: true },
                aurum_1.React.createElement(aurum_1.Icon, { type: 'expand_less' })),
            cachedTable));
};
exports.default = aurum_1.React.memo(MessageList);
