"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ownerBookingToBooking = void 0;
var js_1 = require("@samsa/samsa-api/js");
var ownerBookingToBooking = function (ownerBooking) { return new js_1.Booking({
    id: ownerBooking.id,
    paymentInfo: new js_1.PaymentInfo({
        status: ownerBooking.paymentStatus,
        total: ownerBooking.totalGrossPrice,
        paid: ownerBooking.paid,
        payments: undefined,
        paymentDate: undefined,
        cancellationDate: undefined,
        instalments: undefined
    }),
    positions: ownerBooking.positions,
    bookingDate: ownerBooking.bookingDate,
    files: undefined,
    customer: ownerBooking.customer,
    shortId: ownerBooking.shortId,
    custom: undefined,
    locked: undefined,
    owner: undefined,
    newsletter: undefined,
    reference: undefined,
    comment: ownerBooking.comment
}); };
exports.ownerBookingToBooking = ownerBookingToBooking;
