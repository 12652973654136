"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var js_1 = require("@samsa/samsa-api/js");
var dateUtils_1 = require("../../util/dateUtils");
var formatPrice_1 = require("../../util/formatPrice");
var CustomerPaymentSearchBar_1 = require("./CustomerPaymentSearchBar");
var CustomerInvoiceUtils_1 = require("./CustomerInvoiceUtils");
var useOnChange_1 = require("../../hook/useOnChange");
var getErrorMessage_1 = require("../../util/getErrorMessage");
function PendingCustomerInvoiceList(props) {
    var _this = this;
    var className = 'PendingCustomerInvoice';
    var t = (0, react_i18next_1.useTranslation)().t;
    var fromDate = new Date();
    fromDate.setDate(1);
    var toDate = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0, 23, 59, 59);
    var _a = tslib_1.__read((0, react_1.useState)({
        bookingShortId: null,
        selectedCustomers: [],
        from: (0, dateUtils_1.toDateString)(fromDate),
        to: (0, dateUtils_1.toDateString)(toDate),
        fetchDateType: CustomerPaymentSearchBar_1.fetchDateTypeEnum.PAYMENT_DATE
    }), 2), searchData = _a[0], setSearchData = _a[1];
    var _b = tslib_1.__read((0, react_1.useState)(null), 2), data = _b[0], setData = _b[1];
    var _c = tslib_1.__read((0, react_1.useState)(null), 2), selectedData = _c[0], setSelectedData = _c[1];
    var _d = tslib_1.__read((0, react_1.useState)(null), 2), dataDetails = _d[0], setDataDetails = _d[1];
    var _e = tslib_1.__read((0, react_1.useState)(false), 2), loading = _e[0], setLoading = _e[1];
    var _f = tslib_1.__read((0, react_1.useState)(null), 2), error = _f[0], setError = _f[1];
    var _g = tslib_1.__read((0, react_1.useState)(null), 2), minInvoiceDate = _g[0], setMinInvoiceDate = _g[1];
    var invoiceDate = (0, useOnChange_1.useOnChange)((0, dateUtils_1.toDateString)(new Date(Date.now())));
    // Daten laden
    var loadData = function () {
        (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var page, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('loadData');
                        setLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, js_1.PendingCustomerInvoice.getAll({
                                criteria: (0, CustomerInvoiceUtils_1.translateToPaymentCriteria)(searchData),
                                page: 0,
                                size: 0
                            })];
                    case 2:
                        page = _a.sent();
                        setData(page);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        setData(null);
                        return [3 /*break*/, 4];
                    case 4:
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        }); })();
    };
    (0, react_1.useEffect)(loadData, [searchData]);
    // Details laden
    (0, react_1.useEffect)(function () {
        (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var result, maxPaymentDate, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!selectedData) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, js_1.PendingCustomerInvoicePosition.getAll({
                                receiverCustomerId: selectedData.receiver.customerId,
                                simplePaymentCriteria: (0, CustomerInvoiceUtils_1.translateToPaymentCriteria)(searchData),
                                page: 0,
                                size: 0
                            })];
                    case 2:
                        result = _a.sent();
                        setDataDetails(result);
                        maxPaymentDate = result === null || result === void 0 ? void 0 : result.content.map(function (e) { return new Date(e.paymentDate); }).sort(function (a, b) { return a > b ? -1 : 1; }).shift();
                        setMinInvoiceDate((0, dateUtils_1.toDateString)(maxPaymentDate));
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        console.error('ERROR entryReload', e_2.message, e_2);
                        setDataDetails(null);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        setDataDetails(null);
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        }); })();
    }, [selectedData]); // Was ist mit 'searchData'?
    var resetSelection = function () { return setSelectedData(null); };
    var createInvoice = function () {
        var request = new js_1.CustomerInvoiceRequest(tslib_1.__assign(tslib_1.__assign({}, (0, CustomerInvoiceUtils_1.translateToPaymentCriteria)(searchData)), { positionIdFilter: [], receiverCustomer: selectedData.receiver.customerId, manualInvoiceDate: invoiceDate.value }));
        js_1.CustomerInvoice.create(request).then(function (value) {
            console.log('Rechnung wurde erstellt:', value.id);
            setSelectedData(null);
            loadData();
            if (props.onInvoiceCreation) {
                props.onInvoiceCreation(value);
            }
            // TODO hier müsste nun ein Modal mit der PDF angezeigt werden,
            //  in der diese per Mail (default Customer Email Adresse (value.receiver.email))
            //  verschickt oder runtergeladen werden kann.
        }).catch(function (e) {
            setSelectedData(null);
            loadData();
            handleError(e);
        });
    };
    function handleError(e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var messsage;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (0, getErrorMessage_1.getErrorMessage)(e)];
                    case 1:
                        messsage = _a.sent();
                        setError('Fehler bei der Erstellung der Rechnung: ' + messsage);
                        console.log('Fehler bei der Erstellung der Rechnung:', messsage, JSON.stringify(e));
                        return [2 /*return*/];
                }
            });
        });
    }
    function displayError() {
        return react_1.default.createElement("div", null,
            react_1.default.createElement("p", { className: 'error' }, error));
    }
    function dataTable() {
        return (data === null || data === void 0 ? void 0 : data.numberOfElements) === 0
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            ? noData()
            : react_1.default.createElement(aurum_1.Table, null,
                react_1.default.createElement("thead", null,
                    react_1.default.createElement("tr", null,
                        react_1.default.createElement("th", null, t('EntityAttribute.shortId')),
                        react_1.default.createElement("th", null, t('EntityAttribute.companyName')),
                        react_1.default.createElement("th", null, t('EntityAttribute.email')),
                        react_1.default.createElement("th", null,
                            t(className + '.total'),
                            react_1.default.createElement("br", null),
                            t('Enum.GROSS')))),
                react_1.default.createElement("tbody", null, data === null || data === void 0 ? void 0 : data.content.map(function (value) {
                    // @ts-ignore
                    value.setter = value.setter || (function () { return setSelectedData(value); });
                    // @ts-ignore
                    return react_1.default.createElement("tr", { key: value.receiver.customerId, onClick: value.setter },
                        react_1.default.createElement("td", null, value.receiver.customerShortId),
                        react_1.default.createElement("td", null, value.receiver.companyName),
                        react_1.default.createElement("td", null, value.receiver.email),
                        react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(value.totalGross)));
                })));
    }
    // gruppiere die Einzelnen PendingCustomerInvoicePositions nach bookingId, bookingPosId & paymentId zusammen
    var getGroupedPositions = function (customerPosList) {
        return customerPosList.content
            .reduce(function (newMap, pos) {
            var key = pos.bookingId.concat('_')
                .concat(pos.bookingPositionId).concat('_')
                .concat(pos.paymentId);
            if (!newMap[key]) {
                newMap[key] = [];
            }
            newMap[key].push(pos);
            return newMap;
        }, {});
    };
    function dataDetailsModal() {
        if (selectedData && dataDetails) {
            var groupedPositions = getGroupedPositions(dataDetails);
            // console.log('dataDetailsModal');
            return react_1.default.createElement(aurum_1.Modal, { className: 'dataDetailsModal', onClickBackdrop: resetSelection },
                react_1.default.createElement(aurum_1.Header, null,
                    react_1.default.createElement("h2", null,
                        t(className + '.dataDetailsModal.title'),
                        ": ", selectedData === null || selectedData === void 0 ? void 0 :
                        selectedData.receiver.companyName)),
                react_1.default.createElement(aurum_1.Body, null,
                    react_1.default.createElement(aurum_1.Table, null,
                        react_1.default.createElement("thead", null,
                            react_1.default.createElement("tr", null,
                                react_1.default.createElement("th", null, t('EntityAttribute.bookingId')),
                                react_1.default.createElement("th", null, t('EntityAttribute.title')),
                                react_1.default.createElement("th", null, t('EntityAttribute.travelTimespan')),
                                react_1.default.createElement("th", null, t('EntityAttribute.bookingDate')),
                                react_1.default.createElement("th", null, t('EntityAttribute.paymentId')),
                                react_1.default.createElement("th", null, t('EntityAttribute.paymentDate')),
                                react_1.default.createElement("th", null,
                                    t(className + '.total'),
                                    react_1.default.createElement("br", null),
                                    t('Enum.GROSS')))),
                        react_1.default.createElement("tbody", null, Object.entries(groupedPositions)
                            .map(function (_a) {
                            var _b, _c;
                            var _d = tslib_1.__read(_a, 2), valueList = _d[1];
                            var value = valueList[0];
                            var sumGross = valueList.map(function (pos) { return pos.gross; }).reduce(function (sum, gross) { return sum + gross; });
                            var _e = tslib_1.__read((0, dateUtils_1.formatDateFull)(value.bookingDate).split(' '), 2), bookingDate = _e[0], bookingTime = _e[1];
                            return react_1.default.createElement("tr", { key: value.id },
                                react_1.default.createElement("td", null, value.bookingShortId),
                                react_1.default.createElement("td", null, (_c = (_b = value.productLocalizations) === null || _b === void 0 ? void 0 : _b.de) === null || _c === void 0 ? void 0 : _c.title),
                                react_1.default.createElement("td", null,
                                    (0, dateUtils_1.formatDateShort)(value.travelTimespanFrom),
                                    " -",
                                    react_1.default.createElement("br", null),
                                    (0, dateUtils_1.formatDateShort)(value.travelTimespanTo)),
                                react_1.default.createElement("td", null,
                                    bookingDate,
                                    react_1.default.createElement("br", null),
                                    bookingTime),
                                react_1.default.createElement("td", null, value.paymentShortId),
                                react_1.default.createElement("td", null, (0, dateUtils_1.formatDateFull)(value.paymentDate)),
                                react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(sumGross)));
                        })))),
                react_1.default.createElement(aurum_1.Footer, null,
                    react_1.default.createElement("span", null),
                    react_1.default.createElement(aurum_1.Button, { className: 'btn-right', type: 'error', onClick: resetSelection }, t(['Controls.close'])),
                    react_1.default.createElement(aurum_1.Button, { disabled: dataDetails.totalElements === 0 ||
                            new Date(invoiceDate.value) < new Date(minInvoiceDate), className: 'btn-left', onClick: createInvoice },
                        react_1.default.createElement(aurum_1.Icon, { type: 'create' }),
                        t(className + '.create')),
                    minInvoiceDate
                        ? react_1.default.createElement(aurum_1.Input, { className: 'input-left', min: minInvoiceDate, type: 'date', label: t('PendingCustomerInvoice.date'), value: invoiceDate.inputvalue, onChange: invoiceDate.onChange })
                        : react_1.default.createElement(aurum_1.Progress, null)));
        }
        else {
            return null;
        }
    }
    function noData() {
        return react_1.default.createElement("div", null,
            react_1.default.createElement("p", null, "Es wurden keine Daten gefunden."));
    }
    function failCondition() {
        return react_1.default.createElement("div", null,
            react_1.default.createElement("p", null, "Beim Laden der Daten kam es zu einem Fehler."));
    }
    function progressbar(text) {
        return react_1.default.createElement(aurum_1.Progress, { className: 'table' }, t(text));
    }
    return react_1.default.createElement("div", { className: className },
        dataDetailsModal(),
        react_1.default.createElement(aurum_1.Sheet, { className: 'list' },
            react_1.default.createElement("div", { className: 'title' },
                react_1.default.createElement("h2", null, t('Entity.' + className))),
            react_1.default.createElement(CustomerPaymentSearchBar_1.CustomerPaymentSearchBar, { initialSearchData: searchData, searchCallback: function (data) {
                    setSearchData(data);
                } }),
            error ? displayError() : null,
            loading
                ? progressbar('Progress.loading')
                : (data == null
                    ? failCondition()
                    : dataTable())));
}
exports.default = (0, react_1.memo)(PendingCustomerInvoiceList);
