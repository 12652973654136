"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_i18next_1 = require("react-i18next");
var js_1 = require("@samsa/samsa-api/js");
var aurum_1 = require("@mdsi/aurum");
var PagedSearchTable_1 = require("./PagedSearchTable");
var utils_1 = require("@samsa/samsa-api/js/utils");
var ChannelManagerProtocolView = function () {
    var t = (0, react_i18next_1.useTranslation)().t;
    var columns = [
        {
            name: "ownerShortId",
            valueResolver: function (row) { return row.ownerShortId || 'n/v'; }
        },
        {
            name: "ownerCompanyName",
        },
        {
            name: "channelManagerType",
            valueResolver: function (row) { return t("ChannelManagerProtocolView.Enum." + row.channelManagerType); }
        },
        {
            name: "protocolType",
            valueResolver: function (row) { return t("ChannelManagerProtocolView.Enum." + row.protocolType); }
        },
        {
            name: "message",
        },
        {
            name: "createdAt",
            valueResolver: function (row) { return (0, utils_1.formatDateFull)(row.createdAt); }
        }
    ];
    var onSearch = function (search, page, size, sort) {
        return js_1.ChannelManagerProtocol.getAll({
            search: search,
            page: page,
            size: size,
            sort: sort
        });
    };
    return aurum_1.React.createElement(PagedSearchTable_1.PagedSearchTable, { title: "Channelmanager Protokoll", columns: columns, onSearch: onSearch, withPadding: true });
};
exports.default = aurum_1.React.memo(ChannelManagerProtocolView);
