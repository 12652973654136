"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var js_1 = require("@samsa/samsa-api/js");
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var useAsync_1 = require("../../hook/useAsync");
var useOnChange_1 = require("../../hook/useOnChange");
var dateUtils_1 = require("../../util/dateUtils");
var formatPrice_1 = require("../../util/formatPrice");
// TODO: Dropdown-Daten sortieren
// TODO: Wenn man weniger Owner wählt, dann bleiben FeatureProvider/Produkte weiterhin ausgewählt
//  (der Server berücksichtigt das, aber clientseitig sieht das dumm aus). Vielleicht nur
//  clientseitig filtern statt neuzuladen? Vielleicht alles immer anzeigen, aber FeatureProvider
//  und Produkte, deren Owner abgewählt sind, nach unten sortieren und anders darstellen (grau)?
// TODO: Fehlerbehandlung
var SalesStatistics = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    var t = (0, react_i18next_1.useTranslation)().t;
    var prefix = 'App.SalesStatistics.';
    var selectedOwnerIds = (0, useOnChange_1.useOnChange)();
    var selectedFeatureProviderIds = (0, useOnChange_1.useOnChange)();
    var selectedProductIds = (0, useOnChange_1.useOnChange)();
    var fromDate = (0, useOnChange_1.useOnChange)((0, dateUtils_1.getFirstDayOfMonthAsLocalDateString)());
    var toDate = (0, useOnChange_1.useOnChange)((0, dateUtils_1.getLastDayOfMonthAsLocalDateString)());
    var ownerData = (0, useAsync_1.useAsync)(function () { return js_1.Owner.getAllDropdownOptions(); }, [], true, false, false);
    var featureProviderData = (0, useAsync_1.useAsync)(function () { return js_1.FeatureProvider.getAllDropdownOptions({ 'owner.id': selectedOwnerIds.value }); }, [selectedOwnerIds.value], true, true, false);
    var productData = (0, useAsync_1.useAsync)(function () { return js_1.Product.getAllDropdownOptions({ 'owner.id': selectedOwnerIds.value }); }, [selectedOwnerIds.value], true, true, false);
    var fromDateInput = (react_1.default.createElement(aurum_1.Input, { label: t(prefix + 'fromDate'), type: 'date', value: fromDate.inputvalue, onChange: fromDate.onChange }));
    var toDateInput = (react_1.default.createElement(aurum_1.Input, { label: t(prefix + 'toDate'), type: 'date', value: toDate.inputvalue, onChange: toDate.onChange }));
    var ownerDataAvailable = ((_b = (_a = ownerData.value) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 0;
    var selectedOwners = (_d = (_c = selectedOwnerIds.value) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0;
    var totalOwners = (_f = (_e = ownerData.value) === null || _e === void 0 ? void 0 : _e.length) !== null && _f !== void 0 ? _f : 0;
    var ownerInput = (react_1.default.createElement(aurum_1.Multiselect, { label: "".concat(t(prefix + 'owner'), " (").concat(selectedOwners, "/").concat(totalOwners, ")"), native: false, value: selectedOwnerIds.inputvalue, onChange: selectedOwnerIds.onChange, disabled: !ownerDataAvailable, options: ownerData.value }));
    var featureProviderDataAvailable = ((_h = (_g = featureProviderData.value) === null || _g === void 0 ? void 0 : _g.length) !== null && _h !== void 0 ? _h : 0) > 0;
    var selectedFeatureProviders = (_k = (_j = selectedFeatureProviderIds.value) === null || _j === void 0 ? void 0 : _j.length) !== null && _k !== void 0 ? _k : 0;
    var totalFeatureProviders = (_m = (_l = featureProviderData.value) === null || _l === void 0 ? void 0 : _l.length) !== null && _m !== void 0 ? _m : 0;
    var featureProviderInput = (react_1.default.createElement(aurum_1.Multiselect, { label: "".concat(t(prefix + 'featureProvider'), " (").concat(selectedFeatureProviders, "/").concat(totalFeatureProviders, ")"), native: false, value: selectedFeatureProviderIds.inputvalue, onChange: selectedFeatureProviderIds.onChange, disabled: !featureProviderDataAvailable, options: featureProviderData.value }));
    var productDataAvailable = ((_p = (_o = productData.value) === null || _o === void 0 ? void 0 : _o.length) !== null && _p !== void 0 ? _p : 0) > 0;
    var selectedProducts = (_r = (_q = selectedProductIds.value) === null || _q === void 0 ? void 0 : _q.length) !== null && _r !== void 0 ? _r : 0;
    var totalProducts = (_t = (_s = productData.value) === null || _s === void 0 ? void 0 : _s.length) !== null && _t !== void 0 ? _t : 0;
    var productInput = (react_1.default.createElement(aurum_1.Multiselect, { label: "".concat(t(prefix + 'product'), " (").concat(selectedProducts, "/").concat(totalProducts, ")"), native: false, value: selectedProductIds.inputvalue, onChange: selectedProductIds.onChange, disabled: !productDataAvailable, options: productData.value }));
    var salesData = (0, useAsync_1.useAsync)(function () { return (0, js_1.getSalesStatistics)(new js_1.SalesStatisticsRequestBody({
        from: fromDate.value,
        to: toDate.value,
        ownerIds: selectedOwnerIds.value,
        featureProviderIds: selectedFeatureProviderIds.value,
        productIds: selectedProductIds.value
    })); }, [
        fromDate.value,
        toDate.value,
        selectedOwnerIds.value,
        selectedFeatureProviderIds.value, selectedProductIds.value
    ], false, false, false);
    var dropdownDataAvailable = ownerDataAvailable &&
        featureProviderDataAvailable &&
        productDataAvailable &&
        !salesData.loading;
    var searchButton = (react_1.default.createElement(aurum_1.Button, { disabled: !dropdownDataAvailable, onClick: salesData.loading ? null : salesData.reload },
        react_1.default.createElement(aurum_1.Icon, { size: 'big', type: 'search' }),
        t(prefix + 'search')));
    var downloadButtonAvailable = salesData.value &&
        salesData.value.length > 0;
    var downloadButton = (react_1.default.createElement(aurum_1.Button, { disabled: !downloadButtonAvailable, onClick: function () { return (0, js_1.createSalesStatisticsFile)(new js_1.SalesStatisticsRequestBody({
            from: fromDate.value,
            to: toDate.value,
            ownerIds: selectedOwnerIds.value,
            featureProviderIds: selectedFeatureProviderIds.value,
            productIds: selectedProductIds.value
        }), {
            responseType: 'blob'
        }).then(function (response) {
            // setSepaError(null);
            // @ts-ignore
            var url = window.URL.createObjectURL(new Blob([response]));
            var link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'sales_satistics_' + fromDate.value + '-' + toDate.value + '.xlsx');
            document.body.appendChild(link);
            link.click();
        }).catch(function (error) {
            console.log(error);
        }); } },
        react_1.default.createElement(aurum_1.Icon, { size: 'big', type: 'download' }),
        t(prefix + 'download')));
    var data;
    if (salesData.loading) {
        data = react_1.default.createElement(aurum_1.Progress, null, t(prefix + 'loading'));
    }
    else if (salesData.error) {
        // salesData.error ist ein AxiosError mit 'message' und 'response.data.message'
        data = react_1.default.createElement(aurum_1.Alert, { className: 'loading-error', type: 'error' },
            t(prefix + 'error'),
            ": ",
            t(prefix + 'defaultErrorMessage'));
    }
    else if (salesData.value) {
        data = (react_1.default.createElement(aurum_1.Table, null,
            react_1.default.createElement("thead", null,
                react_1.default.createElement("tr", null,
                    react_1.default.createElement("th", null, t(prefix + 'ownerShortId')),
                    react_1.default.createElement("th", null, t(prefix + 'owner')),
                    react_1.default.createElement("th", null, t(prefix + 'featureProviderShortId')),
                    react_1.default.createElement("th", null, t(prefix + 'featureProvider')),
                    react_1.default.createElement("th", null, t(prefix + 'productShortId')),
                    react_1.default.createElement("th", null, t(prefix + 'product')),
                    react_1.default.createElement("th", null, t(prefix + 'amount')),
                    react_1.default.createElement("th", null, t(prefix + 'sales')))),
            react_1.default.createElement("tbody", null, salesData.value.map(function (r) { return (react_1.default.createElement("tr", { key: r.ownerId + '-' + r.featureProviderId + '-' + r.productId },
                react_1.default.createElement("td", null, r.ownerShortId),
                react_1.default.createElement("td", null, r.owner),
                react_1.default.createElement("td", null, r.featureProviderShortId),
                react_1.default.createElement("td", null, r.featureProvider),
                react_1.default.createElement("td", null, r.productShortId),
                react_1.default.createElement("td", null, r.product),
                react_1.default.createElement("td", null, r.amount),
                react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(r.sales)))); }))));
    }
    else {
        data = react_1.default.createElement("div", null, "Keine Daten bisher geladen");
    }
    return (react_1.default.createElement("div", { className: 'SalesStatistics' },
        react_1.default.createElement(aurum_1.Sheet, null,
            react_1.default.createElement("div", { className: 'input-row' },
                fromDateInput,
                toDateInput,
                ownerInput,
                featureProviderInput,
                productInput,
                searchButton,
                downloadButton),
            data)));
};
exports.default = (0, react_1.memo)(SalesStatistics);
