"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var js_1 = require("@samsa/samsa-api/js");
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var useAsync_1 = require("../../hook/useAsync");
var useOnChange_1 = require("../../hook/useOnChange");
var dateUtils_1 = require("../../util/dateUtils");
var formatPrice_1 = require("../../util/formatPrice");
var OwnerRevenues = function () {
    var t = (0, react_i18next_1.useTranslation)().t;
    var prefix = 'App.OwnerRevenues.';
    var targetDate = (0, useOnChange_1.useOnChange)((0, dateUtils_1.getFirstDayOfMonthAsLocalDateString)());
    var targetDateInput = (react_1.default.createElement(aurum_1.Input, { label: t(prefix + 'targetDate'), type: 'date', value: targetDate.inputvalue, onChange: targetDate.onChange }));
    var ownerRevenueData = (0, useAsync_1.useAsync)(function () { return (0, js_1.getOwnerRevenues)(targetDate.value); }, [targetDate.value], false, false, false);
    var downloadButtonAvailable = ownerRevenueData.value &&
        ownerRevenueData.value.length > 0 &&
        !ownerRevenueData.loading;
    var downloadButton = (react_1.default.createElement(aurum_1.Button, { disabled: !downloadButtonAvailable, onClick: function () { return (0, js_1.createOwnerRevenuesFile)(targetDate.value, {
            responseType: 'blob'
        }).then(function (response) {
            // setSepaError(null);
            // @ts-ignore
            var url = window.URL.createObjectURL(new Blob([response]));
            var link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Liste_Partnereinnahmen_Stichtag_' + targetDate.value + '.xlsx' + '.xlsx');
            document.body.appendChild(link);
            link.click();
        }).catch(function (error) {
            console.log(error);
        }); } },
        react_1.default.createElement(aurum_1.Icon, { size: 'big', type: 'download' }),
        t(prefix + 'download')));
    var data;
    if (ownerRevenueData.loading) {
        data = react_1.default.createElement(aurum_1.Progress, null, t(prefix + 'loading'));
    }
    else if (ownerRevenueData.error) {
        data =
            react_1.default.createElement(aurum_1.Alert, { className: 'loading-error', type: 'error' },
                t(prefix + 'error'),
                ": ",
                t(prefix + 'defaultErrorMessage'));
    }
    else if (ownerRevenueData.value) {
        data = (react_1.default.createElement(aurum_1.Table, null,
            react_1.default.createElement("thead", null,
                react_1.default.createElement("tr", null,
                    react_1.default.createElement("th", null, t(prefix + 'ownerShortId')),
                    react_1.default.createElement("th", null, t(prefix + 'owner')),
                    react_1.default.createElement("th", null, t(prefix + 'bookingShortId')),
                    react_1.default.createElement("th", null, t(prefix + 'paymentDate')),
                    react_1.default.createElement("th", null, t(prefix + 'arrivalDate')),
                    react_1.default.createElement("th", null, t(prefix + 'amount')))),
            react_1.default.createElement("tbody", null, ownerRevenueData.value.map(function (r, key) { return (react_1.default.createElement("tr", { key: key },
                react_1.default.createElement("td", null, r.ownerShortId),
                react_1.default.createElement("td", null, r.ownerCompanyName),
                react_1.default.createElement("td", null, r.bookingShortId),
                react_1.default.createElement("td", null, r.paymentDate),
                react_1.default.createElement("td", null, r.arrivalDate),
                react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(r.amount)))); }))));
    }
    else {
        data = react_1.default.createElement("div", null, "Keine Daten bisher geladen");
    }
    return (react_1.default.createElement("div", { className: 'OwnerRevenues' },
        react_1.default.createElement(aurum_1.Sheet, null,
            react_1.default.createElement("div", { className: 'input-row' },
                react_1.default.createElement("h2", null, t('OwnerRevenues.title')),
                targetDateInput,
                react_1.default.createElement(aurum_1.Button, { disabled: ownerRevenueData.loading, onClick: ownerRevenueData.loading ? null : ownerRevenueData.reload },
                    react_1.default.createElement(aurum_1.Icon, { type: 'search' }),
                    t('Edit.navigation.search-button')),
                downloadButton),
            data)));
};
exports.default = (0, react_1.memo)(OwnerRevenues);
