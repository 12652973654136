"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var Topmenu = function (_a) {
    var _b, _c, _d;
    var title = _a.title, config = _a.config, logOut = _a.logOut, locales = _a.locales;
    var t = (0, react_i18next_1.useTranslation)().t;
    return aurum_1.React.createElement(aurum_1.Sheet, { className: 'Topmenu' },
        aurum_1.React.createElement("span", { className: 'logo' }),
        aurum_1.React.createElement("span", { className: 'version' },
            aurum_1.React.createElement("span", null,
                "Client: ", (_b = config === null || config === void 0 ? void 0 : config.clientVersion) !== null && _b !== void 0 ? _b : '?'),
            aurum_1.React.createElement("span", null,
                "API: ", (_c = config === null || config === void 0 ? void 0 : config.apiVersion) !== null && _c !== void 0 ? _c : '?'),
            aurum_1.React.createElement("span", null,
                "Server: ", (_d = config === null || config === void 0 ? void 0 : config.serverVersion) !== null && _d !== void 0 ? _d : '?')),
        aurum_1.React.createElement("h1", { className: 'title' }, title),
        aurum_1.React.createElement("div", { className: 'spacer' }),
        aurum_1.React.createElement("div", { className: 'help' }),
        aurum_1.React.createElement("div", { className: 'locales' }, locales),
        logOut ? aurum_1.React.createElement("a", { href: '#', className: 'logout', onClick: logOut }, t('App.Menu.logout-button')) : null);
};
exports.default = aurum_1.React.memo(Topmenu);
