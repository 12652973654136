"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransportationPriceMappingForm = void 0;
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var js_1 = require("@samsa/samsa-api/js");
var Edit_1 = require("../Edit");
var useAsync_1 = require("../../hook/useAsync");
var TransportationPriceMappingForm = function (_a) {
    var _b, _c;
    var value = _a.value, onChange = _a.onChange;
    var parent = aurum_1.React.useContext(Edit_1.EntryContext);
    var transportationId = parent === null || parent === void 0 ? void 0 : parent.transportationId;
    var stations = (0, useAsync_1.useAsync)(function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, js_1.Transportation.get(transportationId)];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result.route.stations.sort(function (a, b) { return a.order - b.order; })];
            }
        });
    }); }, [transportationId]).value;
    return aurum_1.React.createElement(aurum_1.Table, { scrollable: false },
        aurum_1.React.createElement("thead", null,
            aurum_1.React.createElement("tr", null,
                aurum_1.React.createElement("th", null, "Start \\ Ziel"), (_b = stations === null || stations === void 0 ? void 0 : stations.map) === null || _b === void 0 ? void 0 :
                _b.call(stations, function (_a) {
                    var destinationStation = _a.station;
                    return aurum_1.React.createElement("th", { key: destinationStation.id }, destinationStation.localizations.de.title);
                }))),
        aurum_1.React.createElement("tbody", null, (_c = stations === null || stations === void 0 ? void 0 : stations.map) === null || _c === void 0 ? void 0 : _c.call(stations, function (_a) {
            var _b;
            var departureStation = _a.station;
            return aurum_1.React.createElement("tr", { key: departureStation.id },
                aurum_1.React.createElement("th", null, departureStation.localizations.de.title), (_b = stations === null || stations === void 0 ? void 0 : stations.map) === null || _b === void 0 ? void 0 :
                _b.call(stations, function (_a) {
                    var _b, _c, _d;
                    var destinationStation = _a.station;
                    var key = (_b = value === null || value === void 0 ? void 0 : value.findIndex) === null || _b === void 0 ? void 0 : _b.call(value, function (val) { return val.departureStation.id === departureStation.id &&
                        val.destinationStation.id === destinationStation.id; });
                    return aurum_1.React.createElement("td", { key: destinationStation.id },
                        aurum_1.React.createElement(aurum_1.Input, { type: 'number', value: (_d = (_c = value[key]) === null || _c === void 0 ? void 0 : _c.price) !== null && _d !== void 0 ? _d : '', onChange: function (event) {
                                var _a;
                                var newvalue = tslib_1.__spreadArray([], tslib_1.__read(value), false);
                                newvalue[key < 0 ? ((_a = value === null || value === void 0 ? void 0 : value.length) !== null && _a !== void 0 ? _a : 0) : key] = new js_1.TransportationPriceMapping(tslib_1.__assign(tslib_1.__assign({}, value[key]), { departureStation: departureStation, destinationStation: destinationStation, price: event.target.value }));
                                // console.log('onChange', key, value, newvalue);
                                onChange(newvalue.filter(function (v) { return v.price; }));
                            } }));
                }));
        })));
};
exports.TransportationPriceMappingForm = TransportationPriceMappingForm;
