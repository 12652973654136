"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDebounce = void 0;
var tslib_1 = require("tslib");
var react_1 = require("react");
var useDebounce = function (value, delay) {
    if (delay === void 0) { delay = 400; }
    var _a = tslib_1.__read((0, react_1.useState)(value), 2), debouncedValue = _a[0], setDebouncedValue = _a[1];
    (0, react_1.useEffect)(function () {
        var handler = setTimeout(function () {
            setDebouncedValue(value);
        }, delay);
        return function () {
            clearTimeout(handler);
        };
    }, [value]);
    return debouncedValue;
};
exports.useDebounce = useDebounce;
