"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tabFilterConfig = {
    Customer: {
        Customer: 'id',
        bookingList: 'customer.customerId',
        messageList: 'recipient.customerId'
    },
    Owner: {
        Owner: 'id',
        bookingList: 'featureOwnerId',
        publicationRequestList: 'ownerId',
        messageList: 'recipient.ownerId',
        FeatureProvider: 'owner.id',
        HousingDraft: 'owner.id'
    },
    // TODO: E-Mail-Adresse (unregistrierter Kunde)
    'Entity.String': {
        bookingList: 'customer.email',
        messageList: 'recipient.email'
    }
};
exports.default = tabFilterConfig;
