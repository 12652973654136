"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var js_1 = require("@samsa/samsa-api/js");
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var dateUtils_1 = require("../../util/dateUtils");
var formatPrice_1 = require("../../util/formatPrice");
var OwnerPaymentSearchBar_1 = require("./OwnerPaymentSearchBar");
var OwnerPaymentList = function () {
    var t = (0, react_i18next_1.useTranslation)().t;
    var _a = tslib_1.__read((0, react_1.useState)(false), 2), loading = _a[0], setLoading = _a[1];
    var _b = tslib_1.__read((0, react_1.useState)(null), 2), paymentInfoDetails = _b[0], setPaymentInfoDetails = _b[1];
    var _c = tslib_1.__read((0, react_1.useState)(null), 2), selectedInfo = _c[0], setSelectedInfo = _c[1];
    var _d = tslib_1.__read((0, react_1.useState)(null), 2), paymentInfos = _d[0], setPaymentInfos = _d[1];
    var fromDate = new Date();
    fromDate.setDate(1);
    var toDate = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0, 23, 59, 59);
    var _e = tslib_1.__read((0, react_1.useState)(), 2), searchData = _e[0], setSearchData = _e[1];
    function failCondition() {
        return react_1.default.createElement("div", null,
            react_1.default.createElement("p", null, "Beim Laden der Daten kam es zu einem Fehler. Bitte nehmen Sie Kontakt mit MDSI IT Solutions GmbH auf."));
    }
    (0, react_1.useEffect)(function () {
        if (!searchData) {
            return;
        }
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var page, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, js_1.OwnerPaymentInfo.getAll({
                                paymentCriteria: {
                                    featureOwnerIds: searchData.selectedOwners,
                                    from: searchData.from,
                                    to: searchData.to,
                                    fetchDateType: searchData.fetchDateType,
                                    bookingShortIds: [searchData.bookingShortId]
                                },
                                page: 0,
                                size: 0
                            })];
                    case 2:
                        page = _a.sent();
                        setPaymentInfos(page);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        failCondition();
                        return [3 /*break*/, 4];
                    case 4:
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [searchData]);
    (0, react_1.useEffect)(function () {
        if (selectedInfo) {
            var promise = js_1.OwnerPaymentInfo.getAllDetail({
                paymentCriteria: {
                    featureOwnerIds: [selectedInfo.ownerId],
                    from: searchData.from,
                    to: searchData.to,
                    fetchDateType: searchData.fetchDateType,
                    bookingShortIds: [searchData.bookingShortId]
                },
                // page: paymentInfoPage,
                page: 0,
                size: 0
            });
            promise.then(function (result) {
                setPaymentInfoDetails(result);
            }).catch(function (e) {
                console.error('ERROR entryReload', e.message, e);
                setPaymentInfoDetails(null);
            });
        }
        else {
            setPaymentInfoDetails(null);
        }
    }, [selectedInfo]); // Was ist mit searchData?
    var resetSelection = function () { return setSelectedInfo(null); };
    var modal = null;
    if (selectedInfo && paymentInfoDetails) {
        modal = react_1.default.createElement(aurum_1.Modal, { className: 'paymentDetailsModal', onClickBackdrop: resetSelection },
            react_1.default.createElement(aurum_1.Header, null,
                react_1.default.createElement("h2", null,
                    t('OwnerPaymentList.paymentDetailsModal.title'),
                    ": ", selectedInfo === null || selectedInfo === void 0 ? void 0 :
                    selectedInfo.companyName)),
            react_1.default.createElement(aurum_1.Body, null,
                react_1.default.createElement(aurum_1.Table, null,
                    react_1.default.createElement("thead", null,
                        react_1.default.createElement("tr", null,
                            react_1.default.createElement("th", null, t('EntityAttribute.bookingId')),
                            react_1.default.createElement("th", null, t('EntityAttribute.title')),
                            react_1.default.createElement("th", null, t('EntityAttribute.travelDate')),
                            react_1.default.createElement("th", null, t('EntityAttribute.bookingDate')),
                            react_1.default.createElement("th", null, t('EntityAttribute.paymentDate')),
                            react_1.default.createElement("th", null, t('EntityAttribute.paymentType')),
                            react_1.default.createElement("th", null,
                                t('OwnerPaymentInfo.total'),
                                react_1.default.createElement("br", null),
                                t('Enum.GROSS')),
                            react_1.default.createElement("th", null,
                                t('OwnerPaymentInfo.fee'),
                                react_1.default.createElement("br", null),
                                t('Enum.GROSS')),
                            react_1.default.createElement("th", null,
                                t('OwnerPaymentInfo.amount'),
                                react_1.default.createElement("br", null),
                                t('Enum.GROSS')))),
                    react_1.default.createElement("tbody", null, paymentInfoDetails.content
                        .map(function (value) {
                        var amountOwner = value.amount - value.fee;
                        return react_1.default.createElement("tr", { key: value.paymentId },
                            react_1.default.createElement("td", null, value.bookingShortId),
                            react_1.default.createElement("td", null, value.featureLocalizations.de.title),
                            react_1.default.createElement("td", null,
                                (0, dateUtils_1.formatDateShort)(value.bookingRequestFrom),
                                " -",
                                react_1.default.createElement("br", null),
                                (0, dateUtils_1.formatDateShort)(value.bookingRequestTo)),
                            react_1.default.createElement("td", null, (0, dateUtils_1.formatDateFull)(value.bookingDate)),
                            react_1.default.createElement("td", null, (0, dateUtils_1.formatDateFull)(value.paymentDate)),
                            react_1.default.createElement("td", null, value.type),
                            react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(value.amount)),
                            react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(value.fee)),
                            react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(amountOwner)));
                    })))),
            react_1.default.createElement(aurum_1.Footer, null,
                react_1.default.createElement("span", null),
                react_1.default.createElement(aurum_1.Button, { className: 'btn-right', type: 'error', onClick: resetSelection }, t(['Controls.close']))));
    }
    function noData() {
        return react_1.default.createElement("div", null,
            react_1.default.createElement("p", null, "Es wurden keine Daten gefunden."));
    }
    function showPaymentInfos() {
        return (paymentInfos === null || paymentInfos === void 0 ? void 0 : paymentInfos.numberOfElements) === 0
            ? noData()
            : react_1.default.createElement(aurum_1.Table, null,
                react_1.default.createElement("thead", null,
                    react_1.default.createElement("tr", null,
                        react_1.default.createElement("th", null, t('EntityAttribute.companyName')),
                        react_1.default.createElement("th", null, t('EntityAttribute.email')),
                        react_1.default.createElement("th", null,
                            t('OwnerPaymentInfo.total'),
                            react_1.default.createElement("br", null),
                            t('Enum.GROSS')),
                        react_1.default.createElement("th", null,
                            t('OwnerPaymentInfo.fee'),
                            react_1.default.createElement("br", null),
                            t('Enum.GROSS')),
                        react_1.default.createElement("th", null, t('OwnerConditions.commissionFee')),
                        react_1.default.createElement("th", null, t('OwnerConditions.commissionFeeCalculation')),
                        react_1.default.createElement("th", null,
                            t('OwnerPaymentInfo.amount'),
                            react_1.default.createElement("br", null),
                            t('Enum.GROSS')))),
                react_1.default.createElement("tbody", null, paymentInfos === null || paymentInfos === void 0 ? void 0 : paymentInfos.content.map(function (value) {
                    var _a;
                    var totalOwner = value.totalAmount - value.totalFee;
                    // @ts-ignore
                    value.setter = value.setter || (function () { return setSelectedInfo(value); });
                    // @ts-ignore
                    return react_1.default.createElement("tr", { key: value.ownerId, onClick: value.setter },
                        react_1.default.createElement("td", null, value.companyName),
                        react_1.default.createElement("td", null, value.email),
                        react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(value.totalAmount)),
                        react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(value.totalFee)),
                        react_1.default.createElement("td", null, (_a = value.commissionFee) !== null && _a !== void 0 ? _a : 'n/v'),
                        react_1.default.createElement("td", null, value.commissionFeeCalculation ? t("Enum.".concat(value.commissionFeeCalculation)) : 'n/v'),
                        react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(totalOwner)));
                })));
    }
    function getProgressbar(text) {
        return react_1.default.createElement(aurum_1.Progress, { className: 'table' }, t(text));
    }
    return react_1.default.createElement("div", { className: 'OwnerPaymentList' },
        modal,
        react_1.default.createElement(aurum_1.Sheet, { className: 'list' },
            react_1.default.createElement("div", { className: 'title' },
                react_1.default.createElement("h2", null, t('Entity.OwnerPaymentList'))),
            react_1.default.createElement(OwnerPaymentSearchBar_1.OwnerPaymentSearchBar, { initialSearchData: {
                    bookingShortId: null,
                    selectedOwners: [],
                    from: (0, dateUtils_1.toDateString)(fromDate),
                    to: (0, dateUtils_1.toDateString)(toDate),
                    fetchDateType: OwnerPaymentSearchBar_1.fetchDateTypeEnum.PAYMENT_DATE
                }, searchCallback: function (data) {
                    setSearchData(data);
                } }),
            loading
                ? getProgressbar('BookingList.loading')
                : (searchData && paymentInfos == null
                    ? failCondition()
                    : showPaymentInfos())));
};
exports.default = (0, react_1.memo)(OwnerPaymentList);
