"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultTableConfigEntry = void 0;
var tslib_1 = require("tslib");
var getDefaultTableConfigEntry = function (entry, localOverrides, entryName) { return (tslib_1.__assign(tslib_1.__assign(tslib_1.__assign({}, base), getGlobalOverride(entry, entryName)), localOverrides)); };
exports.getDefaultTableConfigEntry = getDefaultTableConfigEntry;
var base = {
    show: true,
    align: 'left'
};
var getGlobalOverride = function (entry, entryName) {
    if (entryName === 'id') {
        return { show: false };
    }
    else if (entryName === 'shortId') {
        return { show: true };
    }
    else if (entryName === 'owner' || entryName === 'ownerReference') {
        return { show: false };
    }
    else if (entryName === 'reference') {
        return { show: false };
    }
    else if (entryName === 'deleted') {
        return { show: false };
    }
    else if (entryName === 'discriminator') {
        return { show: false };
    }
    else if (entryName === 'firstPublishedAt') {
        return { show: false };
    }
    else if (entryName === 'linkedContactPersons') {
        return { show: true };
    }
    else if (entryName === 'hasAcceptedPublication') {
        return { show: false };
    }
    else if (entryName === 'lastPublicationStatus') {
        return { show: false };
    }
    else if (entryName === 'custom') {
        return { show: false };
    }
    else if (entryName === 'html' || entryName === 'description' || entryName === 'bounds' || entryName.endsWith('Description') || entryName.endsWith('Template')) {
        return { show: false };
    }
    else if (entry.references) {
        return { show: false };
    }
    else if (entry.fkey) {
        return { show: false };
    }
    return {};
};
