"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileRefFormPreviewModal = exports.Helper = void 0;
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var js_1 = require("@samsa/samsa-api/js");
var react_i18next_1 = require("react-i18next");
var useOnChange_1 = require("../../hook/useOnChange");
var Helper = /** @class */ (function () {
    function Helper() {
    }
    Helper.startsWith = function (linkedFile, startElement) {
        return linkedFile.file.contentType.startsWith(startElement);
    };
    Helper.getBodyContent = function (linkedFile) {
        if (this.startsWith(linkedFile, 'image/')) {
            return aurum_1.React.createElement("img", { src: (0, js_1.getUrl)(linkedFile.file, { background: '#f6f6f6' }) });
        }
        else if (this.startsWith(linkedFile, 'application/pdf')) {
            var url = (0, js_1.getUrl)(linkedFile.file);
            return aurum_1.React.createElement(aurum_1.Pdf, { src: url });
        }
        else {
            return aurum_1.React.createElement("p", null, "upps");
        }
    };
    Helper.resetTitles = function (setFileRefFormPreviewModal, modalImageDeTitle, modalImageEnTitle, modalImageOrder) {
        setFileRefFormPreviewModal(null);
        modalImageDeTitle.set('');
        modalImageEnTitle.set('');
        modalImageOrder.set('');
    };
    return Helper;
}());
exports.Helper = Helper;
var FileRefFormPreviewModal = function (props) {
    var t = (0, react_i18next_1.useTranslation)().t;
    var Type = props.Type, entry = props.entry, linkedFile = props.linkedFile, entryReload = props.entryReload, setFileRefFormPreviewModal = props.setFileRefFormPreviewModal;
    var modalImageDeTitle = (0, useOnChange_1.useOnChange)('');
    var modalImageEnTitle = (0, useOnChange_1.useOnChange)('');
    var modalImageOrder = (0, useOnChange_1.useOnChange)('');
    return aurum_1.React.createElement(aurum_1.Modal, { className: 'imageModal' },
        aurum_1.React.createElement(aurum_1.Body, null, Helper.getBodyContent(linkedFile)),
        aurum_1.React.createElement(aurum_1.Footer, null,
            aurum_1.React.createElement(aurum_1.InputGroup, null,
                aurum_1.React.createElement(aurum_1.Input, { value: modalImageDeTitle.inputvalue, onChange: modalImageDeTitle.onChange, label: t('imageModal.deTitle') }),
                aurum_1.React.createElement(aurum_1.Input, { value: modalImageEnTitle.inputvalue, onChange: modalImageEnTitle.onChange, label: t('imageModal.enTitle') }),
                aurum_1.React.createElement(aurum_1.Input, { type: 'number', value: modalImageOrder.inputvalue, onChange: modalImageOrder.onChange, label: t('imageModal.order') }),
                aurum_1.React.createElement(aurum_1.Button, { type: 'primary', onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, Type.saveFile({
                                        id: entry === null || entry === void 0 ? void 0 : entry.id,
                                        linkedFile: new js_1.LinkedFile(tslib_1.__assign(tslib_1.__assign({}, linkedFile), { order: modalImageOrder.value || null, localizations: new js_1.LocalizationsOfLinkedFileLocalized({
                                                de: new js_1.LinkedFileLocalized({
                                                    title: modalImageDeTitle.value || null
                                                }),
                                                en: new js_1.LinkedFileLocalized({
                                                    title: modalImageEnTitle.value || null
                                                })
                                            }) }))
                                    })];
                                case 1:
                                    _a.sent();
                                    entryReload();
                                    Helper.resetTitles(setFileRefFormPreviewModal, modalImageDeTitle, modalImageEnTitle, modalImageOrder);
                                    return [2 /*return*/];
                            }
                        });
                    }); } }, t(['imageModal.save', 'Controls.save']))),
            aurum_1.React.createElement(aurum_1.Button, { type: 'error', onClick: function () {
                    Helper.resetTitles(setFileRefFormPreviewModal, modalImageDeTitle, modalImageEnTitle, modalImageOrder);
                } }, t(['imageModal.close', 'Controls.close'])),
            aurum_1.React.createElement(aurum_1.Button, { type: 'error-primary', onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, Type.deleteFile({
                                    linkedFileId: linkedFile.id,
                                    id: entry === null || entry === void 0 ? void 0 : entry.id
                                })];
                            case 1:
                                _a.sent();
                                entryReload();
                                Helper.resetTitles(setFileRefFormPreviewModal, modalImageDeTitle, modalImageEnTitle, modalImageOrder);
                                return [2 /*return*/];
                        }
                    });
                }); } }, t(['imageModal.delete', 'Controls.delete']))));
};
exports.FileRefFormPreviewModal = FileRefFormPreviewModal;
