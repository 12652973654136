"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var js_1 = require("@samsa/samsa-api/js");
var dateUtils_1 = require("../util/dateUtils");
var useOnChange_1 = require("../hook/useOnChange");
var BookingPlan = function (props) {
    var housingIds = props === null || props === void 0 ? void 0 : props.housingIds;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _a = tslib_1.__read(aurum_1.React.useState(null), 2), chartData = _a[0], setChartData = _a[1];
    var _b = tslib_1.__read(aurum_1.React.useState(null), 2), days = _b[0], setDays = _b[1];
    var _c = tslib_1.__read(aurum_1.React.useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = tslib_1.__read(aurum_1.React.useState(null), 2), housings = _d[0], setHousings = _d[1];
    var selectedHousings = (0, useOnChange_1.useOnChange)();
    var today = new Date();
    var nextDate = new Date(today);
    nextDate.setDate(nextDate.getDate() + 30);
    var _e = tslib_1.__read(aurum_1.React.useState((0, dateUtils_1.toDateString)(today)), 2), fromDate = _e[0], setFromDate = _e[1];
    var _f = tslib_1.__read(aurum_1.React.useState((0, dateUtils_1.toDateString)(nextDate)), 2), toDate = _f[0], setToDate = _f[1];
    var diffToPx = function (from, to) {
        var diff = new Date(to).getTime() - new Date(from).getTime();
        return "".concat(diff / (12 * 60 * 1000), "px");
    };
    var listparams = {
        from: fromDate,
        to: toDate,
        housingIds: housingIds !== null && housingIds !== void 0 ? housingIds : selectedHousings.value
    };
    var reloadData = function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
        var data, days_1, dayDiff, i, date, e_1;
        var _a, _b;
        return tslib_1.__generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    setLoading(true);
                    return [4 /*yield*/, js_1.Housing.getCapacityForOwnerByHousingIds(listparams)];
                case 1:
                    data = _c.sent();
                    data.forEach(function (_a) {
                        var feature = _a.feature, details = _a.details;
                        var lanes = [];
                        details.forEach(function (detail) {
                            var lane = lanes.findIndex(function (lane) {
                                return !lane[0] || lane[0].to <= detail.from;
                            });
                            if (lane < 0) {
                                lane = lanes.push([]) - 1;
                            }
                            lanes[lane].unshift(detail);
                            detail.lane = lane + 1;
                        });
                        feature.lanes = lanes.length;
                    });
                    days_1 = [];
                    dayDiff = (0, dateUtils_1.getDateDifferenceInDays)(fromDate, toDate);
                    for (i = 0; i <= dayDiff; i++) {
                        date = new Date(fromDate + 'T00:00:00');
                        date.setDate(date.getDate() + i);
                        days_1.push(date);
                    }
                    setChartData(data);
                    if (housings == null) {
                        setHousings((_a = data === null || data === void 0 ? void 0 : data.map) === null || _a === void 0 ? void 0 : _a.call(data, function (_a) {
                            var feature = _a.feature;
                            return ({ value: feature.id, label: feature.localizations.de.title });
                        }));
                        selectedHousings.set((_b = data === null || data === void 0 ? void 0 : data.map) === null || _b === void 0 ? void 0 : _b.call(data, function (_a) {
                            var feature = _a.feature;
                            return feature.id;
                        }));
                    }
                    setDays(days_1);
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _c.sent();
                    console.error('Error reloading data:', e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    aurum_1.React.useEffect(function () {
        reloadData();
    }, [JSON.stringify(listparams)]);
    return aurum_1.React.createElement("div", { className: 'BookingPlan' + ((props === null || props === void 0 ? void 0 : props.openAsModal) ? ' modal' : '') },
        aurum_1.React.createElement(aurum_1.Sheet, { className: 'list' },
            aurum_1.React.createElement("div", { className: 'title' },
                aurum_1.React.createElement("h2", null, t('BookingPlan.title')),
                !chartData
                    ? null
                    : aurum_1.React.createElement("div", { className: 'sub' }, chartData.length)),
            aurum_1.React.createElement(aurum_1.InputGroup, { className: 'search' },
                aurum_1.React.createElement(aurum_1.Input, { type: 'date', label: t('BookingPlan.navigation.fromDate'), value: fromDate, onChange: function (e) { return setFromDate(e.target.value); } }),
                aurum_1.React.createElement(aurum_1.Input, { type: 'date', label: t('BookingPlan.navigation.fromDate'), value: toDate, onChange: function (e) { return setToDate(e.target.value); } }),
                aurum_1.React.createElement(aurum_1.Button, { onClick: reloadData },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'search' }),
                    t('Edit.navigation.search-button'))),
            (props === null || props === void 0 ? void 0 : props.openAsModal) == true
                ? null
                : aurum_1.React.createElement(aurum_1.Multiselect, { native: false, label: t('App.Menu.housings'), value: selectedHousings.inputvalue, onChange: selectedHousings.onChange, options: housings }),
            aurum_1.React.createElement("span", { className: 'span' }),
            aurum_1.React.createElement(aurum_1.Button, { className: 'reload', onClick: reloadData },
                aurum_1.React.createElement(aurum_1.Icon, { type: 'refresh' })),
            !loading && days && chartData
                ? aurum_1.React.createElement(aurum_1.Table, { striped: false, className: 'table' },
                    aurum_1.React.createElement("colgroup", null,
                        aurum_1.React.createElement("col", { width: '1%' }),
                        aurum_1.React.createElement("col", { width: '99%' })),
                    aurum_1.React.createElement("thead", null,
                        aurum_1.React.createElement("tr", null,
                            aurum_1.React.createElement("th", null, "Unterkunft"),
                            aurum_1.React.createElement("th", null,
                                aurum_1.React.createElement("div", { className: 'dates', style: { width: (days.length * 120) + 'px' } }, days.map(function (d) {
                                    return aurum_1.React.createElement("div", { className: 'date', key: (0, dateUtils_1.toDateString)(d), style: {
                                            left: diffToPx(fromDate + 'T00:00:00', d)
                                        } }, (0, dateUtils_1.formatDateYearMonthDay)(d));
                                }))))),
                    aurum_1.React.createElement("tbody", null, chartData && days
                        ? chartData.map(function (_a) {
                            var _b, _c;
                            var feature = _a.feature, details = _a.details;
                            return aurum_1.React.createElement(aurum_1.React.Fragment, { key: feature.id },
                                aurum_1.React.createElement("tr", null,
                                    aurum_1.React.createElement("th", null, (_c = (_b = feature === null || feature === void 0 ? void 0 : feature.localizations) === null || _b === void 0 ? void 0 : _b.de) === null || _c === void 0 ? void 0 : _c.title),
                                    aurum_1.React.createElement("td", null,
                                        aurum_1.React.createElement("div", { className: 'details', style: {
                                                width: (days.length * 120) + 'px',
                                                height: (feature.lanes * 32 + 1) + 'px'
                                            } }, details.map(function (detail, key) {
                                            var _a, _b;
                                            var style = {
                                                top: ((detail.lane - 1) * 32 + 1) + 'px',
                                                left: diffToPx(fromDate + 'T00:00:00', detail.from),
                                                width: diffToPx(detail.from, detail.to)
                                            };
                                            var label = detail.comment;
                                            if (detail.bookingCustomer) {
                                                label = "".concat((_a = detail.bookingCustomer) === null || _a === void 0 ? void 0 : _a.givenName, " ").concat((_b = detail.bookingCustomer) === null || _b === void 0 ? void 0 : _b.familyName).concat(label ? ': ' + label : '');
                                            }
                                            var title = "".concat((0, dateUtils_1.formatDateFull)(detail.from), " - ").concat((0, dateUtils_1.formatDateFull)(detail.to), "\n").concat(label);
                                            return aurum_1.React.createElement("div", { className: ['detail', detail.type].join(' '), key: key, title: title, style: style }, label !== null && label !== void 0 ? label : '\u00A0');
                                        })))));
                        })
                        : aurum_1.React.createElement("tr", null,
                            aurum_1.React.createElement("td", null, "Keine Daten"))))
                : aurum_1.React.createElement(aurum_1.Progress, { className: 'table' }, t('BookingPlan.loading'))));
};
exports.default = aurum_1.React.memo(BookingPlan);
