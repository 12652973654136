"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TradeTerm = void 0;
exports.TradeTerm = {
    split: true,
    form: [
        { name: 'id', show: false },
        { name: 'type', show: true },
        { name: 'localizations', show: true },
        { name: 'files', form: true }
    ]
};
