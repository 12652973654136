"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useObservedRef = void 0;
var react_1 = require("react");
var useObservedRef = function (_a) {
    var createObserver = _a.createObserver, reset = _a.reset;
    var currentNodeRef = (0, react_1.useRef)(null);
    var observerRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        var observer = createObserver();
        var node = currentNodeRef.current;
        if (node) {
            observer.observe(node);
        }
        observerRef.current = observer;
        return function () {
            observer.disconnect();
            observerRef.current = null;
        };
    }, [createObserver]);
    var ref = (0, react_1.useCallback)(function (node) {
        var previous = currentNodeRef.current;
        var observer = observerRef.current;
        if (previous && observer) {
            observer.unobserve(previous);
        }
        if (node && observer) {
            observer.observe(node);
        }
        if (previous && !node) {
            reset();
        }
        currentNodeRef.current = node;
    }, [reset]);
    return ref;
};
exports.useObservedRef = useObservedRef;
