"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var js_1 = require("@samsa/samsa-api/js");
var react_i18next_1 = require("react-i18next");
var HousingAssistant = function () {
    var t = (0, react_i18next_1.useTranslation)().t;
    var _a = tslib_1.__read(aurum_1.React.useState(1), 2), step = _a[0], setStep = _a[1];
    var _b = tslib_1.__read(aurum_1.React.useState([]), 2), aptitudes = _b[0], setAptitudes = _b[1];
    var reload = aurum_1.React.useCallback(function () {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var aptitudes;
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, js_1.HousingAptitude.getAll({
                            size: 0,
                            sort: 'title,asc'
                        })];
                    case 1:
                        aptitudes = _b.sent();
                        setAptitudes((_a = aptitudes === null || aptitudes === void 0 ? void 0 : aptitudes.content) !== null && _a !== void 0 ? _a : []);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    aurum_1.React.useEffect(reload, [reload]);
    var backbtn = step > 1 ? aurum_1.React.createElement(aurum_1.Button, { onClick: function () { return setStep(step - 1); } }, "zur\u00FCck") : aurum_1.React.createElement("span", null);
    var nextbtn = aurum_1.React.createElement(aurum_1.Button, { type: 'primary', onClick: function () { return setStep(step + 1); } }, "weiter");
    var mask = null;
    switch (step) {
        case 8:
            mask = aurum_1.React.createElement(aurum_1.React.Fragment, null,
                aurum_1.React.createElement(aurum_1.Header, null,
                    aurum_1.React.createElement("h2", null, "8. Bilder")),
                aurum_1.React.createElement(aurum_1.Body, null));
            break;
        case 7:
            mask = aurum_1.React.createElement(aurum_1.React.Fragment, null,
                aurum_1.React.createElement(aurum_1.Header, null,
                    aurum_1.React.createElement("h2", null, "7. R\u00E4ume")),
                aurum_1.React.createElement(aurum_1.Body, null));
            break;
        case 6:
            mask = aurum_1.React.createElement(aurum_1.React.Fragment, null,
                aurum_1.React.createElement(aurum_1.Header, null,
                    aurum_1.React.createElement("h2", null, "6. Mahlzeiten")),
                aurum_1.React.createElement(aurum_1.Body, null));
            break;
        case 5:
            mask = aurum_1.React.createElement(aurum_1.React.Fragment, null,
                aurum_1.React.createElement(aurum_1.Header, null,
                    aurum_1.React.createElement("h2", null, "5. Ausstattungsmerkmale")),
                aurum_1.React.createElement(aurum_1.Body, null));
            break;
        case 4:
            mask = aurum_1.React.createElement(aurum_1.React.Fragment, null,
                aurum_1.React.createElement(aurum_1.Header, null,
                    aurum_1.React.createElement("h2", null, "4. Hausregeln")),
                aurum_1.React.createElement(aurum_1.Body, null, aptitudes.map(function (aptitude) {
                    var setting = Math.random() < 0.5 ? 0 : (Math.random() < 0.5 ? 1 : 2);
                    return aurum_1.React.createElement(aurum_1.React.Fragment, { key: aptitude.id },
                        aurum_1.React.createElement(aurum_1.Row, null,
                            aurum_1.React.createElement("h3", null, aptitude.title),
                            aurum_1.React.createElement(aurum_1.InputGroup, { span: 2 },
                                aurum_1.React.createElement(aurum_1.Checkbox, { label: 'Ja', value: setting === 2 }),
                                aurum_1.React.createElement(aurum_1.Checkbox, { label: 'auf Anfrage', value: setting === 1 }),
                                aurum_1.React.createElement(aurum_1.Checkbox, { label: 'Nein', value: setting === 0 }))),
                        setting
                            ? aurum_1.React.createElement(aurum_1.Row, null,
                                aurum_1.React.createElement(aurum_1.Textarea, { label: 'Bemerkung' }))
                            : null);
                })));
            break;
        case 3:
            mask = aurum_1.React.createElement(aurum_1.React.Fragment, null,
                aurum_1.React.createElement(aurum_1.Header, null,
                    aurum_1.React.createElement("h2", null, "3. Beschreibung")),
                aurum_1.React.createElement(aurum_1.Body, null,
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement(aurum_1.Textarea, { autoFocus: true, label: t(['Entity.Housing.description', 'EntityAttribute.description']) }))));
            break;
        case 2:
            mask = aurum_1.React.createElement(aurum_1.React.Fragment, null,
                aurum_1.React.createElement(aurum_1.Header, null,
                    aurum_1.React.createElement("h2", null, "2. Adresse")),
                aurum_1.React.createElement(aurum_1.Body, null,
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement(aurum_1.Input, { autoFocus: true, label: t(['Entity.Housing.streetAddress', 'EntityAttribute.streetAddress']) })),
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement(aurum_1.Input, { label: t(['Entity.Housing.extendedAddress', 'EntityAttribute.extendedAddress']) })),
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement(aurum_1.Input, { label: t(['Entity.Housing.region', 'EntityAttribute.region']) })),
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement(aurum_1.Input, { label: t(['Entity.Housing.country', 'EntityAttribute.country']) }),
                        aurum_1.React.createElement(aurum_1.Input, { label: t(['Entity.Housing.postalCode', 'EntityAttribute.postalCode']) }),
                        aurum_1.React.createElement(aurum_1.Input, { span: 2, label: t(['Entity.Housing.locality', 'EntityAttribute.locality']) })),
                    aurum_1.React.createElement(aurum_1.Well, null,
                        "Hier bitte Kartenansicht vorstellen...",
                        aurum_1.React.createElement(aurum_1.Row, null,
                            aurum_1.React.createElement(aurum_1.Input, { label: t(['Entity.Housing.lat', 'EntityAttribute.lat']) }),
                            aurum_1.React.createElement(aurum_1.Input, { label: t(['Entity.Housing.lon', 'EntityAttribute.lon']) })))));
            break;
        case 1:
            mask = aurum_1.React.createElement(aurum_1.React.Fragment, null,
                aurum_1.React.createElement(aurum_1.Header, null,
                    aurum_1.React.createElement("h2", null, "1. Basisdaten")),
                aurum_1.React.createElement(aurum_1.Body, null,
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement(aurum_1.Input, { autoFocus: true, label: t(['Entity.Housing.title', 'EntityAttribute.title']) })),
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement(aurum_1.Input, { label: t(['Entity.Housing.accessibility', 'EntityAttribute.accessibility']) })),
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement(aurum_1.Input, { span: 2, label: t(['Entity.Housing.housingType', 'EntityAttribute.housingType']) }),
                        aurum_1.React.createElement(aurum_1.Input, { label: t(['Entity.Housing.livingSpace', 'EntityAttribute.livingSpace']) }),
                        aurum_1.React.createElement(aurum_1.Input, { label: t(['Entity.Housing.floorCount', 'EntityAttribute.floorCount']) })),
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement(aurum_1.Input, { label: t(['Entity.Housing.checkIn', 'EntityAttribute.checkIn']) }),
                        aurum_1.React.createElement(aurum_1.Input, { label: t(['Entity.Housing.checkOut', 'EntityAttribute.checkOut']) }))));
            break;
    }
    return aurum_1.React.createElement("div", { className: 'HousingAssistant' },
        aurum_1.React.createElement("h1", null, "Unterkunft anlegen"),
        aurum_1.React.createElement(aurum_1.Sheet, { className: 'assistant' },
            mask,
            aurum_1.React.createElement(aurum_1.Footer, null,
                aurum_1.React.createElement(aurum_1.Row, null,
                    backbtn,
                    nextbtn))));
};
exports.default = HousingAssistant;
