"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var js_1 = require("@samsa/samsa-api/js");
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var useAsync_1 = require("../../hook/useAsync");
var useOnChange_1 = require("../../hook/useOnChange");
var dateUtils_1 = require("../../util/dateUtils");
var formatPrice_1 = require("../../util/formatPrice");
var VoucherStatistics = function () {
    var t = (0, react_i18next_1.useTranslation)().t;
    var prefix = 'App.VoucherStatistics.';
    var from = new Date();
    from.setDate(from.getDate() - 365);
    var fromDate = (0, useOnChange_1.useOnChange)((0, dateUtils_1.toDateString)(from));
    var toDate = (0, useOnChange_1.useOnChange)((0, dateUtils_1.getLastDayOfMonthAsLocalDateString)());
    var fromDateInput = (react_1.default.createElement(aurum_1.Input, { label: t(prefix + 'fromDate'), type: 'date', value: fromDate.inputvalue, onChange: fromDate.onChange }));
    var toDateInput = (react_1.default.createElement(aurum_1.Input, { label: t(prefix + 'toDate'), type: 'date', value: toDate.inputvalue, onChange: toDate.onChange }));
    var voucherData = (0, useAsync_1.useAsync)(function () { return (0, js_1.getVoucherStatistics)({
        from: fromDate.value,
        to: toDate.value
    }); }, [
        fromDate.value,
        toDate.value
    ], false, false, false);
    var downloadButtonAvailable = voucherData.value &&
        voucherData.value.length > 0 &&
        !voucherData.loading;
    var downloadButton = (react_1.default.createElement(aurum_1.Button, { disabled: !downloadButtonAvailable, onClick: function () { return (0, js_1.createVoucherStatisticsFile)({
            from: fromDate.value,
            to: toDate.value
        }, {
            responseType: 'blob'
        }).then(function (response) {
            // setSepaError(null);
            // @ts-ignore
            var url = window.URL.createObjectURL(new Blob([response]));
            var link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Liste_Gutscheine_' + fromDate.value + '-' + toDate.value + '.xlsx');
            document.body.appendChild(link);
            link.click();
        }).catch(function (error) {
            console.log(error);
        }); } },
        react_1.default.createElement(aurum_1.Icon, { size: 'big', type: 'download' }),
        t(prefix + 'download')));
    var data;
    if (voucherData.loading) {
        data = react_1.default.createElement(aurum_1.Progress, null, t(prefix + 'loading'));
    }
    else if (voucherData.error) {
        // salesData.error ist ein AxiosError mit 'message' und 'response.data.message'
        data =
            react_1.default.createElement(aurum_1.Alert, { className: 'loading-error', type: 'error' },
                t(prefix + 'error'),
                ": ",
                t(prefix + 'defaultErrorMessage'));
    }
    else if (voucherData.value) {
        data = (react_1.default.createElement(aurum_1.Table, null,
            react_1.default.createElement("thead", null,
                react_1.default.createElement("tr", null,
                    react_1.default.createElement("th", null, t(prefix + 'voucherObjectId')),
                    react_1.default.createElement("th", null, t(prefix + 'date')),
                    react_1.default.createElement("th", null, t(prefix + 'action')),
                    react_1.default.createElement("th", null, t(prefix + 'value')))),
            react_1.default.createElement("tbody", null, voucherData.value.map(function (r) { return (react_1.default.createElement("tr", { key: r.voucherObjectId + '-' + r.date },
                react_1.default.createElement("td", null, r.voucherObjectId),
                react_1.default.createElement("td", null, r.date),
                react_1.default.createElement("td", null, r.action),
                react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(r.value)))); }))));
    }
    else {
        data = react_1.default.createElement("div", null, "Keine Daten bisher geladen");
    }
    return (react_1.default.createElement("div", { className: 'VoucherStatistics' },
        react_1.default.createElement(aurum_1.Sheet, null,
            react_1.default.createElement("div", { className: 'input-row' },
                react_1.default.createElement("h2", null, t('VoucherStatistics.title')),
                fromDateInput,
                toDateInput,
                react_1.default.createElement(aurum_1.Button, { disabled: voucherData.loading, onClick: voucherData.loading ? null : voucherData.reload },
                    react_1.default.createElement(aurum_1.Icon, { type: 'search' }),
                    t('Edit.navigation.search-button')),
                downloadButton),
            data)));
};
exports.default = (0, react_1.memo)(VoucherStatistics);
