"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFkeyConstraint = void 0;
var getFkeyConstraint = function (prop, entry) {
    var _a;
    var name = undefined;
    var value = undefined;
    if (prop === null || prop === void 0 ? void 0 : prop.references) {
        name = prop.name;
        value = ((_a = prop === null || prop === void 0 ? void 0 : prop.references) === null || _a === void 0 ? void 0 : _a.class) ? entry === null || entry === void 0 ? void 0 : entry.id : entry;
    }
    else if (entry.id) {
        name = prop.name + '.id';
        value = entry.id;
    }
    else {
        name = prop.name;
        value = entry;
    }
    //console.log('getFkeyConstraint', name, value);
    return { name: name, value: value };
};
exports.getFkeyConstraint = getFkeyConstraint;
