"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEmployeeStatus = void 0;
var react_1 = require("react");
var employeeStatus_1 = require("../util/employeeStatus");
var useHasPermission_1 = require("./useHasPermission");
var useEmployeeStatus = function () {
    var canReadBookings = (0, useHasPermission_1.useHasPermission)('BOOKING_READ');
    var canReadOwnerBookings = (0, useHasPermission_1.useHasPermission)('BOOKING_OWNER_READ');
    return (0, react_1.useMemo)(function () {
        if (canReadBookings) {
            return employeeStatus_1.EmployeeStatus.INTERNAL;
        }
        else if (canReadOwnerBookings) {
            return employeeStatus_1.EmployeeStatus.EXTERNAL;
        }
        return employeeStatus_1.EmployeeStatus.UNDEFINED;
    }, [canReadBookings, canReadOwnerBookings]);
};
exports.useEmployeeStatus = useEmployeeStatus;
