"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var js_1 = require("@samsa/samsa-api/js");
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var useOnChange_1 = require("../hook/useOnChange");
var useSort_1 = require("../hook/useSort");
var dateUtils_1 = require("../util/dateUtils");
var formatPrice_1 = require("../util/formatPrice");
var useAsync_1 = require("../hook/useAsync");
var tablefields = [
    { name: 'bookingShortId' },
    { name: 'bookingPositionId' },
    { name: 'bookingDate' },
    { name: 'travelTimespan' },
    { name: 'sumExported' },
    { name: 'sumPaid' },
    { name: 'exportPercentage' },
    { name: 'paymentTypes' }
    // {name: 'paymentShortId'},
    // {name: 'paymentAmount'},
    // {name: 'paymentDate'},
    // {name: 'paymentType'},
    // {name: 'invoiceDate'},
    // {name: 'receiver'}
];
var shortenPositionId = function (entry) {
    var length = entry.bookingPositionId.length;
    var chars = 3;
    var start = entry.bookingPositionId.substring(0, chars);
    var end = entry.bookingPositionId.substring(length - chars, length);
    return "".concat(start, " ... ").concat(end);
};
// TODO: Menüpunkt ausblenden oder so, wenn Benutzer die Permission "OWNER_INVOICE_READ" nicht hat?
// Siehe https://app.asana.com/0/1148573263111881/1201726556846430/f
var BookingExportInfo = function () {
    var _a;
    // console.log('Edit.render()');
    var t = (0, react_i18next_1.useTranslation)().t;
    var _b = tslib_1.__read(aurum_1.React.useState([]), 2), pageSelection = _b[0], setPageSelection = _b[1];
    var size = (0, useOnChange_1.useOnChange)(20);
    var page = (0, useOnChange_1.useOnChange)(0);
    var _c = tslib_1.__read((0, useSort_1.useSort)('bookingDate,desc'), 2), sort = _c[0], toggleSort = _c[1];
    var fromDate = (0, useOnChange_1.useOnChange)((0, dateUtils_1.getFirstDayOfMonthAsLocalDateString)());
    var toDate = (0, useOnChange_1.useOnChange)((0, dateUtils_1.getLastDayOfMonthAsLocalDateString)());
    var listparams = {
        size: size.value,
        page: page.value,
        // sort: ['bookingPositionId,asc', sort],
        sort: ['bookingDate,desc', sort],
        from: fromDate.value,
        to: toDate.value
    };
    var promise = function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/, (0, js_1.getBookingExportInfo)(listparams, {
                    timeout: 300000
                })];
        });
    }); };
    var _d = (0, useAsync_1.useAsync)(promise, [size.value, page.value, sort, fromDate.value, toDate.value], true, true, false), value = _d.value, error = _d.error, reload = _d.reload, loading = _d.loading;
    var _e = tslib_1.__read(aurum_1.React.useState(null), 2), cachedTable = _e[0], setCachedTable = _e[1];
    aurum_1.React.useEffect(function () {
        // console.log('setCachedTable.render()');
        var content;
        if (error) {
            content = (aurum_1.React.createElement("tr", null,
                aurum_1.React.createElement("td", { colSpan: tablefields.length },
                    aurum_1.React.createElement(aurum_1.Alert, { type: 'error' }, error.message))));
        }
        else if (!value) {
            content = (aurum_1.React.createElement("tr", null,
                aurum_1.React.createElement("td", { colSpan: tablefields.length },
                    aurum_1.React.createElement(aurum_1.Progress, null, t('BookingExportInfo.loading')))));
        }
        else if (value) {
            content = value.content.map(function (entry) {
                var className = null;
                var title = null;
                // if ((entry.sumPaid ?? 0) !== (entry.paymentAmount ?? 0)) {
                //   className = 'error';
                //   title = `Die Summe in der Buchungsposition (Spalte "Eingenommen": ${formatPrice(entry.sumPaid)}) stimmt nicht mit der Summe in der Zahlung (Spalte "Zahlung": ${formatPrice(entry.paymentAmount)}) überein!`;
                // } else if (entry.fullyExported && (entry.sumPaid ?? 0) !== (entry.sumfullyExported ?? 0)) {
                //   className = 'error';
                //   title = `Die Summe in der Buchungsposition (Spalte "Eingenommen": ${formatPrice(entry.sumPaid)}) stimmt nicht mit der Summe in der Partnerabrechnung (Spalte "Abgerechnet": ${formatPrice(entry.sumfullyExported)}) überein!`;
                // } else if (!entry.fullyExported && entry.sumPaid) {
                //   className = 'warn';
                //   title = 'Es existiert keine Partnerabrechnung zu diesem Datensatz!';
                // } else if (!entry.fullyExported && !entry.sumPaid) {
                //   title = 'Es existiert keine Partnerabrechnung zu diesem Datensatz - was aber egal ist, da die Summe in der Buchungsposition (Spalte "Eingenommen": 0,00 €) gleich Null ist.';
                // }
                if (!entry.fullyExported && !entry.hasInvoicePositions) {
                    className = 'error';
                    title = 'Es existiert keine Partnerabrechnung zu diesem Datensatz.';
                }
                else if (!entry.fullyExported && entry.hasInvoicePositions) {
                    className = 'warn';
                    title = "Unvollst\u00E4ndige Partnerabrechnung (".concat((0, formatPrice_1.formatPrice)(entry.sumExported), " von ").concat((0, formatPrice_1.formatPrice)(entry.sumPaid), ")");
                }
                else if (entry.fullyExported && !entry.sumPaid && !entry.sumExported) {
                    title = 'Keine Partnerabrechnung (vermutlich, da eingenommene Summe gleich 0,00 € ist)';
                }
                return aurum_1.React.createElement("tr", { key: entry.bookingPositionId, className: className, title: title }, tablefields.map(function (prop) {
                    if (prop.name === 'bookingPositionId') {
                        return aurum_1.React.createElement("td", { key: prop.name }, shortenPositionId(entry));
                    }
                    if (prop.name === 'travelTimespan') {
                        var from = entry.requestFrom;
                        var to = entry.requestTo;
                        if (entry.requestFrom) {
                            from = (0, dateUtils_1.formatDateFullNoSeconds)(entry.requestFrom);
                        }
                        if (entry.requestTo) {
                            to = (0, dateUtils_1.formatDateFullNoSeconds)(entry.requestTo);
                        }
                        if (from === to) {
                            return aurum_1.React.createElement("td", { key: prop.name }, from);
                        }
                        return aurum_1.React.createElement("td", { key: prop.name },
                            from,
                            " - ",
                            to);
                    }
                    var val = (entry[prop.name] != null ? entry[prop.name] : '');
                    if (prop.name === 'bookingDate') {
                        val = (0, dateUtils_1.formatDateFullNoSeconds)(val);
                    }
                    else if (prop.name === 'sumPaid') {
                        val = (0, formatPrice_1.formatPrice)(val);
                    }
                    else if (prop.name === 'paymentAmount') {
                        val = (0, formatPrice_1.formatPrice)(val);
                    }
                    else if (prop.name === 'sumExported') {
                        val = (0, formatPrice_1.formatPrice)(val);
                    }
                    else if (prop.name === 'invoiceDate') {
                        val = (0, dateUtils_1.formatDateShort)(val);
                    }
                    else if (prop.name === 'paymentDate') {
                        val = (0, dateUtils_1.formatDateFullNoSeconds)(val);
                    }
                    else if (prop.name === 'paymentType') {
                        val = val ? t("Enum.".concat(val)) : val;
                    }
                    else if (prop.name === 'paymentTypes') {
                        val = val ? val.map(function (e) { return t("Enum.".concat(e)); }).join(', ') : val;
                    }
                    else if (prop.name === 'exportPercentage') {
                        val = (val * 100).toFixed(0) + ' %';
                    }
                    else {
                        val = val.toString();
                    }
                    return aurum_1.React.createElement("td", { key: prop.name }, val);
                }));
            });
        }
        setCachedTable(aurum_1.React.createElement(aurum_1.Table, { className: 'table' },
            aurum_1.React.createElement("thead", null,
                aurum_1.React.createElement("tr", null, tablefields.map(function (prop) {
                    if (prop.name === 'travelTimespan') {
                        return aurum_1.React.createElement("th", { key: prop.name }, t("EntityAttribute.".concat(prop.name)));
                    }
                    var title = t("EntityAttribute.".concat(prop.name));
                    if (prop.name.startsWith('generatedFor')) {
                        title = title.split(' ').slice(0, 3).join(' ');
                    }
                    else if (prop.name === 'paymentTypes') {
                        title = t('EntityAttribute.paymentType');
                    }
                    return aurum_1.React.createElement("th", { key: prop.name, className: loading ? '' : 'clickable', onClick: function () {
                            if (!loading) {
                                return toggleSort(prop.name)();
                            }
                        } },
                        title,
                        sort === "".concat(prop.name, ",asc") ? aurum_1.React.createElement(aurum_1.Icon, { type: 'arrow_drop_up', size: 'big' }) : null,
                        sort === "".concat(prop.name, ",desc") ? aurum_1.React.createElement(aurum_1.Icon, { type: 'arrow_drop_down', size: 'big' }) : null);
                }))),
            aurum_1.React.createElement("tbody", null, content)));
    }, [error, loading, sort, t, value]);
    var totalPages = (_a = value === null || value === void 0 ? void 0 : value.totalPages) !== null && _a !== void 0 ? _a : 1;
    aurum_1.React.useEffect(function () {
        setPageSelection(Array.apply(null, Array(totalPages))
            // eslint-disable-next-line react/no-array-index-key
            .map(function (_v, page) { return aurum_1.React.createElement("option", { key: page, value: page, label: (page + 1) + '/' + totalPages }); }));
    }, [totalPages]);
    return aurum_1.React.createElement("div", { className: 'BookingList' },
        aurum_1.React.createElement(aurum_1.Sheet, { className: 'list' },
            aurum_1.React.createElement("div", { className: 'title' },
                aurum_1.React.createElement("h2", null, t('BookingExportInfo.title')),
                !value
                    ? null
                    : aurum_1.React.createElement("div", { className: 'sub' }, value.totalElements === 0
                        ? t('Edit.navigation.paginationNone')
                        : t('Edit.navigation.pagination', {
                            from: value.number * value.size + 1,
                            to: Math.min(value.number * value.size + value.size, value.totalElements),
                            total: value.totalElements
                        }))),
            aurum_1.React.createElement(aurum_1.InputGroup, { className: 'search' },
                aurum_1.React.createElement(aurum_1.Input, { type: 'date', label: t('BookingExportInfo.navigation.from'), value: fromDate.inputvalue, onChange: fromDate.onChange, disabled: loading }),
                aurum_1.React.createElement(aurum_1.Input, { type: 'date', label: t('BookingExportInfo.navigation.to'), value: toDate.inputvalue, onChange: toDate.onChange, disabled: loading }),
                aurum_1.React.createElement(aurum_1.Button, { onClick: reload, disabled: loading },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'search' }),
                    t('Edit.navigation.search-button'))),
            aurum_1.React.createElement(aurum_1.InputGroup, { className: 'navigate' },
                aurum_1.React.createElement(aurum_1.Button, { disabled: (value === null || value === void 0 ? void 0 : value.first) || loading, onClick: function () { return page.set(0); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'first_page' })),
                aurum_1.React.createElement(aurum_1.Button, { disabled: (value === null || value === void 0 ? void 0 : value.first) || loading, onClick: function () { return page.set(page.value - 1); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'navigate_before' })),
                aurum_1.React.createElement(aurum_1.Select, { disabled: !(pageSelection === null || pageSelection === void 0 ? void 0 : pageSelection.length) || loading, label: t('Edit.navigation.page'), value: (pageSelection === null || pageSelection === void 0 ? void 0 : pageSelection.length) ? value === null || value === void 0 ? void 0 : value.number : '', onChange: page.onChange, options: pageSelection }),
                aurum_1.React.createElement(aurum_1.Button, { disabled: (value === null || value === void 0 ? void 0 : value.last) || loading, onClick: function () { return page.set(page.value - -1); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'navigate_next' })),
                aurum_1.React.createElement(aurum_1.Button, { disabled: (value === null || value === void 0 ? void 0 : value.last) || loading, onClick: function () { return page.set((value === null || value === void 0 ? void 0 : value.totalPages) - 1); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'last_page' }))),
            aurum_1.React.createElement(aurum_1.ButtonGroup, { className: 'pagesize' },
                aurum_1.React.createElement(aurum_1.Button, { onClick: function () {
                        size.set(20);
                        page.set(0);
                    }, type: size.value === 20 ? 'primary' : null, disabled: loading }, "20"),
                aurum_1.React.createElement(aurum_1.Button, { onClick: function () {
                        size.set(50);
                        page.set(0);
                    }, type: size.value === 50 ? 'primary' : null, disabled: loading }, "50"),
                aurum_1.React.createElement(aurum_1.Button, { onClick: function () {
                        size.set(100);
                        page.set(0);
                    }, type: size.value === 100 ? 'primary' : null, disabled: loading }, "100")),
            cachedTable));
};
exports.default = aurum_1.React.memo(BookingExportInfo);
