"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var js_1 = require("@samsa/samsa-api/js");
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var useAsync_1 = require("../../hook/useAsync");
var useOnChange_1 = require("../../hook/useOnChange");
var dateUtils_1 = require("../../util/dateUtils");
// TODO: Dropdown-Daten sortieren
// TODO: Wenn man weniger Owner wählt, dann bleiben FeatureProvider weiterhin ausgewählt
//  (der Server berücksichtigt das, aber clientseitig sieht das dumm aus). Vielleicht nur
//  clientseitig filtern statt neuzuladen? Vielleicht alles immer anzeigen, aber FeatureProvider
//  deren Owner abgewählt sind, nach unten sortieren und anders darstellen (grau)?
// TODO: Fehlerbehandlung
var FeatureProblems = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var t = (0, react_i18next_1.useTranslation)().t;
    var prefix = 'App.FeatureProblems.';
    var selectedOwnerIds = (0, useOnChange_1.useOnChange)();
    var selectedFeatureProviderIds = (0, useOnChange_1.useOnChange)();
    var selectedFeatureTypeIds = (0, useOnChange_1.useOnChange)();
    var fromDate = (0, useOnChange_1.useOnChange)((0, dateUtils_1.getNowAsLocalDateString)());
    var toDate = (0, useOnChange_1.useOnChange)((0, dateUtils_1.getTodayInOneYearAsLocalDateString)());
    var ownerData = (0, useAsync_1.useAsync)(function () { return js_1.Owner.getAllDropdownOptions(); }, [], true, false, false);
    var featureProviderData = (0, useAsync_1.useAsync)(function () { return js_1.FeatureProvider.getAllDropdownOptions({ 'owner.id': selectedOwnerIds.value }); }, [selectedOwnerIds.value], true, true, false);
    var featureTypeData = [
        'HOUSING', 'TRANSPORTATION', 'BICYCLE', 'EVENT', 'PARKINGSPACE', 'ARTICLE', 'VISITORTAX'
    ];
    var fromDateInput = (react_1.default.createElement(aurum_1.Input, { label: t(prefix + 'fromDate'), type: 'date', value: fromDate.inputvalue, onChange: fromDate.onChange }));
    var toDateInput = (react_1.default.createElement(aurum_1.Input, { label: t(prefix + 'toDate'), type: 'date', value: toDate.inputvalue, onChange: toDate.onChange }));
    var ownerDataAvailable = ((_b = (_a = ownerData.value) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 0;
    var selectedOwners = (_d = (_c = selectedOwnerIds.value) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0;
    var totalOwners = (_f = (_e = ownerData.value) === null || _e === void 0 ? void 0 : _e.length) !== null && _f !== void 0 ? _f : 0;
    var ownerInput = (react_1.default.createElement(aurum_1.Multiselect, { label: "".concat(t(prefix + 'owner'), " (").concat(selectedOwners, "/").concat(totalOwners, ")"), native: false, value: selectedOwnerIds.inputvalue, onChange: selectedOwnerIds.onChange, disabled: !ownerDataAvailable, options: ownerData.value }));
    var featureProviderDataAvailable = ((_h = (_g = featureProviderData.value) === null || _g === void 0 ? void 0 : _g.length) !== null && _h !== void 0 ? _h : 0) > 0;
    var selectedFeatureProviders = (_k = (_j = selectedFeatureProviderIds.value) === null || _j === void 0 ? void 0 : _j.length) !== null && _k !== void 0 ? _k : 0;
    var totalFeatureProviders = (_m = (_l = featureProviderData.value) === null || _l === void 0 ? void 0 : _l.length) !== null && _m !== void 0 ? _m : 0;
    var featureProviderInput = (react_1.default.createElement(aurum_1.Multiselect, { label: "".concat(t(prefix + 'featureProvider'), " (").concat(selectedFeatureProviders, "/").concat(totalFeatureProviders, ")"), native: false, value: selectedFeatureProviderIds.inputvalue, onChange: selectedFeatureProviderIds.onChange, disabled: !featureProviderDataAvailable, options: featureProviderData.value }));
    var selectedProducts = (_p = (_o = selectedFeatureTypeIds.value) === null || _o === void 0 ? void 0 : _o.length) !== null && _p !== void 0 ? _p : 0;
    var totalProducts = (_q = featureTypeData === null || featureTypeData === void 0 ? void 0 : featureTypeData.length) !== null && _q !== void 0 ? _q : 0;
    var featureTypeInput = (react_1.default.createElement(aurum_1.Multiselect, { label: "".concat(t(prefix + 'featureType'), " (").concat(selectedProducts, "/").concat(totalProducts, ")"), native: false, value: selectedFeatureTypeIds.inputvalue, onChange: selectedFeatureTypeIds.onChange, options: featureTypeData.map(function (id) { return ({ id: id, value: id }); }) }));
    var problems = (0, useAsync_1.useAsync)(function () { return (0, js_1.getFeatureProblems)(new js_1.FeatureProblemsRequestBody({
        from: fromDate.value,
        to: toDate.value,
        ownerIds: selectedOwnerIds.value,
        featureProviderIds: selectedFeatureProviderIds.value,
        featureTypes: selectedFeatureTypeIds.value
    })); }, [
        fromDate.value,
        toDate.value,
        selectedOwnerIds.value,
        selectedFeatureProviderIds.value, selectedFeatureTypeIds.value
    ], true, false, false);
    var dropdownDataAvailable = ownerDataAvailable &&
        featureProviderDataAvailable;
    var searchButton = (react_1.default.createElement(aurum_1.Button, { disabled: !dropdownDataAvailable, onClick: problems.reload },
        react_1.default.createElement(aurum_1.Icon, { size: 'big', type: 'search' }),
        t(prefix + 'search')));
    var downloadButtonAvailable = problems.value &&
        problems.value.length > 0;
    var downloadButton = (react_1.default.createElement(aurum_1.Button, { disabled: !downloadButtonAvailable, onClick: function () { return (0, js_1.createFeatureProblemsFile)(new js_1.FeatureProblemsRequestBody({
            from: fromDate.value,
            to: toDate.value,
            ownerIds: selectedOwnerIds.value,
            featureProviderIds: selectedFeatureProviderIds.value,
            featureTypes: selectedFeatureTypeIds.value
        }), {
            responseType: 'blob'
        }).then(function (response) {
            // setSepaError(null);
            // @ts-ignore
            var url = window.URL.createObjectURL(new Blob([response]));
            var link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'feature_problems_' + fromDate.value + '-' + toDate.value + '.xlsx');
            document.body.appendChild(link);
            link.click();
        }).catch(function (error) {
            console.log(error);
        }); } },
        react_1.default.createElement(aurum_1.Icon, { size: 'big', type: 'download' }),
        t(prefix + 'download')));
    var data;
    if (problems.loading) {
        data = react_1.default.createElement(aurum_1.Progress, null, t(prefix + 'loading'));
    }
    else if (problems.error) {
        // salesData.error ist ein AxiosError mit 'message' und 'response.data.message'
        data =
            react_1.default.createElement(aurum_1.Alert, { className: 'loading-error', type: 'error' },
                t(prefix + 'error'),
                ": ",
                t(prefix + 'defaultErrorMessage'));
    }
    else if (problems.value) {
        data = (react_1.default.createElement(aurum_1.Table, null,
            react_1.default.createElement("thead", null,
                react_1.default.createElement("tr", null,
                    react_1.default.createElement("th", null, t(prefix + 'ownerShortId')),
                    react_1.default.createElement("th", null, t(prefix + 'owner')),
                    react_1.default.createElement("th", null, t(prefix + 'featureProviderShortId')),
                    react_1.default.createElement("th", null, t(prefix + 'featureProvider')),
                    react_1.default.createElement("th", null, t(prefix + 'featureType')),
                    react_1.default.createElement("th", null, t(prefix + 'featureShortId')),
                    react_1.default.createElement("th", null, t(prefix + 'feature')),
                    react_1.default.createElement("th", null, t(prefix + 'reason')))),
            react_1.default.createElement("tbody", null, problems.value.map(function (r) { return (react_1.default.createElement("tr", { key: r.ownerId + '-' + r.featureProviderId + '-' + r.featureId },
                react_1.default.createElement("td", null, r.ownerShortId),
                react_1.default.createElement("td", null, r.owner),
                react_1.default.createElement("td", null, r.featureProviderShortId),
                react_1.default.createElement("td", null, r.featureProvider),
                react_1.default.createElement("td", null, r.featureType),
                react_1.default.createElement("td", null, r.featureShortId),
                react_1.default.createElement("td", null, r.feature),
                react_1.default.createElement("td", null, r.reason))); }))));
    }
    else {
        data = react_1.default.createElement("div", null, "Keine Daten bisher geladen");
    }
    return (react_1.default.createElement("div", { className: 'FeatureProblems' },
        react_1.default.createElement(aurum_1.Sheet, null,
            react_1.default.createElement("div", { className: 'input-row' },
                fromDateInput,
                toDateInput,
                ownerInput,
                featureProviderInput,
                featureTypeInput,
                searchButton,
                downloadButton),
            data)));
};
exports.default = (0, react_1.memo)(FeatureProblems);
