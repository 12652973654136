"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var js_1 = require("@samsa/samsa-api/js");
var Demo6 = function () {
    var _a = tslib_1.__read(aurum_1.React.useState(null), 2), data = _a[0], setData = _a[1];
    var reload = aurum_1.React.useCallback(function () {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var drafted, published, waiting;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, js_1.HousingDraft.getAll({
                            size: 1
                        })];
                    case 1:
                        drafted = _a.sent();
                        return [4 /*yield*/, js_1.Housing.getAll({
                                size: 1
                            })];
                    case 2:
                        published = _a.sent();
                        return [4 /*yield*/, js_1.PublicationRequest.getAll({
                                size: 1,
                                status: js_1.PublicationRequest.enum.status.REQUESTED,
                                targetType: 'Housing'
                            })];
                    case 3:
                        waiting = _a.sent();
                        setData({
                            drafted: drafted === null || drafted === void 0 ? void 0 : drafted.totalElements,
                            published: published === null || published === void 0 ? void 0 : published.totalElements,
                            waiting: waiting === null || waiting === void 0 ? void 0 : waiting.totalElements
                        });
                        return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    aurum_1.React.useEffect(reload, [reload]);
    return aurum_1.React.createElement(aurum_1.Sheet, { className: 'tile size-1-1' },
        aurum_1.React.createElement(aurum_1.Header, null,
            aurum_1.React.createElement("h3", null, "Ver\u00F6ffentlichungen (Unterk\u00FCnfte)")),
        aurum_1.React.createElement(aurum_1.Body, null, data
            ? aurum_1.React.createElement(aurum_1.React.Fragment, null,
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("h1", null, data === null || data === void 0 ? void 0 : data.drafted),
                    aurum_1.React.createElement("h1", null, data === null || data === void 0 ? void 0 : data.waiting),
                    aurum_1.React.createElement("h1", null, data === null || data === void 0 ? void 0 : data.published)),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("span", null, "angelegt"),
                    aurum_1.React.createElement("span", null, "angefragt"),
                    aurum_1.React.createElement("span", null, "ver\u00F6ffentlicht")))
            : aurum_1.React.createElement(aurum_1.Progress, null)),
        aurum_1.React.createElement(aurum_1.Footer, null,
            aurum_1.React.createElement(aurum_1.Row, null,
                aurum_1.React.createElement(aurum_1.Button, null,
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'list' }),
                    "Unterk\u00FCnfte bearbeiten"))));
};
exports.default = aurum_1.React.memo(Demo6);
