"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var pie_1 = require("@nivo/pie");
var js_1 = require("@samsa/samsa-api/js");
var Dashboard_1 = require("../Dashboard");
var Demo3 = function () {
    var _a = tslib_1.__read(aurum_1.React.useState(null), 2), data = _a[0], setData = _a[1];
    var reload = aurum_1.React.useCallback(function () {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var sum, result, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, js_1.HousingDraft.getAll({
                            size: 1
                        })];
                    case 1:
                        sum = _a.sent();
                        return [4 /*yield*/, js_1.HousingType.getAll({
                                size: 0
                            })];
                    case 2:
                        result = _a.sent();
                        return [4 /*yield*/, Promise.all(result.content.map(function (_a) {
                                var id = _a.id, localizations = _a.localizations;
                                return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                                    var result, _b;
                                    var _c;
                                    return tslib_1.__generator(this, function (_d) {
                                        switch (_d.label) {
                                            case 0:
                                                _d.trys.push([0, 2, , 3]);
                                                return [4 /*yield*/, js_1.HousingDraft.getAll({
                                                        size: 1,
                                                        'type.id': id
                                                    })];
                                            case 1:
                                                result = _d.sent();
                                                return [3 /*break*/, 3];
                                            case 2:
                                                _b = _d.sent();
                                                return [3 /*break*/, 3];
                                            case 3: return [2 /*return*/, {
                                                    id: id,
                                                    label: (_c = localizations === null || localizations === void 0 ? void 0 : localizations.de) === null || _c === void 0 ? void 0 : _c.title,
                                                    color: (0, Dashboard_1.getColor)(id),
                                                    value: result === null || result === void 0 ? void 0 : result.totalElements
                                                }];
                                        }
                                    });
                                });
                            }))];
                    case 3:
                        data = _a.sent();
                        setData(tslib_1.__spreadArray(tslib_1.__spreadArray([], tslib_1.__read(data), false), [
                            {
                                id: '',
                                label: 'nicht angegeben',
                                color: (0, Dashboard_1.getColor)(),
                                value: (sum === null || sum === void 0 ? void 0 : sum.totalElements) - data.map(function (e) { return e.value; }).reduce(function (a, b) { return a + b; }, 0)
                            }
                        ], false));
                        return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    aurum_1.React.useEffect(reload, [reload]);
    return aurum_1.React.createElement(aurum_1.Sheet, { className: 'tile size-1-2' },
        aurum_1.React.createElement(aurum_1.Header, null,
            aurum_1.React.createElement("h3", null, "Unterk\u00FCnfte nach Art")),
        aurum_1.React.createElement(aurum_1.Body, null, data
            ? aurum_1.React.createElement(aurum_1.React.Fragment, null,
                aurum_1.React.createElement(pie_1.ResponsivePie, { data: data, colors: function (e) {
                        return e.data.color;
                    }, enableRadialLabels: false, innerRadius: 0.25, padAngle: 0.5, sliceLabelsSkipAngle: 20, sliceLabel: 'label', sliceLabelsTextColor: '#fff', sortByValue: true }))
            : aurum_1.React.createElement(aurum_1.Progress, null)));
};
exports.default = aurum_1.React.memo(Demo3);
