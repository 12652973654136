"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var js_1 = require("@samsa/samsa-api/js");
var react_1 = require("react");
var RegisterOwner = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _j = tslib_1.__read((0, react_1.useState)(''), 2), givenName = _j[0], setGivenName = _j[1];
    var _k = tslib_1.__read((0, react_1.useState)(''), 2), middleName = _k[0], setMiddleName = _k[1];
    var _l = tslib_1.__read((0, react_1.useState)(''), 2), familyName = _l[0], setFamilyName = _l[1];
    var _m = tslib_1.__read((0, react_1.useState)(''), 2), companyName = _m[0], setCompanyName = _m[1];
    var _o = tslib_1.__read((0, react_1.useState)(''), 2), email = _o[0], setEmail = _o[1];
    var _p = tslib_1.__read((0, react_1.useState)(''), 2), password = _p[0], setPassword = _p[1];
    var _q = tslib_1.__read((0, react_1.useState)(null), 2), gender = _q[0], setGender = _q[1];
    var _r = tslib_1.__read((0, react_1.useState)(null), 2), ownerType = _r[0], setOwnerType = _r[1];
    var _s = tslib_1.__read((0, react_1.useState)(''), 2), streetAddress = _s[0], setStreetAddress = _s[1];
    var _t = tslib_1.__read((0, react_1.useState)(''), 2), extendedAddress = _t[0], setExtendedAddress = _t[1];
    var _u = tslib_1.__read((0, react_1.useState)(null), 2), country = _u[0], setCountry = _u[1];
    var _v = tslib_1.__read((0, react_1.useState)([]), 2), countries = _v[0], setCountries = _v[1];
    var _w = tslib_1.__read((0, react_1.useState)(''), 2), postalCode = _w[0], setPostalCode = _w[1];
    var _x = tslib_1.__read((0, react_1.useState)(''), 2), locality = _x[0], setLocality = _x[1];
    var _y = tslib_1.__read((0, react_1.useState)(''), 2), region = _y[0], setRegion = _y[1];
    var _z = tslib_1.__read((0, react_1.useState)(''), 2), birthDate = _z[0], setBirthDate = _z[1];
    var _0 = tslib_1.__read((0, react_1.useState)(''), 2), postOfficeBox = _0[0], setPostOfficeBox = _0[1];
    var _1 = tslib_1.__read((0, react_1.useState)(true), 2), sendMails = _1[0], setSendMails = _1[1];
    var _2 = tslib_1.__read((0, react_1.useState)(null), 2), errorData = _2[0], setErrorData = _2[1];
    var _3 = tslib_1.__read((0, react_1.useState)(false), 2), savingInProgress = _3[0], setSavingInProgress = _3[1];
    var _4 = tslib_1.__read((0, react_1.useState)(null), 2), customerProfile = _4[0], setCustomerProfile = _4[1];
    var _5 = tslib_1.__read((0, react_1.useState)(null), 2), owner = _5[0], setOwner = _5[1];
    var _6 = tslib_1.__read((0, react_1.useState)(false), 2), accountAlreadyExisting = _6[0], setAccountAlreadyExisting = _6[1];
    var catchError = function (error) {
        var _a;
        var errorObject = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data;
        if ((errorObject === null || errorObject === void 0 ? void 0 : errorObject.fieldErrors) || (errorObject === null || errorObject === void 0 ? void 0 : errorObject.reason)) {
            setErrorData(tslib_1.__assign(tslib_1.__assign({}, errorObject), { showModal: true }));
        }
        else {
            setErrorData({ reason: error.message, showModal: true });
        }
    };
    // TODO: Bereits vorhandene Customer auswählbar machen(geht aktuell nicht, weil serverseitige Logik fehlt)
    // const [contactPersonList, setcontactPersonList] = useState<PageOfCustomerContactPerson>(null);
    // const [customerList, setCustomerList] = useState<PageOfCustomer>(null);
    // const [selectedCustomerId, setSelectedCustomerId] = useState('');
    // const [selectedContactPersonId, setSelectedContactPersonId] = useState('');
    //
    // const loadAllCustomers = useCallback(() => {
    //   const promise = getAllCustomer({size: 0});
    //   promise.then((result) => {
    //     setCustomerList(result);
    //   }).catch((e) => {
    //     if (!isCancel(e)) {
    //       console.error('ERROR listReload', e.message, e);
    //     }
    //   });
    // }, []);
    // useEffect(loadAllCustomers, []);
    //
    // const loadContactPersonCallback = useCallback(() => {
    //   console.log(selectedCustomerId);
    //   const promise = getAllCustomerContactPerson({'customer.id': selectedCustomerId});
    //   promise.then((result) => {
    //     setcontactPersonList(result);
    //   }).catch((e) => {
    //     if (!isCancel(e)) {
    //       setcontactPersonList(null);
    //     }
    //   });
    // }, [selectedCustomerId]);
    // useEffect(loadContactPersonCallback, [selectedCustomerId]);
    var freeAllFields = function () {
        setPassword('');
        setEmail('');
        setCompanyName('');
        setEmail('');
        setFamilyName('');
        setGivenName('');
        setOwnerType(null);
        setLocality('');
        setExtendedAddress('');
        setStreetAddress('');
        setPostalCode('');
        setRegion('');
        setPostOfficeBox('');
        setBirthDate('');
        setCountry(null);
        setGender(null);
        setSendMails(false);
    };
    var registerCustomerProfile = function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, js_1.CustomerProfile.register({
                        urlTemplate: null,
                        customerRegistration: new js_1.CustomerRegistration({
                            givenName: givenName,
                            middleName: middleName,
                            familyName: familyName,
                            email: email,
                            companyName: companyName,
                            sendMails: sendMails,
                            gender: gender,
                            password: password,
                            noConfirmation: true
                        })
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var registerOwnerProfile = function (force) { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
        var ownerRegistration;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ownerRegistration = new js_1.OwnerRegistration({
                        givenName: givenName,
                        middleName: middleName,
                        familyName: familyName,
                        email: email,
                        username: email,
                        companyName: companyName,
                        sendMails: sendMails,
                        ownerType: ownerType,
                        birthDate: birthDate,
                        gender: gender,
                        address: new js_1.Address({
                            locality: locality,
                            postalCode: postalCode,
                            country: countries.filter(function (value) { return value.localizations === country; })[0],
                            extendedAddress: extendedAddress,
                            postOfficeBox: postOfficeBox,
                            region: region,
                            streetAddress: streetAddress
                        }),
                        linkWithExistingAccount: true,
                        password: force ? '' : password
                    });
                    return [4 /*yield*/, js_1.OwnerProfile.register(ownerRegistration)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var setAccountRole = function (result) { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
        var _a, _b;
        var _c;
        return tslib_1.__generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _b = (_a = js_1.AccountRole).set;
                    _c = {
                        accountId: result.contactPerson.accountId
                    };
                    return [4 /*yield*/, js_1.AccountRole.getAll({ title: 'Partner-Admin' })];
                case 1: return [4 /*yield*/, _b.apply(_a, [(_c.accountRole = (_d.sent()).content.shift(),
                            _c)])];
                case 2:
                    _d.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    aurum_1.React.useEffect(function () {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var countries_1, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, js_1.Country.getAll()];
                    case 1:
                        countries_1 = _a.sent();
                        setCountries(countries_1.content ? countries_1.content : []);
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    var stopSavingInProgressAndCloseModal = function () {
        setSavingInProgress(false);
        setCustomerProfile(null);
    };
    var errorModal = null;
    if (errorData === null || errorData === void 0 ? void 0 : errorData.showModal) {
        errorModal = aurum_1.React.createElement(aurum_1.Modal, { className: 'simpleModal' },
            aurum_1.React.createElement(aurum_1.Header, null,
                aurum_1.React.createElement("h2", null, "".concat(t('Edit.errorModal.title')))),
            aurum_1.React.createElement(aurum_1.Body, null,
                aurum_1.React.createElement(aurum_1.Row, null, "".concat(t('Edit.errorModal.reason'), ": '").concat(t('Edit.errorModal.Enum.' + errorData.reason), "'")),
                ((_a = errorData.fieldErrors) === null || _a === void 0 ? void 0 : _a.length) ? aurum_1.React.createElement(aurum_1.Row, null, "".concat(t('Edit.errorModal.fieldErrors'), ": ")) : null, (_b = errorData.fieldErrors) === null || _b === void 0 ? void 0 :
                _b.map(function (f) {
                    var _a;
                    return aurum_1.React.createElement(aurum_1.Row, { key: f === null || f === void 0 ? void 0 : f.field }, '• ' + t("EntityAttribute.".concat(f === null || f === void 0 ? void 0 : f.field)) + ": ".concat((_a = f === null || f === void 0 ? void 0 : f.messageList) === null || _a === void 0 ? void 0 : _a.join(', ')));
                })),
            aurum_1.React.createElement(aurum_1.Footer, null,
                aurum_1.React.createElement(aurum_1.Button, { type: 'error', onClick: function () {
                        setErrorData(tslib_1.__assign(tslib_1.__assign({}, errorData), { showModal: false }));
                    } }, t(['Edit.errorModal.close', 'Controls.close']))));
    }
    (0, react_1.useEffect)(function () {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var customerProfile_1, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, (0, js_1.getCustomerProfileByEmail)(email)];
                    case 1:
                        customerProfile_1 = _a.sent();
                        setAccountAlreadyExisting(customerProfile_1 != null && customerProfile_1.contactPerson != null);
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.error(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); })();
    }, [email]);
    var customerExistModal = null;
    if (customerProfile != null) {
        customerExistModal = aurum_1.React.createElement(aurum_1.Modal, { className: 'customerExistModal' },
            aurum_1.React.createElement(aurum_1.Header, null,
                aurum_1.React.createElement("h2", null, "".concat(t('Error.EmailAlreadyExist')))),
            aurum_1.React.createElement(aurum_1.Body, null,
                aurum_1.React.createElement("div", { className: 'div-explanation' },
                    aurum_1.React.createElement("p", null, t('PartnerFormular.linkExplanation'))),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("p", null),
                    aurum_1.React.createElement("p", null, t('PartnerFormular.currentCustomerProfil')),
                    aurum_1.React.createElement("p", null, t('PartnerFormular.newOwnerProfil'))),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("p", null, t('EntityAttribute.email')),
                    aurum_1.React.createElement("p", null, (_c = customerProfile === null || customerProfile === void 0 ? void 0 : customerProfile.customer) === null || _c === void 0 ? void 0 : _c.email),
                    aurum_1.React.createElement("p", null, (_d = customerProfile === null || customerProfile === void 0 ? void 0 : customerProfile.customer) === null || _d === void 0 ? void 0 : _d.email)),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("p", null, t('EntityAttribute.companyName')),
                    aurum_1.React.createElement("p", null),
                    aurum_1.React.createElement("p", null, companyName)),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("p", null, t('EntityAttribute.givenName')),
                    aurum_1.React.createElement("p", null, (_e = customerProfile === null || customerProfile === void 0 ? void 0 : customerProfile.contactPerson) === null || _e === void 0 ? void 0 : _e.givenName),
                    aurum_1.React.createElement("p", null, givenName)),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("p", null, t('EntityAttribute.familyName')),
                    aurum_1.React.createElement("p", null, (_f = customerProfile === null || customerProfile === void 0 ? void 0 : customerProfile.contactPerson) === null || _f === void 0 ? void 0 : _f.familyName),
                    aurum_1.React.createElement("p", null, familyName)),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("p", null, t('EntityAttribute.shortId')),
                    aurum_1.React.createElement("p", null, (_g = customerProfile === null || customerProfile === void 0 ? void 0 : customerProfile.customer) === null || _g === void 0 ? void 0 : _g.shortId),
                    aurum_1.React.createElement("p", null)),
                aurum_1.React.createElement("p", null,
                    aurum_1.React.createElement("i", null, t('OwnerRegistration.modal.hint')))),
            aurum_1.React.createElement(aurum_1.Footer, null,
                aurum_1.React.createElement(aurum_1.Button, { className: 'btn-left', type: 'primary', onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                        var e_3;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 3, , 4]);
                                    return [4 /*yield*/, registerOwnerProfile(true)];
                                case 1:
                                    _a.sent();
                                    return [4 /*yield*/, setAccountRole(customerProfile)];
                                case 2:
                                    _a.sent();
                                    stopSavingInProgressAndCloseModal();
                                    freeAllFields();
                                    return [3 /*break*/, 4];
                                case 3:
                                    e_3 = _a.sent();
                                    catchError(e_3);
                                    return [3 /*break*/, 4];
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); } }, t('EntityAttribute.link')),
                aurum_1.React.createElement(aurum_1.Button, { className: 'btn-right', type: 'error', onClick: function () {
                        stopSavingInProgressAndCloseModal();
                    } }, t(['Edit.errorModal.close', 'Controls.close']))));
    }
    var ownerExistModal = null;
    if (owner != null) {
        ownerExistModal = aurum_1.React.createElement(aurum_1.Modal, { className: 'ownerExistModal' },
            aurum_1.React.createElement(aurum_1.Header, null,
                aurum_1.React.createElement("h2", null, "".concat(t('Error.EmailAlreadyExist')))),
            aurum_1.React.createElement(aurum_1.Body, null,
                aurum_1.React.createElement("div", { className: 'div-explanation' },
                    aurum_1.React.createElement("p", null, t('OwnerRegistration.modal.explanation'))),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("p", null),
                    aurum_1.React.createElement("p", null, t('OwnerRegistration.modal.currentOwnerProfil')),
                    aurum_1.React.createElement("p", null, t('PartnerFormular.newOwnerProfil'))),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("p", null, t('EntityAttribute.email')),
                    aurum_1.React.createElement("p", null, owner === null || owner === void 0 ? void 0 : owner.email),
                    aurum_1.React.createElement("p", null, email)),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("p", null, t('EntityAttribute.companyName')),
                    aurum_1.React.createElement("p", null, owner === null || owner === void 0 ? void 0 : owner.companyName),
                    aurum_1.React.createElement("p", null, companyName)),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("p", null, t('EntityAttribute.givenName')),
                    aurum_1.React.createElement("p", null),
                    aurum_1.React.createElement("p", null, givenName)),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("p", null, t('EntityAttribute.familyName')),
                    aurum_1.React.createElement("p", null),
                    aurum_1.React.createElement("p", null, familyName))),
            aurum_1.React.createElement(aurum_1.Footer, null,
                aurum_1.React.createElement(aurum_1.Button, { className: 'btn-right', type: 'error', onClick: function () {
                        setOwner(null);
                        stopSavingInProgressAndCloseModal();
                    } }, t(['Edit.errorModal.close', 'Controls.close']))));
    }
    return aurum_1.React.createElement(aurum_1.Sheet, { className: 'PartnerFormular' },
        errorModal,
        customerExistModal,
        ownerExistModal,
        aurum_1.React.createElement("h2", null, t('PartnerFormular.title')),
        aurum_1.React.createElement(aurum_1.Row, null,
            aurum_1.React.createElement(aurum_1.Input, { label: t('EntityAttribute.givenName'), value: givenName, onChange: function (e) {
                    setGivenName(e.target.value);
                }, autoComplete: false, name: 'pls-no-autocomplete0' }),
            aurum_1.React.createElement(aurum_1.Input, { label: t('EntityAttribute.middleName'), optional: true, value: middleName, onChange: function (e) {
                    setMiddleName(e.target.value);
                }, autoComplete: false, name: 'pls-no-autocomplete1' }),
            aurum_1.React.createElement(aurum_1.Input, { label: t('EntityAttribute.familyName'), value: familyName, onChange: function (e) {
                    setFamilyName(e.target.value);
                }, autoComplete: false, name: 'pls-no-autocomplete2' })),
        aurum_1.React.createElement(aurum_1.Row, null,
            aurum_1.React.createElement(aurum_1.Input, { label: t('EntityAttribute.companyName'), optional: true, value: companyName, onChange: function (e) {
                    setCompanyName(e.target.value);
                }, autoComplete: false, name: 'pls-no-autocomplete3' }),
            aurum_1.React.createElement(aurum_1.Input, { label: t('EntityAttribute.email'), value: email, onChange: function (e) {
                    setEmail(e.target.value);
                }, autoComplete: false, name: 'pls-no-autocomplete4' }),
            aurum_1.React.createElement(aurum_1.Input, { type: 'password', label: t('EntityAttribute.password'), disabled: accountAlreadyExisting, value: password, onChange: function (e) {
                    setPassword(e.target.value);
                }, autoComplete: 'off', name: 'pls-no-autocomplete5' })),
        aurum_1.React.createElement(aurum_1.Row, null,
            aurum_1.React.createElement(aurum_1.Select, { label: t('EntityAttribute.ownerType'), value: ownerType || '', onChange: function (e) {
                    setOwnerType(e.target.value);
                }, options: Object.values(js_1.Owner.enum.ownerType).map(function (ownerType) { return ({
                    value: ownerType,
                    label: t("Enum.".concat(ownerType))
                }); }) }),
            aurum_1.React.createElement(aurum_1.Input, { optional: ownerType === js_1.Owner.enum.ownerType.COMPANY, type: 'date', label: t('EntityAttribute.birthDate'), value: birthDate, native: false, onChange: function (e) {
                    setBirthDate(e.target.value);
                } }),
            aurum_1.React.createElement(aurum_1.Select, { label: t('EntityAttribute.country'), optional: true, value: country, onChange: function (e) {
                    setCountry(e.target.value);
                }, options: (_h = countries === null || countries === void 0 ? void 0 : countries.map) === null || _h === void 0 ? void 0 : _h.call(countries, function (country) {
                    return { id: country, label: country.localizations.de.title };
                }), autoComplete: false, name: 'pls-no-autocomplete10' })),
        aurum_1.React.createElement(aurum_1.Row, null,
            aurum_1.React.createElement(aurum_1.Input, { label: t('EntityAttribute.streetAddress'), optional: true, value: streetAddress, onChange: function (e) {
                    setStreetAddress(e.target.value);
                }, autoComplete: false, name: 'pls-no-autocomplete5' }),
            aurum_1.React.createElement(aurum_1.Input, { label: t('EntityAttribute.postalCode'), optional: true, value: postalCode, onChange: function (e) {
                    setPostalCode(e.target.value);
                }, autoComplete: false, name: 'pls-no-autocomplete6' }),
            aurum_1.React.createElement(aurum_1.Input, { label: t('EntityAttribute.locality'), optional: true, value: locality, onChange: function (e) {
                    setLocality(e.target.value);
                }, autoComplete: false, name: 'pls-no-autocomplete7' })),
        aurum_1.React.createElement(aurum_1.Row, null,
            aurum_1.React.createElement(aurum_1.Input, { label: t('EntityAttribute.extendedAddress'), optional: true, value: extendedAddress, onChange: function (e) {
                    setExtendedAddress(e.target.value);
                }, autoComplete: false, name: 'pls-no-autocomplete8' }),
            aurum_1.React.createElement(aurum_1.Input, { label: t('EntityAttribute.region'), optional: true, value: region, onChange: function (e) {
                    setRegion(e.target.value);
                }, autoComplete: false, name: 'pls-no-autocomplete9' }),
            aurum_1.React.createElement(aurum_1.Input, { label: t('EntityAttribute.postOfficeBox'), optional: true, value: postOfficeBox, onChange: function (e) {
                    setPostOfficeBox(e.target.value);
                }, autoComplete: false, name: 'pls-no-autocomplete10' })),
        aurum_1.React.createElement(aurum_1.Row, { columns: 2 },
            aurum_1.React.createElement(aurum_1.Select, { label: t('EntityAttribute.gender'), value: gender || '', onChange: function (e) {
                    setGender(e.target.value);
                }, options: Object.values(js_1.OwnerRegistration.enum.gender).map(function (gender) { return ({
                    value: gender,
                    label: t("Enum.".concat(gender))
                }); }) }),
            aurum_1.React.createElement(aurum_1.Checkbox, { label: t('EntityAttribute.sendMails'), onClick: function (e) {
                    setSendMails(e.target.checked);
                }, name: 'sendMails' })),
        savingInProgress
            ? aurum_1.React.createElement(aurum_1.Progress, null)
            : aurum_1.React.createElement(aurum_1.Button, { type: 'primary', onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                    var owner_1, cp, result, e_4;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                setSavingInProgress(true);
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 7, , 8]);
                                if (!givenName || !givenName.trim() || !familyName || !familyName.trim() ||
                                    !familyName || !familyName.trim() || !email || !email.trim() || !gender || !gender.trim() ||
                                    !ownerType || (ownerType === js_1.OwnerRegistration.enum.ownerType.PERSON && (!birthDate || !birthDate.trim()))) {
                                    throw new Error('INFORMATION_INCOMPLETE');
                                }
                                return [4 /*yield*/, js_1.Owner.getByEmail(email)];
                            case 2:
                                owner_1 = _a.sent();
                                if (owner_1 === null || owner_1 === void 0 ? void 0 : owner_1.id) {
                                    setOwner(owner_1);
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, js_1.CustomerProfile.getByEmail(email)];
                            case 3:
                                cp = _a.sent();
                                if (cp.contactPerson && cp.customer) {
                                    setCustomerProfile(cp);
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, registerCustomerProfile()];
                            case 4:
                                result = _a.sent();
                                return [4 /*yield*/, registerOwnerProfile(false)];
                            case 5:
                                _a.sent();
                                return [4 /*yield*/, setAccountRole(result)];
                            case 6:
                                _a.sent();
                                freeAllFields();
                                return [3 /*break*/, 8];
                            case 7:
                                e_4 = _a.sent();
                                catchError(e_4);
                                return [3 /*break*/, 8];
                            case 8:
                                // }
                                setSavingInProgress(false);
                                return [2 /*return*/];
                        }
                    });
                }); } }, t(['partnerFormular.save'])));
};
exports.default = aurum_1.React.memo(RegisterOwner);
