"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMasterData = void 0;
var isMasterData = function (Type) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return !!((Type === null || Type === void 0 ? void 0 : Type.name) &&
        ((_b = (_a = Type === null || Type === void 0 ? void 0 : Type.get) === null || _a === void 0 ? void 0 : _a.isAllowed) === null || _b === void 0 ? void 0 : _b.call(_a)) &&
        ((_d = (_c = Type === null || Type === void 0 ? void 0 : Type.getAll) === null || _c === void 0 ? void 0 : _c.isAllowed) === null || _d === void 0 ? void 0 : _d.call(_c)) &&
        (((_f = (_e = Type === null || Type === void 0 ? void 0 : Type.save) === null || _e === void 0 ? void 0 : _e.isAllowed) === null || _f === void 0 ? void 0 : _f.call(_e)) || ((_h = (_g = Type === null || Type === void 0 ? void 0 : Type.delete) === null || _g === void 0 ? void 0 : _g.isAllowed) === null || _h === void 0 ? void 0 : _h.call(_g))));
};
exports.isMasterData = isMasterData;
