"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var js_1 = require("@samsa/samsa-api/js");
var days = [
    { id: 1, name: 'MONDAY' },
    { id: 2, name: 'TUESDAY' },
    { id: 3, name: 'WEDNESDAY' },
    { id: 4, name: 'THURSDAY' },
    { id: 5, name: 'FRIDAY' },
    { id: 6, name: 'SATURDAY' },
    { id: 7, name: 'SUNDAY' }
];
function getPeriodOption(t) {
    return Object.keys(js_1.OwnerInvoiceAutomated.enum.period)
        .map(function (value) {
        return aurum_1.React.createElement("option", { key: value, value: value, label: t('Enum.' + value) });
    });
}
function getFetchDateTypeOption(t) {
    return Object.keys(js_1.OwnerInvoiceAutomated.enum.fetchDateType)
        .map(function (value) {
        return aurum_1.React.createElement("option", { key: value, value: value, label: t('Enum.' + value) });
    });
}
function getDayOfWeek(t) {
    return days.map(function (value) { return aurum_1.React.createElement("option", { key: value.id, value: value.id, label: t('Enum.' + value.name) }); });
}
var OwnerInvoiceAutomatedForm = function (_a) {
    var config = _a.config, updateConfigInvoiceAutomated = _a.updateConfigInvoiceAutomated, updateSelectedSetting = _a.updateSelectedSetting;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _b = tslib_1.__read((0, react_1.useState)(false), 2), invoiceAutomatedActivate = _b[0], _setInvoiceAutomatedActivate = _b[1];
    var _c = tslib_1.__read((0, react_1.useState)(undefined), 2), period = _c[0], _setPeriod = _c[1];
    var _d = tslib_1.__read((0, react_1.useState)(undefined), 2), fetchDateType = _d[0], _setFetchDateType = _d[1];
    var _e = tslib_1.__read((0, react_1.useState)(false), 2), sendingMail = _e[0], _setSendingMail = _e[1];
    var _f = tslib_1.__read((0, react_1.useState)(undefined), 2), dayOfWeek = _f[0], _setDayOfWeek = _f[1];
    // Pfusch-Lösung, aber sonst werden die aktuellen Werte nicht geladen
    aurum_1.React.useEffect(function () {
        var _a;
        var invoiceAutomated = config.invoiceAutomated;
        _setInvoiceAutomatedActivate(!!(invoiceAutomated === null || invoiceAutomated === void 0 ? void 0 : invoiceAutomated.id));
        _setPeriod(invoiceAutomated === null || invoiceAutomated === void 0 ? void 0 : invoiceAutomated.period);
        _setFetchDateType(invoiceAutomated === null || invoiceAutomated === void 0 ? void 0 : invoiceAutomated.fetchDateType);
        _setSendingMail((_a = invoiceAutomated === null || invoiceAutomated === void 0 ? void 0 : invoiceAutomated.sendMail) !== null && _a !== void 0 ? _a : false);
        _setDayOfWeek(invoiceAutomated === null || invoiceAutomated === void 0 ? void 0 : invoiceAutomated.dayOfWeek);
    }, [config]);
    var setInvoiceAutomatedActivate = function (e) { return _setInvoiceAutomatedActivate(e.target.checked); };
    var setPeriod = function (e) { return _setPeriod(e.target.value); };
    var setFetchDateType = function (e) { return _setFetchDateType(e.target.value); };
    var setSendingMail = function (e) { return _setSendingMail(e.target.checked); };
    var setDayOfWeek = function (e) { return _setDayOfWeek(e.target.value); };
    (0, react_1.useEffect)(function () {
        updateSelectedSetting(invoiceAutomatedActivate);
        var data = null;
        if (invoiceAutomatedActivate) {
            data = {
                fetchDateType: fetchDateType,
                period: period,
                sendMail: sendingMail,
                dayOfWeek: period === js_1.OwnerInvoiceAutomated.enum.period.WEEKLY ? dayOfWeek : null
            };
        }
        updateConfigInvoiceAutomated(data);
    }, [invoiceAutomatedActivate, updateConfigInvoiceAutomated,
        fetchDateType, period, sendingMail, dayOfWeek]);
    return aurum_1.React.createElement("div", { className: 'OwnerConfigForm' },
        aurum_1.React.createElement("h2", null, t('Entity.OwnerInvoiceAutomated')),
        aurum_1.React.createElement(aurum_1.Input, { type: 'checkbox', label: t('Entity.OwnerInvoiceAutomated') + ' aktivieren', value: invoiceAutomatedActivate, onClick: setInvoiceAutomatedActivate }),
        invoiceAutomatedActivate
            ? aurum_1.React.createElement(aurum_1.Row, null,
                aurum_1.React.createElement(aurum_1.Input, { type: 'select', label: 'period', value: period, native: false, options: getPeriodOption(t), onChange: setPeriod }),
                aurum_1.React.createElement(aurum_1.Input, { type: 'select', label: t('EntityAttribute.fetchDateType'), value: fetchDateType, native: false, options: getFetchDateTypeOption(t), onChange: setFetchDateType }),
                aurum_1.React.createElement(aurum_1.Input, { type: 'checkbox', label: t('EntityAttribute.OwnerInvoiceAutomated.sendMail'), value: sendingMail, onChange: setSendingMail }),
                period && period === js_1.OwnerInvoiceAutomated.enum.period.WEEKLY
                    ? aurum_1.React.createElement(aurum_1.Input, { type: 'select', label: t('EntityAttribute.dayOfWeek'), value: dayOfWeek === null || dayOfWeek === void 0 ? void 0 : dayOfWeek.toString(), native: false, options: getDayOfWeek(t), onChange: setDayOfWeek })
                    : null)
            : null);
};
exports.default = OwnerInvoiceAutomatedForm;
