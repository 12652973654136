"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PagedSearchTable = void 0;
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var react_1 = tslib_1.__importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var useOnChange_1 = require("../../hook/useOnChange");
var useSort_1 = require("../../hook/useSort");
function PagedSearchTable(props) {
    var _a;
    var t = (0, react_i18next_1.useTranslation)().t;
    var sizes = props.sizes || [25, 100];
    var defaultSortable = props.defaultSortable !== false;
    function defaultIdResolver(row) {
        // @ts-ignore
        return row.id;
    }
    var idResolver = props.idResolver || defaultIdResolver;
    function defaultOnSelection(row) {
        console.log('defaultOnSelection', idResolver(row));
    }
    function defaultValueResolver(entry, column) {
        var val = entry;
        column.name.split('.').forEach(function (name) {
            val = val === null || val === void 0 ? void 0 : val[name];
        });
        val = val !== null && val !== void 0 ? val : '';
        return val.toString();
    }
    var columns = props.columns;
    columns.forEach(function (column) {
        column.valueResolver = column.valueResolver ? column.valueResolver : function (value) { return defaultValueResolver(value, column); };
        column.sortable = column.sortable === false || column.sortable === true ? column.sortable : defaultSortable;
        column.title = column.title ? column.title : t('EntityAttribute.' + column.name);
    });
    var onSelection = props.onSelection || defaultOnSelection;
    var _b = tslib_1.__read((0, react_1.useState)(null), 2), data = _b[0], setData = _b[1];
    var selected = (0, useOnChange_1.useOnChange)(null);
    function handleSelection(selectedId) {
        var selectedType = data.content.filter(function (row) { return idResolver(row) === selectedId; }).pop();
        if (selectedType) {
            selected.set(selectedType);
            onSelection === null || onSelection === void 0 ? void 0 : onSelection(selectedType);
        }
        else {
            selected.set(selectedType);
            onSelection === null || onSelection === void 0 ? void 0 : onSelection(null);
        }
    }
    var sortablefields = columns.filter(function (f) { return f.sortable !== false; });
    var _c = tslib_1.__read((0, useSort_1.useSort)(sortablefields.length ? sortablefields[0].name + ',asc' : null), 2), sort = _c[0], toggleSort = _c[1];
    var size = (0, useOnChange_1.useOnChange)(sizes[0]);
    var page = (0, useOnChange_1.useOnChange)(0);
    var search = (0, useOnChange_1.useOnChange)('');
    var _d = tslib_1.__read((0, react_1.useState)([]), 2), pageSelection = _d[0], setPageSelection = _d[1];
    var _e = tslib_1.__read((0, react_1.useState)(null), 2), cachedTable = _e[0], setCachedTable = _e[1];
    function handleLoadData() {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, props.onSearch(search.value, page.value, size.value, [sort])];
                    case 1:
                        result = _a.sent();
                        if (result) {
                            setPageSelection(Array.apply(null, Array(result.totalPages))
                                // eslint-disable-next-line react/no-array-index-key
                                .map(function (_v, page) { return react_1.default.createElement("option", { key: page, value: page, label: (page + 1) + '/' + result.totalPages }); }));
                        }
                        else {
                            setPageSelection([]);
                        }
                        setData(result);
                        return [2 /*return*/];
                }
            });
        });
    }
    (0, react_1.useEffect)(function () {
        handleLoadData();
    }, []);
    (0, react_1.useEffect)(function () {
        handleLoadData();
    }, tslib_1.__spreadArray([size.value, page.value, search.value, sort], tslib_1.__read(((_a = props.dependencies) !== null && _a !== void 0 ? _a : [])), false));
    (0, react_1.useEffect)(function () {
        setCachedTable(react_1.default.createElement(aurum_1.Table, { className: 'table' },
            react_1.default.createElement("thead", null,
                react_1.default.createElement("tr", null, columns.map(function (column) {
                    var classes = column.classes || [];
                    var sortable = column.sortable || false;
                    return react_1.default.createElement("th", { key: column.name, onClick: sortable ? toggleSort(column.name) : null, className: classes.join(' ') },
                        column.title,
                        sortable && !(sort === "".concat(column.name, ",asc") || sort === "".concat(column.name, ",desc"))
                            ? react_1.default.createElement(aurum_1.Icon, { type: 'unfold_more' })
                            : null,
                        sort === "".concat(column.name, ",asc") ? react_1.default.createElement(aurum_1.Icon, { type: 'expand_more' }) : null,
                        sort === "".concat(column.name, ",desc") ? react_1.default.createElement(aurum_1.Icon, { type: 'expand_less' }) : null);
                }))),
            react_1.default.createElement("tbody", null, !data
                ? react_1.default.createElement("tr", null,
                    react_1.default.createElement("td", { colSpan: columns.length },
                        react_1.default.createElement(aurum_1.Progress, null)))
                : data.content.filter(function (entry) { return entry != null; }).map(function (entry) {
                    var id = idResolver(entry);
                    var primary = selected.value != null ? id === idResolver(selected.value) : false;
                    // @ts-ignore
                    entry.setter = entry.setter || (function () { return handleSelection(id); });
                    var classes = [];
                    if (primary) {
                        classes.push('primary');
                    }
                    if (onSelection) {
                        classes.push('clickable');
                    }
                    // @ts-ignore
                    return react_1.default.createElement("tr", { key: id, onClick: entry.setter, className: classes.join(' ') }, columns.map(function (column) {
                        var classes = column.classes || [];
                        return react_1.default.createElement("td", { key: column.name, className: classes.join(' ') }, column.valueResolver(entry));
                    }));
                }))));
    }, [data, selected.value]);
    function listReload() {
        handleLoadData();
    }
    function firstPage() {
        page.set(0);
    }
    function previousPage() {
        page.set(data ? Math.max(page.value - 1, 0) : 0);
    }
    function nextPage() {
        // - -1 wegen string concat
        page.set(data ? page.value - -1 : 0);
    }
    function lastPage() {
        page.set(data ? (data === null || data === void 0 ? void 0 : data.totalPages) - 1 : 0);
    }
    function getClassName() {
        var classes = ['PagedSearchTable', 'list'];
        if (!props.onSelection) {
            classes.push('-only');
        }
        if (props.withPadding) {
            classes.push('-padding');
        }
        if (props.className) {
            classes.push(props.className);
        }
        return classes.join(' ');
    }
    return react_1.default.createElement("div", { className: getClassName() },
        react_1.default.createElement(aurum_1.Sheet, { className: 'list' },
            react_1.default.createElement("div", { className: 'title' },
                react_1.default.createElement("h2", null, props.title),
                !data
                    ? null
                    : react_1.default.createElement("div", { className: 'sub' }, data.totalElements === 0
                        ? t('Edit.navigation.paginationNone')
                        : t('Edit.navigation.pagination', {
                            from: data.number * data.size + 1,
                            to: Math.min(data.number * data.size + data.size, data.totalElements),
                            total: data.totalElements
                        }))),
            react_1.default.createElement("div", { className: 'children' }, props.children
                ? props.children
                : react_1.default.createElement(aurum_1.Input, { autoFocus: true, type: 'text', label: t('Edit.navigation.search-input'), value: search.inputvalue, onChange: search.onChange })),
            react_1.default.createElement(aurum_1.InputGroup, { className: 'navigate' },
                react_1.default.createElement(aurum_1.Button, { disabled: data === null || data === void 0 ? void 0 : data.first, onClick: firstPage },
                    react_1.default.createElement(aurum_1.Icon, { type: 'first_page' })),
                react_1.default.createElement(aurum_1.Button, { disabled: data === null || data === void 0 ? void 0 : data.first, onClick: previousPage },
                    react_1.default.createElement(aurum_1.Icon, { type: 'navigate_before' })),
                react_1.default.createElement(aurum_1.Select, { disabled: !(pageSelection === null || pageSelection === void 0 ? void 0 : pageSelection.length), label: t('Edit.navigation.page'), value: (pageSelection === null || pageSelection === void 0 ? void 0 : pageSelection.length) ? data === null || data === void 0 ? void 0 : data.number : '', onChange: page.onChange, options: pageSelection }),
                react_1.default.createElement(aurum_1.Button, { disabled: data === null || data === void 0 ? void 0 : data.last, onClick: nextPage },
                    react_1.default.createElement(aurum_1.Icon, { type: 'navigate_next' })),
                react_1.default.createElement(aurum_1.Button, { disabled: data === null || data === void 0 ? void 0 : data.last, onClick: lastPage },
                    react_1.default.createElement(aurum_1.Icon, { type: 'last_page' }))),
            react_1.default.createElement(aurum_1.ButtonGroup, { className: 'pagesize' }, sizes.map(function (value, key) {
                return react_1.default.createElement(aurum_1.Button, { key: key, onClick: function () {
                        size.set(value);
                        page.set(0);
                    }, type: size.value === value ? 'primary' : null }, value);
            })),
            react_1.default.createElement(aurum_1.Button, { className: 'reload', onClick: listReload },
                react_1.default.createElement(aurum_1.Icon, { type: 'refresh' })),
            cachedTable));
}
exports.PagedSearchTable = PagedSearchTable;
