"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var aurum_1 = require("@mdsi/aurum");
var js_1 = require("@samsa/samsa-api/js");
var react_i18next_1 = require("react-i18next");
var dateUtils_1 = require("../../../util/dateUtils");
var useOnChange_1 = require("../../../hook/useOnChange");
var RebookingTransportationChild = function (props) {
    var t = (0, react_i18next_1.useTranslation)().t;
    var oldTrip = props.oldTrip;
    var positions = props.positions;
    var updateRebookingEntries = props.updateRebookingEntries;
    // Parameter für Rebooking request
    var _a = tslib_1.__read((0, react_1.useState)(false), 2), isOldTripSelected = _a[0], setOldTripIdSelected = _a[1];
    var _b = tslib_1.__read((0, react_1.useState)(), 2), newTripId = _b[0], setNewTripId = _b[1];
    var rebookDate = (0, useOnChange_1.useOnChange)(null);
    // Map mit jeder TripId und den entsprechenden Buchungspositionen
    var _c = tslib_1.__read((0, react_1.useState)(), 2), selectTripContent = _c[0], setSelectTripContent = _c[1];
    // mögliche Trips, auf die Umgebucht werden kann
    var _d = tslib_1.__read((0, react_1.useState)([]), 2), possibleTrips = _d[0], setPossibleTrips = _d[1];
    var _e = tslib_1.__read((0, react_1.useState)(), 2), tripContent = _e[0], setTripContent = _e[1];
    var _f = tslib_1.__read((0, react_1.useState)(false), 2), isLoadingTrips = _f[0], setLoadingTrips = _f[1];
    var getPossibleTrips = function (rebookDate, tripId) { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
        var bookingPositionIds;
        return tslib_1.__generator(this, function (_a) {
            bookingPositionIds = positions
                .map(function (it) { var _a; return (_a = it.id) !== null && _a !== void 0 ? _a : ''; })
                .filter(function (it) { return it !== ''; });
            return [2 /*return*/, js_1.Transportation.getRebookableTrips({
                    bookingPositionIds: bookingPositionIds,
                    date: rebookDate,
                    tripId: tripId
                })];
        });
    }); };
    var getIcon = function (selected) { return selected
        ? react_1.default.createElement(aurum_1.Icon, { className: 'checkIcon', type: 'check', size: 'big' })
        : react_1.default.createElement(aurum_1.Icon, { className: 'closeIcon', type: 'close', size: 'big' }); };
    var getTextWithIcon = function (selected, message) { return (react_1.default.createElement("div", { className: 'rebookMessage' },
        react_1.default.createElement("p", null,
            getIcon(selected),
            " ",
            message))); };
    var setRebookDate = function (daysIncrement) {
        setTripContent(undefined);
        var selectedDate = new Date(rebookDate.value);
        selectedDate.setDate(selectedDate.getDate() + daysIncrement);
        rebookDate.set((0, dateUtils_1.toDateString)(selectedDate));
    };
    var increaseDate = function () {
        setRebookDate(1);
    };
    var decreaseDate = function () {
        setRebookDate(-1);
    };
    // Nach erfolgreichem Umbuchen soll keine Checkbox mehr angewählt werden können
    var changeNewTripIdState = function (id) {
        setNewTripId(id);
        updateRebookingEntries(oldTrip.id, id, positions.map(function (it) { return it.id; }));
    };
    var changeOldTripIdState = function (selected, disable) {
        if (disable) {
            return;
        }
        // Eintrag ist angehakt: Eintrag wird abgehakt, somit werden alle Daten wieder
        // auf default gesetzt und der Eintrag aus der umzubuchenden Position wird entfernt
        if (selected) {
            setTripContent(undefined);
            setNewTripId(undefined);
            rebookDate.set(null);
            updateRebookingEntries(oldTrip.id, null, null, false);
        }
        setOldTripIdSelected(!selected);
    };
    // Erstellt den Content mit Checkbox
    (0, react_1.useEffect)(function () {
        var _a;
        var toDateString = function (date) { return date.toISOString().substr(1, 10); };
        var allDay = (_a = oldTrip.transportationTour) === null || _a === void 0 ? void 0 : _a.allDay;
        var title = null;
        var disable = false;
        if ((!allDay && new Date(oldTrip.departure) < new Date(Date.now())) ||
            (!allDay && toDateString(new Date(oldTrip.departure)) < toDateString(new Date()))) {
            disable = true;
            title = t('Rebooking.trip-in-past');
        }
        else if (positions === null || positions === void 0 ? void 0 : positions.filter(function (position) { var _a, _b; return (_b = (_a = position.request) === null || _a === void 0 ? void 0 : _a.features) === null || _b === void 0 ? void 0 : _b.filter(function (request) { return request.yieldManagementComment != null || request.yieldManagementPrice != null; }).length; }).length) {
            disable = true;
            title = t('Rebooking.yield-management');
        }
        var row = react_1.default.createElement("div", { className: 'abba' },
            react_1.default.createElement("table", null,
                react_1.default.createElement("tbody", null,
                    react_1.default.createElement("tr", { key: oldTrip.id, tabIndex: -1, onClick: function () { return changeOldTripIdState(isOldTripSelected, disable); }, className: disable ? 'disable' : 'clickable' },
                        react_1.default.createElement("td", { className: 'position-checkbox' },
                            react_1.default.createElement(aurum_1.Input, { type: 'checkbox', value: isOldTripSelected, disabled: disable })),
                        react_1.default.createElement("td", { className: 'positions' },
                            react_1.default.createElement("table", null,
                                react_1.default.createElement("tbody", null, positions === null || positions === void 0 ? void 0 : positions.map(function (position) {
                                    return react_1.default.createElement("tr", { key: position.id, className: 'none-hover' },
                                        react_1.default.createElement("td", null, position.product.localizations.de.title));
                                }))),
                            title && react_1.default.createElement(aurum_1.Alert, null, title))))));
        setSelectTripContent(row);
    }, [isOldTripSelected]);
    // Lädt die möglichen neuen Trips anhand der alten Trip-Id und des Datums
    (0, react_1.useEffect)(function () {
        if (!isOldTripSelected || rebookDate.value == null) {
            return;
        }
        var date = (0, dateUtils_1.toDateString)(new Date(rebookDate.value));
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var possibleTrips_1, e_1, errorObject, message;
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        setLoadingTrips(true);
                        return [4 /*yield*/, getPossibleTrips(date, oldTrip.id)];
                    case 1:
                        possibleTrips_1 = _b.sent();
                        setPossibleTrips(possibleTrips_1);
                        return [3 /*break*/, 4];
                    case 2:
                        e_1 = _b.sent();
                        errorObject = (_a = e_1.response) === null || _a === void 0 ? void 0 : _a.data;
                        message = errorObject === null || errorObject === void 0 ? void 0 : errorObject.message;
                        setTripContent(getTextWithIcon(false, message));
                        return [3 /*break*/, 4];
                    case 3:
                        setLoadingTrips(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); })();
    }, [isOldTripSelected, rebookDate.value]);
    // Erstellt Tabelle mit den möglichen Trips
    (0, react_1.useEffect)(function () {
        if (!isOldTripSelected) {
            setTripContent(undefined);
            return;
        }
        var distinctPriceCategories = Array.from(possibleTrips.reduce(function (acc, currentTrip) {
            currentTrip.defaultPriceCategoryInfo.capacities
                .map(function (capacity) { return capacity.priceCategory; })
                .forEach(function (priceCategory) { return acc.add(priceCategory); });
            return acc;
        }, new Set()));
        var tableBody = possibleTrips.length === 0
            ? react_1.default.createElement("tr", { className: 'none-hover' },
                react_1.default.createElement("td", { colSpan: 4 },
                    react_1.default.createElement("p", null, t('Rebooking.noTrips'))))
            : possibleTrips
                .map(function (trip) {
                var _a, _b, _c, _d, _e, _f, _g;
                return (react_1.default.createElement("tr", { key: trip.id, onClick: function () { return changeNewTripIdState(trip.id); }, className: 'clickable' },
                    react_1.default.createElement("td", { className: 'position-checkbox' },
                        react_1.default.createElement(aurum_1.Input, { type: 'checkbox', value: trip.id === newTripId, onChange: function () { return changeNewTripIdState(trip.id); } })),
                    react_1.default.createElement("td", null,
                        (0, dateUtils_1.formatTime)(trip.departure),
                        " Uhr"),
                    react_1.default.createElement("td", null,
                        (0, dateUtils_1.formatTime)(trip.arrival),
                        " Uhr"),
                    react_1.default.createElement("td", null, (_d = (_c = (_b = (_a = trip.transportationTour) === null || _a === void 0 ? void 0 : _a.route) === null || _b === void 0 ? void 0 : _b.localizations) === null || _c === void 0 ? void 0 : _c.de) === null || _d === void 0 ? void 0 : _d.title),
                    react_1.default.createElement("td", null, (_g = (_f = (_e = trip.provider) === null || _e === void 0 ? void 0 : _e.localizations) === null || _f === void 0 ? void 0 : _f.de) === null || _g === void 0 ? void 0 : _g.title),
                    distinctPriceCategories.map(function (category) {
                        var capacities = trip.defaultPriceCategoryInfo.capacities
                            .filter(function (capacity) { return capacity.priceCategory === category; });
                        var total = 0;
                        var reserved = 0;
                        var unit = '';
                        capacities.forEach(function (capacity) {
                            var _a, _b, _c;
                            total += (_a = capacity.total) !== null && _a !== void 0 ? _a : 0;
                            reserved += (_b = capacity.reserved) !== null && _b !== void 0 ? _b : 0;
                            unit = (_c = capacity.unit) !== null && _c !== void 0 ? _c : '';
                        });
                        return react_1.default.createElement("td", { key: category }, total ? reserved + '/' + total + ' ' + (unit ? t('Enum.TransportationTripCapacity.Unit.' + unit) : '') : '');
                    })));
            });
        var body = react_1.default.createElement(aurum_1.Table, null,
            react_1.default.createElement("thead", null,
                react_1.default.createElement("tr", null,
                    react_1.default.createElement("th", null),
                    react_1.default.createElement("th", null, t('EntityAttribute.departure')),
                    react_1.default.createElement("th", null, t('EntityAttribute.arrival')),
                    react_1.default.createElement("th", null, t('EntityAttribute.route')),
                    react_1.default.createElement("th", null, t('EntityAttribute.provider')),
                    distinctPriceCategories.map(function (category) { return (react_1.default.createElement("th", { key: category }, category !== null && category !== void 0 ? category : 'Kapazität')); }))),
            react_1.default.createElement("tbody", null, tableBody));
        setTripContent(body);
    }, [possibleTrips, newTripId]);
    var getTitle = function () {
        var _a, _b, _c, _d;
        var result = '';
        result += (((_d = (_c = (_b = (_a = oldTrip.transportationTour) === null || _a === void 0 ? void 0 : _a.route) === null || _b === void 0 ? void 0 : _b.localizations) === null || _c === void 0 ? void 0 : _c.de) === null || _d === void 0 ? void 0 : _d.title) || 'n/v') + '  |  ';
        result += (0, dateUtils_1.formatDateYearMonthDay)(oldTrip.departure) + '  -  ';
        result += (0, dateUtils_1.formatTime)(oldTrip.departure) + ' Uhr';
        return result;
    };
    return (react_1.default.createElement("div", { className: 'rebookingChild', key: oldTrip.id },
        react_1.default.createElement(aurum_1.Header, null,
            react_1.default.createElement("h2", null, getTitle())),
        react_1.default.createElement(aurum_1.Body, null,
            react_1.default.createElement("div", { className: 'left' },
                react_1.default.createElement("h2", null, "Positionen"),
                selectTripContent || react_1.default.createElement(aurum_1.Progress, null)),
            react_1.default.createElement("div", { className: 'right' },
                react_1.default.createElement("h2", null, t('Rebooking.possibleTrips')),
                react_1.default.createElement(aurum_1.InputGroup, { className: 'dateGroup' },
                    react_1.default.createElement(aurum_1.Button, { className: 'dateBtn', disabled: !rebookDate.inputvalue, onClick: function () { return decreaseDate(); } }, "-"),
                    react_1.default.createElement(aurum_1.Input, { className: 'rebookingDate', type: 'date', label: t('Rebooking.date'), value: rebookDate.inputvalue, onChange: rebookDate.onChange }),
                    react_1.default.createElement(aurum_1.Button, { className: 'dateBtn', disabled: !rebookDate.inputvalue, onClick: function () { return increaseDate(); } }, "+")),
                isLoadingTrips ? react_1.default.createElement(aurum_1.Progress, null) : tripContent))));
};
exports.default = react_1.default.memo(RebookingTransportationChild);
