"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFieldMap = exports.getFields = exports.buildConfig = exports.generateDefaultFormConfigEntry = exports.generateDefaultTableConfigEntry = exports.mergeConfigEntry = exports.expandBaseConfigEntry = void 0;
var tslib_1 = require("tslib");
var samsa = tslib_1.__importStar(require("@samsa/samsa-api/js"));
var mergedComponentConfig_1 = require("../component/config/mergedComponentConfig");
var getDefaultFormConfigEntry_1 = require("../component/config/getDefaultFormConfigEntry");
var getDefaultTableConfigEntry_1 = require("../component/config/getDefaultTableConfigEntry");
var isZoomable_1 = require("./isZoomable");
var isPseudoEnum_1 = require("./isPseudoEnum");
var expandBaseConfigEntry = function (value) {
    if (typeof value !== 'object') {
        value = { name: value, show: true };
    }
    return value;
};
exports.expandBaseConfigEntry = expandBaseConfigEntry;
var mergeConfigEntry = function (baseValue, overrides) { return (tslib_1.__assign(tslib_1.__assign({}, baseValue), overrides !== null && overrides !== void 0 ? overrides : {})); };
exports.mergeConfigEntry = mergeConfigEntry;
var generateDefaultTableConfigEntry = function (entry, overrides) {
    if (overrides === void 0) { overrides = {}; }
    var parts = entry.name.split('.');
    return (0, getDefaultTableConfigEntry_1.getDefaultTableConfigEntry)(entry, overrides, parts[parts.length - 1]);
};
exports.generateDefaultTableConfigEntry = generateDefaultTableConfigEntry;
var generateDefaultFormConfigEntry = function (entry, overrides) {
    if (overrides === void 0) { overrides = {}; }
    var parts = entry.name.split('.');
    return (0, getDefaultFormConfigEntry_1.getDefaultFormConfigEntry)(entry, overrides, parts[parts.length - 1]);
};
exports.generateDefaultFormConfigEntry = generateDefaultFormConfigEntry;
var sortingFunc = function (a, b) {
    var fieldname_order = [
        // IDs
        'id',
        'shortId',
        // Referenzen und ähnliches
        'reference',
        'owner',
        'ownerReference',
        'provider',
        // Arten/Gruppen
        'type',
        'groupTitle',
        // Bezeichnung/Beschreibung
        'title',
        'description',
        'localizations',
        // Unternehmen
        'companyName',
        // Personen
        'gender',
        'givenName',
        'middleName',
        'familyName',
        'honorificPrefix',
        'honorificSuffix',
        'birthDate',
        // Adresse
        'address',
        'streetAddress',
        'extendedAddress',
        'country',
        'postalCode',
        'locality',
        'region',
        'postOfficeBox',
        'geoPoint',
        // Kontaktdaten
        'email',
        'phone',
        'mobilePhone',
        'website'
    ];
    if (a.sort < b.sort) {
        return -1;
    }
    if (a.sort > b.sort) {
        return 1;
    }
    var indexA = fieldname_order.indexOf(a.name);
    var indexB = fieldname_order.indexOf(b.name);
    if (indexA >= 0 && indexB === -1) {
        return -1;
    }
    if (indexA === -1 && indexB >= 0) {
        return 1;
    }
    if (indexA >= 0 && indexB >= 0) {
        return indexA - indexB;
    }
    if (!a.block && b.block) {
        return -1;
    }
    if (a.block && !b.block) {
        return 1;
    }
    if (!a.array && b.array) {
        return -1;
    }
    if (a.array && !b.array) {
        return 1;
    }
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
};
var dataConfig = null;
var buildConfig = function () {
    var config = mergedComponentConfig_1.mergedComponentConfig;
    var newConfig = {};
    var masterData = Object.values(samsa)
        .filter(function (Type) { var _a; return (_a = Type === null || Type === void 0 ? void 0 : Type.definition) === null || _a === void 0 ? void 0 : _a.properties; })
        .sort(function (a, b) { return a < b ? -1 : 1; });
    masterData.forEach(function (Type) {
        newConfig[Type.name] = {};
    });
    masterData.forEach(function (Type) {
        Type.definition.properties.forEach(function (p) {
            var entry = (0, exports.mergeConfigEntry)(p, newConfig[Type.name][p.name]);
            newConfig[Type.name][p.name] = tslib_1.__assign(tslib_1.__assign({}, entry), { table: (0, exports.generateDefaultTableConfigEntry)(entry), form: (0, exports.generateDefaultFormConfigEntry)(entry) });
        });
    });
    Object.entries(config).forEach(function (_a) {
        var _b = tslib_1.__read(_a, 2), key = _b[0], value = _b[1];
        var currentConfigEntry = newConfig[key];
        if (!currentConfigEntry) {
            console.error("Ignoring unkown type '".concat(key, "'!"));
            return;
        }
        var merge = function (entryKey, override) {
            currentConfigEntry[entryKey] = tslib_1.__assign(tslib_1.__assign({}, currentConfigEntry[entryKey]), override);
        };
        Object.entries(value).forEach(function (_a) {
            var _b = tslib_1.__read(_a, 2), key = _b[0], value = _b[1];
            if (!['table', 'form'].includes(key)) {
                currentConfigEntry['__' + key] = value;
            }
        });
        // @ts-ignore
        if (value.table) {
            // @ts-ignore
            value.table.map(exports.expandBaseConfigEntry).forEach(function (entry, sort) {
                if (!currentConfigEntry[entry.name]) {
                    console.error("Table: Ignoring property ".concat(key + '.' + entry.name, ": Unkown property name"));
                    return;
                }
                if (entry.name.includes('.')) {
                    console.error("Table: Ignoring property ".concat(key + '.' + entry.name, ": Overriding of sub-properties unsupported"));
                    return;
                }
                merge(entry.name, {
                    table: tslib_1.__assign(tslib_1.__assign({}, (0, exports.generateDefaultTableConfigEntry)({ name: entry.name }, entry)), { sort: sort })
                });
            });
        }
        if (value.form) {
            // @ts-ignore
            value.form.map(exports.expandBaseConfigEntry).forEach(function (entry, sort) {
                if (!currentConfigEntry[entry.name]) {
                    console.error("Form: Ignoring property ".concat(key + '.' + entry.name, ": Unkown property name"));
                    return;
                }
                if (entry.name.includes('.')) {
                    console.error("Form: Ignoring property ".concat(key + '.' + entry.name, ": Overriding of sub-properties unsupported"));
                    return;
                }
                merge(entry.name, {
                    form: tslib_1.__assign(tslib_1.__assign({}, (0, exports.generateDefaultFormConfigEntry)({ name: entry.name }, entry)), { sort: sort })
                });
            });
        }
    });
    Object.entries(newConfig).forEach(function (_a) {
        var _b = tslib_1.__read(_a, 2), typeKey = _b[0], currentConfigEntry = _b[1];
        var tableEntries = [];
        var formEntries = [];
        if (currentConfigEntry.__split == null) {
            // eslint-disable-next-line import/namespace
            currentConfigEntry.__split = !samsa[typeKey].get;
        }
        Object.entries(currentConfigEntry).forEach(function (_a) {
            var _b, _c;
            var _d = tslib_1.__read(_a, 2), key = _d[0], entry = _d[1];
            if (!entry.table || !entry.form) {
                if (!key.startsWith('__')) {
                    console.warn("".concat(typeKey, ": Skipping unkown type '").concat(key, "'!"));
                }
                return;
            }
            var Type = samsa[entry.type];
            var block = (Type && !(0, isZoomable_1.isZoomable)(Type) && !(0, isPseudoEnum_1.isPseudoEnum)(Type));
            if (entry.table.show) {
                tableEntries.push({ name: entry.name, array: entry.array, block: block, sort: (_b = entry.table.sort) !== null && _b !== void 0 ? _b : Infinity });
            }
            if (entry.form.show) {
                formEntries.push({ name: entry.name, array: entry.array, block: block, sort: (_c = entry.form.sort) !== null && _c !== void 0 ? _c : Infinity });
            }
        });
        newConfig[typeKey].__sort = {
            table: tableEntries.sort(sortingFunc).map(function (e) { return e.name; }),
            form: formEntries.sort(sortingFunc).map(function (e) { return e.name; })
        };
    });
    dataConfig = newConfig;
    console.log('DataConfig initialized:', dataConfig);
};
exports.buildConfig = buildConfig;
var getFields = function (Type, t, type) {
    var config = dataConfig[Type.name];
    if (!config) {
        console.error("Config entry missing for '".concat(Type.name, "'!"));
        return null;
    }
    var fields = [];
    var parseProp = function (prop, name) {
        var _a, _b;
        if (name === void 0) { name = null; }
        var extendedprop = tslib_1.__assign(tslib_1.__assign(tslib_1.__assign({}, prop), (_a = config[name !== null && name !== void 0 ? name : prop.name]) !== null && _a !== void 0 ? _a : {}), (_b = config[prop.name]) !== null && _b !== void 0 ? _b : {});
        extendedprop.table = tslib_1.__assign({}, extendedprop.table);
        extendedprop.form = tslib_1.__assign({}, extendedprop.form);
        extendedprop.sortable = !extendedprop.array && !extendedprop.object &&
            extendedprop.type[0] === extendedprop.type[0].toLowerCase();
        if (!extendedprop.sort && (extendedprop.array || extendedprop.object)) {
            extendedprop.sort = -1;
        }
        // eslint-disable-next-line import/namespace
        var SubType = samsa[extendedprop.type];
        var subTypeConfig = dataConfig[SubType === null || SubType === void 0 ? void 0 : SubType.name];
        if ((subTypeConfig === null || subTypeConfig === void 0 ? void 0 : subTypeConfig.__split) && !extendedprop.array && type === 'table'
        /* SubType && !isZoomable(SubType) && !isPseudoEnum(SubType) && !extendedprop.array */ ) {
            extendedprop.table.show = false;
            extendedprop.form.show = extendedprop.name.includes('.') ? false : config[extendedprop.name].form.show;
            subTypeConfig.__sort[type].map(function (e) { return subTypeConfig[e]; }).forEach(function (propLocalized) {
                parseProp(tslib_1.__assign(tslib_1.__assign({}, propLocalized), { name: "".concat(prop.name, ".").concat(propLocalized.name), form: { show: false } }), propLocalized.name);
            });
        }
        var translationIds = [];
        var parts = [];
        prop.name.split('.').reverse().forEach(function (part) {
            parts.push(part);
            var key = parts.reverse().join('.');
            translationIds.unshift("EntityAttribute.".concat(key));
            translationIds.unshift("Entity.".concat(Type.name, ".").concat(key));
        });
        if (extendedprop.form || extendedprop.table) {
            // @ts-ignore
            extendedprop.title = t === null || t === void 0 ? void 0 : t(translationIds);
        }
        fields.push(extendedprop);
    };
    config.__sort[type].map(function (e) { return config[e]; }).forEach(function (prop) { return parseProp(prop); });
    return fields.filter(function (f) { return f[type].show; });
};
exports.getFields = getFields;
var getFieldMap = function (Type, t, type) {
    var map = {};
    if (Type == null) {
        console.error('\'Type\' is null or undefined!');
        return map;
    }
    var sort = 0;
    var progress = function (Type, prefix) {
        var fields = (0, exports.getFields)(Type, t, type);
        fields === null || fields === void 0 ? void 0 : fields.forEach(function (field) {
            // eslint-disable-next-line import/namespace
            var Type = samsa[field.type];
            var fieldPath = "".concat(prefix, ".").concat(field.name);
            map[fieldPath] = {
                sort: sort++,
                field: field
            };
            if (Type && !(0, isZoomable_1.isZoomable)(Type) && !(0, isPseudoEnum_1.isPseudoEnum)(Type)) {
                progress(Type, fieldPath);
            }
        });
    };
    if (!(Type === null || Type === void 0 ? void 0 : Type.name)) {
        console.error('Type.name missing for ', Type, Type === null || Type === void 0 ? void 0 : Type.toString());
    }
    progress(Type, Type.name);
    return map;
};
exports.getFieldMap = getFieldMap;
