"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initMap = void 0;
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var google_maps_react_1 = require("google-maps-react");
var js_1 = require("@samsa/samsa-api/js");
var Dashboard_1 = require("../Dashboard");
var getIcon = function (_a) {
    var google = _a.google, _b = _a.color, color = _b === void 0 ? '#7b9eb0' : _b, _c = _a.size, size = _c === void 0 ? 12 : _c, _d = _a.type, type = _d === void 0 ? 'point' : _d;
    return {
        url: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='".concat(size, "' width='").concat(size, "'%3E%3Ccircle cx='").concat(size / 2, "' cy='").concat(size / 2, "' r='").concat(size / 2 - 0.5, "' stroke='white' stroke-width='1' fill='").concat(encodeURIComponent(color), "' /%3E%3C/svg%3E"),
        anchor: new google.maps.Point(size / 2 + 0.5, size / 2 + 0.5),
        scaledSize: new google.maps.Size(size, size)
    };
};
var transformPolygon = function (text) {
    var arr = text.slice(11, -1).split(',').map(function (part) {
        var _a = tslib_1.__read(part.trim().split(' '), 2), lat = _a[0], lon = _a[1];
        return {
            lat: parseFloat(lat.trim()),
            lng: parseFloat(lon.trim())
        };
    });
    return arr;
};
var MapContainer = function (_a) {
    var _b, _c, _d, _e, _f;
    var google = _a.google, _g = _a.points, points = _g === void 0 ? [] : _g, _h = _a.polygons, polygons = _h === void 0 ? [] : _h, _j = _a.polylines, polylines = _j === void 0 ? [] : _j;
    var center = {
        lat: 53.5,
        lng: 7.5
    };
    var bounds = new google.maps.LatLngBounds();
    (_b = points === null || points === void 0 ? void 0 : points.forEach) === null || _b === void 0 ? void 0 : _b.call(points, function (point) {
        if (point.lat && point.lng) {
            bounds.extend(point);
        }
    });
    return aurum_1.React.createElement(google_maps_react_1.Map, { google: google, initialCenter: center, zoom: 9, bounds: bounds, zoomControl: true, mapTypeControl: false, scaleControl: false, streetViewControl: false, rotateControl: false, fullscreenControl: true, styles: [ /*
          {elementType: 'geometry', stylers: [{color: '#dddddd'}]},
          // {
          //   featureType: 'landscape.man_made',
          //   elementType: 'geometry',
          //   stylers: [{color: '#666666'}] // #d8717f #f8f8f8
          // },
          // {
          //   featureType: 'poi',
          //   elementType: 'geometry',
          //   stylers: [{color: '#4caf50'}] // #87b749
          // },
          // {
          //   featureType: 'road',
          //   elementType: 'geometry',
          //   stylers: [{color: '#999999'}]
          // },
          // {
          //   featureType: 'transit',
          //   elementType: 'geometry',
          //   stylers: [{color: '#999999'}]
          // },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{color: '#ffffff'}]
          },
          {
            featureType: 'water',
            elementType: 'labels',
            stylers: [{visibility: 'off'}]
          },
          {
            featureType: 'all',
            elementType: 'labels',
            stylers: [{visibility: 'off'}]
          }
        */] }, (_c = polygons === null || polygons === void 0 ? void 0 : polygons.map) === null || _c === void 0 ? void 0 :
        _c.call(polygons, function (polygon) {
            return aurum_1.React.createElement(google_maps_react_1.Polygon, { key: polygon === null || polygon === void 0 ? void 0 : polygon.id, paths: polygon === null || polygon === void 0 ? void 0 : polygon.paths, title: polygon === null || polygon === void 0 ? void 0 : polygon.title, strokeColor: polygon === null || polygon === void 0 ? void 0 : polygon.color, strokeOpacity: 1, strokeWeight: 1, fillColor: polygon === null || polygon === void 0 ? void 0 : polygon.color, fillOpacity: 0.1 });
        }), (_d = points === null || points === void 0 ? void 0 : points.map) === null || _d === void 0 ? void 0 :
        _d.call(points, function (point) {
            var _a, _b;
            return aurum_1.React.createElement(google_maps_react_1.Marker, { key: point === null || point === void 0 ? void 0 : point.id, position: point, title: point === null || point === void 0 ? void 0 : point.title, icon: getIcon({
                    google: google,
                    color: (_a = point === null || point === void 0 ? void 0 : point.color) !== null && _a !== void 0 ? _a : '#7b9eb0',
                    size: (_b = point === null || point === void 0 ? void 0 : point.size) !== null && _b !== void 0 ? _b : 12
                }) });
        }), (_e = polygons === null || polygons === void 0 ? void 0 : polygons.map) === null || _e === void 0 ? void 0 :
        _e.call(polygons, function (polygon) {
            var _a, _b, _c, _d;
            var lats = (_b = (_a = polygon === null || polygon === void 0 ? void 0 : polygon.paths) === null || _a === void 0 ? void 0 : _a.map) === null || _b === void 0 ? void 0 : _b.call(_a, function (e) { return e === null || e === void 0 ? void 0 : e.lat; });
            var lngs = (_d = (_c = polygon === null || polygon === void 0 ? void 0 : polygon.paths) === null || _c === void 0 ? void 0 : _c.map) === null || _d === void 0 ? void 0 : _d.call(_c, function (e) { return e === null || e === void 0 ? void 0 : e.lng; });
            var lat = (Math.min.apply(Math, tslib_1.__spreadArray([], tslib_1.__read(lats), false)) + Math.max.apply(Math, tslib_1.__spreadArray([], tslib_1.__read(lats), false))) / 2;
            var lng = (Math.min.apply(Math, tslib_1.__spreadArray([], tslib_1.__read(lngs), false)) + Math.max.apply(Math, tslib_1.__spreadArray([], tslib_1.__read(lngs), false))) / 2;
            return aurum_1.React.createElement(google_maps_react_1.Marker, { key: polygon === null || polygon === void 0 ? void 0 : polygon.id, position: {
                    lat: lat,
                    lng: lng
                }, title: polygon === null || polygon === void 0 ? void 0 : polygon.title, icon: getIcon({ google: google, color: polygon === null || polygon === void 0 ? void 0 : polygon.color, size: 24 }) });
        }), (_f = polylines === null || polylines === void 0 ? void 0 : polylines.map) === null || _f === void 0 ? void 0 :
        _f.call(polylines, function (polyline) {
            return aurum_1.React.createElement(google_maps_react_1.Polyline, { key: polyline === null || polyline === void 0 ? void 0 : polyline.id, path: polyline === null || polyline === void 0 ? void 0 : polyline.path, title: polyline === null || polyline === void 0 ? void 0 : polyline.title, strokeColor: polyline === null || polyline === void 0 ? void 0 : polyline.color, strokeOpacity: 1, strokeWeight: 3 });
        }));
};
var WrappedMapContainer = null;
var initMap = function (googleApiKey) {
    WrappedMapContainer = (0, google_maps_react_1.GoogleApiWrapper)({
        apiKey: googleApiKey
    })(MapContainer);
};
exports.initMap = initMap;
var Demo5 = function () {
    var _a = tslib_1.__read(aurum_1.React.useState([]), 2), destinations = _a[0], setDestinations = _a[1];
    var _b = tslib_1.__read(aurum_1.React.useState([]), 2), housings = _b[0], setHousings = _b[1];
    var _c = tslib_1.__read(aurum_1.React.useState([]), 2), stations = _c[0], setStations = _c[1];
    var _d = tslib_1.__read(aurum_1.React.useState([]), 2), bicycleLocations = _d[0], setBicyleLocations = _d[1];
    var _e = tslib_1.__read(aurum_1.React.useState([]), 2), eventLocations = _e[0], setEventLocations = _e[1];
    var _f = tslib_1.__read(aurum_1.React.useState([]), 2), events = _f[0], setEvents = _f[1];
    var _g = tslib_1.__read(aurum_1.React.useState([]), 2), routes = _g[0], setRoutes = _g[1];
    var reload = aurum_1.React.useCallback(function () {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                                var destinationborders;
                                var _a, _b;
                                return tslib_1.__generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0: return [4 /*yield*/, js_1.DestinationBorder.getAll({
                                                size: 0,
                                                sort: ['destination.area,DESC', 'id']
                                            })];
                                        case 1:
                                            destinationborders = _c.sent();
                                            setDestinations((_b = (_a = destinationborders === null || destinationborders === void 0 ? void 0 : destinationborders.content) === null || _a === void 0 ? void 0 : _a.map) === null || _b === void 0 ? void 0 : _b.call(_a, function (destinationborder) {
                                                var _a, _b, _c, _d, _e, _f, _g, _h;
                                                return ({
                                                    paths: transformPolygon(destinationborder === null || destinationborder === void 0 ? void 0 : destinationborder.border),
                                                    color: (0, Dashboard_1.getColor)((_c = (_b = (_a = destinationborder === null || destinationborder === void 0 ? void 0 : destinationborder.destination) === null || _a === void 0 ? void 0 : _a.localizations) === null || _b === void 0 ? void 0 : _b.de) === null || _c === void 0 ? void 0 : _c.title),
                                                    title: "".concat((_f = (_e = (_d = destinationborder === null || destinationborder === void 0 ? void 0 : destinationborder.destination) === null || _d === void 0 ? void 0 : _d.localizations) === null || _e === void 0 ? void 0 : _e.de) === null || _f === void 0 ? void 0 : _f.title),
                                                    type: (_h = (_g = destinationborder === null || destinationborder === void 0 ? void 0 : destinationborder.destination) === null || _g === void 0 ? void 0 : _g.type) === null || _h === void 0 ? void 0 : _h.id,
                                                    id: destinationborder === null || destinationborder === void 0 ? void 0 : destinationborder.id
                                                });
                                            }));
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(),
                            (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                                var stations;
                                var _a, _b;
                                return tslib_1.__generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0: return [4 /*yield*/, js_1.TransportationStation.getAll({
                                                size: 0
                                            })];
                                        case 1:
                                            stations = _c.sent();
                                            setStations((_b = (_a = stations === null || stations === void 0 ? void 0 : stations.content) === null || _a === void 0 ? void 0 : _a.map) === null || _b === void 0 ? void 0 : _b.call(_a, function (station) {
                                                var _a, _b, _c, _d, _e, _f;
                                                return ({
                                                    lat: (_b = (_a = station === null || station === void 0 ? void 0 : station.address) === null || _a === void 0 ? void 0 : _a.geoPoint) === null || _b === void 0 ? void 0 : _b.lat,
                                                    lng: (_d = (_c = station === null || station === void 0 ? void 0 : station.address) === null || _c === void 0 ? void 0 : _c.geoPoint) === null || _d === void 0 ? void 0 : _d.lon,
                                                    title: "".concat((_f = (_e = station === null || station === void 0 ? void 0 : station.localizations) === null || _e === void 0 ? void 0 : _e.de) === null || _f === void 0 ? void 0 : _f.title, "\n").concat(station === null || station === void 0 ? void 0 : station.address),
                                                    color: (0, Dashboard_1.getColor)('Transportation'),
                                                    size: 18,
                                                    id: station === null || station === void 0 ? void 0 : station.id
                                                });
                                            }));
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(),
                            (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                                var routes;
                                var _a, _b;
                                return tslib_1.__generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0: return [4 /*yield*/, js_1.TransportationRoute.getAll({
                                                size: 0
                                            })];
                                        case 1:
                                            routes = _c.sent();
                                            setRoutes((_b = (_a = routes === null || routes === void 0 ? void 0 : routes.content) === null || _a === void 0 ? void 0 : _a.map) === null || _b === void 0 ? void 0 : _b.call(_a, function (route) {
                                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
                                                var lat = 0;
                                                var lng = 0;
                                                var path = [];
                                                var count = (_a = route === null || route === void 0 ? void 0 : route.stations) === null || _a === void 0 ? void 0 : _a.length;
                                                (_c = (_b = route === null || route === void 0 ? void 0 : route.stations) === null || _b === void 0 ? void 0 : _b.forEach) === null || _c === void 0 ? void 0 : _c.call(_b, function (link) {
                                                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
                                                    lat += (_d = (_c = (_b = (_a = link === null || link === void 0 ? void 0 : link.station) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.geoPoint) === null || _c === void 0 ? void 0 : _c.lat) !== null && _d !== void 0 ? _d : 0;
                                                    lng += (_h = (_g = (_f = (_e = link === null || link === void 0 ? void 0 : link.station) === null || _e === void 0 ? void 0 : _e.address) === null || _f === void 0 ? void 0 : _f.geoPoint) === null || _g === void 0 ? void 0 : _g.lon) !== null && _h !== void 0 ? _h : 0;
                                                    path.push({
                                                        lat: (_l = (_k = (_j = link === null || link === void 0 ? void 0 : link.station) === null || _j === void 0 ? void 0 : _j.address) === null || _k === void 0 ? void 0 : _k.geoPoint) === null || _l === void 0 ? void 0 : _l.lat,
                                                        lng: (_p = (_o = (_m = link === null || link === void 0 ? void 0 : link.station) === null || _m === void 0 ? void 0 : _m.address) === null || _o === void 0 ? void 0 : _o.geoPoint) === null || _p === void 0 ? void 0 : _p.lon
                                                    });
                                                });
                                                return {
                                                    point: {
                                                        lat: lat / count,
                                                        lng: lng / count,
                                                        title: "".concat((_e = (_d = route === null || route === void 0 ? void 0 : route.localizations) === null || _d === void 0 ? void 0 : _d.de) === null || _e === void 0 ? void 0 : _e.title, "\n").concat((_h = (_g = (_f = route === null || route === void 0 ? void 0 : route.stations) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.station) === null || _h === void 0 ? void 0 : _h.address),
                                                        color: (0, Dashboard_1.getColor)('Transportation'),
                                                        size: 12,
                                                        id: route === null || route === void 0 ? void 0 : route.id
                                                    },
                                                    line: {
                                                        path: path,
                                                        title: "".concat((_k = (_j = route === null || route === void 0 ? void 0 : route.localizations) === null || _j === void 0 ? void 0 : _j.de) === null || _k === void 0 ? void 0 : _k.title, "\n").concat((_o = (_m = (_l = route === null || route === void 0 ? void 0 : route.stations) === null || _l === void 0 ? void 0 : _l[0]) === null || _m === void 0 ? void 0 : _m.station) === null || _o === void 0 ? void 0 : _o.address),
                                                        color: (0, Dashboard_1.getColor)('Transportation'),
                                                        id: route === null || route === void 0 ? void 0 : route.id
                                                    }
                                                };
                                            }));
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(),
                            (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                                var locations;
                                var _a, _b;
                                return tslib_1.__generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0: return [4 /*yield*/, js_1.BicycleLocation.getAll({
                                                size: 0
                                            })];
                                        case 1:
                                            locations = _c.sent();
                                            setBicyleLocations((_b = (_a = locations === null || locations === void 0 ? void 0 : locations.content) === null || _a === void 0 ? void 0 : _a.map) === null || _b === void 0 ? void 0 : _b.call(_a, function (location) {
                                                var _a, _b, _c, _d, _e, _f;
                                                return ({
                                                    lat: (_b = (_a = location === null || location === void 0 ? void 0 : location.address) === null || _a === void 0 ? void 0 : _a.geoPoint) === null || _b === void 0 ? void 0 : _b.lat,
                                                    lng: (_d = (_c = location === null || location === void 0 ? void 0 : location.address) === null || _c === void 0 ? void 0 : _c.geoPoint) === null || _d === void 0 ? void 0 : _d.lon,
                                                    title: "".concat((_f = (_e = location === null || location === void 0 ? void 0 : location.localizations) === null || _e === void 0 ? void 0 : _e.de) === null || _f === void 0 ? void 0 : _f.title, "\n").concat(location === null || location === void 0 ? void 0 : location.address),
                                                    color: (0, Dashboard_1.getColor)('Bicycle'),
                                                    size: 18,
                                                    id: location === null || location === void 0 ? void 0 : location.id
                                                });
                                            }));
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(),
                            (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                                var locations;
                                var _a, _b;
                                return tslib_1.__generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0: return [4 /*yield*/, js_1.EventLocation.getAll({
                                                size: 0
                                            })];
                                        case 1:
                                            locations = _c.sent();
                                            setEventLocations((_b = (_a = locations === null || locations === void 0 ? void 0 : locations.content) === null || _a === void 0 ? void 0 : _a.map) === null || _b === void 0 ? void 0 : _b.call(_a, function (location) {
                                                var _a, _b, _c, _d, _e, _f;
                                                return ({
                                                    lat: (_b = (_a = location === null || location === void 0 ? void 0 : location.address) === null || _a === void 0 ? void 0 : _a.geoPoint) === null || _b === void 0 ? void 0 : _b.lat,
                                                    lng: (_d = (_c = location === null || location === void 0 ? void 0 : location.address) === null || _c === void 0 ? void 0 : _c.geoPoint) === null || _d === void 0 ? void 0 : _d.lon,
                                                    title: "".concat((_f = (_e = location === null || location === void 0 ? void 0 : location.localizations) === null || _e === void 0 ? void 0 : _e.de) === null || _f === void 0 ? void 0 : _f.title, "\n").concat(location === null || location === void 0 ? void 0 : location.address),
                                                    color: (0, Dashboard_1.getColor)('Event'),
                                                    size: 18,
                                                    id: location === null || location === void 0 ? void 0 : location.id
                                                });
                                            }));
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(),
                            (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                                var events;
                                var _a, _b;
                                return tslib_1.__generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0: return [4 /*yield*/, js_1.EventDraft.getAll({
                                                size: 0
                                            })];
                                        case 1:
                                            events = _c.sent();
                                            setEvents((_b = (_a = events === null || events === void 0 ? void 0 : events.content) === null || _a === void 0 ? void 0 : _a.map) === null || _b === void 0 ? void 0 : _b.call(_a, function (event) {
                                                var _a, _b, _c, _d;
                                                return ({
                                                    point: {
                                                        lat: (event.meetingArea.address.geoPoint.lat + event.location.address.geoPoint.lat) / 2,
                                                        lng: (event.meetingArea.address.geoPoint.lon + event.location.address.geoPoint.lon) / 2,
                                                        title: "".concat((_b = (_a = event === null || event === void 0 ? void 0 : event.localizations) === null || _a === void 0 ? void 0 : _a.de) === null || _b === void 0 ? void 0 : _b.title),
                                                        color: (0, Dashboard_1.getColor)('Event'),
                                                        size: 12,
                                                        id: event === null || event === void 0 ? void 0 : event.id
                                                    },
                                                    line: {
                                                        path: [
                                                            { lat: event.meetingArea.address.geoPoint.lat, lng: event.meetingArea.address.geoPoint.lon },
                                                            { lat: event.location.address.geoPoint.lat, lng: event.location.address.geoPoint.lon }
                                                        ],
                                                        title: "".concat((_d = (_c = event === null || event === void 0 ? void 0 : event.localizations) === null || _c === void 0 ? void 0 : _c.de) === null || _d === void 0 ? void 0 : _d.title),
                                                        color: (0, Dashboard_1.getColor)('Event'),
                                                        id: event === null || event === void 0 ? void 0 : event.id
                                                    }
                                                });
                                            }));
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(),
                            (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                                var housings, getPage;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            housings = [];
                                            getPage = function (page) {
                                                if (page === void 0) { page = 0; }
                                                return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                                                    var data;
                                                    var _a, _b;
                                                    return tslib_1.__generator(this, function (_c) {
                                                        switch (_c.label) {
                                                            case 0: return [4 /*yield*/, js_1.HousingDraft.getAll({
                                                                    size: 1000,
                                                                    page: page,
                                                                    sort: 'id'
                                                                })];
                                                            case 1:
                                                                data = _c.sent();
                                                                housings.push.apply(housings, tslib_1.__spreadArray([], tslib_1.__read((_b = (_a = data === null || data === void 0 ? void 0 : data.content) === null || _a === void 0 ? void 0 : _a.map) === null || _b === void 0 ? void 0 : _b.call(_a, function (housing) {
                                                                    var _a, _b, _c, _d, _e, _f;
                                                                    return ({
                                                                        lat: (_b = (_a = housing === null || housing === void 0 ? void 0 : housing.address) === null || _a === void 0 ? void 0 : _a.geoPoint) === null || _b === void 0 ? void 0 : _b.lat,
                                                                        lng: (_d = (_c = housing === null || housing === void 0 ? void 0 : housing.address) === null || _c === void 0 ? void 0 : _c.geoPoint) === null || _d === void 0 ? void 0 : _d.lon,
                                                                        title: "".concat((_f = (_e = housing === null || housing === void 0 ? void 0 : housing.localizations) === null || _e === void 0 ? void 0 : _e.de) === null || _f === void 0 ? void 0 : _f.title, "\n").concat(housing === null || housing === void 0 ? void 0 : housing.address),
                                                                        color: (0, Dashboard_1.getColor)('Housing'),
                                                                        size: 12,
                                                                        id: housing === null || housing === void 0 ? void 0 : housing.id
                                                                    });
                                                                })), false));
                                                                setHousings(tslib_1.__spreadArray([], tslib_1.__read(housings), false));
                                                                if (!!data.last) return [3 /*break*/, 3];
                                                                return [4 /*yield*/, getPage(page + 1)];
                                                            case 2:
                                                                _c.sent();
                                                                _c.label = 3;
                                                            case 3: return [2 /*return*/];
                                                        }
                                                    });
                                                });
                                            };
                                            return [4 /*yield*/, getPage()];
                                        case 1:
                                            _a.sent();
                                            setHousings(tslib_1.__spreadArray([], tslib_1.__read(housings), false));
                                            return [2 /*return*/];
                                    }
                                });
                            }); })()
                        ])];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    aurum_1.React.useEffect(reload, [reload]);
    console.log('events', events);
    return aurum_1.React.createElement(aurum_1.Sheet, { className: 'tile size-3-3' },
        aurum_1.React.createElement(aurum_1.Header, null,
            aurum_1.React.createElement("h3", null,
                "Karte aller Unterk\u00FCnfte (", housings === null || housings === void 0 ? void 0 :
                housings.length,
                "), Haltestellen (", stations === null || stations === void 0 ? void 0 :
                stations.length,
                "), Routen (", routes === null || routes === void 0 ? void 0 :
                routes.length,
                "), Vermietungsstandorte (", bicycleLocations === null || bicycleLocations === void 0 ? void 0 :
                bicycleLocations.length,
                "), Veranstaltungen (", eventLocations === null || eventLocations === void 0 ? void 0 :
                eventLocations.length,
                ") und Destinationen (", destinations === null || destinations === void 0 ? void 0 :
                destinations.length,
                ")")),
        aurum_1.React.createElement(aurum_1.Body, null,
            aurum_1.React.createElement("div", { style: { width: '100%', height: '100%', position: 'relative' } }, WrappedMapContainer
                ? aurum_1.React.createElement(WrappedMapContainer, { points: tslib_1.__spreadArray(tslib_1.__spreadArray(tslib_1.__spreadArray(tslib_1.__spreadArray(tslib_1.__spreadArray(tslib_1.__spreadArray([], tslib_1.__read(housings), false), tslib_1.__read(stations), false), tslib_1.__read(eventLocations), false), tslib_1.__read(bicycleLocations), false), tslib_1.__read(routes.map(function (e) { return e.point; })), false), tslib_1.__read(events.map(function (e) { return e.point; })), false), polygons: destinations, polylines: tslib_1.__spreadArray(tslib_1.__spreadArray([], tslib_1.__read(routes.map(function (e) { return e.line; })), false), tslib_1.__read(events.map(function (e) { return e.line; })), false) })
                : aurum_1.React.createElement(aurum_1.Progress, null, "Lade..."))));
};
exports.default = aurum_1.React.memo(Demo5);
