"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var asyncPool = function (poolLimit, array, iteratorFn) { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
    var countEntries, countStarted, countEnded, lastprogress, lastprogressfloored, lastprogresstime, progress, ret, executing, _loop_1, array_1, array_1_1, item, e_1_1, r;
    var e_1, _a;
    return tslib_1.__generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (typeof array === 'number') {
                    array = Array.apply(null, Array(array));
                }
                if (typeof array === 'number') {
                    return [2 /*return*/, null];
                }
                countEntries = array.length;
                countStarted = 0;
                countEnded = 0;
                poolLimit = Math.min(poolLimit, countEntries);
                lastprogress = null;
                lastprogressfloored = 0;
                lastprogresstime = 0;
                progress = function (progress, force) {
                    if (force === void 0) { force = false; }
                    if (lastprogress === progress) {
                        return;
                    }
                    if (force) {
                        asyncPool.onProgress(progress);
                        lastprogress = progress;
                        return;
                    }
                    var progresstime = Date.now();
                    var diff = progresstime - lastprogresstime;
                    var progressfloored = Math.floor(progress * 100);
                    if ((progressfloored !== lastprogressfloored || diff > 1000) && diff > 100) {
                        asyncPool.onProgress(progress);
                        lastprogress = progress;
                        lastprogressfloored = progressfloored;
                        lastprogresstime = progresstime;
                    }
                };
                ret = [];
                executing = [];
                progress(0, true);
                _loop_1 = function (item) {
                    var p, e;
                    return tslib_1.__generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                countStarted++;
                                progress((countStarted + countEnded) / 2 / countEntries);
                                p = Promise.resolve().then(function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                                    var r;
                                    return tslib_1.__generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, iteratorFn(item, array, countStarted - 1)];
                                            case 1:
                                                r = _a.sent();
                                                countEnded++;
                                                progress((countStarted + countEnded) / 2 / countEntries);
                                                return [2 /*return*/, r];
                                        }
                                    });
                                }); });
                                ret.push(p);
                                e = p.then(function () { return executing.splice(executing.indexOf(e), 1); });
                                executing.push(e);
                                if (!(executing.length >= poolLimit)) return [3 /*break*/, 2];
                                return [4 /*yield*/, Promise.race(executing)];
                            case 1:
                                _c.sent();
                                _c.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                };
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, 7, 8]);
                array_1 = tslib_1.__values(array), array_1_1 = array_1.next();
                _b.label = 2;
            case 2:
                if (!!array_1_1.done) return [3 /*break*/, 5];
                item = array_1_1.value;
                return [5 /*yield**/, _loop_1(item)];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4:
                array_1_1 = array_1.next();
                return [3 /*break*/, 2];
            case 5: return [3 /*break*/, 8];
            case 6:
                e_1_1 = _b.sent();
                e_1 = { error: e_1_1 };
                return [3 /*break*/, 8];
            case 7:
                try {
                    if (array_1_1 && !array_1_1.done && (_a = array_1.return)) _a.call(array_1);
                }
                finally { if (e_1) throw e_1.error; }
                return [7 /*endfinally*/];
            case 8: return [4 /*yield*/, Promise.all(ret)];
            case 9:
                r = _b.sent();
                progress(1, true);
                return [2 /*return*/, r];
        }
    });
}); };
asyncPool.onProgress = function (progress) { return progress; };
exports.default = asyncPool;
