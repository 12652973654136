"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var js_1 = require("@samsa/samsa-api/js");
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var react_1 = require("react");
var useOnChange_1 = require("../../hook/useOnChange");
var useSort_1 = require("../../hook/useSort");
var dateUtils_1 = require("../../util/dateUtils");
var formatPrice_1 = require("../../util/formatPrice");
var useAsync_1 = require("../../hook/useAsync");
var tablefields = [
    { name: 'id' },
    { name: 'totalGross' },
    { name: 'totalNet' },
    { name: 'totalGrossInvoice' },
    { name: 'totalNetInvoice' },
    { name: 'createdAt' },
    { name: 'download' }
];
var DatevList = function () {
    var _a;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _b = tslib_1.__read((0, react_1.useState)([]), 2), pageSelection = _b[0], setPageSelection = _b[1];
    var size = (0, useOnChange_1.useOnChange)(40);
    var page = (0, useOnChange_1.useOnChange)(0);
    var _c = tslib_1.__read((0, useSort_1.useSort)('createdAt,desc'), 2), sort = _c[0], toggleSort = _c[1];
    var today = new Date();
    var nextDate = new Date(today);
    nextDate.setDate(nextDate.getDate() + 14);
    var _d = (0, useAsync_1.useAsync)(function (res) { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
        var listparams, cancelToken, e_1;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    listparams = {
                        size: size.value,
                        page: page.value,
                        sort: [sort, 'id,asc']
                    };
                    cancelToken = (0, js_1.getCancelToken)();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, js_1.getAllDatevExport)(tslib_1.__assign({}, listparams), {
                            cancelToken: cancelToken.token
                        })];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    e_1 = _a.sent();
                    if (!(0, js_1.isCancel)(e_1)) {
                        console.error('ERROR listReload', e_1.message);
                        res.error = e_1;
                    }
                    return [3 /*break*/, 4];
                case 4:
                    res.status = useAsync_1.AsyncResultStatus.IGNORE;
                    return [2 /*return*/, null];
            }
        });
    }); }, [page.value, size.value, sort], true, true, false), listReloading = _d.loading, listError = _d.error, list = _d.value, listReload = _d.reload;
    var _e = tslib_1.__read((0, react_1.useState)(), 2), selectedDatevId = _e[0], setSelectedDatevId = _e[1];
    var _f = tslib_1.__read((0, react_1.useState)(), 2), cachedTable = _f[0], setCachedTable = _f[1];
    (0, react_1.useEffect)(function () {
        // console.log('setCachedTable.render()');
        var _a;
        var body;
        if (!list && listReloading) {
            body = (aurum_1.React.createElement("tr", { key: 'progress' },
                aurum_1.React.createElement("td", { colSpan: tablefields.length },
                    aurum_1.React.createElement(aurum_1.Progress, null, "Lade..."))));
        }
        else if (list) {
            body = (aurum_1.React.createElement(aurum_1.React.Fragment, null, (_a = list.content) === null || _a === void 0 ? void 0 : _a.map(function (datevExport) {
                var classNames = ['datevExport', 'clickable'];
                if (datevExport.id === selectedDatevId) {
                    classNames.push('primary');
                }
                return aurum_1.React.createElement("tr", { key: datevExport.id, onClick: function () { return setSelectedDatevId(datevExport.id); }, className: classNames.join(' ') }, tablefields.map(function (prop) {
                    var val = (datevExport[prop.name] != null ? datevExport[prop.name] : '');
                    if (prop.name === 'totalGross') {
                        val = (0, formatPrice_1.formatPrice)(datevExport.totalGross);
                    }
                    else if (prop.name === 'totalNet') {
                        val = (0, formatPrice_1.formatPrice)(datevExport.totalNet);
                    }
                    else if (prop.name === 'totalGrossInvoice') {
                        val = (0, formatPrice_1.formatPrice)(datevExport.totalGrossInvoice);
                    }
                    else if (prop.name === 'totalNetInvoice') {
                        val = (0, formatPrice_1.formatPrice)(datevExport.totalNetInvoice);
                    }
                    else if (prop.name === 'createdAt') {
                        val = (0, dateUtils_1.formatDateFull)(datevExport.createdAt);
                    }
                    else if (prop.name === 'download') {
                        val = aurum_1.React.createElement(aurum_1.Button, { type: 'primary', align: 'right', className: 'createExcelFile', onClick: function () {
                                (0, js_1.createDetailedDatevBookingFile)({
                                    exportId: datevExport.id,
                                    summarize: false
                                }, {
                                    responseType: 'blob'
                                }).then(function (response) {
                                    // setSepaError(null);
                                    // @ts-ignore
                                    var url = window.URL.createObjectURL(new Blob([response]));
                                    var link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', 'datev_export_' + (datevExport === null || datevExport === void 0 ? void 0 : datevExport.createdAt) + '.xlsx');
                                    document.body.appendChild(link);
                                    link.click();
                                }).catch(function (error) {
                                    console.log(error);
                                });
                            } },
                            aurum_1.React.createElement(aurum_1.Icon, { type: 'download', size: 'big' }),
                            t('DatevList.createDatevFile'));
                    }
                    else {
                        val = val.toString();
                    }
                    return aurum_1.React.createElement("td", { className: prop.name === 'download' ? 'datevExcelDownload' : undefined, key: prop.name }, val);
                }));
            })));
        }
        else if (listError) {
            body = (aurum_1.React.createElement("tr", { key: 'error' },
                aurum_1.React.createElement("td", { colSpan: 8 },
                    aurum_1.React.createElement(aurum_1.Alert, { className: 'datev-list-error', type: 'error' }, "Fehler beim Laden der Datev Exporte"))));
        }
        setCachedTable(aurum_1.React.createElement(aurum_1.Table, { className: 'table' },
            aurum_1.React.createElement("thead", null,
                aurum_1.React.createElement("tr", null, tablefields.map(function (prop) {
                    if (prop.name.match('download')) {
                        return aurum_1.React.createElement("th", null);
                    }
                    if ('id'.match(prop.name)) {
                        return aurum_1.React.createElement("th", { key: prop.name }, t("EntityAttribute.".concat(prop.name)));
                    }
                    var title = t("EntityAttribute.".concat(prop.name));
                    if (prop.name.startsWith('generatedFor')) {
                        title = title.split(' ').slice(0, 3).join(' ');
                    }
                    var tooltip = 'aufsteigend';
                    if (sort === "".concat(prop.name, ",asc")) {
                        tooltip = 'absteigend';
                    }
                    else if (sort === "".concat(prop.name, ",desc")) {
                        tooltip = 'aufsteigend';
                    }
                    return aurum_1.React.createElement("th", { key: prop.name, onClick: toggleSort(prop.name), className: 'clickable', title: "Klicken, um ".concat(tooltip, " nach '").concat(title, "' zu sortieren") },
                        title,
                        !(sort === "".concat(prop.name, ",asc") || sort === "".concat(prop.name, ",desc"))
                            ? aurum_1.React.createElement(aurum_1.Icon, { type: 'unfold_more' })
                            : null,
                        sort === "".concat(prop.name, ",asc") ? aurum_1.React.createElement(aurum_1.Icon, { type: 'expand_less' }) : null,
                        sort === "".concat(prop.name, ",desc") ? aurum_1.React.createElement(aurum_1.Icon, { type: 'expand_more' }) : null);
                }))),
            aurum_1.React.createElement("tbody", null, body)));
    }, [list, listError, listReloading, selectedDatevId, sort, t, tablefields, toggleSort]);
    var totalPages = (_a = list === null || list === void 0 ? void 0 : list.totalPages) !== null && _a !== void 0 ? _a : 1;
    aurum_1.React.useEffect(function () {
        setPageSelection(Array.apply(null, Array(totalPages))
            // eslint-disable-next-line react/no-array-index-key
            .map(function (_v, page) { return aurum_1.React.createElement("option", { key: page, value: page, label: (page + 1) + '/' + totalPages }); }));
    }, [totalPages]);
    return aurum_1.React.createElement("div", { className: 'DatevList' },
        aurum_1.React.createElement(aurum_1.Sheet, { className: 'list' },
            aurum_1.React.createElement("div", { className: 'title' },
                aurum_1.React.createElement("h2", null, t('DatevList.title')),
                !list
                    ? null
                    : aurum_1.React.createElement("div", { className: 'sub' }, list.totalElements === 0
                        ? t('Edit.navigation.paginationNone')
                        : t('Edit.navigation.pagination', {
                            from: list.number * list.size + 1,
                            to: Math.min(list.number * list.size + list.size, list.totalElements),
                            total: list.totalElements
                        }))),
            aurum_1.React.createElement(aurum_1.InputGroup, { className: 'navigate' },
                aurum_1.React.createElement(aurum_1.Button, { disabled: list === null || list === void 0 ? void 0 : list.first, onClick: function () { return page.set(0); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'first_page' })),
                aurum_1.React.createElement(aurum_1.Button, { disabled: list === null || list === void 0 ? void 0 : list.first, onClick: function () { return page.set(page.value - 1); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'navigate_before' })),
                aurum_1.React.createElement(aurum_1.Select, { disabled: !(pageSelection === null || pageSelection === void 0 ? void 0 : pageSelection.length), label: t('Edit.navigation.page'), value: page.inputvalue, onChange: page.onChange, options: pageSelection }),
                aurum_1.React.createElement(aurum_1.Button, { disabled: list === null || list === void 0 ? void 0 : list.last, onClick: function () { return page.set(page.value - -1); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'navigate_next' })),
                aurum_1.React.createElement(aurum_1.Button, { disabled: list === null || list === void 0 ? void 0 : list.last, onClick: function () { return page.set((list === null || list === void 0 ? void 0 : list.totalPages) - 1); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'last_page' }))),
            aurum_1.React.createElement(aurum_1.ButtonGroup, { className: 'pagesize' },
                aurum_1.React.createElement(aurum_1.Button, { onClick: function () {
                        size.set(40);
                        page.set(0);
                    }, type: size.value === 40 ? 'primary' : undefined }, "40"),
                aurum_1.React.createElement(aurum_1.Button, { onClick: function () {
                        size.set(100);
                        page.set(0);
                    }, type: size.value === 100 ? 'primary' : undefined }, "100")),
            aurum_1.React.createElement(aurum_1.Button, { className: 'reload' + (listReloading ? ' loading' : ''), disabled: listReloading, onClick: listReload },
                aurum_1.React.createElement(aurum_1.Icon, { type: 'refresh' })),
            cachedTable));
};
exports.default = (0, react_1.memo)(DatevList);
