"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLeftMenu = void 0;
var tslib_1 = require("tslib");
var isMasterData_1 = require("../../util/isMasterData");
var calcLeaves = function (children) {
    var arr = [];
    children.forEach(function (e) {
        var isArray = Array.isArray(e);
        var label = isArray ? e[0] : e;
        var leaves = isArray && Array.isArray(e[1]) ? calcLeaves(e[1]) : null;
        arr.push.apply(arr, tslib_1.__spreadArray([label], tslib_1.__read(leaves || []), false));
    });
    return arr;
};
var getLeftMenu = function (samsa, _token) {
    var generateList = function (startWith, list) {
        if (list === void 0) { list = []; }
        var masterDataList = Object.values(samsa)
            .filter(function (Type) {
            var _a;
            return (0, isMasterData_1.isMasterData)(Type) && Type.name !== 'MasterData' &&
                (((_a = Type === null || Type === void 0 ? void 0 : Type.name) === null || _a === void 0 ? void 0 : _a.startsWith(startWith)) || list.includes(Type === null || Type === void 0 ? void 0 : Type.name));
        })
            .map(function (e) { return e.name; });
        var customList = list
            .filter(function (Type) { return !samsa[Type]; });
        return tslib_1.__spreadArray(tslib_1.__spreadArray([], tslib_1.__read(masterDataList), false), tslib_1.__read(customList), false).sort(function (a, b) {
            var indexA = list.indexOf(a);
            var indexB = list.indexOf(b);
            if (indexA >= 0 && indexB >= 0) {
                return indexA - indexB;
            }
            else if (indexA >= 0) {
                return -1;
            }
            else if (indexB >= 0) {
                return 1;
            }
            return a.replace('Draft', '') < b.replace('Draft', '') ? -1 : 1;
        });
    };
    var other = [];
    var tempMenu = [
        ['dashboard', 'view_quilt'],
        ['support', 'contact_support'],
        ['publicationRequestList', 'list'],
        ['bookingList', 'view_list'],
        ['admin', [
                ['tax', [
                        'Tax',
                        'GlobalTaxKey'
                    ]],
                ['lists', [
                        'Icon',
                        'Country',
                        'Destination',
                        'PaymentProvider',
                        'MaturityType',
                        'PrivilegeType',
                        'DestinationType',
                        'DestinationBorder',
                        'TradeTermType'
                    ]],
                'AccountRole',
                'TicketTemplate',
                'ChannelManagerProtocolView',
                'resetPasswordForCustomer',
                'getSumOfUnexportedPositions',
                'bookingExportInfo',
                'salesStatistics',
                'ownerRevenues',
                'featureProblems',
                'voucherStatistics',
                'deleteAccount',
                'datevList',
                'dac7ExportList',
                'paymentList',
                'readableProtocol'
            ]],
        ['owner', [
                'OwnerPaymentsMenu',
                'registerOwner',
                'registerEmployee',
                'Owner',
                'Employee',
                'ownerConfigForm',
                'ownerConditionMerchant'
            ]],
        ['customer', generateList('Customer', [
                // 'CustomerPaymentsMenu',
                'customerContactPersonsWithBadLegitimations'
            ])],
        ['message', [
                'messageList',
                'MessageTemplate',
                'MessageTemplateType',
                'MailTemplate',
                'newsletterReceiverList',
            ]],
        ['product', generateList('Product', [
                'ProductDraft',
                'FeatureGroup',
                'FeaturePriceTaxKey',
                'FeatureProvider',
                'FeatureTaxKey'
            ])],
        ['article', generateList('Article', [])],
        ['housing', generateList('Housing', [
                ['bookingPlan', 'event_note'],
                'HousingDraft',
                'HousingPriceDraft',
                'HousingAdditionalPriceDraft',
                'HousingAdditionalPriceType',
                'HousingOptionDraft',
                'HousingOptionPriceDraft',
                'HousingCapacitySync',
                'HousingCapacityExport',
                'HousingType',
                'HousingEquipment',
                'HousingRegulation',
                'HousingAptitude',
                'HousingOptionType',
                'HousingRoomType',
                'HousingSleepingPossibility',
                'HousingFloor',
                'HousingKeyDelivery'
            ])],
        ['transportation', generateList('Transportation', [])],
        ['--renting', generateList('Renting', [])],
        ['bicycle', generateList('Bicycle', [])],
        ['--camping', generateList('Camping', [])],
        ['parkingspace', generateList('Parkingspace', [])],
        ['--parking', generateList('Parking', [])],
        ['--strandkorb', generateList('Strandkorb', [])],
        ['event', generateList('Event', [])],
        ['visitorTax', generateList('VisitorTax', [])],
        ['webhook', generateList('Webhook', [])],
        ['campersite', [
                'CampersiteDraft',
                'CampersiteObject',
                'CampersiteLocation',
                'CampersiteParkingPriceDraft',
                ['campersiteOptions', [
                        'CampersiteOptionType',
                        'CampersiteOptionDraft',
                        'CampersiteOptionPriceDraft'
                    ]]
            ]],
        ['voucher', [
                generateList('Voucher', []),
                'voucherListing'
            ]],
        ['promocode', generateList('Promocode', [])],
        ['other', other],
        ['test', [
                ['housingListingTest', 'list'],
                ['transportationListingTest', 'list']
            ]]
    ];
    var list = calcLeaves(tempMenu);
    other.push.apply(other, tslib_1.__spreadArray([], tslib_1.__read(Object.values(samsa)
        .filter(function (Type) { return (0, isMasterData_1.isMasterData)(Type) && Type.name !== 'MasterData' && !list.includes(Type.name); })
        .map(function (e) { return e.name; })
        .sort(function (a, b) {
        return a.replace('Draft', '') < b.replace('Draft', '') ? -1 : 1;
    })), false));
    return tempMenu;
};
exports.getLeftMenu = getLeftMenu;
