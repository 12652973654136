"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSort = void 0;
var tslib_1 = require("tslib");
var react_1 = require("react");
var useSort = function (initialState) {
    if (initialState === void 0) { initialState = 'id,asc'; }
    var _a = tslib_1.__read((0, react_1.useState)(initialState), 2), sort = _a[0], setSort = _a[1];
    var toggleSort = (0, react_1.useCallback)(function (base) { return function () {
        setSort(function (sort) { return sort === "".concat(base, ",asc") ? "".concat(base, ",desc") : "".concat(base, ",asc"); });
    }; }, []);
    return [sort, toggleSort];
};
exports.useSort = useSort;
