"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var aurum_1 = require("@mdsi/aurum");
var js_1 = require("@samsa/samsa-api/js");
var react_i18next_1 = require("react-i18next");
var formatPrice_1 = require("../../util/formatPrice");
var dateUtils_1 = require("../../util/dateUtils");
var ExpandableTable = function (props) {
    var entry = props.booking;
    var owners = props.owners;
    var updateCancelPositions = props.updateCancelPositions;
    var updateRebookPositions = props.updateRebookPositions;
    var cancelPosition = props.cancelPositions;
    var t = (0, react_i18next_1.useTranslation)().t;
    // stateChanger
    var _a = tslib_1.__read((0, react_1.useState)([]), 2), expandedRows = _a[0], setExpandedRows = _a[1];
    var areAllPositionsCancelled = !entry.positions.find(function (p) { return p.cancellationDate == null; });
    var nonCancelledPositions = entry.positions.filter(function (p) { return p.cancellationDate == null; });
    var areAllPositionsMarked = !areAllPositionsCancelled && !nonCancelledPositions.find(function (position) { return !cancelPosition[position.id]; });
    var getFeature = function (position, linkId) { return position.product.features.find(function (feature) { return feature.id === linkId; }).feature; };
    var getOwner = function (ownerId) { return owners.find(function (owner) { return owner.id === ownerId; }); };
    var featureTableHeader = react_1.default.createElement("h2", null, t('EntityAttribute.features'));
    var featureOptionsTableHeader = react_1.default.createElement("h2", null, t('EntityAttribute.options'));
    function canceledAddition(ele) {
        return (ele.status || ele.paymentStatus) === 'CANCELED' && ele.paymentDate && ele.cancellationDate
            ? t('BookingList.Enums.CANCELED.addition')
            : '';
    }
    function handleRowClick(rowId) {
        var isRowCurrentlyExpanded = expandedRows.includes(rowId);
        var newExpandedRows = isRowCurrentlyExpanded
            ? expandedRows.filter(function (id) { return id !== rowId; })
            : expandedRows.concat(rowId);
        setExpandedRows(newExpandedRows);
    }
    function getDetailTableBicycleOptions(featureRequest, feature) {
        return react_1.default.createElement(aurum_1.Table, { className: "bicycle-option-".concat(feature.id) },
            react_1.default.createElement("thead", null,
                react_1.default.createElement("tr", null,
                    react_1.default.createElement("th", null, t('EntityAttribute.title')),
                    react_1.default.createElement("th", null, t('PublicationList.navigation.targetType')),
                    react_1.default.createElement("th", null, t('EntityAttribute.unit')),
                    react_1.default.createElement("th", null, t('EntityAttribute.amount')),
                    react_1.default.createElement("th", null, t('EntityAttribute.fromDate')),
                    react_1.default.createElement("th", null, t('EntityAttribute.toDate')))),
            react_1.default.createElement("tbody", null, featureRequest.options.map(function (optionRequest) {
                var _a, _b, _c, _d, _e, _f;
                var option = feature.options.find(function (option) { return option.id === optionRequest.optionId; });
                return react_1.default.createElement("tr", { key: option === null || option === void 0 ? void 0 : option.id },
                    react_1.default.createElement("td", null, (_a = option === null || option === void 0 ? void 0 : option.localizations.de.title) !== null && _a !== void 0 ? _a : 'n/v'),
                    react_1.default.createElement("td", null, (_c = (_b = option === null || option === void 0 ? void 0 : option.type) === null || _b === void 0 ? void 0 : _b.localizations.de.title) !== null && _c !== void 0 ? _c : 'n/v'),
                    react_1.default.createElement("td", null, (_d = t("Enum.".concat(option.unit))) !== null && _d !== void 0 ? _d : 'n/v'),
                    react_1.default.createElement("td", null, optionRequest === null || optionRequest === void 0 ? void 0 : optionRequest.amount),
                    react_1.default.createElement("td", null, (_e = (0, dateUtils_1.formatDateYearMonthDay)(optionRequest === null || optionRequest === void 0 ? void 0 : optionRequest.from)) !== null && _e !== void 0 ? _e : 'n/v'),
                    react_1.default.createElement("td", null, (_f = (0, dateUtils_1.formatDateYearMonthDay)(optionRequest === null || optionRequest === void 0 ? void 0 : optionRequest.to)) !== null && _f !== void 0 ? _f : 'n/v'));
            })));
    }
    function getDetailTableBicycle(featureRequests, position) {
        var body = featureRequests.map(function (featureRequest) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            // @ts-ignore
            var bicycle = getFeature(position, featureRequest.linkId);
            var owner = getOwner((_a = bicycle === null || bicycle === void 0 ? void 0 : bicycle.owner) === null || _a === void 0 ? void 0 : _a.id);
            var parentRow = react_1.default.createElement("tr", { key: featureRequest === null || featureRequest === void 0 ? void 0 : featureRequest.id, className: ((_b = featureRequest.options) === null || _b === void 0 ? void 0 : _b.length) > 0 ? 'clickable' : null, onClick: function () { return handleRowClick(featureRequest === null || featureRequest === void 0 ? void 0 : featureRequest.id); } },
                react_1.default.createElement("td", null, (_e = (_d = (_c = bicycle === null || bicycle === void 0 ? void 0 : bicycle.localizations) === null || _c === void 0 ? void 0 : _c.de) === null || _d === void 0 ? void 0 : _d.title) !== null && _e !== void 0 ? _e : 'n/v'),
                react_1.default.createElement("td", null, (0, dateUtils_1.formatDateYearMonthDay)(featureRequest === null || featureRequest === void 0 ? void 0 : featureRequest.from)),
                react_1.default.createElement("td", null, (0, dateUtils_1.formatDateYearMonthDay)(featureRequest === null || featureRequest === void 0 ? void 0 : featureRequest.to)),
                react_1.default.createElement("td", null, (_f = featureRequest.cancellationConditionId) !== null && _f !== void 0 ? _f : 'n/v'),
                react_1.default.createElement("td", null, (_g = owner === null || owner === void 0 ? void 0 : owner.companyName) !== null && _g !== void 0 ? _g : 'n/v'),
                react_1.default.createElement("td", null, (_h = owner === null || owner === void 0 ? void 0 : owner.email) !== null && _h !== void 0 ? _h : 'n/v'),
                react_1.default.createElement("td", null, (_k = (_j = bicycle === null || bicycle === void 0 ? void 0 : bicycle.provider) === null || _j === void 0 ? void 0 : _j.email) !== null && _k !== void 0 ? _k : 'n/v'),
                react_1.default.createElement("td", null, (_m = (_l = bicycle === null || bicycle === void 0 ? void 0 : bicycle.provider) === null || _l === void 0 ? void 0 : _l.phone) !== null && _m !== void 0 ? _m : 'n/v'));
            // wenn der featureRequest Optionen hat und die ID in der Liste expandedRows enthalten ist
            // wird die Tabelle mit den Optionen angezeigt
            if (((_o = featureRequest.options) === null || _o === void 0 ? void 0 : _o.length) > 0 && expandedRows.includes(featureRequest.id)) {
                return react_1.default.createElement(react_1.default.Fragment, null,
                    parentRow,
                    react_1.default.createElement("tr", { key: featureRequest.id + 'options', className: 'none-hover' },
                        react_1.default.createElement("td", { colSpan: 9, className: 'feature-options-table' },
                            react_1.default.createElement(react_1.default.Fragment, null,
                                featureOptionsTableHeader,
                                getDetailTableBicycleOptions(featureRequest, bicycle)))));
            }
            // gibt es keine Optionen, bzw. die ID ist nicht in expandedRows enthalten, wird nur die Zeile
            // zurück gegeben
            return parentRow;
        });
        return react_1.default.createElement(aurum_1.Table, null,
            react_1.default.createElement("thead", null,
                react_1.default.createElement("tr", null,
                    react_1.default.createElement("th", null, t('EntityAttribute.title')),
                    react_1.default.createElement("th", null, t('EntityAttribute.fromDate')),
                    react_1.default.createElement("th", null, t('EntityAttribute.toDate')),
                    react_1.default.createElement("th", null, t('Entity.CancellationCondition')),
                    react_1.default.createElement("th", null,
                        t('EntityAttribute.companyName'),
                        " (",
                        t('EntityAttribute.owner'),
                        ")"),
                    react_1.default.createElement("th", null,
                        t('EntityAttribute.email'),
                        " (",
                        t('EntityAttribute.owner'),
                        ")"),
                    react_1.default.createElement("th", null,
                        t('EntityAttribute.email'),
                        " (",
                        t('EntityAttribute.provider'),
                        ")"),
                    react_1.default.createElement("th", null,
                        t('EntityAttribute.phone'),
                        " (",
                        t('EntityAttribute.provider'),
                        ")"))),
            react_1.default.createElement("tbody", null, body));
    }
    function getDetailTableEventOptions(featureRequest, feature) {
        return react_1.default.createElement(aurum_1.Table, { className: "event-option-".concat(feature.id) },
            react_1.default.createElement("thead", null,
                react_1.default.createElement("tr", null,
                    react_1.default.createElement("th", null, t('EntityAttribute.title')),
                    react_1.default.createElement("th", null, t('EntityAttribute.unit')))),
            react_1.default.createElement("tbody", null, featureRequest.options.map(function (optionRequest) {
                var _a, _b;
                var option = feature.options.find(function (option) { return option.id === optionRequest.optionId; });
                return react_1.default.createElement("tr", { key: option === null || option === void 0 ? void 0 : option.id },
                    react_1.default.createElement("td", null, (_a = option === null || option === void 0 ? void 0 : option.localizations.de.title) !== null && _a !== void 0 ? _a : 'n/v'),
                    react_1.default.createElement("td", null, (_b = t("Enum.".concat(option.unit))) !== null && _b !== void 0 ? _b : 'n/v'));
            })));
    }
    function getDetailTableEvent(featureRequests, position) {
        var body = featureRequests.map(function (featureRequest) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
            // @ts-ignore
            var feature = getFeature(position, featureRequest.linkId);
            var owner = getOwner((_a = feature === null || feature === void 0 ? void 0 : feature.owner) === null || _a === void 0 ? void 0 : _a.id);
            var parentRow = react_1.default.createElement("tr", { key: featureRequest === null || featureRequest === void 0 ? void 0 : featureRequest.id, className: ((_b = featureRequest.options) === null || _b === void 0 ? void 0 : _b.length) > 0 ? 'clickable' : null, onClick: function () { return handleRowClick(featureRequest === null || featureRequest === void 0 ? void 0 : featureRequest.id); } },
                react_1.default.createElement("td", null, (_e = (_d = (_c = feature === null || feature === void 0 ? void 0 : feature.localizations) === null || _c === void 0 ? void 0 : _c.de) === null || _d === void 0 ? void 0 : _d.title) !== null && _e !== void 0 ? _e : 'n/v'),
                react_1.default.createElement("td", null, (_j = (_h = (_g = (_f = feature === null || feature === void 0 ? void 0 : feature.location) === null || _f === void 0 ? void 0 : _f.localizations) === null || _g === void 0 ? void 0 : _g.de) === null || _h === void 0 ? void 0 : _h.title) !== null && _j !== void 0 ? _j : 'n/v'),
                react_1.default.createElement("td", null, (_o = (_m = (_l = (_k = feature === null || feature === void 0 ? void 0 : feature.meetingArea) === null || _k === void 0 ? void 0 : _k.localizations) === null || _l === void 0 ? void 0 : _l.de) === null || _m === void 0 ? void 0 : _m.title) !== null && _o !== void 0 ? _o : 'n/v'),
                react_1.default.createElement("td", null, (_p = featureRequest === null || featureRequest === void 0 ? void 0 : featureRequest.cancellationConditionId) !== null && _p !== void 0 ? _p : 'n/v'),
                react_1.default.createElement("td", null, (_q = owner === null || owner === void 0 ? void 0 : owner.companyName) !== null && _q !== void 0 ? _q : 'n/v'),
                react_1.default.createElement("td", null, (_r = owner === null || owner === void 0 ? void 0 : owner.email) !== null && _r !== void 0 ? _r : 'n/v'),
                react_1.default.createElement("td", null, (_t = (_s = feature === null || feature === void 0 ? void 0 : feature.provider) === null || _s === void 0 ? void 0 : _s.email) !== null && _t !== void 0 ? _t : 'n/v'),
                react_1.default.createElement("td", null, (_v = (_u = feature === null || feature === void 0 ? void 0 : feature.provider) === null || _u === void 0 ? void 0 : _u.phone) !== null && _v !== void 0 ? _v : 'n/v'));
            // wenn der featureRequest Optionen hat und die ID in der Liste expandedRows enthalten ist
            // wird die Tabelle mit den Optionen angezeigt
            if (((_w = featureRequest.options) === null || _w === void 0 ? void 0 : _w.length) > 0 && expandedRows.includes(featureRequest.id)) {
                return react_1.default.createElement(react_1.default.Fragment, null,
                    parentRow,
                    react_1.default.createElement("tr", { key: featureRequest.id + 'options', className: 'none-hover' },
                        react_1.default.createElement("td", { colSpan: 9, className: 'feature-options-table' },
                            react_1.default.createElement(react_1.default.Fragment, null,
                                featureOptionsTableHeader,
                                getDetailTableEventOptions(featureRequest, feature)))));
            }
            // gibt es keine Optionen, bzw. die ID ist nicht in expandedRows enthalten, wird nur die Zeile
            // zurück gegeben
            return parentRow;
        });
        return react_1.default.createElement(aurum_1.Table, null,
            react_1.default.createElement("thead", null,
                react_1.default.createElement("tr", null,
                    react_1.default.createElement("th", null, t('EntityAttribute.title')),
                    react_1.default.createElement("th", null, t('Entity.EventLocation')),
                    react_1.default.createElement("th", null, t('EntityAttribute.meetingArea')),
                    react_1.default.createElement("th", null, t('Entity.CancellationCondition')),
                    react_1.default.createElement("th", null,
                        t('EntityAttribute.companyName'),
                        " (",
                        t('EntityAttribute.owner'),
                        ")"),
                    react_1.default.createElement("th", null,
                        t('EntityAttribute.email'),
                        " (",
                        t('EntityAttribute.owner'),
                        ")"),
                    react_1.default.createElement("th", null,
                        t('EntityAttribute.email'),
                        " (",
                        t('EntityAttribute.provider'),
                        ")"),
                    react_1.default.createElement("th", null,
                        t('EntityAttribute.phone'),
                        " (",
                        t('EntityAttribute.provider'),
                        ")"))),
            react_1.default.createElement("tbody", null, body));
    }
    function getDetailTableTransportation(featureRequests, position) {
        return react_1.default.createElement(aurum_1.Table, null,
            react_1.default.createElement("thead", null,
                react_1.default.createElement("tr", null,
                    react_1.default.createElement("th", null, t('EntityAttribute.title')),
                    react_1.default.createElement("th", null, t('EntityAttribute.transportationType')),
                    react_1.default.createElement("th", null, t('EntityAttribute.order')),
                    react_1.default.createElement("th", null, t('EntityAttribute.route')),
                    react_1.default.createElement("th", null, t('Entity.FeatureProvider')))),
            react_1.default.createElement("tbody", null, featureRequests
                .sort(function (a, b) { return a.order < b.order ? -1 : 1; })
                .map(function (featureRequest) {
                var _a, _b, _c, _d, _e;
                // @ts-ignore
                var feature = getFeature(position, featureRequest.linkId);
                return react_1.default.createElement("tr", { key: featureRequest.id },
                    react_1.default.createElement("td", null, (_a = feature.localizations.de.title) !== null && _a !== void 0 ? _a : 'n/v'),
                    react_1.default.createElement("td", null, (_b = t('App.Menu.transportation')) !== null && _b !== void 0 ? _b : 'n/v'),
                    react_1.default.createElement("td", null, (_c = featureRequest.order) !== null && _c !== void 0 ? _c : 'n/v'),
                    react_1.default.createElement("td", null, (_d = feature.route.localizations.de.title) !== null && _d !== void 0 ? _d : 'n/v'),
                    react_1.default.createElement("td", null, (_e = feature.provider.localizations.de.title) !== null && _e !== void 0 ? _e : 'n/v'));
            })));
    }
    function getDetailTableHousingOptions(featureRequest, feature) {
        return react_1.default.createElement(aurum_1.Table, { className: "housing-option-".concat(feature.id) },
            react_1.default.createElement("thead", null,
                react_1.default.createElement("tr", null,
                    react_1.default.createElement("th", null, t('EntityAttribute.title')),
                    react_1.default.createElement("th", null, t('PublicationList.navigation.targetType')),
                    react_1.default.createElement("th", null, t('EntityAttribute.unit')),
                    react_1.default.createElement("th", null, t('EntityAttribute.amount')),
                    react_1.default.createElement("th", null, t('EntityAttribute.fromDate')),
                    react_1.default.createElement("th", null, t('EntityAttribute.toDate')))),
            react_1.default.createElement("tbody", null, featureRequest.options.map(function (optionRequest) {
                var _a, _b, _c, _d, _e, _f;
                var option = feature.options.find(function (option) { return option.id === optionRequest.optionId; });
                return react_1.default.createElement("tr", { key: option === null || option === void 0 ? void 0 : option.id },
                    react_1.default.createElement("td", null, (_a = option === null || option === void 0 ? void 0 : option.localizations.de.title) !== null && _a !== void 0 ? _a : 'n/v'),
                    react_1.default.createElement("td", null, (_c = (_b = option === null || option === void 0 ? void 0 : option.type) === null || _b === void 0 ? void 0 : _b.localizations.de.title) !== null && _c !== void 0 ? _c : 'n/v'),
                    react_1.default.createElement("td", null, (_d = t("Enum.".concat(option.unit))) !== null && _d !== void 0 ? _d : 'n/v'),
                    react_1.default.createElement("td", null, optionRequest === null || optionRequest === void 0 ? void 0 : optionRequest.amount),
                    react_1.default.createElement("td", null, (_e = (0, dateUtils_1.formatDateYearMonthDay)(optionRequest === null || optionRequest === void 0 ? void 0 : optionRequest.from)) !== null && _e !== void 0 ? _e : 'n/v'),
                    react_1.default.createElement("td", null, (_f = (0, dateUtils_1.formatDateYearMonthDay)(optionRequest === null || optionRequest === void 0 ? void 0 : optionRequest.to)) !== null && _f !== void 0 ? _f : 'n/v'));
            })));
    }
    function getDetailTableHousing(featureRequests, position) {
        var body = featureRequests.map(function (featureRequest) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
            // @ts-ignore
            var feature = getFeature(position, featureRequest.linkId);
            var owner = getOwner((_a = feature === null || feature === void 0 ? void 0 : feature.owner) === null || _a === void 0 ? void 0 : _a.id);
            var parentRow = react_1.default.createElement("tr", { key: featureRequest === null || featureRequest === void 0 ? void 0 : featureRequest.id, className: ((_b = featureRequest.options) === null || _b === void 0 ? void 0 : _b.length) > 0 ? 'clickable' : null, onClick: function () { return handleRowClick(featureRequest === null || featureRequest === void 0 ? void 0 : featureRequest.id); } },
                react_1.default.createElement("td", null, (_e = (_d = (_c = feature === null || feature === void 0 ? void 0 : feature.localizations) === null || _c === void 0 ? void 0 : _c.de) === null || _d === void 0 ? void 0 : _d.title) !== null && _e !== void 0 ? _e : 'n/v'),
                react_1.default.createElement("td", null, (_j = (_h = (_g = (_f = feature === null || feature === void 0 ? void 0 : feature.type) === null || _f === void 0 ? void 0 : _f.localizations) === null || _g === void 0 ? void 0 : _g.de) === null || _h === void 0 ? void 0 : _h.title) !== null && _j !== void 0 ? _j : 'n/v'),
                react_1.default.createElement("td", null, (_l = (_k = feature === null || feature === void 0 ? void 0 : feature.address) === null || _k === void 0 ? void 0 : _k.locality) !== null && _l !== void 0 ? _l : 'n/v'),
                react_1.default.createElement("td", null,
                    (0, dateUtils_1.formatDateYearMonthDay)(featureRequest === null || featureRequest === void 0 ? void 0 : featureRequest.from),
                    " ", (_m = featureRequest === null || featureRequest === void 0 ? void 0 : featureRequest.checkIn) !== null && _m !== void 0 ? _m : 'n/v'),
                react_1.default.createElement("td", null,
                    (0, dateUtils_1.formatDateYearMonthDay)(featureRequest === null || featureRequest === void 0 ? void 0 : featureRequest.to),
                    " ", (_o = featureRequest === null || featureRequest === void 0 ? void 0 : featureRequest.checkOut) !== null && _o !== void 0 ? _o : 'n/v'),
                react_1.default.createElement("td", null, (_p = featureRequest.cancellationConditionId) !== null && _p !== void 0 ? _p : 'n/v'),
                react_1.default.createElement("td", null, (_q = owner === null || owner === void 0 ? void 0 : owner.companyName) !== null && _q !== void 0 ? _q : 'n/v'),
                react_1.default.createElement("td", null, (_r = owner === null || owner === void 0 ? void 0 : owner.email) !== null && _r !== void 0 ? _r : 'n/v'),
                react_1.default.createElement("td", null, (_t = (_s = feature === null || feature === void 0 ? void 0 : feature.provider) === null || _s === void 0 ? void 0 : _s.email) !== null && _t !== void 0 ? _t : 'n/v'),
                react_1.default.createElement("td", null, (_v = (_u = feature === null || feature === void 0 ? void 0 : feature.provider) === null || _u === void 0 ? void 0 : _u.phone) !== null && _v !== void 0 ? _v : 'n/v'));
            // wenn der featureRequest Optionen hat und die ID in der Liste expandedRows enthalten ist
            // wird die Tabelle mit den Optionen angezeigt
            if (((_w = featureRequest.options) === null || _w === void 0 ? void 0 : _w.length) > 0 && expandedRows.includes(featureRequest.id)) {
                return react_1.default.createElement(react_1.default.Fragment, null,
                    parentRow,
                    react_1.default.createElement("tr", { key: featureRequest.id + 'options', className: 'none-hover' },
                        react_1.default.createElement("td", { colSpan: 9, className: 'feature-options-table' },
                            react_1.default.createElement(react_1.default.Fragment, null,
                                featureOptionsTableHeader,
                                getDetailTableHousingOptions(featureRequest, feature)))));
            }
            // gibt es keine Optionen, bzw. die ID ist nicht in expandedRows enthalten, wird nur die Zeile
            // zurück gegeben
            return parentRow;
        });
        return react_1.default.createElement(aurum_1.Table, null,
            react_1.default.createElement("thead", null,
                react_1.default.createElement("tr", null,
                    react_1.default.createElement("th", null, t('EntityAttribute.title')),
                    react_1.default.createElement("th", null, "Typ"),
                    react_1.default.createElement("th", null, t('EntityAttribute.locality')),
                    react_1.default.createElement("th", null, t('EntityAttribute.fromDate')),
                    react_1.default.createElement("th", null, t('EntityAttribute.toDate')),
                    react_1.default.createElement("th", null, t('Entity.CancellationCondition')),
                    react_1.default.createElement("th", null,
                        t('EntityAttribute.companyName'),
                        " (",
                        t('EntityAttribute.owner'),
                        ")"),
                    react_1.default.createElement("th", null,
                        t('EntityAttribute.email'),
                        " (",
                        t('EntityAttribute.owner'),
                        ")"),
                    react_1.default.createElement("th", null,
                        t('EntityAttribute.email'),
                        " (",
                        t('EntityAttribute.provider'),
                        ")"),
                    react_1.default.createElement("th", null,
                        t('EntityAttribute.phone'),
                        " (",
                        t('EntityAttribute.provider'),
                        ")"))),
            react_1.default.createElement("tbody", null, body));
    }
    function getTableFeatureRequest(p) {
        // wenn die parentId (= Positionsid) nicht in der Liste ist, bleiben die Details geschlossen/versteckt
        if (!expandedRows.includes(p.id)) {
            return null;
        }
        // groupedRequests = [discriminator, FeatureRequest[]]
        var groupedRequests = p.request.features.reduce(function (arr, request) {
            if (!arr[request.discriminator]) {
                arr[request.discriminator] = [];
            }
            arr[request.discriminator].push(request);
            return arr;
        }, {});
        return Object.entries(groupedRequests).map(function (_a) {
            var _b = tslib_1.__read(_a, 2), k = _b[0], v = _b[1];
            var sorted = v.sort(function (a, b) {
                return a.order < b.order ? -1 : 1;
            });
            var detailTable;
            switch (k) {
                case 'HousingRequest':
                    detailTable = getDetailTableHousing(sorted, p);
                    break;
                case 'TransportationRequest':
                    detailTable = getDetailTableTransportation(sorted, p);
                    break;
                case 'BicycleRequest':
                    detailTable = getDetailTableBicycle(sorted, p);
                    break;
                case 'EventRequest':
                    detailTable = getDetailTableEvent(sorted, p);
                    break;
                default:
                    return null;
            }
            return react_1.default.createElement("td", { key: k, className: 'feature-table', colSpan: 11 },
                react_1.default.createElement(react_1.default.Fragment, null,
                    featureTableHeader,
                    detailTable));
        }).filter(function (e) { return !!e; });
    }
    function getParkingspaceTitle(p) {
        var _a, _b;
        return (_b = (_a = p.request.features.filter(function (r) { return r instanceof js_1.ParkingspaceRequest; })
            .map(function (r) { return r; })
            .map(function (r) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            var parkingspaceLink = (_a = p.product.features) === null || _a === void 0 ? void 0 : _a.find(function (feature) { return feature.id === r.linkId; });
            var parkingspaceLocations = (_c = (_b = parkingspaceLink === null || parkingspaceLink === void 0 ? void 0 : parkingspaceLink.feature) === null || _b === void 0 ? void 0 : _b.locations) === null || _c === void 0 ? void 0 : _c.filter(function (value) { return (value === null || value === void 0 ? void 0 : value.id) === (r === null || r === void 0 ? void 0 : r.parkingspaceLocationId); });
            var text;
            if (parkingspaceLocations.length) {
                text = parkingspaceLocations.map(function (e) { var _a, _b; return (_b = (_a = e === null || e === void 0 ? void 0 : e.localizations) === null || _a === void 0 ? void 0 : _a.de) === null || _b === void 0 ? void 0 : _b.title; }).sort().join(' | ');
            }
            else {
                text = (_e = (_d = parkingspaceLink === null || parkingspaceLink === void 0 ? void 0 : parkingspaceLink.feature) === null || _d === void 0 ? void 0 : _d.locations) === null || _e === void 0 ? void 0 : _e.map(function (e) { var _a, _b; return (_b = (_a = e === null || e === void 0 ? void 0 : e.localizations) === null || _a === void 0 ? void 0 : _a.de) === null || _b === void 0 ? void 0 : _b.title; }).sort().join(' | ');
            }
            return ' (' + text + ' | ' + (parkingspaceLocations[0] ? (_g = (_f = parkingspaceLocations[0]) === null || _f === void 0 ? void 0 : _f.address) === null || _g === void 0 ? void 0 : _g.locality : (_k = (_j = (_h = parkingspaceLink === null || parkingspaceLink === void 0 ? void 0 : parkingspaceLink.feature) === null || _h === void 0 ? void 0 : _h.locations[0]) === null || _j === void 0 ? void 0 : _j.address) === null || _k === void 0 ? void 0 : _k.locality) + ')';
        })) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : '';
    }
    function getTableRowPosition(p) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        var className = null;
        if ((_a = cancelPosition[p.id]) !== null && _a !== void 0 ? _a : false) {
            className = 'primary';
        }
        else if (p.paymentStatus === js_1.BookingPosition.enum.paymentStatus.PENDING) {
            className = 'info';
        }
        else if (p.paymentStatus === js_1.BookingPosition.enum.paymentStatus.CANCEL) {
            className = 'warn';
        }
        else if (p.paymentStatus === js_1.BookingPosition.enum.paymentStatus.CANCELED) {
            className = 'error';
        }
        var customData = (_c = (_b = p.request) === null || _b === void 0 ? void 0 : _b.custom) === null || _c === void 0 ? void 0 : _c.data;
        var externalRef = null;
        if (customData === null || customData === void 0 ? void 0 : customData.r2ref) {
            if ((_d = customData === null || customData === void 0 ? void 0 : customData.r2ref) === null || _d === void 0 ? void 0 : _d.reservid) {
                externalRef = 'R2-' + ((_e = customData === null || customData === void 0 ? void 0 : customData.r2ref) === null || _e === void 0 ? void 0 : _e.reservid) + '/' + ((_f = customData === null || customData === void 0 ? void 0 : customData.r2ref) === null || _f === void 0 ? void 0 : _f.lfdnr) + (((_g = customData === null || customData === void 0 ? void 0 : customData.r2ref) === null || _g === void 0 ? void 0 : _g.ticket) ? ' / ' + ((_h = customData === null || customData === void 0 ? void 0 : customData.r2ref) === null || _h === void 0 ? void 0 : _h.ticket) : '');
            }
            else if ((_j = customData === null || customData === void 0 ? void 0 : customData.r2ref) === null || _j === void 0 ? void 0 : _j.length) {
                var refs_1 = [];
                (_l = (_k = customData === null || customData === void 0 ? void 0 : customData.r2ref) === null || _k === void 0 ? void 0 : _k.forEach) === null || _l === void 0 ? void 0 : _l.call(_k, function (r2ref) {
                    refs_1.push('R2-' + (r2ref === null || r2ref === void 0 ? void 0 : r2ref.reservid) + '/' + (r2ref === null || r2ref === void 0 ? void 0 : r2ref.lfdnr) + ((r2ref === null || r2ref === void 0 ? void 0 : r2ref.ticket) ? ' / ' + (r2ref === null || r2ref === void 0 ? void 0 : r2ref.ticket) : ''));
                });
                externalRef = refs_1.join('\n');
            }
            else {
                externalRef = 'R2-FEHLER';
            }
        }
        else if (customData === null || customData === void 0 ? void 0 : customData.f1ref) {
            if ((_m = customData === null || customData === void 0 ? void 0 : customData.f1ref) === null || _m === void 0 ? void 0 : _m.buchung) {
                externalRef = 'F1-' + ((_o = customData === null || customData === void 0 ? void 0 : customData.f1ref) === null || _o === void 0 ? void 0 : _o.buchung) + (((_p = customData === null || customData === void 0 ? void 0 : customData.f1ref) === null || _p === void 0 ? void 0 : _p.ticket) ? ' / ' + ((_q = customData === null || customData === void 0 ? void 0 : customData.f1ref) === null || _q === void 0 ? void 0 : _q.ticket) : '');
            }
            else {
                externalRef = 'F1-FEHLER';
            }
        }
        else if (customData === null || customData === void 0 ? void 0 : customData.teref) {
            if ((_r = customData === null || customData === void 0 ? void 0 : customData.teref) === null || _r === void 0 ? void 0 : _r.booking) {
                externalRef = 'TE-' + ((_s = customData === null || customData === void 0 ? void 0 : customData.teref) === null || _s === void 0 ? void 0 : _s.booking) + (((_t = customData === null || customData === void 0 ? void 0 : customData.teref) === null || _t === void 0 ? void 0 : _t.ticket) ? ' / ' + ((_u = customData === null || customData === void 0 ? void 0 : customData.teref) === null || _u === void 0 ? void 0 : _u.ticket) : '');
            }
            else {
                externalRef = 'TE-FEHLER';
            }
        }
        var titleAddendum = '';
        var acquiredCodes = p.request.features.filter(function (r) { return r instanceof js_1.VoucherAcquireRequest; })
            .map(function (r) { return r; })
            .map(function (r) { return r.generatedCode; });
        var redeemedCodes = p.request.features.filter(function (r) { return r instanceof js_1.VoucherRedeemRequest; })
            .map(function (r) { return r; })
            .map(function (r) { return r.objectId; });
        var codes = tslib_1.__spreadArray(tslib_1.__spreadArray([], tslib_1.__read(acquiredCodes), false), tslib_1.__read(redeemedCodes), false);
        if (codes.length) {
            titleAddendum = " (".concat(codes.join(', '), ")");
        }
        var parent = react_1.default.createElement("tr", { key: p.id, className: className + ' clickable' },
            react_1.default.createElement("td", { className: 'position-checkbox' + (p.cancellationDate != null ? ' disabled' : '') },
                react_1.default.createElement(aurum_1.Input, { type: 'checkbox', label: '', disabled: p.cancellationDate != null, value: (_v = cancelPosition[p.id]) !== null && _v !== void 0 ? _v : false, onChange: function (event) {
                        var _a, _b, _c;
                        var checked = event.target.checked;
                        updateCancelPositions(function (state) {
                            var _a;
                            return tslib_1.__assign(tslib_1.__assign({}, state), (_a = {}, _a[p.id] = checked, _a));
                        });
                        var count = (_c = (_b = (_a = p === null || p === void 0 ? void 0 : p.request) === null || _a === void 0 ? void 0 : _a.features) === null || _b === void 0 ? void 0 : _b.filter(function (f) { return f instanceof js_1.TransportationRequest; })) === null || _c === void 0 ? void 0 : _c.length;
                        if (count > 0) {
                            updateRebookPositions(function (state) {
                                var _a;
                                return tslib_1.__assign(tslib_1.__assign({}, state), (_a = {}, _a[p.id] = checked, _a));
                            });
                        }
                    } })),
            react_1.default.createElement("td", { onClick: function () { return handleRowClick(p.id); } },
                p.product.localizations.de.title + getParkingspaceTitle(p) + titleAddendum,
                react_1.default.createElement("br", null),
                Array.from(new Set(p.request.features.map(function (fr) { return fr.yieldManagementComment; }).filter(function (c) { return !!c; })))
                    .map(function (c) { return react_1.default.createElement("div", { key: c, className: 'badge' }, c); })),
            react_1.default.createElement("td", { onClick: function () { return handleRowClick(p.id); }, style: { whiteSpace: 'pre' } }, externalRef !== null && externalRef !== void 0 ? externalRef : 'n/v'),
            react_1.default.createElement("td", { onClick: function () { return handleRowClick(p.id); } }, (0, formatPrice_1.formatPrice)(p.price.price)),
            react_1.default.createElement("td", { onClick: function () { return handleRowClick(p.id); } }, (0, formatPrice_1.formatPrice)(p.paid)),
            react_1.default.createElement("td", { onClick: function () { return handleRowClick(p.id); } },
                t('BookingList.Enums.' + p.paymentStatus),
                " ",
                canceledAddition(p)),
            react_1.default.createElement("td", { onClick: function () { return handleRowClick(p.id); } }, (0, dateUtils_1.formatDateFull)(p.paymentDate)),
            react_1.default.createElement("td", { onClick: function () { return handleRowClick(p.id); } }, (0, dateUtils_1.formatDateFull)(p.bookingDate)),
            (0, dateUtils_1.formatDateFull)(p.request.from) === (0, dateUtils_1.formatDateFull)(p.request.to)
                ? react_1.default.createElement("td", { onClick: function () { return handleRowClick(p.id); } }, (0, dateUtils_1.formatDateFull)(p.request.from))
                : react_1.default.createElement("td", { onClick: function () { return handleRowClick(p.id); } },
                    (0, dateUtils_1.formatDateFull)(p.request.from),
                    " - ",
                    (0, dateUtils_1.formatDateFull)(p.request.to)));
        var subTable = getTableFeatureRequest(p);
        return react_1.default.createElement(react_1.default.Fragment, { key: p.id + '-fragment' },
            parent,
            react_1.default.createElement("tr", { key: p.id + 'details', className: 'none-hover' }, subTable));
    }
    function createTableBody() {
        var rows = [];
        entry.positions.forEach(function (p) {
            rows.push(getTableRowPosition(p));
        });
        return react_1.default.createElement("tbody", null, rows);
    }
    function handleGlobalCheckbox(event) {
        var checked = !!event.target.checked;
        var newCancellationState = {};
        var newRebookingState = {};
        nonCancelledPositions.forEach(function (p) {
            var _a, _b;
            var _c, _d, _e;
            // handle cancellation
            newCancellationState = tslib_1.__assign(tslib_1.__assign({}, newCancellationState), (_a = {}, _a[p.id] = checked, _a));
            // handle rebooking
            var count = (_e = (_d = (_c = p === null || p === void 0 ? void 0 : p.request) === null || _c === void 0 ? void 0 : _c.features) === null || _d === void 0 ? void 0 : _d.filter(function (f) { return f instanceof js_1.TransportationRequest; })) === null || _e === void 0 ? void 0 : _e.length;
            if (count > 0) {
                newRebookingState = tslib_1.__assign(tslib_1.__assign({}, newRebookingState), (_b = {}, _b[p.id] = checked, _b));
            }
        });
        updateCancelPositions(newCancellationState);
        updateRebookPositions(newRebookingState);
    }
    return react_1.default.createElement(aurum_1.Table, null,
        react_1.default.createElement("thead", null,
            react_1.default.createElement("tr", null,
                react_1.default.createElement("th", { className: 'position-checkbox' + (areAllPositionsCancelled ? ' disabled' : '') },
                    react_1.default.createElement(aurum_1.Input, { type: 'checkbox', label: '', disabled: areAllPositionsCancelled, value: areAllPositionsMarked, onChange: function (event) { return handleGlobalCheckbox(event); } })),
                react_1.default.createElement("th", null, t('EntityAttribute.title')),
                react_1.default.createElement("th", null, t('BookingList.externalSystem')),
                react_1.default.createElement("th", null, t('EntityAttribute.totalGrossPrice')),
                react_1.default.createElement("th", null, t('EntityAttribute.paid')),
                react_1.default.createElement("th", null, t('EntityAttribute.paymentStatus')),
                react_1.default.createElement("th", null, t('EntityAttribute.paymentDate')),
                react_1.default.createElement("th", null, t('EntityAttribute.bookingDate')),
                react_1.default.createElement("th", null, t('EntityAttribute.travelTimespan')))),
        createTableBody());
};
exports.default = react_1.default.memo(ExpandableTable);
