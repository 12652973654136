"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Address = void 0;
exports.Address = {
    split: true,
    table: [
        'streetAddress',
        { name: 'extendedAddress', show: false },
        { name: 'country', show: false },
        'postalCode',
        'locality',
        { name: 'region', show: false },
        { name: 'postOfficeBox', show: false },
        { name: 'geoPoint', show: false }
    ],
    form: [
        'streetAddress',
        'extendedAddress',
        'country',
        'postalCode',
        'locality',
        'region',
        'postOfficeBox',
        'geoPoint'
    ]
};
