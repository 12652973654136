"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isZoomable = void 0;
var tslib_1 = require("tslib");
var samsa = tslib_1.__importStar(require("@samsa/samsa-api/js"));
var isMasterData_1 = require("./isMasterData");
var isZoomable = function (Type) {
    var _a, _b, _c, _d;
    if ((_a = Type === null || Type === void 0 ? void 0 : Type.definition) === null || _a === void 0 ? void 0 : _a.subTypes) {
        return (_d = (_c = (_b = Type === null || Type === void 0 ? void 0 : Type.definition) === null || _b === void 0 ? void 0 : _b.subTypes) === null || _c === void 0 ? void 0 : _c.some) === null || _d === void 0 ? void 0 : _d.call(_c, function (subTypeName) {
            var subType = samsa[subTypeName];
            return (0, exports.isZoomable)(subType);
        });
    }
    return (0, isMasterData_1.isMasterData)(Type);
};
exports.isZoomable = isZoomable;
