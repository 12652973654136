"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useElementSize = void 0;
var tslib_1 = require("tslib");
var useObservedRef_1 = require("./useObservedRef");
var react_1 = require("react");
var defaultSize = { width: 0, height: 0, columns: null, rows: null };
var useElementSize = function () {
    var _a = tslib_1.__read((0, react_1.useState)(defaultSize), 2), size = _a[0], setSize = _a[1];
    var createObserver = (0, react_1.useCallback)(function () {
        // @ts-ignore
        return new window.ResizeObserver(function (_a) {
            var _b = tslib_1.__read(_a, 1), entry = _b[0];
            var columns = null;
            var rows = null;
            if (window.getComputedStyle) {
                var children = tslib_1.__spreadArray([], tslib_1.__read(entry.target.children), false);
                var displays_1 = children.map(function (child) {
                    var before = child.style.display;
                    child.style.display = 'none';
                    return before;
                });
                var _c = window.getComputedStyle(entry.target), gridTemplateColumns = _c.gridTemplateColumns, gridTemplateRows = _c.gridTemplateRows;
                children.forEach(function (child, key) {
                    child.style.display = displays_1[key];
                });
                if (gridTemplateColumns && gridTemplateColumns !== '' && gridTemplateColumns !== 'none') {
                    columns = gridTemplateColumns.split(' ').length;
                }
                if (gridTemplateRows && gridTemplateRows !== '' && gridTemplateRows !== 'none') {
                    rows = gridTemplateRows.split(' ').length;
                }
            }
            setSize({
                width: entry.contentRect.width,
                height: entry.contentRect.height,
                columns: columns,
                rows: rows
            });
        });
    }, []);
    var reset = (0, react_1.useCallback)(function () {
        setSize(defaultSize);
    }, []);
    var ref = (0, useObservedRef_1.useObservedRef)({ createObserver: createObserver, reset: reset });
    return { size: size, ref: ref };
};
exports.useElementSize = useElementSize;
