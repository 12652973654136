"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var js_1 = require("@samsa/samsa-api/js");
var aurum_1 = require("@mdsi/aurum");
var dateUtils_1 = require("../../util/dateUtils");
var formatPrice_1 = require("../../util/formatPrice");
var sumRows = 5;
var VoucherListing = function (_a) {
    var objectInfo = _a.objectInfo, setModal = _a.setModal;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _b = tslib_1.__read((0, react_1.useState)(false), 2), loadingData = _b[0], setLoadingData = _b[1];
    var _c = tslib_1.__read((0, react_1.useState)(), 2), data = _c[0], setData = _c[1];
    var loadData = (0, react_1.useCallback)(function () {
        setLoadingData(true);
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var r, e_1;
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        if (!objectInfo.object.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, (0, js_1.getRedemtionHistory)((_a = objectInfo.object) === null || _a === void 0 ? void 0 : _a.id)];
                    case 1:
                        r = _b.sent();
                        setData(r);
                        _b.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        console.log('loadData error');
                        console.error(e_1);
                        return [3 /*break*/, 4];
                    case 4:
                        setLoadingData(false);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    (0, react_1.useEffect)(loadData, [loadData]);
    var historyEmpty = !objectInfo.object.history.length
        ? react_1.default.createElement("tr", null,
            react_1.default.createElement("td", { colSpan: sumRows }, t('Voucher.modal.emptyRedemptionHistory')))
        : null;
    var getArt = function (value) { return value > 0
        ? t('BookingList.Enums.REDEEMED')
        : t('BookingList.Enums.REDEEMED_REVERSE'); };
    var body = data ? data.map(function (it) { return react_1.default.createElement("tr", { key: objectInfo.object.id + it.bookingShortId },
        react_1.default.createElement("td", null, (0, dateUtils_1.formatDateFull)(it.redemptionAt)),
        react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(it.redeemedValue)),
        react_1.default.createElement("td", null, getArt(it.redeemedValue)),
        react_1.default.createElement("td", null, it.bookingShortId),
        react_1.default.createElement("td", null, it.customerName)); }) : (loadingData ? react_1.default.createElement("tr", null,
        react_1.default.createElement("td", { colSpan: sumRows },
            react_1.default.createElement(aurum_1.Progress, null))) : null);
    return (react_1.default.createElement(aurum_1.Modal, { className: 'voucher-redemption-history', onClickBackdrop: function () { return setModal(null); } },
        react_1.default.createElement(aurum_1.Header, null,
            react_1.default.createElement("h2", null, t('Voucher.modal.redeemedHistory')),
            react_1.default.createElement("div", { className: 'sub' },
                react_1.default.createElement("div", { className: "column" },
                    react_1.default.createElement("p", null, objectInfo.object.id),
                    react_1.default.createElement("p", null,
                        t('Voucher.modal.currentResidualAmount'),
                        ": ",
                        (0, formatPrice_1.formatPrice)(objectInfo.object.currentResidualAmount)),
                    react_1.default.createElement("p", null,
                        t('Voucher.modal.voucherValue'),
                        ": ",
                        (0, formatPrice_1.formatPrice)(objectInfo.object.voucherValue))),
                react_1.default.createElement("div", { className: "column" },
                    react_1.default.createElement("p", null, t('Voucher.modal.buyingCustomer')),
                    react_1.default.createElement("p", null,
                        t('BookingList.detailsModal.title'),
                        ": ",
                        objectInfo.bookingShortId),
                    react_1.default.createElement("p", null,
                        t('Entity.Booking.customer'),
                        ": ",
                        objectInfo.customerName)))),
        react_1.default.createElement(aurum_1.Body, null,
            react_1.default.createElement(aurum_1.Table, null,
                react_1.default.createElement("thead", null,
                    react_1.default.createElement("tr", null,
                        react_1.default.createElement("td", null, t('Voucher.modal.redemptionAt')),
                        react_1.default.createElement("td", null, t('Voucher.modal.redeemedValue')),
                        react_1.default.createElement("td", null, t('EntityAttribute.paymentType')),
                        react_1.default.createElement("td", null, t('EntityAttribute.bookingId')),
                        react_1.default.createElement("td", null, t('Entity.Booking.customer')),
                        react_1.default.createElement("td", null))),
                react_1.default.createElement("tbody", null,
                    historyEmpty,
                    body))),
        react_1.default.createElement(aurum_1.Footer, null,
            react_1.default.createElement(aurum_1.Button, { className: 'btnClose', type: 'error', onClick: function () {
                    setModal(null);
                } }, t(['bookingDetailsModal.close', 'Controls.close'])))));
};
exports.default = (0, react_1.memo)(VoucherListing);
