"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaxModal = void 0;
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var js_1 = require("@samsa/samsa-api/js");
var aurum_1 = require("@mdsi/aurum");
var Form_1 = require("../Form");
var Edit_1 = require("../Edit");
var react_i18next_1 = require("react-i18next");
function TaxModal(props) {
    var _this = this;
    var _a, _b;
    var t = (0, react_i18next_1.useTranslation)().t;
    var entry = props.entry, selected = props.selected, setShowModalTax = props.setShowModalTax, entryReload = props.entryReload, changed = props.changed;
    var _c = tslib_1.__read((0, react_1.useState)(false), 2), savingData = _c[0], setSavingData = _c[1];
    var _d = tslib_1.__read((0, react_1.useState)(true), 2), loading = _d[0], setLoading = _d[1];
    var _e = tslib_1.__read(react_1.default.useState(null), 2), errorData = _e[0], setErrorData = _e[1];
    // @ts-ignore
    var _f = tslib_1.__read((0, react_1.useState)(null), 2), taxKey = _f[0], setTaxKey = _f[1];
    var Type = js_1.FeaturePriceTaxKey;
    var modalHint = t('Entity.FeaturePriceTaxKey');
    if (entry instanceof js_1.FeatureDraft) {
        Type = js_1.FeatureTaxKey;
        modalHint = t('Entity.FeatureTaxKey');
    }
    (0, react_1.useEffect)(function () {
        (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var taxKey, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, Type.get(selected)];
                    case 1:
                        taxKey = _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        e_1 = _a.sent();
                        // Wenn noch keine Steuer hinterlegt ist, wird hier ein leeres Objekt angezeigt, damit
                        // Daten hinterlegt werden können
                        taxKey = {};
                        return [3 /*break*/, 4];
                    case 3:
                        setTaxKey(taxKey);
                        setShowModalTax(true);
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); })();
    }, [selected]);
    return react_1.default.createElement(aurum_1.Modal, { className: 'taxModal', onClickBackdrop: function () { return setShowModalTax(false); } },
        react_1.default.createElement(aurum_1.Header, null,
            react_1.default.createElement("h2", null, (_b = (_a = entry === null || entry === void 0 ? void 0 : entry.localizations) === null || _a === void 0 ? void 0 : _a.de) === null || _b === void 0 ? void 0 : _b.title),
            react_1.default.createElement("p", null, modalHint)),
        react_1.default.createElement(aurum_1.Body, null, loading ? react_1.default.createElement(aurum_1.Progress, null)
            : react_1.default.createElement(Edit_1.EntryContext.Provider, { value: taxKey },
                react_1.default.createElement(Form_1.Form, { key: 'main', t: t, errorData: errorData, onChange: setTaxKey, Type: Type, initialValue: taxKey, entryReload: entryReload, value: taxKey }))),
        react_1.default.createElement(aurum_1.Footer, null, savingData ? react_1.default.createElement(aurum_1.Progress, null)
            : react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement("span", null),
                react_1.default.createElement(aurum_1.Button, { align: 'left', onSave: true, type: changed ? 'primary' : null, onClick: function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var tk, e_2, error;
                        var _a;
                        return tslib_1.__generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    setSavingData(true);
                                    _b.label = 1;
                                case 1:
                                    _b.trys.push([1, 3, 4, 5]);
                                    tk = tslib_1.__assign(tslib_1.__assign({}, taxKey), { id: selected });
                                    return [4 /*yield*/, Type.save(tk)];
                                case 2:
                                    _b.sent();
                                    setShowModalTax(false);
                                    return [3 /*break*/, 5];
                                case 3:
                                    e_2 = _b.sent();
                                    error = (_a = e_2.response) === null || _a === void 0 ? void 0 : _a.data;
                                    if ((error === null || error === void 0 ? void 0 : error.fieldErrors) || (error === null || error === void 0 ? void 0 : error.reason)) {
                                        setErrorData(tslib_1.__assign(tslib_1.__assign({}, error), { showModal: true }));
                                    }
                                    else {
                                        setErrorData({ reason: e_2.message, showModal: true });
                                    }
                                    return [3 /*break*/, 5];
                                case 4:
                                    setSavingData(false);
                                    return [7 /*endfinally*/];
                                case 5: return [2 /*return*/];
                            }
                        });
                    }); } },
                    react_1.default.createElement(aurum_1.Icon, { type: 'save' }),
                    t('Edit.action.save')),
                react_1.default.createElement(aurum_1.Button, { type: 'error', onClick: function () { return setShowModalTax(false); } }, t('Controls.close')))));
}
exports.TaxModal = TaxModal;
