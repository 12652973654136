"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHasPermission = void 0;
var react_1 = require("react");
var App_1 = require("../component/App");
var useHasPermission = function (permission) {
    var accountRole = (0, react_1.useContext)(App_1.AccountRoleContext);
    return (0, react_1.useMemo)(function () {
        var _a;
        if ((Array.isArray(permission) && !permission.length)) {
            return true;
        }
        if (!accountRole) {
            return false;
        }
        var permissions = (_a = accountRole.permissions) === null || _a === void 0 ? void 0 : _a.map(function (p) { return p.id; });
        if (!(permissions === null || permissions === void 0 ? void 0 : permissions.length)) {
            return false;
        }
        if (!Array.isArray(permission)) {
            return permissions.includes(permission);
        }
        return permission.every(function (p) { return permissions.includes(p); });
    }, [accountRole, permission]);
};
exports.useHasPermission = useHasPermission;
