"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.finalizeMenu = void 0;
var tslib_1 = require("tslib");
var samsa = tslib_1.__importStar(require("@samsa/samsa-api/js"));
var LeftMenu_1 = require("../component/config/LeftMenu");
var isMasterData_1 = require("./isMasterData");
var buildMenu = function (children, parent) {
    if (parent === void 0) { parent = null; }
    return children.map(function (e) {
        var _a;
        var isArray = Array.isArray(e);
        var label = isArray ? e[0] : e;
        var icon = isArray && !Array.isArray(e[1]) ? e[1] : null;
        var children = isArray && Array.isArray(e[1]) ? buildMenu(e[1], label) : null;
        var dependencies = [];
        if (children) {
            children.forEach(function (c) {
                dependencies.push.apply(dependencies, tslib_1.__spreadArray([c.label], tslib_1.__read(c.dependencies), false));
            });
        }
        var labelParts = label === null || label === void 0 ? void 0 : label.split('--');
        var disabled = ((_a = label === null || label === void 0 ? void 0 : label.split('--')) === null || _a === void 0 ? void 0 : _a.length) > 1;
        return {
            label: disabled ? labelParts[1] : label,
            icon: icon,
            children: children,
            dependencies: dependencies,
            parent: parent,
            disabled: disabled
        };
    });
};
var calcLeaves = function (children) {
    var arr = [];
    children.forEach(function (e) {
        var isArray = Array.isArray(e);
        var label = isArray ? e[0] : e;
        var leaves = isArray && Array.isArray(e[1]) ? calcLeaves(e[1]) : null;
        arr.push.apply(arr, tslib_1.__spreadArray([label], tslib_1.__read(leaves || []), false));
    });
    return arr;
};
var finalizeMenu = function (token) {
    var rawMenu = (0, LeftMenu_1.getLeftMenu)(samsa, token);
    // const masterDataObj = rawMenu.find((e) => e[0] === 'masterData');
    // if (!masterDataObj) {
    //   console.error('Can\'t find element \'masterData\' in menu structure!');
    //   return;
    // }
    //
    // let masterDataList = masterDataObj[1] ?? [];
    // if (!masterDataList || !Array.isArray(masterDataList)) {
    //   masterDataList = [masterDataList];
    // }
    //
    // const obj = ['other', []];
    // let objAdded = false;
    //
    // const masterData = Object.values(samsa)
    //   .filter((Type: any) => isMasterData(Type) && Type.name !== 'MasterData')
    //   .map((e) => e.name).sort((a, b) => a < b ? -1 : 1);
    // const leaves = calcLeaves(LeftMenu);
    // const missingEntries = [];
    // masterData.forEach((e) => {
    //   if (!leaves.includes(e)) {
    //     // @ts-ignore
    //     obj[1].push(e);
    //     missingEntries.push(e);
    //
    //     if (!objAdded) {
    //       masterDataList.push(obj);
    //       objAdded = true;
    //     }
    //   }
    // });
    //
    // if (missingEntries.length) {
    //   console.warn('Entries missing in menu:', missingEntries);
    // }
    var builtMenu = buildMenu(rawMenu);
    // console.log('menu', builtMenu);
    var isMasterData2 = function (Type) {
        return !!((Type === null || Type === void 0 ? void 0 : Type.name) &&
            (Type === null || Type === void 0 ? void 0 : Type.get) &&
            (Type === null || Type === void 0 ? void 0 : Type.getAll) &&
            ((Type === null || Type === void 0 ? void 0 : Type.save) || (Type === null || Type === void 0 ? void 0 : Type.delete)));
    };
    var isElementValid = function (element) {
        if (!element.children) {
            return !(isMasterData2(samsa[element.label]) && !(0, isMasterData_1.isMasterData)(samsa[element.label]));
        }
        else {
            element.children = element.children.filter(isElementValid);
            return element.children.length > 0;
        }
    };
    var filteredMenu = builtMenu.filter(isElementValid);
    // console.log('filteredMenu', filteredMenu);
    return filteredMenu;
};
exports.finalizeMenu = finalizeMenu;
