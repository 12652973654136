"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorBound = void 0;
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var ErrorBound = /** @class */ (function (_super) {
    tslib_1.__extends(ErrorBound, _super);
    function ErrorBound(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { hasError: false };
        _this.reset = _this.reset.bind(_this);
        return _this;
    }
    ErrorBound.getDerivedStateFromError = function (error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error };
    };
    ErrorBound.prototype.componentDidCatch = function (error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error('ERROR', error, errorInfo);
    };
    ErrorBound.prototype.reset = function () {
        this.setState({ hasError: false, error: null });
    };
    ErrorBound.prototype.render = function () {
        var _a, _b;
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return aurum_1.React.createElement(aurum_1.Sheet, { className: 'ErrorBound' },
                aurum_1.React.createElement(aurum_1.Alert, { type: 'error' },
                    aurum_1.React.createElement("div", { className: 'alert-header' },
                        aurum_1.React.createElement("h2", null, (_a = this.state.error) === null || _a === void 0 ? void 0 : _a.message),
                        aurum_1.React.createElement(aurum_1.Button, { className: 'reset', type: 'error-primary', onClick: this.reset },
                            aurum_1.React.createElement(aurum_1.Icon, { size: 'big', type: 'refresh' }))),
                    aurum_1.React.createElement("pre", null, (_b = this.state.error) === null || _b === void 0 ? void 0 : _b.stack)));
        }
        return this.props.children;
    };
    return ErrorBound;
}(aurum_1.React.PureComponent));
exports.ErrorBound = ErrorBound;
