"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateToPaymentCriteria = void 0;
var translateToPaymentCriteria = function (data) { return ({
    customerIds: data.selectedCustomers,
    from: data.from,
    to: data.to,
    fetchDateType: data.fetchDateType,
    bookingShortIds: [data.bookingShortId]
}); };
exports.translateToPaymentCriteria = translateToPaymentCriteria;
