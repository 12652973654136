"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var js_1 = require("@samsa/samsa-api/js");
var react_1 = require("react");
var useAsync_1 = require("../hook/useAsync");
var DeleteAccount = function () {
    var _a, _b;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _c = tslib_1.__read(aurum_1.React.useState(false), 2), deleteAccount = _c[0], setDeleteAccount = _c[1];
    var _d = tslib_1.__read(aurum_1.React.useState(''), 2), email = _d[0], setEmail = _d[1];
    var _e = tslib_1.__read((0, react_1.useState)(), 2), alert = _e[0], setAlert = _e[1];
    (0, useAsync_1.useAsync)(function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
        var e_1, error;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return tslib_1.__generator(this, function (_l) {
            switch (_l.label) {
                case 0:
                    if (!deleteAccount) return [3 /*break*/, 5];
                    _l.label = 1;
                case 1:
                    _l.trys.push([1, 3, , 4]);
                    setAlert({ type: 'info', label: 'Account wird gelöscht..' });
                    return [4 /*yield*/, (0, js_1.deleteAccountByEmail)(email)];
                case 2:
                    _l.sent();
                    setAlert({ type: 'success', label: 'Account wurde erfolgreich gelöscht' });
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _l.sent();
                    error = (_k = (_j = (_f = (_c = (_b = (_a = e_1 === null || e_1 === void 0 ? void 0 : e_1.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : (_e = (_d = e_1 === null || e_1 === void 0 ? void 0 : e_1.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.REASON) !== null && _f !== void 0 ? _f : (_h = (_g = e_1 === null || e_1 === void 0 ? void 0 : e_1.response) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.error_description) !== null && _j !== void 0 ? _j : e_1 === null || e_1 === void 0 ? void 0 : e_1.message) !== null && _k !== void 0 ? _k : 'An error occured';
                    setAlert({ label: error });
                    return [3 /*break*/, 4];
                case 4:
                    setDeleteAccount(false);
                    _l.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); }, [deleteAccount]);
    return aurum_1.React.createElement(aurum_1.Sheet, { className: 'login', onKeyDown: function (e) { return e.key === 'Enter'
            ? setDeleteAccount(true)
            : null; } },
        aurum_1.React.createElement(aurum_1.Row, null,
            aurum_1.React.createElement(aurum_1.Input, { autoFocus: true, type: 'text', label: t('EntityAttribute.email'), value: email, onChange: function (e) {
                    setEmail(e.target.value);
                } })),
        alert
            ? aurum_1.React.createElement(aurum_1.Row, null,
                aurum_1.React.createElement(aurum_1.Alert, { type: (_a = alert.type) !== null && _a !== void 0 ? _a : 'error' }, (_b = alert === null || alert === void 0 ? void 0 : alert.label.substring(0, 150)) !== null && _b !== void 0 ? _b : ''))
            : null,
        aurum_1.React.createElement(aurum_1.Row, null,
            aurum_1.React.createElement(aurum_1.Button, { type: 'primary', onClick: function () { return setDeleteAccount(true); } },
                t('EntityAttribute.deleteAccount'),
                aurum_1.React.createElement(aurum_1.Icon, { type: 'navigate_next' }))));
};
exports.default = aurum_1.React.memo(DeleteAccount);
