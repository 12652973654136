"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var js_1 = require("@samsa/samsa-api/js");
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var react_1 = require("react");
var file_saver_1 = require("file-saver");
var useOnChange_1 = require("../hook/useOnChange");
var useSort_1 = require("../hook/useSort");
var useDebounce_1 = require("../hook/useDebounce");
var dateUtils_1 = require("../util/dateUtils");
var tablefields = [
    { name: 'email' },
    { name: 'registrationDate' },
];
var NewsletterReceiverList = function (props) {
    var _a;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _b = tslib_1.__read(aurum_1.React.useState([]), 2), pageSelection = _b[0], setPageSelection = _b[1];
    var size = (0, useOnChange_1.useOnChange)(20);
    var page = (0, useOnChange_1.useOnChange)(0);
    var search = (0, useOnChange_1.useOnChange)('');
    var searchDebounced = (0, useDebounce_1.useDebounce)(search.value);
    var today = new Date();
    var nextDate = new Date(today);
    nextDate.setDate(nextDate.getDate() + 14);
    var _c = tslib_1.__read((0, useSort_1.useSort)('email,desc'), 2), sort = _c[0], toggleSort = _c[1];
    var _d = tslib_1.__read(aurum_1.React.useState(null), 2), list = _d[0], setList = _d[1];
    var _e = tslib_1.__read(aurum_1.React.useState(null), 2), searchString = _e[0], setSearchString = _e[1];
    var _f = tslib_1.__read(aurum_1.React.useState(false), 2), exporting = _f[0], setExporting = _f[1];
    var selectedReceiver = (0, useOnChange_1.useOnChange)();
    function exportNewsletterReceiver() {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, file, fileName;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setExporting(true);
                        return [4 /*yield*/, js_1.NewsletterReceiver.getAll({ size: 0 })];
                    case 1:
                        result = _a.sent();
                        file = new Blob([result.content.map(function (re) { return [re.email, re.registrationDate]; }).join('\n')], { type: 'text/csv;charset=utf-8' });
                        fileName = "newsletter-receiver-".concat((0, dateUtils_1.toLocalISOString)(new Date()), ".csv");
                        return [4 /*yield*/, (0, file_saver_1.saveAs)(file, fileName)];
                    case 2:
                        _a.sent();
                        setExporting(false);
                        return [2 /*return*/];
                }
            });
        });
    }
    (0, react_1.useEffect)(function () {
        setSearchString(searchDebounced);
        page.set(0);
    }, [searchDebounced]);
    var listparams = {
        size: size.value,
        page: page.value,
        search: searchString,
        sort: [sort, 'id,asc']
    };
    var listReload = aurum_1.React.useCallback(function () {
        var cancelToken = (0, js_1.getCancelToken)();
        var promise = (0, js_1.getAllNewsletterReceiver)(listparams, {
            cancelToken: cancelToken.token
        });
        promise.then(function (result) {
            setList(result);
        }).catch(function (e) {
            if (!(0, js_1.isCancel)(e)) {
                console.error('ERROR listReload', e.message, e);
                setList(null);
            }
        });
        return function () {
            cancelToken.cancel();
        };
    }, [JSON.stringify(listparams)]);
    aurum_1.React.useEffect(listReload, [listReload]);
    var _g = tslib_1.__read(aurum_1.React.useState(null), 2), cachedTable = _g[0], setCachedTable = _g[1];
    aurum_1.React.useEffect(function () {
        // console.log('setCachedTable.render()');
        setCachedTable(aurum_1.React.createElement(aurum_1.Table, { className: 'table' },
            aurum_1.React.createElement("thead", null,
                aurum_1.React.createElement("tr", null, tablefields.map(function (prop) {
                    return aurum_1.React.createElement("th", { key: prop.name, onClick: toggleSort(prop.name) },
                        t("EntityAttribute.".concat(prop.name)),
                        sort === "".concat(prop.name, ",asc") ? aurum_1.React.createElement(aurum_1.Icon, { type: 'arrow_drop_up' }) : null,
                        sort === "".concat(prop.name, ",desc") ? aurum_1.React.createElement(aurum_1.Icon, { type: 'arrow_drop_down' }) : null);
                }))),
            aurum_1.React.createElement("tbody", null, !list ? aurum_1.React.createElement("tr", null,
                aurum_1.React.createElement("td", { colSpan: tablefields.length },
                    aurum_1.React.createElement(aurum_1.Progress, null))) : list.content.map(function (entry) {
                // @ts-ignore
                entry.setter = entry.setter || (function () { return selectedReceiver.set(entry.email); });
                var className = null;
                if (entry.email == selectedReceiver.value) {
                    className = 'primary';
                }
                // @ts-ignore
                return aurum_1.React.createElement("tr", { key: entry.email, className: className, onClick: entry.setter },
                    aurum_1.React.createElement("td", { key: 'email' }, entry.email),
                    aurum_1.React.createElement("td", null, entry.registrationDate));
            }))));
    }, [list, selectedReceiver]);
    var totalPages = (_a = list === null || list === void 0 ? void 0 : list.totalPages) !== null && _a !== void 0 ? _a : 1;
    aurum_1.React.useEffect(function () {
        setPageSelection(Array.apply(null, Array(totalPages))
            // eslint-disable-next-line react/no-array-index-key
            .map(function (_v, page) { return aurum_1.React.createElement("option", { key: page, value: page, label: (page + 1) + '/' + totalPages }); }));
    }, [totalPages]);
    return aurum_1.React.createElement("div", { className: 'NewsletterReceiverList' },
        aurum_1.React.createElement(aurum_1.Sheet, { className: 'list' },
            aurum_1.React.createElement("div", { className: 'title' },
                aurum_1.React.createElement("h2", null, t('NewsletterReceiverList.title')),
                !list
                    ? null
                    : aurum_1.React.createElement("div", { className: 'sub' }, list.totalElements === 0
                        ? t('Edit.navigation.paginationNone')
                        : t('Edit.navigation.pagination', {
                            from: list.number * list.size + 1,
                            to: Math.min(list.number * list.size + list.size, list.totalElements),
                            total: list.totalElements
                        }))),
            aurum_1.React.createElement(aurum_1.InputGroup, { className: 'search' },
                aurum_1.React.createElement(aurum_1.Input, { autoFocus: true, type: 'text', label: t('Edit.navigation.search-input'), value: search.inputvalue, onChange: search.onChange }),
                aurum_1.React.createElement(aurum_1.Button, null,
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'search' }),
                    t('Edit.navigation.search-button'))),
            aurum_1.React.createElement(aurum_1.InputGroup, { className: 'navigate' },
                aurum_1.React.createElement(aurum_1.Button, { disabled: list === null || list === void 0 ? void 0 : list.first, onClick: function () { return page.set(0); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'first_page' })),
                aurum_1.React.createElement(aurum_1.Button, { disabled: list === null || list === void 0 ? void 0 : list.first, onClick: function () { return page.set(page.value - 1); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'navigate_before' })),
                aurum_1.React.createElement(aurum_1.Select, { disabled: !(pageSelection === null || pageSelection === void 0 ? void 0 : pageSelection.length), label: t('Edit.navigation.page'), value: (pageSelection === null || pageSelection === void 0 ? void 0 : pageSelection.length) ? list === null || list === void 0 ? void 0 : list.number : '', onChange: page.onChange, options: pageSelection }),
                aurum_1.React.createElement(aurum_1.Button, { disabled: list === null || list === void 0 ? void 0 : list.last, onClick: function () { return page.set(page.value - -1); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'navigate_next' })),
                aurum_1.React.createElement(aurum_1.Button, { disabled: list === null || list === void 0 ? void 0 : list.last, onClick: function () { return page.set((list === null || list === void 0 ? void 0 : list.totalPages) - 1); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'last_page' }))),
            aurum_1.React.createElement(aurum_1.ButtonGroup, { className: 'pagesize' },
                aurum_1.React.createElement(aurum_1.Button, { onClick: function () {
                        size.set(20);
                        page.set(0);
                    }, type: size.value === 20 ? 'primary' : null }, "20"),
                aurum_1.React.createElement(aurum_1.Button, { onClick: function () {
                        size.set(100);
                        page.set(0);
                    }, type: size.value === 100 ? 'primary' : null }, "100")),
            aurum_1.React.createElement(aurum_1.ButtonGroup, null,
                aurum_1.React.createElement(aurum_1.Button, { className: 'reload', onClick: listReload },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'refresh' })),
                aurum_1.React.createElement(aurum_1.Button, { type: 'error', className: 'remove', disabled: selectedReceiver.value == null, onClick: function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, (0, js_1.removeNewsletterReceiver)(selectedReceiver.value)];
                                case 1:
                                    _a.sent();
                                    listReload();
                                    selectedReceiver.set(null);
                                    return [2 /*return*/];
                            }
                        });
                    }); } },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'delete' }))),
            aurum_1.React.createElement(aurum_1.Button, { className: 'expand', disabled: true },
                aurum_1.React.createElement(aurum_1.Icon, { type: 'expand_less' })),
            exporting
                ? aurum_1.React.createElement(aurum_1.Button, { className: 'export' },
                    aurum_1.React.createElement(aurum_1.Progress, null))
                : aurum_1.React.createElement(aurum_1.Button, { className: 'export', onClick: function () { return exportNewsletterReceiver(); } }, "Export"),
            cachedTable));
};
exports.default = aurum_1.React.memo(NewsletterReceiverList);
