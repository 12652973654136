"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAsync = exports.AsyncResultStatus = void 0;
var tslib_1 = require("tslib");
var react_1 = require("react");
var AsyncResultStatus;
(function (AsyncResultStatus) {
    AsyncResultStatus[AsyncResultStatus["SUCCESS"] = 0] = "SUCCESS";
    AsyncResultStatus[AsyncResultStatus["IGNORE"] = 1] = "IGNORE";
    AsyncResultStatus[AsyncResultStatus["FAILURE"] = 2] = "FAILURE";
})(AsyncResultStatus = exports.AsyncResultStatus || (exports.AsyncResultStatus = {}));
var useAsync = function (asyncFunction, deps, runOnInit, reloadOnChange, clearValueOnReload) {
    if (runOnInit === void 0) { runOnInit = true; }
    if (reloadOnChange === void 0) { reloadOnChange = true; }
    if (clearValueOnReload === void 0) { clearValueOnReload = true; }
    var _a = tslib_1.__read((0, react_1.useState)({
        value: null,
        error: null,
        loading: false
    }), 2), result = _a[0], setResult = _a[1];
    var execute = (0, react_1.useCallback)(function () {
        setResult(function (result) { return ({
            value: clearValueOnReload ? null : result.value,
            error: null,
            loading: true
        }); });
        var res = {
            status: AsyncResultStatus.SUCCESS,
            error: undefined
        };
        return asyncFunction(res)
            .then(function (response) {
            if (res.status === AsyncResultStatus.FAILURE || res.error) {
                return setResult({
                    value: null,
                    error: res.error,
                    loading: false
                });
            }
            else if (res.status === AsyncResultStatus.SUCCESS) {
                return setResult({
                    value: response,
                    error: null,
                    loading: false
                });
            }
            return setResult(function (oldValue) { return (tslib_1.__assign(tslib_1.__assign({}, oldValue), { error: null })); });
        })
            .catch(function (error) { return setResult({
            value: null,
            error: error,
            loading: false
        }); });
    }, deps); // Scheiß auf die asyncFunction, wenn die einer zur Laufzeit ändert, hat der Pech gehabt
    (0, react_1.useEffect)(function () {
        if (runOnInit) {
            execute().then();
        }
    }, []); // Absichtlich leer
    (0, react_1.useEffect)(function () {
        if (reloadOnChange) {
            execute().then();
        }
    }, [execute, reloadOnChange]);
    return {
        value: result.value,
        error: result.error,
        loading: result.loading,
        reload: execute
    };
};
exports.useAsync = useAsync;
