"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var js_1 = require("@samsa/samsa-api/js");
var dateUtils_1 = require("../../util/dateUtils");
var formatPrice_1 = require("../../util/formatPrice");
var useOnChange_1 = require("../../hook/useOnChange");
var OwnerPaymentSearchBar_1 = require("./OwnerPaymentSearchBar");
var OwnerInvoiceUtils_1 = require("./OwnerInvoiceUtils");
function PendingOwnerInvoiceList(props) {
    var _this = this;
    var className = 'PendingOwnerInvoice';
    var t = (0, react_i18next_1.useTranslation)().t;
    var fromDate = new Date();
    fromDate.setDate(1);
    var toDate = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0, 23, 59, 59);
    var _a = tslib_1.__read((0, react_1.useState)(null), 2), searchData = _a[0], setSearchData = _a[1];
    var _b = tslib_1.__read((0, react_1.useState)(null), 2), data = _b[0], setData = _b[1];
    var _c = tslib_1.__read((0, react_1.useState)(false), 2), creating = _c[0], setCreating = _c[1];
    var _d = tslib_1.__read((0, react_1.useState)(null), 2), selectedData = _d[0], setSelectedData = _d[1];
    var _e = tslib_1.__read((0, react_1.useState)(null), 2), dataDetails = _e[0], setDataDetails = _e[1];
    var _f = tslib_1.__read((0, react_1.useState)(false), 2), loading = _f[0], setLoading = _f[1];
    var _g = tslib_1.__read((0, react_1.useState)(null), 2), error = _g[0], setError = _g[1];
    var invoiceDate = (0, useOnChange_1.useOnChange)((0, dateUtils_1.toDateString)(new Date(Date.now())));
    // Daten laden
    var loadData = function () {
        if (!searchData) {
            return;
        }
        (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var page, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('loadData');
                        setLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, js_1.PendingOwnerInvoice.getAll({
                                paymentCriteria: (0, OwnerInvoiceUtils_1.translateToPaymentCriteria)(searchData),
                                page: 0,
                                size: 0
                            })];
                    case 2:
                        page = _a.sent();
                        setData(page);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        setData(null);
                        return [3 /*break*/, 4];
                    case 4:
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        }); })();
    };
    (0, react_1.useEffect)(loadData, [searchData]);
    // Details laden
    (0, react_1.useEffect)(function () {
        (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var result, invoiceDateTemp, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!selectedData) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, js_1.PendingOwnerInvoicePosition.getAll({
                                receiverOwnerId: selectedData.receiver.ownerId,
                                paymentCriteria: (0, OwnerInvoiceUtils_1.translateToPaymentCriteria)(searchData),
                                page: 0,
                                size: 20
                            })];
                    case 2:
                        result = _a.sent();
                        setDataDetails(result);
                        invoiceDateTemp = new Date(searchData.to);
                        invoiceDateTemp.setDate(invoiceDateTemp.getDate() + 1);
                        invoiceDate.set((0, dateUtils_1.toDateString)(invoiceDateTemp));
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        console.error('ERROR entryReload', e_2.message, e_2);
                        setDataDetails(null);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        setDataDetails(null);
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        }); })();
    }, [selectedData]); // Was ist mit 'searchData'?
    var resetSelection = function () { return setSelectedData(null); };
    var createInvoice = function () {
        setCreating(true);
        var request = new js_1.OwnerInvoiceRequest(tslib_1.__assign(tslib_1.__assign({}, (0, OwnerInvoiceUtils_1.translateToPaymentCriteria)(searchData)), { positionIdFilter: [], receiverOwner: selectedData.receiver.ownerId, manualInvoiceDate: invoiceDate.value }));
        js_1.OwnerInvoice.create(request, {
            timeout: 120000
        }).then(function (value) {
            console.log('Rechnung wurde erstellt:', value.id);
            setSelectedData(null);
            setCreating(false);
            loadData();
            if (props.onInvoiceCreation) {
                props.onInvoiceCreation(value);
            }
            // TODO hier müsste nun ein Modal mit der PDF angezeigt werden,
            //  in der diese per Mail (default Owner Email Adresse (value.receiver.email))
            //  verschickt oder runtergeladen werden kann.
        }).catch(function (e) {
            setSelectedData(null);
            setCreating(false);
            loadData();
            setError('Fehler bei der Erstellung der Rechnung: ' + e.message);
            console.log('Fehler bei der Erstellung der Rechnung:', e, JSON.stringify(e));
            // TODO Hier müsste eine Fehlermeldung angezeigt werden
        });
    };
    function displayError() {
        return react_1.default.createElement("div", null,
            react_1.default.createElement("p", { className: 'error' }, error));
    }
    function dataTable() {
        return (data === null || data === void 0 ? void 0 : data.numberOfElements) === 0
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            ? noData()
            : react_1.default.createElement(aurum_1.Table, null,
                react_1.default.createElement("thead", null,
                    react_1.default.createElement("tr", null,
                        react_1.default.createElement("th", null, t('EntityAttribute.companyName')),
                        react_1.default.createElement("th", null, t('EntityAttribute.email')),
                        react_1.default.createElement("th", null,
                            t(className + '.total'),
                            react_1.default.createElement("br", null),
                            t('Enum.GROSS')),
                        react_1.default.createElement("th", null,
                            t(className + '.fee'),
                            react_1.default.createElement("br", null),
                            t('Enum.GROSS')),
                        react_1.default.createElement("th", null, t('OwnerConditions.commissionFee')),
                        react_1.default.createElement("th", null, t('OwnerConditions.commissionFeeCalculation')),
                        react_1.default.createElement("th", null,
                            t(className + '.amount'),
                            react_1.default.createElement("br", null),
                            t('Enum.GROSS')))),
                react_1.default.createElement("tbody", null, data === null || data === void 0 ? void 0 : data.content.map(function (value) {
                    var _a;
                    var totalOwner = value.totalGross - value.totalFee;
                    // @ts-ignore
                    value.setter = value.setter || (function () { return setSelectedData(value); });
                    // @ts-ignore
                    return react_1.default.createElement("tr", { key: value.receiver.ownerId, onClick: value.setter },
                        react_1.default.createElement("td", null, value.receiver.companyName),
                        react_1.default.createElement("td", null, value.receiver.email),
                        react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(value.totalGross)),
                        react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(value.totalFee)),
                        react_1.default.createElement("td", null, (_a = value.commissionFee) !== null && _a !== void 0 ? _a : 'n/v'),
                        react_1.default.createElement("td", null, value.commissionFeeCalculation ? t("Enum.".concat(value.commissionFeeCalculation)) : 'n/v'),
                        react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(totalOwner)));
                })));
    }
    // gruppiere die Einzelnen PendingOwnerInvoicePositions nach bookingId, bookingPosId & paymentId zusammen
    var getGroupedPositions = function (ownerPosList) {
        return ownerPosList.content
            .reduce(function (newMap, pos) {
            var key = pos.bookingId.concat('_')
                .concat(pos.bookingPositionId).concat('_')
                .concat(pos.paymentId);
            if (!newMap[key]) {
                newMap[key] = [];
            }
            newMap[key].push(pos);
            return newMap;
        }, {});
    };
    function dataDetailsModal() {
        if (selectedData && dataDetails) {
            var groupedPositions = Object.entries(getGroupedPositions(dataDetails));
            // console.log('dataDetailsModal');
            return react_1.default.createElement(aurum_1.Modal, { className: 'dataDetailsModal', onClickBackdrop: resetSelection },
                react_1.default.createElement(aurum_1.Header, null,
                    react_1.default.createElement("h2", null,
                        t(className + '.dataDetailsModal.title'),
                        ": ", selectedData === null || selectedData === void 0 ? void 0 :
                        selectedData.receiver.companyName),
                    react_1.default.createElement("h3", null,
                        "ACHTUNG: Es werden nur die ersten ",
                        groupedPositions.length,
                        " Positionen angezeigt.")),
                react_1.default.createElement(aurum_1.Body, null,
                    react_1.default.createElement(aurum_1.Table, null,
                        react_1.default.createElement("thead", null,
                            react_1.default.createElement("tr", null,
                                react_1.default.createElement("th", null, t('EntityAttribute.bookingId')),
                                react_1.default.createElement("th", null, t('EntityAttribute.title')),
                                react_1.default.createElement("th", null, t('EntityAttribute.travelTimespan')),
                                react_1.default.createElement("th", null, t('EntityAttribute.bookingDate')),
                                react_1.default.createElement("th", null, t('EntityAttribute.paymentId')),
                                react_1.default.createElement("th", null, t('EntityAttribute.paymentDate')),
                                react_1.default.createElement("th", null,
                                    t(className + '.total'),
                                    react_1.default.createElement("br", null),
                                    t('Enum.GROSS')),
                                react_1.default.createElement("th", null,
                                    t(className + '.fee'),
                                    react_1.default.createElement("br", null),
                                    t('Enum.GROSS')),
                                react_1.default.createElement("th", null,
                                    t(className + '.amount'),
                                    react_1.default.createElement("br", null),
                                    t('Enum.GROSS')))),
                        react_1.default.createElement("tbody", null, groupedPositions.map(function (_a) {
                            var _b = tslib_1.__read(_a, 2), valueList = _b[1];
                            var value = valueList[0];
                            var sumGross = valueList.map(function (pos) { return pos.gross; }).reduce(function (sum, gross) { return sum + gross; });
                            var sumFee = valueList.map(function (pos) { return pos.fee; }).reduce(function (sum, fee) { return sum + fee; });
                            var amountOwner = sumGross - sumFee;
                            var _c = tslib_1.__read((0, dateUtils_1.formatDateFull)(value.bookingDate).split(' '), 2), bookingDate = _c[0], bookingTime = _c[1];
                            return react_1.default.createElement("tr", { key: value.id },
                                react_1.default.createElement("td", null, value.bookingShortId),
                                react_1.default.createElement("td", null, value.featureLocalizations.de.title),
                                react_1.default.createElement("td", null,
                                    (0, dateUtils_1.formatDateShort)(value.travelTimespanFrom),
                                    " -",
                                    react_1.default.createElement("br", null),
                                    (0, dateUtils_1.formatDateShort)(value.travelTimespanTo)),
                                react_1.default.createElement("td", null,
                                    bookingDate,
                                    react_1.default.createElement("br", null),
                                    bookingTime),
                                react_1.default.createElement("td", null, value.paymentShortId),
                                react_1.default.createElement("td", null, (0, dateUtils_1.formatDateFull)(value.paymentDate)),
                                react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(sumGross)),
                                react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(sumFee)),
                                react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(amountOwner)));
                        })))),
                react_1.default.createElement(aurum_1.Footer, null,
                    react_1.default.createElement("span", null),
                    react_1.default.createElement(aurum_1.Button, { className: 'btn-right', type: 'error', onClick: resetSelection }, t(['Controls.close'])),
                    react_1.default.createElement(aurum_1.Button, { disabled: creating || dataDetails.totalElements === 0, className: 'btn-left', onClick: createInvoice },
                        react_1.default.createElement(aurum_1.Icon, { type: 'create' }),
                        t(className + '.create')),
                    react_1.default.createElement(aurum_1.Input, { className: 'input-left', type: 'date', label: t('PendingOwnerInvoice.date'), value: invoiceDate.inputvalue, onChange: invoiceDate.onChange })));
        }
        else {
            return null;
        }
    }
    function noData() {
        return react_1.default.createElement("div", null,
            react_1.default.createElement("p", null, "Es wurden keine Daten gefunden."));
    }
    function failCondition() {
        return react_1.default.createElement("div", null,
            react_1.default.createElement("p", null, "Beim Laden der Daten kam es zu einem Fehler."));
    }
    function progressbar(text) {
        return react_1.default.createElement(aurum_1.Progress, { className: 'table' }, t(text));
    }
    return react_1.default.createElement("div", { className: className },
        dataDetailsModal(),
        react_1.default.createElement(aurum_1.Sheet, { className: 'list' },
            react_1.default.createElement("div", { className: 'title' },
                react_1.default.createElement("h2", null, t('Entity.' + className))),
            react_1.default.createElement(OwnerPaymentSearchBar_1.OwnerPaymentSearchBar, { initialSearchData: {
                    bookingShortId: null,
                    selectedOwners: [],
                    from: null,
                    to: (0, dateUtils_1.toDateString)(toDate),
                    fetchDateType: OwnerPaymentSearchBar_1.fetchDateTypeEnum.PAYMENT_DATE
                }, searchCallback: function (data) {
                    setSearchData(data);
                } }),
            error ? displayError() : null,
            loading
                ? progressbar('Progress.loading')
                : (searchData && data == null
                    ? failCondition()
                    : dataTable())));
}
exports.default = (0, react_1.memo)(PendingOwnerInvoiceList);
