"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var js_1 = require("@samsa/samsa-api/js");
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var useOnChange_1 = require("../hook/useOnChange");
var useAsync_1 = require("../hook/useAsync");
var dateUtils_1 = require("../util/dateUtils");
var formatPrice_1 = require("../util/formatPrice");
var sort = function (a, b) { return Math.sign(new Date(b.month).getTime() - new Date(a.month).getTime()); };
var mapInfoToRow = function (info) {
    return (aurum_1.React.createElement("tr", { key: info.month },
        aurum_1.React.createElement("td", null, (0, dateUtils_1.formatDateMonthPlusYear)(info.month)),
        aurum_1.React.createElement("td", { className: 'align-right' }, (0, formatPrice_1.formatPrice)(info.unexportedSum))));
};
// TODO: Menüpunkt ausblenden oder so, wenn Benutzer die Permission "OWNER_INVOICE_READ" nicht hat?
// Siehe https://app.asana.com/0/1148573263111881/1201726556846430/f
var SumOfUnexportedPositions = function () {
    var t = (0, react_i18next_1.useTranslation)().t;
    var dateFrom = (0, useOnChange_1.useOnChange)(null);
    var dateTo = (0, useOnChange_1.useOnChange)((0, dateUtils_1.getNowAsLocalDateString)());
    var promise = function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, js_1.getSumOfUnexportedPositions)({
                        from: dateFrom.value,
                        to: dateTo.value
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var _a = (0, useAsync_1.useAsync)(promise, [dateFrom.value, dateTo.value], true, false), loading = _a.loading, value = _a.value, error = _a.error, reload = _a.reload;
    var content;
    if (error) {
        content = aurum_1.React.createElement(aurum_1.Alert, { type: 'error' }, error.message);
    }
    else if (loading || !value) {
        content = aurum_1.React.createElement(aurum_1.Progress, null, t('SumOfUnexportedPositions.loading'));
    }
    else {
        var total = value.reduce(function (acc, e) { return acc + e.unexportedSum; }, 0);
        content = (aurum_1.React.createElement(aurum_1.Table, { scrollable: true },
            aurum_1.React.createElement("thead", null,
                aurum_1.React.createElement("tr", null,
                    aurum_1.React.createElement("th", null, t('SumOfUnexportedPositions.month')),
                    aurum_1.React.createElement("th", { className: 'align-right' }, t('SumOfUnexportedPositions.sum')))),
            aurum_1.React.createElement("tbody", null, value.sort(sort).map(mapInfoToRow)),
            aurum_1.React.createElement("tfoot", null,
                aurum_1.React.createElement("tr", null,
                    aurum_1.React.createElement("th", null, t('SumOfUnexportedPositions.total')),
                    aurum_1.React.createElement("th", { className: 'align-right' }, (0, formatPrice_1.formatPrice)(total))))));
    }
    return aurum_1.React.createElement("div", { className: 'SumOfUnexportedPositions' },
        aurum_1.React.createElement(aurum_1.Sheet, { className: 'list' },
            aurum_1.React.createElement("div", { className: 'title' },
                aurum_1.React.createElement("h2", null, t('SumOfUnexportedPositions.title')),
                aurum_1.React.createElement("h3", null,
                    "(",
                    t('SumOfUnexportedPositions.description'),
                    ")")),
            aurum_1.React.createElement("div", { className: 'navigation' },
                aurum_1.React.createElement(aurum_1.InputGroup, null,
                    aurum_1.React.createElement(aurum_1.Input, { autoFocus: true, type: 'date', label: t('SumOfUnexportedPositions.inputs.date-from'), value: dateFrom.inputvalue, onChange: dateFrom.onChange }),
                    aurum_1.React.createElement(aurum_1.Input, { autoFocus: true, type: 'date', label: t('SumOfUnexportedPositions.inputs.date-to'), value: dateTo.inputvalue, onChange: dateTo.onChange })),
                aurum_1.React.createElement(aurum_1.Button, { onClick: reload },
                    aurum_1.React.createElement(aurum_1.Icon, { type: 'search' }),
                    t('Edit.navigation.search-button'))),
            content));
};
exports.default = aurum_1.React.memo(SumOfUnexportedPositions);
