"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var js_1 = require("@samsa/samsa-api/js");
var aurum_1 = require("@mdsi/aurum");
var dateUtils_1 = require("../../util/dateUtils");
var formatPrice_1 = require("../../util/formatPrice");
var PagedSearchTable_1 = require("../channel_manager_protocol/PagedSearchTable");
var useOnChange_1 = require("../../hook/useOnChange");
var getErrorMessage_1 = require("../../util/getErrorMessage");
// import fileDownload from 'js-file-download';
var sleep = function (ms) { return new Promise(function (resolve) { return setTimeout(resolve, ms); }); };
var invoiceDateTypeEnum;
(function (invoiceDateTypeEnum) {
    invoiceDateTypeEnum["CREATED_AT"] = "CREATED_AT";
    invoiceDateTypeEnum["INVOICE_DATE"] = "INVOICE_DATE";
})(invoiceDateTypeEnum || (invoiceDateTypeEnum = {}));
var OwnerInvoiceList = function (_a) {
    var _b;
    var _c = _a.preselectedInvoice, preselectedInvoice = _c === void 0 ? null : _c;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _d = tslib_1.__read((0, react_1.useState)([]), 2), owners = _d[0], setOwners = _d[1];
    var fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 31);
    var selectedOwners = (0, useOnChange_1.useOnChange)([]);
    var email = (0, useOnChange_1.useOnChange)();
    var from = (0, useOnChange_1.useOnChange)((0, dateUtils_1.toDateString)(fromDate));
    var to = (0, useOnChange_1.useOnChange)((0, dateUtils_1.toDateString)(new Date()));
    var allFetchTypes = [invoiceDateTypeEnum.CREATED_AT, invoiceDateTypeEnum.INVOICE_DATE];
    var fetchDateType = (0, useOnChange_1.useOnChange)(invoiceDateTypeEnum.CREATED_AT);
    var selectedData = (0, useOnChange_1.useOnChange)(null);
    // const dataDetails = useOnChange<OwnerInvoicePositionBookingInfo[]>(null);
    var _e = tslib_1.__read((0, react_1.useState)(null), 2), pdf = _e[0], setPdf = _e[1];
    var _f = tslib_1.__read((0, react_1.useState)('send'), 2), emailSent = _f[0], setEmailSent = _f[1];
    var _g = tslib_1.__read((0, react_1.useState)(null), 2), sepaError = _g[0], setSepaError = _g[1];
    var _h = tslib_1.__read((0, react_1.useState)(null), 2), dataDetails = _h[0], setDataDetails = _h[1];
    var _j = tslib_1.__read((0, react_1.useState)(null), 2), dataDetailsLoading = _j[0], setDataDetailsLoading = _j[1];
    (0, react_1.useEffect)(function () {
        var _a;
        var completed = false;
        if ((_a = selectedData.value) === null || _a === void 0 ? void 0 : _a.id) {
            setDataDetailsLoading({});
            setDataDetails(null);
            (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                var page, details, result, completion;
                var _a;
                return tslib_1.__generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            page = 0;
                            details = [];
                            _b.label = 1;
                        case 1:
                            if (!!completed) return [3 /*break*/, 6];
                            return [4 /*yield*/, js_1.OwnerInvoicePosition.getFiltered({
                                    invoiceId: (_a = selectedData.value) === null || _a === void 0 ? void 0 : _a.id,
                                    page: page,
                                    size: 100
                                })];
                        case 2:
                            result = (_b.sent());
                            details.push.apply(details, tslib_1.__spreadArray([], tslib_1.__read(result.content), false));
                            setDataDetails(details.map(function (e) { return e; }));
                            completion = details.length / result.totalElements;
                            setDataDetailsLoading({
                                completion: completion,
                                children: "".concat(details.length, "/").concat(result.totalElements, " geladen")
                            });
                            page++;
                            if (!result.last) return [3 /*break*/, 3];
                            completed = true;
                            return [3 /*break*/, 5];
                        case 3: return [4 /*yield*/, sleep(100)];
                        case 4:
                            _b.sent();
                            _b.label = 5;
                        case 5: return [3 /*break*/, 1];
                        case 6:
                            setDataDetailsLoading(null);
                            completed = true;
                            return [2 /*return*/];
                    }
                });
            }); })();
        }
        return function () {
            setDataDetailsLoading(null);
            completed = true;
        };
    }, [(_b = selectedData.value) === null || _b === void 0 ? void 0 : _b.id]);
    var failCondition = function () { return react_1.default.createElement("div", null,
        react_1.default.createElement("p", null, "Beim Laden der Daten kam es zu einem Fehler. Bitte nehmen Sie Kontakt mit MDSI IT Solutions GmbH auf.")); };
    var loadOwners = (0, react_1.useCallback)(function () {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var r, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, js_1.Owner.getAll({ size: 0 })];
                    case 1:
                        r = _a.sent();
                        setOwners(r.content);
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log('loadOwners error');
                        console.error(e_1);
                        failCondition();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    (0, react_1.useEffect)(loadOwners, [loadOwners]);
    var sendEmail = (0, react_1.useCallback)(function () {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('sendEmail');
                        setEmailSent('sending');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        console.log('sent email', email.value, selectedData.value.id);
                        return [4 /*yield*/, js_1.OwnerInvoice.send({
                                email: { email: email.value },
                                id: selectedData.value.id
                            })];
                    case 2:
                        _a.sent();
                        setEmailSent('sent');
                        console.log('email was sent');
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        setEmailSent('error');
                        console.error('email could not be send', e_2.message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); })();
    }, [selectedData, email.value]);
    var setSelection = function (selectedValue) { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
        var buffer;
        var _a;
        return tslib_1.__generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setSepaError(null);
                    if (!((selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.id) == null)) return [3 /*break*/, 1];
                    selectedData.set(null);
                    setPdf(null);
                    email.set(null);
                    return [3 /*break*/, 3];
                case 1:
                    selectedData.set(selectedValue);
                    return [4 /*yield*/, js_1.OwnerInvoice.getPDF(selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.id, {
                            responseType: 'arraybuffer',
                            timeout: 600000
                        })];
                case 2:
                    buffer = _b.sent();
                    setPdf(buffer);
                    email.set((_a = selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.receiver) === null || _a === void 0 ? void 0 : _a.email);
                    setEmailSent('send');
                    _b.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var resetSelection = function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            setSelection(null);
            return [2 /*return*/];
        });
    }); };
    var downloadSepa = function (window, document) {
        js_1.OwnerInvoice.getSepa(selectedData.value.id, {
            responseType: 'blob'
        })
            .then(function (response) {
            setSepaError(null);
            // @ts-ignore
            var url = window.URL.createObjectURL(new Blob([response]));
            var link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'sepa_rg_' + selectedData.value.shortId + '.xml');
            document.body.appendChild(link);
            link.click();
        }).catch(function (error) {
            (0, getErrorMessage_1.getErrorMessage)(error)
                .then(setSepaError);
        });
    };
    (0, react_1.useEffect)(function () {
        if (preselectedInvoice) {
            // todo übergeben wird ne invoice, wir arbeiten hier aber mit OwnerInvoiceBookingInfo die abgespeckt ist.
            //  Müsste evtl angepasst werden
            // @ts-ignore
            setSelection(preselectedInvoice);
        }
    }, [preselectedInvoice]);
    var dataDetailsModal = function () {
        var _a, _b, _c;
        if (selectedData.value) {
            return react_1.default.createElement(aurum_1.Modal, { className: 'dataDetailsModal', onClickBackdrop: resetSelection },
                react_1.default.createElement(aurum_1.Header, null,
                    react_1.default.createElement(aurum_1.Row, null,
                        react_1.default.createElement("h2", null,
                            t('OwnerInvoice.dataDetailsModal.title'),
                            ": ", (_a = selectedData === null || selectedData === void 0 ? void 0 : selectedData.value) === null || _a === void 0 ? void 0 :
                            _a.receiver.companyName),
                        react_1.default.createElement("p", null, sepaError !== null && sepaError !== void 0 ? sepaError : '')),
                    react_1.default.createElement(aurum_1.Row, null,
                        react_1.default.createElement(aurum_1.InputGroup, { span: 3, className: 'emailSendControl' },
                            react_1.default.createElement(aurum_1.Input, { disabled: emailSent !== 'send', label: t('EntityAttribute.email'), value: email.inputvalue, onChange: email.onChange }),
                            react_1.default.createElement(aurum_1.Button, { disabled: emailSent !== 'send' || !email.value, onClick: sendEmail },
                                react_1.default.createElement(aurum_1.Icon, { type: 'send' }),
                                t('Controls.' + emailSent))),
                        react_1.default.createElement(aurum_1.Button, { span: 1, disabled: ((_b = selectedData === null || selectedData === void 0 ? void 0 : selectedData.value) === null || _b === void 0 ? void 0 : _b.id) == null, onClick: function () { return downloadSepa(window, document); } },
                            react_1.default.createElement(aurum_1.Icon, { type: 'download' }),
                            t(['OwnerInvoice.getSepa'])),
                        dataDetailsLoading ? react_1.default.createElement(aurum_1.Progress, tslib_1.__assign({ span: 2 }, dataDetailsLoading)) : react_1.default.createElement("span", { span: 2 }))),
                react_1.default.createElement(aurum_1.Body, null,
                    react_1.default.createElement(aurum_1.Row, { align: 'start' },
                        pdf ? react_1.default.createElement(aurum_1.Pdf, { data: pdf }) : react_1.default.createElement(aurum_1.Progress, null),
                        react_1.default.createElement(aurum_1.Table, null,
                            react_1.default.createElement("thead", null,
                                react_1.default.createElement("tr", null,
                                    react_1.default.createElement("th", null, t('EntityAttribute.title')),
                                    react_1.default.createElement("th", null, t('EntityAttribute.bookingId')),
                                    react_1.default.createElement("th", null, t('EntityAttribute.bookingDate')),
                                    react_1.default.createElement("th", null, t('EntityAttribute.travelTimespan')),
                                    react_1.default.createElement("th", null,
                                        t('OwnerInvoice.total'),
                                        react_1.default.createElement("br", null),
                                        t('Enum.GROSS')),
                                    react_1.default.createElement("th", null,
                                        t('OwnerInvoice.fee'),
                                        react_1.default.createElement("br", null),
                                        t('Enum.GROSS')),
                                    react_1.default.createElement("th", null,
                                        t('OwnerInvoice.amount'),
                                        react_1.default.createElement("br", null),
                                        t('Enum.GROSS')))),
                            react_1.default.createElement("tbody", null, (_c = dataDetails === null || dataDetails === void 0 ? void 0 : dataDetails.map) === null || _c === void 0 ? void 0 : _c.call(dataDetails, function (valuePosition) {
                                var ownerInvoicePosition = valuePosition.ownerInvoicePosition;
                                var bookingInfo = valuePosition.bookingInfoRef;
                                var feeGross = ownerInvoicePosition.fee;
                                var amountOwner = ownerInvoicePosition.gross - feeGross;
                                var _a = tslib_1.__read((0, dateUtils_1.formatDateFull)(bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.bookingDate).split(' '), 2), bookingDate = _a[0], bookingTime = _a[1];
                                return react_1.default.createElement("tr", { key: ownerInvoicePosition.id },
                                    react_1.default.createElement("td", null, ownerInvoicePosition.localizations.de.title),
                                    react_1.default.createElement("td", null, bookingInfo.bookingShortId),
                                    react_1.default.createElement("td", null,
                                        bookingDate,
                                        react_1.default.createElement("br", null),
                                        bookingTime),
                                    react_1.default.createElement("td", null,
                                        (0, dateUtils_1.formatDateShort)(bookingInfo.travelTimespanFrom),
                                        " -",
                                        react_1.default.createElement("br", null),
                                        (0, dateUtils_1.formatDateShort)(bookingInfo.travelTimespanTo)),
                                    react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(ownerInvoicePosition.gross)),
                                    react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(feeGross)),
                                    react_1.default.createElement("td", null, (0, formatPrice_1.formatPrice)(amountOwner)));
                            }))))),
                react_1.default.createElement(aurum_1.Footer, null,
                    react_1.default.createElement(aurum_1.Button, { className: 'btn-right', type: 'error', onClick: resetSelection }, t(['Controls.close']))));
        }
        else {
            return null;
        }
    };
    var columns = [
        {
            name: 'shortId',
            valueResolver: function (row) { return row.shortId || 'n/v'; },
            title: t('EntityAttribute.id')
        },
        {
            name: 'companyName',
            valueResolver: function (row) { return row.receiver.companyName || 'n/v'; }
        },
        {
            name: 'email',
            valueResolver: function (row) { return row.receiver.email || 'n/v'; }
        },
        {
            name: 'totalGross',
            valueResolver: function (row) { return (0, formatPrice_1.formatPrice)(row.totalGross); },
            title: "".concat(t('OwnerInvoice.total'), " (").concat(t('Enum.GROSS'), ")")
        },
        {
            name: 'totalFeeGross',
            valueResolver: function (row) { return (0, formatPrice_1.formatPrice)(row.totalFee); },
            title: "".concat(t('OwnerInvoice.fee'), " (").concat(t('Enum.GROSS'), ")")
        },
        {
            name: 'ownerPrice',
            valueResolver: function (row) { return (0, formatPrice_1.formatPrice)(row.totalGross - row.totalFee); },
            title: "".concat(t('OwnerInvoice.amount'), " (").concat(t('Enum.GROSS'), ")")
        },
        {
            name: 'invoiceDate',
            valueResolver: function (row) { return (0, dateUtils_1.formatDateYearMonthDay)(row.invoiceDate); },
            title: t('OwnerInvoice.invoiceDate')
        },
        {
            name: 'createdAt',
            valueResolver: function (row) { return (0, dateUtils_1.formatDateFull)(row.createdAt); },
            title: t('OwnerInvoice.createdAt')
        }
    ];
    var onSearch = function (search, page, size, sort) {
        var _a;
        return js_1.OwnerInvoice.getFiltered({
            filterObjectOwnerInvoice: {
                from: from.value,
                to: to.value,
                fetchDateType: fetchDateType.value,
                ownerIds: (_a = selectedOwners.value) !== null && _a !== void 0 ? _a : []
            },
            page: page,
            size: size,
            sort: sort
        });
    };
    var onSelection = function (selectedValue) {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var buffer;
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if ((selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.id) == null) {
                            return [2 /*return*/];
                        }
                        console.log('loadDataDetails');
                        selectedData.set(selectedValue);
                        return [4 /*yield*/, js_1.OwnerInvoice.getPDF(selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.id, {
                                responseType: 'arraybuffer',
                                timeout: 600000
                            })];
                    case 1:
                        buffer = _b.sent();
                        setPdf(buffer);
                        email.set((_a = selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.receiver) === null || _a === void 0 ? void 0 : _a.email);
                        setEmailSent('send');
                        return [2 /*return*/];
                }
            });
        }); })();
    };
    return (react_1.default.createElement(PagedSearchTable_1.PagedSearchTable, { title: 'geschriebene Abrechnungen', columns: columns, onSearch: onSearch, onSelection: onSelection, dependencies: [from.inputvalue, to.inputvalue, fetchDateType.inputvalue, selectedOwners.inputvalue] },
        react_1.default.createElement(aurum_1.InputGroup, { className: 'dateSearchControl' },
            react_1.default.createElement(aurum_1.Input, { type: 'date', label: t('EntityAttribute.fromDate'), value: from.inputvalue, native: false, onChange: from.onChange }),
            react_1.default.createElement(aurum_1.Input, { type: 'date', label: t('EntityAttribute.toDate'), value: to.inputvalue, native: false, onChange: to.onChange }),
            react_1.default.createElement(aurum_1.Input, { type: 'select', label: t('EntityAttribute.fetchDateType'), value: fetchDateType.inputvalue, native: false, options: allFetchTypes.map(function (value) {
                    return react_1.default.createElement("option", { key: value, value: value, label: t('Enum.' + value) });
                }), onChange: fetchDateType.onChange }),
            react_1.default.createElement(aurum_1.Input, { type: 'multiselect', label: t('Entity.Owner'), value: selectedOwners.inputvalue, native: false, options: owners.map(function (value) {
                    return react_1.default.createElement("option", { key: value.id, value: value.id, label: value.companyName });
                }), onChange: selectedOwners.onChange })),
        dataDetailsModal()));
};
exports.default = (0, react_1.memo)(OwnerInvoiceList);
