"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInterval = void 0;
var react_1 = require("react");
var useInterval = function (callback, delay) {
    var savedCallback = (0, react_1.useRef)();
    // Remember the latest callback.
    (0, react_1.useEffect)(function () {
        savedCallback.current = callback;
    }, [callback]);
    // Set up the interval.
    (0, react_1.useEffect)(function () {
        function tick() {
            var _a;
            if ((savedCallback === null || savedCallback === void 0 ? void 0 : savedCallback.current) && typeof savedCallback.current === 'function') {
                // @ts-ignore
                // eslint-disable-next-line no-unused-expressions
                (_a = savedCallback.current) === null || _a === void 0 ? void 0 : _a.call(savedCallback);
            }
        }
        if (delay !== null) {
            var id_1 = setInterval(tick, delay);
            return function () { return clearInterval(id_1); };
        }
        return function () { return null; };
    }, [delay]);
};
exports.useInterval = useInterval;
