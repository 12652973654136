"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasChanged = void 0;
var hasChanged = function (a, b) {
    if (a === b) {
        return false;
    }
    if (a == null && b == null) {
        return false;
    }
    return JSON.stringify(a !== null && a !== void 0 ? a : null) !== JSON.stringify(b !== null && b !== void 0 ? b : null);
};
exports.hasChanged = hasChanged;
