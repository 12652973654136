"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Owner = void 0;
exports.Owner = {
    split: true,
    form: [
        'id',
        'shortId',
        // Partnerdaten
        { name: 'ownerType', title: 'Partnerdaten' },
        'companyName',
        'birthDate',
        'address',
        { name: 'email', title: 'Kontaktdaten' },
        'phone',
        'mobilePhone',
        'defaultEmployeeId',
        // Konto- und rechtliche Daten
        'invoiceInfo',
        { name: 'placesOfBusiness', skipSpacing: true },
        // Einstellungen
        { name: 'defaultCustomerContactPersonRole', title: 'Einstellungen' },
        'defaultEmployeeRole',
        'generateCustomerReceiptInvoice',
        'generateDailyInvoice',
        'noPaymentSplitFeeTax',
        'saveExternalReceiptUrl',
        'syncServiceUrl',
        'mailTemplates',
        'ticketConfig',
        // verknüpfte Partner
        'linkedOwners',
        // Gutschein-Konfiguration
        'voucherConfig',
        // Koordinaten
        // meh
        // Stornobedingungen
        'cancellationConditions',
        // Geschäftsbedingungen
        'tradeTerms'
    ]
};
