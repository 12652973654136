"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var hasChanged_1 = require("../../../util/hasChanged");
var timeZones = {
    '+14:00': '+14',
    '+13:00': '+13',
    '+12:00': '+12',
    '+11:00': '+11',
    '+10:00': '+10',
    '+09:00': '+9',
    '+08:00': '+8',
    '+07:00': '+7',
    '+06:00': '+6',
    '+05:00': '+5',
    '+04:00': '+4',
    '+03:00': '+3',
    '+02:00': '+2',
    '+01:00': '+1',
    Z: 'UTC',
    '-01:00': '-1',
    '-02:00': '-2',
    '-03:00': '-3',
    '-04:00': '-4',
    '-05:00': '-5',
    '-06:00': '-6',
    '-07:00': '-7',
    '-08:00': '-8',
    '-09:00': '-9',
    '-10:00': '-10',
    '-11:00': '-11',
    '-12:00': '-12'
};
var DateTimeFormInput = function (props) {
    var _a, _b, _c, _d, _e;
    var t = (0, react_i18next_1.useTranslation)().t;
    var type = 'date';
    var defaultValue = '';
    var value = (_a = props.value) !== null && _a !== void 0 ? _a : defaultValue;
    var timeZone = null;
    var changed = (0, hasChanged_1.hasChanged)(props.value, props.initialValue);
    var initialValue = (_b = props.initialValue) !== null && _b !== void 0 ? _b : defaultValue;
    var initialTimeZone = null;
    var changedTimeZone = false;
    var useTimeZone = false;
    switch (props.field.format) {
        case 'date':
            type = 'date';
            break;
        case 'time':
            type = 'time';
            break;
        case 'time-with-timezone': {
            type = 'time';
            useTimeZone = true;
            break;
        }
        case 'date-time':
            type = 'datetime'; // TODO: Aurum kennt noch kein 'datetime-local', und 'datetime' ist deprecated
            break;
        case 'date-time-with-timezone': {
            type = 'datetime'; // TODO: Aurum kennt noch kein 'datetime-local', und 'datetime' ist deprecated
            useTimeZone = true;
            break;
        }
    }
    if (useTimeZone) {
        if (value) {
            if ((_c = value.endsWith) === null || _c === void 0 ? void 0 : _c.call(value, 'Z')) {
                timeZone = 'Z';
                value = value.substr(0, value.length - 1);
            }
            else if (['+', '-'].includes(value.substr(value.length - 6, 1))) {
                timeZone = value.substr(value.length - 6, 6);
                value = value.substr(0, value.length - 6);
            }
        }
        if (props.initialValue) {
            if ((_e = (_d = props.initialValue).endsWith) === null || _e === void 0 ? void 0 : _e.call(_d, 'Z')) {
                initialTimeZone = 'Z';
                initialValue = props.initialValue.substr(0, props.initialValue.length - 1);
            }
            else if (['+', '-'].includes(props.initialValue.substr(props.initialValue.length - 6, 1))) {
                initialTimeZone = props.initialValue.substr(props.initialValue.length - 6, 6);
                initialValue = props.initialValue.substr(0, props.initialValue.length - 6);
            }
        }
        changed = (0, hasChanged_1.hasChanged)(value, initialValue);
        changedTimeZone = (0, hasChanged_1.hasChanged)(timeZone, initialTimeZone);
    }
    var classes = [props.field.name];
    if (props.field.form.width === 'full') {
        classes.push('width-full');
    }
    var onChange = aurum_1.React.useCallback(function (_a) {
        var _b;
        var target = _a.target;
        var val = target.value;
        if ((val === null || val === void 0 ? void 0 : val.length) === 16) {
            val += ':00';
        }
        var newValue = [val, timeZone].filter(function (e) { return e != null; }).join('');
        var changed = (0, hasChanged_1.hasChanged)(newValue, props.initialValue);
        // eslint-disable-next-line no-unused-expressions
        (_b = props.onChange) === null || _b === void 0 ? void 0 : _b.call(props, newValue, changed);
    }, [timeZone, props.onChange]);
    var onChangeTimeZone = aurum_1.React.useCallback(function (_a) {
        var _b;
        var target = _a.target;
        var newValue = [value, target.value].filter(function (e) { return e != null; }).join('');
        var changed = (0, hasChanged_1.hasChanged)(newValue, props.initialValue);
        // eslint-disable-next-line no-unused-expressions
        (_b = props.onChange) === null || _b === void 0 ? void 0 : _b.call(props, newValue, changed);
    }, [value, props.onChange]);
    var input = aurum_1.React.createElement(aurum_1.Input, { type: type, label: props.label, value: value, className: classes.join(' '), onChange: onChange, changed: changed, disabled: props.field.readOnly || props.field.form.disabled, optional: props.field.optional, validationMessages: props.errorData });
    if (useTimeZone) {
        return aurum_1.React.createElement(aurum_1.InputGroup, null,
            input,
            aurum_1.React.createElement(aurum_1.Select, { label: t('EntityAttribute.timeZone'), value: timeZone !== null && timeZone !== void 0 ? timeZone : '', disabled: props.field.readOnly || props.field.form.disabled, changed: changedTimeZone, options: Object.entries(timeZones).map(function (_a) {
                    var _b = tslib_1.__read(_a, 2), value = _b[0], label = _b[1];
                    return ({ value: value, label: label });
                }), onChange: onChangeTimeZone }));
    }
    return input;
};
exports.default = aurum_1.React.memo(DateTimeFormInput);
